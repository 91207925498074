import { Component, OnInit, Inject, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms"; 
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {

  formBuilder: any;
  addForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
  ) { }

  ngOnInit() {
    this.addForm = this.formBuilder.group({
      id : [],
      user_name : ['', Validators.required],
    })
  }

}
