import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ConformModelComponent } from 'src/app/pages/patient/conform-model/conform-model.component';
import { AuthService } from 'src/app/services/auth.service';
import { AllocdocComponent } from 'src/app/vedio/allocdoc/allocdoc.component';

@Component({
  selector: 'app-process-completed',
  templateUrl: './process-completed.component.html',
  styleUrls: ['./process-completed.component.css']
})
export class ProcessCompletedComponent implements OnInit {
 
  config:any;

  isPopupOpened=true;
  userData: any;
  full_name: string;
  patient_id: string;
  visits: any;
  modal: any;
  visitslist: any[]=[];
  _id: any;
  response:any;
  doctlist: any;
  searchOptions = {
    docter_id:"",
    patient_id:""
   };
  patientlist: any[];
  constructor(private router: Router,
    public dialog: MatDialog,
  private formBuilder: FormBuilder,
  protected auth: AuthService,
  protected route: ActivatedRoute) {
 
    


    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }



     this.patient_id= sessionStorage.getItem("patient_id");
    this.patientdata();
    this.doctors();
    
   }

  ngOnInit() {
    // this.auth.childremoveData.subscribe(data => {
    //   let ind = this.visitslist.findIndex(patient => patient._id === data._id);
    //   if(ind !== null) {
    //     this.visitslist.splice(ind, 1);
    //   }
    // });




if(this.visits.alloc_status===3){
console.log(this.userData);


}


  }
  patientdata(){

    this.auth.processcompleted().subscribe(res => {
      
      if (res.status == "success") {
     {
     
         this.visits=res.response.reverse(); 


         this.config= {
          itemsPerPage: 15,
          currentPage: 1,
          totalItems: this.visits.count
      
        }
        let obj = {};
        let visits=this.visits;
        const unique = () => {
          let result = [];
          
          visits.forEach((item, i) => {
            obj[item['patient_id']] = i;
          });
          
          for (let key in obj) {
            let index = obj[key];
            result.push(visits[index])
          }
          
          return result;
        }
        
        this.patientlist = unique(); // for example; 
        
        console.log(visits);
        
        //  console.log("32",res.response[0]._id);
        //  this.userData=res.response;

        //  sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //    sessionStorage.setItem("patient_id",this.userData.patient_id);
         
        //    this.userData=JSON.parse(sessionStorage.getItem("userData"));
        //    this.userData= this.userData[0];
        //    console.log(76,this.userData.patient_id)
     
     }}

    },
  
 
    //    () => {
    //   swal("Login failed, Login detils not exits ");
    //  }
     );

   }

  



   pageChanged(event){
    this.config.currentPage = event;
  }
  

   Assign(patient): void {
    
    const dialogRef = this.dialog.open(AllocdocComponent, {
      //  height: '100px',
      //  width: '100px',
       width:'10px',
       panelClass: 'my-dialog-container',
       data :patient
    });
    
    dialogRef.afterClosed().subscribe(result => {
    this.patientdata();
    });


  
   }

   
   needsearch(){

    this.auth.needsearch(this.searchOptions).subscribe(res => {
      
      if (res.status == "success") {
     {
     
         this.visits=res.response.reverse(); 


         this.config= {
          itemsPerPage: 6,
          currentPage: 1,
          totalItems: this.visits.count
      
        }


        //  console.log("32",res.response[0]._id);
        //  this.userData=res.response;

        //  sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //    sessionStorage.setItem("patient_id",this.userData.patient_id);
         
        //    this.userData=JSON.parse(sessionStorage.getItem("userData"));
        //    this.userData= this.userData[0];
        //    console.log(76,this.userData.patient_id)
     
     }}

    },
  
 
    //    () => {
    //   swal("Login failed, Login detils not exits ");
    //  }
     );

   } 



   logo(){
    localStorage.setItem('login','');
    location.reload();

  }

action(patient)
{
  const dialogRef = this.dialog.open(ConformModelComponent, {

     width:'10px',
     panelClass: 'my-dialog-container',
  });
  
  dialogRef.afterClosed().subscribe(result => {
    if(result.sts=='yes')
    {
this.save(patient);
    }
    else{

    }
  });

}

save(patient) {
  //alert(this.alloc_time);

  let arr={patient_id:patient.patient_id,_id:patient._id,alloc_status:3};


  this.auth.allocate(arr).subscribe(res=>{
    if(res.status=="success"){
      if(res.status=='success'){
        this.patientdata();
        var swal;
        swal("Status changed successfully");

      }
    }
  })
}



   doctors(){
    
    this.auth.docBytype({type:1}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         console.log(33,this.doctlist);
      }
    }, (err) => {

     }
     );
}

logout(){
  
  localStorage.clear();

sessionStorage.clear();

this.router.navigate(['/admin-sign'])

 }
}



