import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import 'rxjs';
import { identifierName } from '@angular/compiler';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  presentToast: any;
 
  b:any;
  c:any;

  isLoggedIn: boolean;
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization':sessionStorage.getItem('token')
 });
//  baseUrl: string = 'http://18.206.155.160:4100';
  //  baseUrl: string = 'http://18.220.79.100:4100';
  // baseUrl: string = 'http://192.168.1.61:4100';
  // baseUrl: string = 'http://115.118.240.231:4100';

//  baseUrl: string = 'http://13.232.99.234:4100';
  //  baseUrl: string = 'http://13.233.122.108:4100';

    //  baseUrl: string = 'http://13.232.99.234:4100';
  //baseUrl: string = 'http://13.233.122.108:4200';
   //baseUrl: string = 'https://onehealthforall.org';
   //baseUrl : string = "https://d2d0z8bgditd8n.cloudfront.net"

 //baseUrl: string = 'https://da5a-13-233-122-108.ngrok.io';


baseUrl: string = 'https://13.233.122.108.nip.io'   

//baseUrl: string = 'https://www.meedhukhan.co.in';
    //baseUrl: string = 'http://192.168.1.50:4100';


inde

  NewpatientData: Subject<any> = new Subject();
  
  NewdoctorData: Subject<any> = new Subject();
  NewlabData:Subject<any> = new Subject();
  LabratingData:Subject<any> = new Subject();
  DoctorratingData:Subject<any> = new Subject();
  HospitalratingData:Subject<any> = new Subject();
  childData:Subject<any> = new Subject();
  hosptlregData:Subject<any> = new Subject();
  doctorlistData:Subject<any> = new Subject();
  editpatientData:Subject<any> = new Subject();
  labeditData:Subject<any> = new Subject();
  childremoveData:Subject<any> = new Subject();
  childeditData:Subject<any> = new Subject();
  doctoreditData:Subject<any> = new Subject();
  userToken: any;
  // patienthistoryData:Subject<any> = new Subject();
  constructor( protected http: HttpClient, private router:Router  ){
    let s= localStorage.getItem('login');
    this.isLoggedIn= Boolean(s);
    sessionStorage.setItem('baseUrl',this.baseUrl);
   this. userToken= sessionStorage.getItem("token");

  }
  isAuthenticated(): boolean {
    return this.isLoggedIn;
  }
  login(data): Observable<any> {
    return new Observable(subscribe => {
      // this.http.post(this.baseUrl + '/user/login', data,).subscribe((data: any) => {
        this.http.post(this.baseUrl + '/user/loginType', data).subscribe((data: any) => {
        if(data.status == 'success') {
          localStorage.setItem('login','true');
          let s= localStorage.getItem('login');

          this.isLoggedIn= Boolean(s);
          let token = data.response;
           sessionStorage.setItem('userToken', token);
        }

        subscribe.next(data);
        subscribe.complete();
      }, (err) => {
        subscribe.error(err);
      });
    });
  }
  editaft(data?):Observable<any>{

    return this.http.post(this.baseUrl + '/patient/patientData',data,{ });
  }

  forgotsave(data?):Observable<any>{

    return this.http.post<any>(this.baseUrl + '/user/newPasswordSaveing',data);
  } 


  verify(data?):Observable<any>{

    return this.http.post(this.baseUrl + '/user/missingPassword',data);
  }

  signature(data,id):Observable<any>{
    return this.http.post(this.baseUrl + "/lab/upload?id="+id,data,{ });
  }
  doctorsignature(data,id){
    return this.http.post(this.baseUrl + "/docters/upload?id="+id,data,{ });
  }
  newpatient(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient/create',data);
  }
  //doctor registration
  doctor(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/create',data);
  }
  //add doctors

//lab registrationfi
  lab(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab/create',data);
  }
  //
  patientcheck(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/checkpateint',data,{ });
  }
  doctorvisit(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/create',data,{ });
  }

  patientlist(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/patientlist',data,{ });
  }
  patientsearch(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/tableSearch',data,{ });
  }
  updates(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_visits/create',data,{ });
  }
  labupdate(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_visits/find',data,{ });
  }
  LabHistory(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_visits/lipidProfileResult',data,{ });
  }
  lipidprofile(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_visits/lipidProfileResultView',data,{ });
  }
  doctoredit(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/edit',data,{ });
  }
  getdoctor(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/SearchByDocterId',data,{ });
  }

  editdoc(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/edit',data,{ });
  }
  patientList(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/findById',data,{ });
  }
  labInfo(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/findLabInfo',data,{ });
  }
  editpatient(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient/edit',data,{ });
  }
  labedit(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab/edit',data,{ });
  }
  labrating(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_rating/create',data,{ });
  }
  hospitalrating(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/hospital_rating/create',data,{ });
  }
  doctorrating(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_rating/create',data,{ });
  }
 child(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient_child/create',data,{ });
  }
  // finding data for child
  findchild(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient_child/getingpatientData',data,{ });
  }
  //lab_user find
  
  //hospital registration
  hosptlreg(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/hospital_registation/create',data);
  }
  //patientchild remove
  childremove(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient_child/remove',data,{ });
  }
//lablist data
  lablist(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab/labList',data,{ });
  }
 // patientlist edit details
  childedit(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient_child/edit',data,{ });
  }
  //hospitallist
  hospitalList(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/hospital_registation/hospitalList',data);
  }
  //registration questions
  // quest():Observable<any>
  // {
  //   return this.http.post(this.baseUrl + '/questions/find',"");
  // }
  quest(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/questions/find',data);
  }
  //patientmedicalhistory search
  patienthistory(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/patient_history',data,{ });
  }
  patient_history1(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/patient_history2',data,{ });
  }
  forgot(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/user/forgotPassword',data);
  }
 // image upload totalImages
  uploadImage(data,id):Observable<any>{
    return this.http.post(this.baseUrl + "/patient/upload?id="+id,data,{ });
  }

  totalImages(data):Observable<any>{
    return this.http.post(this.baseUrl + "/patient/totalImages", data);
  }
  // doctor check patient
  checkrest(data) {
    return this.http.post(this.baseUrl + '/docter_visits/checkPatientRestrict',data);
  }
// Doctor List in Patient Details Page
ristlist(data) {
  return this.http.post(this.baseUrl + '/restricted/patientData',data,{ });
}
// Doctor List in Patient Details Page
   docterlist(data):Observable<any>  {
     return this.http.post(this.baseUrl + '/test_report/doctersList',data,{ }); 
  }
  getimages(data) {
    return this.http.post(this.baseUrl + '/patient/totalImages',{patient_id:data});
  }
  getimgdeleate(data){
    return this.http.post(this.baseUrl +'/patient/imageDelete',data,{ });
  }
  labEdit(data) {
     return this.http.post(this.baseUrl + '/lab/searchingForLab',data,{ })
   }
  restrict(data) {
    return this.http.post(this.baseUrl +'/restricted/findAndUpdate',data,{ });
  }
  // sigimages(data) {
  //   return this.http.post(this.baseUrl +'/lab/totalImages',data,{ });
  // }
  sigimages(data) {
    return this.http.post(this.baseUrl +'/lab_user/totalImages',data,{ });
  }
  
  Doctorsigimages(data) {
    return this.http.post(this.baseUrl +'/docters/totalImages',data,{ });
  }
  sigdelet(data) {
    return this.http.post(this.baseUrl +'/lab_user/imageDelete',data,{ });
  }
  // sigdelet(data) {
  //   return this.http.post(this.baseUrl +'/lab/imageDelete',data,{ });
  // }
  docsigdelet(data) {
    return this.http.post(this.baseUrl +'/docters/imageDelete',data,{ });
  }
  testValues(data) {
    return this.http.post(this.baseUrl +'/lab_tests/findData',data,{ });
  }
  updateval(data):Observable<any>
  {
    return this.http.post(this.baseUrl + '/test_report/edittest',data,{ }); 
  }
 readingsave(data):Observable<any>
  {
    return this.http.post(this.baseUrl + '/test_report/create',data,{ }); 
  }
  readingedit(data):Observable<any>
  {
    return this.http.post(this.baseUrl + '/test_report/findById',data,{ }); 
  }
  totalTestNames(data) {
    return this.http.post(this.baseUrl + '/test_list/requireList',data,{ })
  }
  SearchingForChildData(data){
    return this.http.post(this.baseUrl + '/test_report/yearSearch',data,{ })
  }
  doctorVisits(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/create',data,{ });
  }

  lab_tests():Observable<any>
  {
    return this.http.post(this.baseUrl + '/test_list/requireList',""); 
  }
  testbyid(data?):Observable<any>
  {
    return this.http.post(this.baseUrl + '/lab_tests/findData',data,{ }); 
  }

  labhistory(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/test_report/yearSearch',data,{ });
  }
  test_report(data) {
    return this.http.post(this.baseUrl + '/test_report/TestReportData2',data,{ });
  }
  patient_history(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/patient_history',data,{ });
  }
  countryList(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/country',data);
  }

  autofind(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/autoFill/find',data,{ });
  }
  autoCreate(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/autoFill/create',data,{ });
  }
  doctorlist(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/docterNames',data,{ });
  }
  list(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/docterNames',data,{ });
  }

  totalDocters(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/doctersList',data,{ });
  }
  spetiality(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_specialist/doctersSpecialist',data,{ });
  }
  needadoc(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/needAdoctor/create',data,{ });
  }
  findrequestid(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/needAdoctor/find',data,{ });
  }
  // findrequesall():Observable<any>{
  //   return this.http.post(this.baseUrl + '/needAdoctor/findData', { });
  // }

  spetialitybydoc(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/getDoctorsData',data,{ });
  }

  allocate(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/needAdoctor/edit',data,{ });
  }
  notification(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/needAdoctor/listOfdata',data,{ });
  }
  docBytype(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docters/getingDataForType',data,{ });
  }
  needsearch(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/needAdoctor/searchData',data,{ });
  }
  pinlab(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/patient/testByLab',data,{ });
  }
  searchWithDates(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/test_report/searchWithDates1',data,{ });
  }
  viewTestData(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/test_report/viewTestData',data,{ });
  }
  SearchForPatientNames(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/test_report/SearchForPatientNames',data,{ });
  }
  approvesig(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/docter_visits/edit',data,{ });
  }
  //lab-user
  lab_create(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/create',data);
  }
  //lab_user find
  lab_userFind(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/find',data);
  }
  lab_useredit(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/edit',data);
  }
  lab_userremove(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/remove',data,{ });
  }
  lab_userSearch(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/search',data,{ });
  }
  lab_forgotPassword(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/forgotPassword_labuser',data,{ });
  }
  lab_newPassword(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/user/newPasswordSaveing',data,{ });
  }
  //user change password
  lab_user_forgotpassword(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/user_forgotpassword',data,{ });
  }
  //validating otp format (lab_user)
  lab_user_validate_code(data?):Observable<any>{
    return this.http.post(this.baseUrl + '/lab_user/validate_code',data,{ });
  }
  //lab admin (user_create)
lab_admin_userCreate(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/admin_user/create',data,{ });
}
lab_admin_userFind():Observable<any>{
 
  return this.http.post(this.baseUrl + '/admin_user/find', { });
}
lab_admin_userEdit(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/admin_user/Edit',data, { });
}
lab_admin_userRemove(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/admin_user/remove',data, { });
}
// image upload totalImages
Lab_uploadImage(data,id):Observable<any>{
  return this.http.post(this.baseUrl + '/lab/upload',data,{ });
}

Lab_totalImages(data):Observable<any>{
  return this.http.post(this.baseUrl + "/lab/totalImages", data);
}
Lab_getimages(data) {
  return this.http.post(this.baseUrl + '/lab/totalImages',{lab_id:data});
}
Lab_getimgdeleate(data){
  return this.http.post(this.baseUrl +'/lab/imageDelete',data,{ });
}
//request for lab
lab_find(){
  return this.http.post(this.baseUrl +'/lab/find',{ });
}
lab_gst(id){
  return this.http.post(this.baseUrl +'/lab/find',{ _id:id});
}
//approve for lab 
lab_approved(data){
  return this.http.post(this.baseUrl +'/lab_user/approveMail',data,{ });
}

//test prices
test_fee_find(data){
  return this.http.post(this.baseUrl +'/test_fee/find',data,{ });
}
test_fee_edit(data){
  return this.http.post(this.baseUrl +'/test_fee/edit',data,{ });
}
///for process and approved task

patientEdit(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/edit',data,{ });
}
testProcess(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/findData',data,{ });
}
//approve
testApprove(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/findData',data,{ });
}
testUpdate(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/edit',data,{ });
}
testDisapprve(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/edit',data,{ });
}
blocklab_find(data){
  return this.http.post(this.baseUrl +'/lab/findlabid',data);
}
//block-unblock using status
bloc_lab(data){
  return this.http.post(this.baseUrl +'/lab/edit',data);
}
findrequesall():Observable<any>{
  return this.http.post(this.baseUrl + '/needAdoctor/findPendingData', { });
}
processcompleted():Observable<any>{
  return this.http.post(this.baseUrl + '/needAdoctor/findData', { });
}
doc_find(){
  return this.http.post(this.baseUrl+'/doctor_online/findall','')
}
doc_approved(data){
  return this.http.post(this.baseUrl+'/doctor_online/editStatus',data);
}
//lab user sign
signature1(data,id):Observable<any>{
  return this.http.post(this.baseUrl + "/lab_user/upload?id="+id,data,{ });
}
// sigimages1(data) {
//   return this.http.post(this.baseUrl +'/lab_user/totalImages',data,{ });
// }
fileuplod(id,data):Observable<any>{
  return this.http.post(this.baseUrl + "/labdoc/upload?_id="+id,data,{ });
}
//razorpay 
labPayment(data){
  return this.http.post(this.baseUrl+'/lab_payment/create',data);
}
//user gst
lab_user_gst(data){
  return this.http.post(this.baseUrl+'/lab/findGST',data);
}

//lab admin changepassword
labadminpassword(data){
  return this.http.post(this.baseUrl+'/lab/forgotpassword',data);
}

patientpassword(data){
  return this.http.post(this.baseUrl+'/patient/forgotpassword',data);
}



//doctor-online

doc(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/doctor_online/create',data);
}

//doctor side patient search

vaccine(data){
  return this.http.post(this.baseUrl+'/required_vaccines/create',data);
}
vaccine_get(data){
  return this.http.post(this.baseUrl+'/required_vaccines/find',data);
}
vaccine_dose(data){
return this.http.post(this.baseUrl+'/required_vaccines/edit',data);
}
vaccine_edit(data){
  return this.http.post(this.baseUrl+'/required_vaccines/edit_vaccine',data)
}
vaccine_remove(data){
  return this.http.post(this.baseUrl+'/required_vaccines/remove',data)
}
allergycreate(data){
  return this.http.post(this.baseUrl+'/allergies/create',data);
}

allergyfind(data){
  return this.http.post(this.baseUrl+'/allergies/find',data);
}

//dtr pd change
docadminpassword(data){
  return this.http.post(this.baseUrl+'/docters/forgotpassword',data);
}
//dtr profile api
doctorProfile(data){
  return this.http.post(this.baseUrl+'/doctor_online/findDoctor',data);
}

plan(text){
  console.log(text);
  this.c=text;
  console.log(this.c);
  
  }
  plan2(a){
    this.b=a;
    console.log(this.b);
  }

  
logout(){

  //localStorage.setItem('login','');
sessionStorage.setItem('login','');
  //localStorage.clear();
  sessionStorage.clear();
  
 this.router.navigate(['login'])
location.reload()
}

//online sts api

doconlinestatus(data){
  return this.http.post(this.baseUrl+'/doctor_online/findonline',data);
}
doconoffstatus(data){
  return this.http.post(this.baseUrl+'/doctor_online/edit',data);
}

//image upload
fileuplodoc(id,data):Observable<any>{
  return this.http.post(this.baseUrl + "/doctordoc/upload?id="+id,data,{ });
}


fileuplodlabgetimg(id):Observable<any>{
  return this.http.post(this.baseUrl + "/labdoc/totalImages",{_id:id});
}
fileuploddocgetimg(id):Observable<any>{
  return this.http.post(this.baseUrl + "/doctor_online/find_docinfo",{_id:id});
}
//vaccine with dtr name
tablesearch(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/tableSearch2',data,{ });
  
}
doctorlistVacc(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docters/docterNames2',data,{ });
}

//allery with dtr name
tablesearch3(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_visits/tableSearch3',data,{ });
}
doctorlistAller(data?):Observable<any>{
  return this.http.post(this.baseUrl + '/docters/docterNames3',data,{ });
}

editonecharges(data){
  return this.http.post(this.baseUrl + '/doctor_online/edit',data,{ });
 
}

pymntOdreports(){
  return this.http.post(this.baseUrl + '/lab/findPayment_Status',{ });
 
}

onlineconsultationrepo(id):Observable<any>{
  return this.http.post(this.baseUrl + '/needAdoctor/finddocData', {docter_id:id });
}

//reports

processcompleted1():Observable<any>{
  return this.http.post(this.baseUrl + '/docter_fee/find', { });
}

searchwithdates2(id,firstdate,seconddate):Observable<any>{
  return this.http.post(this.baseUrl + '/docter_fee/searchWithDates2', {docter_id:id,firstDate:firstdate,secondDate:seconddate });
}
labReportsWithFilters(){
  return this.http.post(this.baseUrl + '/lab/findPayment_Status1',{ });
 
}

labRegStep2(id,subject,text,status){
  return this.http.post(this.baseUrl + '/lab/finddoc',{ _id:id,subject:subject,text:text,status:status });
}

deleteimagesdoc(image,_id){
  return this.http.post(this.baseUrl +'/doctordoc/deletefile/'+image,{_id:_id});
}
deleteimageslab(image,_id){
  return this.http.post(this.baseUrl +'/labdoc/deletefile/'+image,{_id:_id});
}

createOrder(data){
  //'http://localhost:2099/razorpay' this.baseUrl+'/razorpay/create/orderId'
  return this.http.post(this.baseUrl+'/razorpay/create/orderId',data);
}

signatureVerify(data){
  return this.http.post(this.baseUrl+'/razorpay/api/payment/verify',data);
}
}



// lab_user/forgotPassword_labuser