import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-patient-vaccine',
  templateUrl: './patient-vaccine.component.html',
  styleUrls: ['./patient-vaccine.component.css']
})
export class PatientVaccineComponent implements OnInit {
  [x: string]: any;
  userData: any;
  patient_id: string;
  searchOptions = {
    year:"",
    docter_name:"",
    child_name:""
  };
  visits: any;
  years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  vaccinedata: any;
  config:any;
  constructor(
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    private datePipe: DatePipe,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
  ) { 

    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    console.log(100, this.userData);
    this.patient_id=sessionStorage.getItem("patient_id"); 

    console.log(1001, this.patient_id);

    this.doctorlist();
    this.Search();
    this.list();
  }

  ngOnInit() {

    window.scrollTo(0,0)
    this.patientVaccine();
    
  }
  doctorlist(){
    if(this.docter_name==undefined)
    {
      this.docter_name="";
    }
    this.auth.doctorlistVacc({patient_id:this. patient_id}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
          
        };
         console.log(33,this.doctlist);
         this.list(); 
     }
    }, () => {
     }
     );
   }
  // doctorlist(){
  //   if(this.docter_name==undefined)
  //   {
  //     this.docter_name="";
  //   }
  //   this.auth.doctorlist({patient_id:this. patient_id}).subscribe(res => {
      
  //     if (res.status == "success") {
     
  //        this.doctlist=res.response 
  //        this.config = {
  //         itemsPerPage: 5,
  //         currentPage: 1,
  //         totalItems: this.visits.count
          
  //       };
  //        console.log(33,this.doctlist);
  //        this.list(); 
  //    }
  //   }, () => {
  //    }
  //    );
  //  }
   Search() {
    let date=this.searchOptions.year.split('T');
    let newDate = date[0].split('-');
    let day=newDate[1];
    let month=newDate[2];
    let year=newDate[0];
    this.searchOptions.year=year;

    //  this.auth.patienthistory({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
    //   if (res.status == 'success') {
    //       this.visits = res.response.reverse();
    //       this.config1 = {
    //         itemsPerPage: 5,
    //         currentPage: 1,
    //         totalItems: this.visits.count
    //       };
    //       console.log(39,this.visits);
    //       this.labhistory();
    //   }
    // }, console.log);
    // this.auth.patient_history1({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
      this.auth.tablesearch({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name,docter_name:this.searchOptions.docter_name}).subscribe(res => {

      if (res.status == 'success') {
          // this.visits = res.response[0].docter_visits.reverse();
          this.vaccinedata=res.response.reverse();

          console.log("vcccc===>", this.vaccinedata)
          // this.allergydata=res.response[2].allergies.reverse();
          this.config = {
            itemsPerPage: 5,
            currentPage: 1,
            totalItems: this.vaccinedata.count
            
          };
          console.log(this.visits);
          console.log(this.allergydata);

          // this.config1 = {
          //   itemsPerPage: 10,
          //   currentPage: 1,
          //   totalItems: this.visits.count
            
          // };
         
          console.log(39,this.visits);
          // this.labhistory();
      }
    }, console.log);

  }



list()
{
   // this.labupdateForm .value.docter_id=this.docter_id;
    this.auth.findchild( {patient_id:this.patient_id}).subscribe(res => {
      
      if (res.status == "success") {
        if (res.response != "") {
       this.userData=res.response.reverse();
       this.config = {
        itemsPerPage: 5,
        currentPage: 1,
        totalItems: this.visits.count
        
      };
         // sessionStorage.setItem("full_name",this.name);
         // this.router.navigateByUrl("/patientsearch");
        }
     
      }
    },
       
 
     //   () => {
     //  alert("Login failed, Login detils not exits ");
     // }
     );
   }
   pageChanged(event){
    this.config.currentPage = event;
  }

  logo(){
    this.router.navigate(['/login']);
  }
}
