import { Component, OnInit, Renderer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MzModalService } from 'ngx-materialize';
import { ConformModelComponent } from 'src/app/pages/patient/conform-model/conform-model.component';
import { ViewimageComponent } from 'src/app/pages/viewimage/viewimage.component';
declare var swal;

@Component({
  selector: 'app-pics',
  templateUrl: './pics.component.html',
  styleUrls: ['./pics.component.css']
})
export class PicsComponent implements OnInit {
  result: any;
  imagesurl: any[];
  patient_id: any;
  images: string | Blob;
  userData: any;
  phone_no: string;
  id: any;

  constructor(private router: Router,
    public dialog: MatDialog,
    // private formBuilder: FormBuilder,
    public form_val : FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService,
    private renderer: Renderer) { 
      this.patient_id= sessionStorage.getItem("patient_id");
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.patient_id= sessionStorage.getItem("patient_id");
      this.phone_no= sessionStorage.getItem("phone_no");
      this.id= this.userData._id;
    }

  ngOnInit() {
    window.scrollTo(0,0)
    this.getimg(this.patient_id)

  }
  getimg(patientid) {
    // location.reload();
    console.log('patientid',  this.patient_id)
     this.auth.getimages(patientid).subscribe(res => {
       this.result=res;
       if (this.result.status == "success") {
         this.result=this.result.response;
         this.imagesurl=[];
         for(let obj of this.result[0].imagPath)
         {
           console.log(sessionStorage.getItem('baseUrl')+obj);
           this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
         }
       }
      }, () => {
       swal("Server failed");
 
     });
   }


   selectImage(event){
    if(event.target.files.length>0){
      const file= event.target.files[0];
      this.images = file;
    }
  }
  logout(){
    this.router.navigateByUrl('/login')
  }


   confirmdeleat(id,url,sts): void {
    const dialogRef = this.dialog.open(ConformModelComponent, {
      width: '80px',
      height:'80px',
      data :{'sts':sts}
      
    });
    dialogRef.afterClosed().subscribe(result => {
   
      console.log(result.sts)
      if(result.sts=="yes")
      {
       this.deleteupload(id,url)
      }
   
    //  this.login();
    });
  }
  view(img): void {
    const dialogRef = this.dialog.open(ViewimageComponent, {
      width: '100px',
      data:img

    });
    dialogRef.afterClosed().subscribe(result => {
   
      console.log(result.sts)
     
   
    //  this.login();
    });
  }

  onSubmit(){
    const formData = new FormData();
    formData.append('file', this.images);
    formData.append('id', this.id);
    console.log('patient_id',this.id)
    this.auth.uploadImage(formData, this.id).subscribe(
            (res) =>{
              this.images="";
              swal("The file has been uploaded");
              this.getimg(this.patient_id);
             
              this.images="";

            } ,
            (err) => console.log(err) 
       );
      //  location.reload()
       }



   deleteupload(id,url)
   {
     this.auth.getimgdeleate({_id:id,imagePath:url}).subscribe(res => {
       this.result=res;
       if (this.result.status == "success") {
         this.result=this.result.response;
         this.imagesurl=[];
         this.getimg(this.patient_id);
       }
      }, (err) => {
       swal("Server failed");
 
     });
   }
   logo(){
     
   }

}
