import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {jsPDF} from 'jspdf';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { switchAll } from 'rxjs/operators';
import { MatDialogConfig } from '@angular/material/dialog';
import { UserPaymentDialougeComponent } from '../user-payment-dialouge/user-payment-dialouge.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare var swal;
@Component({
  selector: 'app-lab-userpayment',
  templateUrl: './lab-userpayment.component.html',
  styleUrls: ['./lab-userpayment.component.css']
})
export class LabUserpaymentComponent implements OnInit {
  resultres: any;
  response: any;
  test_fee: number;
  patient_id: string;
  phone_no: string;
  name: string;
  lab_id: any;
  visits: any;
  config:any;
  user_test: any;
  filterresult: any[];
  // filterresult: any[];
  value: any;
   sum: any;
  formatPrice: any;
  totalPrice: number;
  Gst: number;
  AfterGst: number;
  // @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  el: any;
  labData: any;
  lab_name: any;
  payment_status: any;
  fee: any;
  labName: string;
  user_name: any;
  _id: any;
  disabled: boolean;
  lab_phon: any;
  labid: any;
  lab_phone: any;
  lab_phone_number: any;
  lab_user_data: string;
  labAddress: any;
  LaBName: any;
  LabPhone: any;
  labAddressName: any;
obj:any;
  userLabname: any;
 
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
  ) { 
    this.patient_id=sessionStorage.getItem("patient_id");
    this.phone_no=sessionStorage.getItem("phone_no");
    this.name=sessionStorage.getItem("full_name");
    this.lab_id=sessionStorage.getItem('lab_id');
    this.labName=sessionStorage.getItem('lab_name');
    console.log("name",this.labName);

    this.visits="";
    

     this.user_test = JSON.parse(sessionStorage.getItem("lab-userpayment"));
     console.log("patient_data" , this.user_test);
     this._id=this.user_test._id;
   
  }

  ngOnInit() {
   

    

    sessionStorage.getItem("userData");


    console.log("ladAdmin/user" , JSON.parse(sessionStorage.getItem("userData")));



     this.labData=JSON.parse(sessionStorage.getItem("userData"));


      //this.LaBName = this.labData.lab_name;
      //this.LabPhone = this.labData.phone_no;
      // this.labAddressName = this.labData.lab_address;

      // console.log("456666===>", this.labAddressName);
     
      //user  side
    

     this.labid=sessionStorage.getItem("lab_id");

    console.log("98988lab_id======>", this.labid)
 
     
    

    //this.name = sessionStorage.getItem("lab_name");

    console.log("this.labName===>", sessionStorage.getItem("lab_name"))
    this.patientData();
     
      




    this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res=>{ 
      this.resultres = res;

      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].gst;
       this.userLabname = this.resultres.response[0].name;
       this.labAddressName = this.resultres.response[0].address;
       this.LabPhone = this.resultres.response[0].phone_no;
       console.log(132,    this.userLabname );
       
       this.Gst= this.response.gst;
       console.log("testname===>", this.response.gst)
       console.log(this.response);
       this.Gst = this.response.gst;
       sessionStorage.setItem('gst',this.response);

       this.Gst = Number(sessionStorage.getItem("gst"));
       console.log("this.gst=====>", this.Gst)
      //  console.log('45454', this.response);
      }
    })

    
    this.auth.test_fee_find({lab_id:this.labid}).subscribe(res => {
      this.resultres = res;
      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].data;
       //this.response=this.resultres.response[0].data;
        console.log("this.response==>", this.response);

       console.log("testname===>",  this.response.data)
   
       let user_fee=[];
       
         //this.Gst = Number(sessionStorage.getItem("gst"));
       let addPrice = 0;

        for(let obj of this.user_test.test_name){
        console.log("obj", obj)
        var filter_fee = this.response.filter(fee => fee.name === obj.name)
         //console.log("filter_fee", filter_fee .filter(fee => fee.name === obj.name);)

          addPrice = addPrice + Number(filter_fee[0].test_fee);
          console.log("add===>", addPrice)
          user_fee.push({name:obj.name,fee:filter_fee[0].test_fee})
          console.log("add===>", obj.name + "name",filter_fee[0].test_fee)
          //console.log("test feee"+filter_fee[0].test_fee);

        }
     // console.log("addPrice",addPrice);
      this.totalPrice = addPrice;
       this.AfterGst = ((this.totalPrice/100)*this.Gst)+this.totalPrice;


      this.filterresult = user_fee;
        
      // console.log("45454545", this.filterresult);
    ///  console.log("user_fee", user_fee)
      


       }
  

       
  
     
    })
  }
   
    

  
  // add(filterresult){  
  //   this.value=filterresult  
  //   for(let j=0;j<filterresult;j++){  
  //        this.sum+= this.value[j].fee;  
  //        }  
  //       }
  patientData(){
    this.auth.patientList({patient_id:this.patient_id}).subscribe(res => {
      console.log("878787", this.patient_id)
      
        this.resultres = res;
        if( this.resultres .status == "success"){
          
         this.response=this.resultres.response;
       
       
        }
      })
}
payment_Status(obj) : void {
  // document.getElementById("button2").disabled = false;
  (<HTMLInputElement>document.getElementById("button2")).disabled = false;
  //this.router.navigateByUrl('/payment-status');
  // let printContents = document.getElementById(cmpName).innerHTML;
  // let originalContents = document.body.innerHTML;

  // document.body.innerHTML = printContents;


  // document.body.innerHTML = originalContents;
  //   this.router.navigate(['payment-status'], { state: { obj: this.user_test } }     this.userLabname
  // sessionStorage.getItem("lab_name"));

  
  
    const dialogRef = this.dialog.open(UserPaymentDialougeComponent, {
      width: '650px',
      data: {state: { obj: this.user_test }},
       disableClose: true });
    this.auth.patientEdit({lab_id:this.lab_id,test_fee:this.AfterGst,lab_name:this.userLabname,status:1,payment_status:1,_id:this._id}).subscribe(res =>{
      console.log("this.AfterGst", this._id);
     })

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      (<HTMLInputElement>document.getElementById("button1")).disabled = true;
     (<HTMLInputElement>document.getElementById("button3")).disabled = true;
    
    });
 

}
cancel(e){
  localStorage.clear();
  this.router.navigate(['/user-lab-test-update']);
}
cancel1(e){
  localStorage.clear();
  this.router.navigate(['/user-lab-test-update']);
}



disablesecondbutton() {
  // document.getElementById("button1").disabled = true;
  swal({
    title: "Do You Want Print?",
    // text: "Once deleted, you will not be able to recover this imaginary file!",
    // icon: "warning",
    buttons: true,
    confirmButtonText: 'Yes',
 cancelButtonText: "No",
    dangerMode: true,
    
  })
  .then((willDelete) => {
    if (willDelete) {
     window.print();
    } else {
      localStorage.clear();
      
      this.router.navigate(['/Labtestupdate']);
    }
  });
}

logout(){
  this.router.navigate(['/new-lab']);
}

}

