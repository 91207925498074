import { Component, OnInit, Renderer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MzModalService } from 'ngx-materialize';
import { EditpatientComponent } from '../editpatient/editpatient.component';
import { AddpersonComponent } from '../addperson/addperson.component';
import {MatDialogModule, MatDialog} from '@angular/material/dialog';
import { ConformModelComponent } from '../patient/conform-model/conform-model.component';
import { ViewimageComponent } from '../viewimage/viewimage.component';
import { ChangepassComponent } from 'src/app/changepass/changepass.component';
declare var swal;
@Component({
  selector: 'app-medicaldetails',
  templateUrl: './medicaldetails.component.html',
  styleUrls: ['./medicaldetails.component.css']
})
export class MedicaldetailsComponent implements OnInit {
  //router: any;
 
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,public dialog: MatDialog,
    protected route: ActivatedRoute) {}

  ngOnInit() {

    window.scrollTo(0,0)
      
  }
  popup(){
    swal("Feature will be added soon")
  }

  logo(){
    console.log("hellooo")
   //this.router.navigateUrl("/login")

   this.router.navigate(['/login']);



    // login
  }
}
