import { Inject, Optional, ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { ConformModelComponent } from 'src/app/pages/patient/conform-model/conform-model.component';
import { ViewimageComponent } from 'src/app/pages/viewimage/viewimage.component';
import { AuthService } from 'src/app/services/auth.service';

declare var swal;


@Component({
  selector: 'app-lab-sign',
   templateUrl: './lab-sign.component.html',
 styleUrls: ['./lab-sign.component.css']
})
export class LabSignComponent  extends MzBaseModal  implements OnInit {
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  @ViewChild (SignaturePad,{static: false}) public signaturePad: SignaturePad;

  sname:any;
  result: any;
  imagesurl: any[];
  lab_id: any;
  images: string | Blob;
  userData: any;
  phone_no: string;
  id: any;

    constructor(public dialogRef: MatDialogRef<LabSignComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private modalService: MzModalService,
      private fb: FormBuilder,
      private router: Router,
      protected auth: AuthService,
      protected activatedRoute: ActivatedRoute) {


      super();
      this.lab_id= sessionStorage.getItem("lab_id");
      this.id= sessionStorage.getItem("id");

       }
       
   
  
     
       ngOnInit() {
         this.getimg(this.id)
       }
       
  public options: Object = {};

  public _signature: any = null;

  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }

  set signature(value: any) {
    this._signature = value;
    console.log('set signature to ' + this._signature);
    console.log('signature data :');
    console.log(this.signaturePad.toData());
    this.propagateChange(this.signature);
  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png', 0.5);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
  }





       getimg(labid) {
         // location.reload();
          this.auth.sigimages({_id:labid}).subscribe(res => {
            
            this.result=res;
            if (this.result.status == "success") {
              this.result=this.result.response;
              this.imagesurl=[];
              for(let obj of this.result[0].imagPath)
              {
                console.log(sessionStorage.getItem('baseUrl')+obj);
                this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
              }
            }
           }, () => {
            swal("Server failed");
      
          });
        }
     
     
        selectImage(event){
         if(event.target.files.length>0){
           const file= event.target.files[0];
           this.images = file;
         }
       }
       logout(){
         this.router.navigateByUrl('/login')
       }
     
     
        confirmdeleat(id,url): void {
         const dialogRef = this.dialog.open(ConformModelComponent, {
           width: '80px',
           height:'80px'
           
         });
         dialogRef.afterClosed().subscribe(result => {
        
           console.log(result.sts)
           if(result.sts=="yes")
           {
            this.deleteupload(id,url)
           }
        
         //  this.login();
         });
       }
       view(img): void {
         const dialogRef = this.dialog.open(ViewimageComponent, {
           width: '100px',
           data:img
     
         });
         dialogRef.afterClosed().subscribe(result => {
        
           console.log(result.sts)
          
        
         //  this.login();
         });
       }
     
       dataURLToBlob(dataURL) {
        const parts = dataURL.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const raw = window.atob(parts[1]);
        const rawLength = raw.length;
        const uInt8Array = new Uint8Array(rawLength);
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
      }

       onSubmit(){
        if(this.images ==undefined && this.signature ==undefined){
          swal("please add signature");
              }
          else{
          let blob;
              const formData = new FormData();
              console.log("images",this.images);
              if(this.images!=undefined){
                formData.append('file', this.images);
          
              }
              else{
                 blob = this.dataURLToBlob(this.signature);
          
                formData.append('file', blob);
          
              }
        
       
         formData.append('id', this.id);
         this.auth.signature(formData, this.id).subscribe(
        
                 (res) =>{
                   this.images="";
                   swal("Uploaded Succesfully");
                   this.getimg(this.id);
                   this.images="";
     
                 } ,
                 (err) => console.log(err) 
            );
           //  location.reload()
            }
          }
        
     
        deleteupload(id,url)
        {
          this.auth.sigdelet({_id:id,imagePath:url}).subscribe(res => {
            this.result=res;
            if (this.result.status == "success") {
              this.result=this.result.response;
              this.imagesurl=[];
              this.getimg(this.id)

            }
           }, (err) => {
            swal("Server failed");
      
          });
        }
        close()
        {
          this.dialogRef.close();
        }
        drawBegin(){
          
        }
      
     }
     