import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-lab-user',
  templateUrl: './lab-user.component.html',
  styleUrls: ['./lab-user.component.css']
})
export class LabUserComponent implements OnInit {
  relationForm: FormGroup;
  admin_user_phone:any
  isLabAccount: boolean;
  isLabAccount_result: string;
  isActivateDoctor: boolean;
  isActivateDoctor_result: any;
  isProcessPayments: boolean;
  isProcessPayments_result: string;
  isReports: boolean;
  isReports_result: string;
  isFeeSetup: boolean;
  isFeeSetup_result: string;
  isBlockAccount: boolean;
  isBlockAccount_result: string;
  isConnectDoctor: boolean;
  isConnectDoctor_result: string;
  
  user_name:any
  email:any
  password:any

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
  ) {
    this.relationForm = this.formBuilder.group({
      //admin_user_phone: ['', Validators.required,],
      admin_user_phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      password: ['', [Validators.required, Validators.pattern("^(0)?[0-6]{6}$")]],
      // password: ['', Validators.compose([
      //   Validators.required, Validators.minLength(6),])],
      user_name: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      status: [true, Validators.required],
      role: [[]]
    })
    //^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]).{8,32}$
    // //this.lab_user_id = this.relationForm.controls['lab_user_id'];
    // this.relationForm = this.formBuilder.group({
    //   // lab_id: ['',Validators.required],
    //   user_name: ['',Validators.required],
    //   lab_user_id: ['',Validators.required],
    //   email: ['',Validators.compose([
    //     Validators.required,
    //     Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    //   ])],
    //   //phone_no: ['',Validators.required],
    //   password: ['',Validators.required],
    //   status: [true ,Validators.required],
    //    role:[[]]
    // });


    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
  }


  ngOnInit() {
  }
  add() {
    this.auth.lab_admin_userCreate(this.relationForm.value).subscribe(res => {
      if (res.status == "success") {
        // if(res.status=='success'){
        // this.auth.childData.next(res.response);
        // this.dialogRef.close();
        swal("User added successfully");
        // location.reload();

        // }
      }
    }, () => {
      swal("User added, User details not exsits ");
    })
  }
  get relationForm_form() {
    return this.relationForm.controls;
  }

  LabAccount(e) {
    console.log(78888, e.target.checked);
    if (e.target.checked == true) {
      this.isLabAccount = true;
      this.isLabAccount_result = "Activate Lab Account";
      this.relationForm.value.role.push(this.isLabAccount_result);
      // console.log()
    } else {
      this.isLabAccount = false;
      this.isLabAccount_result = "Activate Lab Account";
      this.relationForm.value.role.pop(this.isLabAccount_result);
      this.isLabAccount_result = "";
    }
  }
  ActivateDoctor(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isActivateDoctor = true;
      this.isActivateDoctor_result = "Activate Online Doctor Account";
      this.relationForm.value.role.push(this.isActivateDoctor_result);
      // console.log()
    } else {
      this.isActivateDoctor = false;
      this.isActivateDoctor_result = "Activate Lab Account";
      this.relationForm.value.role.pop(this.isActivateDoctor_result);
      this.isActivateDoctor_result = "";
    }
  }
  ProcessPayments(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isProcessPayments = true;
      this.isProcessPayments_result = "Process Payments";
      this.relationForm.value.role.push(this.isProcessPayments_result);
      // console.log()
    } else {
      this.isProcessPayments = false;
      this.isProcessPayments_result = "Process Payments";
      this.relationForm.value.role.pop(this.isProcessPayments_result);
      this.isProcessPayments_result = "";
    }
  }
  Reports(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isReports= true;
      this.isReports_result = "Reports";
      this.relationForm.value.role.push(this.isReports_result);
      // console.log()
    } else {
      this.isReports = false;
      this.isReports_result = "Reports";
      this.relationForm.value.role.pop(this.isReports_result);
      this.isReports_result = "";
    }
  }
  FeeSetup(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isFeeSetup= true;
      this.isFeeSetup_result = "FeeSetup";
      this.relationForm.value.role.push(this.isFeeSetup_result);
      // console.log()
    } else {
      this.isFeeSetup = false;
      this.isFeeSetup_result = "FeeSetup";
      this.relationForm.value.role.pop(this.isFeeSetup_result);
      this.isFeeSetup_result = "";
    }
  }
  BlockAccount(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isBlockAccount= true;
      this.isBlockAccount_result = "Block Account";
      this.relationForm.value.role.push(this.isBlockAccount_result);
      // console.log()
    } else {
      this.isBlockAccount = false;
      this.isBlockAccount_result = "Block Account";
      this.relationForm.value.role.pop(this.isBlockAccount_result);
      this.isBlockAccount_result = "";
    }
  }
  ConnectDoctor(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isConnectDoctor= true;
      this.isConnectDoctor_result = "Connect Doctor Online";
      this.relationForm.value.role.push(this.isConnectDoctor_result);
      // console.log()
    } else {
      this.isConnectDoctor = false;
      this.isConnectDoctor_result = "Connect Doctor Online";
      this.relationForm.value.role.pop(this.isConnectDoctor_result);
      this.isConnectDoctor_result = "";
    }
  }
  logout(){
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/admin-sign');
  }
}
