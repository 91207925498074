import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators,FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import{ ActivatedRoute, Router} from '@angular/router';
import { DisclaimerComponent } from 'src/app/disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from 'src/app/privacy-policy/privacy-policy.component';
import { TermsServiceComponent } from 'src/app/terms-service/terms-service.component';
import{AuthService} from './../../services/auth.service';
declare var swal: any;
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  validations_form: FormGroup;
  patientData:any;
  patient:any
  docter_name: any;
  age;
  showAge;  
  questlist: any;
  listOfCountries: any;
  email: string;
  userName = '';
  constructor(private fb: FormBuilder,
            protected auth: AuthService,
            protected route: ActivatedRoute,
            private router: Router, 
            public dialog: MatDialog,
            private formBuilder: FormBuilder,
    ) {
      
      this.questions();
      this.countries();

      
    }
      
  ngOnInit() {
    if(sessionStorage.getItem("ptype")=='3'){
        this.createlabForm();
    }
else{

     this.createForm();
    }
  }
  

  openDialog() {
    const dialogRef = this.dialog.open(TermsServiceComponent, {
      panelClass: 'my-custom-dialog-class'
    });
    
  }
  privacyPolicy(){
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      panelClass: 'my-custom-dialog-class'
    });
  }
  openDisclaimar(){
    const dialogRef = this.dialog.open(DisclaimerComponent, {
      panelClass: 'my-custom-dialog-class'
    });
  }
  createForm() {
    this.validations_form = this.formBuilder.group({
     // patient_id: ['', Validators.required],
     full_name: ['',Validators.required],
    date_of_birth: ['',Validators.required],
    email: ['',Validators.compose([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ])],
    address: ['',Validators.required],
    city: ['',Validators.required],
    state:['', Validators.required],
    sex:['',Validators.required],
    check:['',Validators.required],
    // age:['', Validators.compose([
    //   Validators.required,
    //   Validators.maxLength(3),
    //   Validators.minLength(1),
    // ])],
    //age:sessionStorage.setItem("age", this.showAge),
    phone_no:['', Validators.compose([
      Validators.required,
      Validators.maxLength(10),
      Validators.minLength(5),
    ])],
    password:['',Validators.required],
    question:['', Validators.required],
    answer:['', Validators.compose([
      Validators.required,
      Validators.maxLength(20),
      Validators.minLength(4),
    ])],


    country:['',Validators.required],
    pin_no:['', Validators.compose([
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ])],
    insurance: [false]
    });
  }


  createlabForm() {
    this.validations_form = this.fb.group({
     // patient_id: ['', Validators.required],
     full_name: ['',Validators.required],
    date_of_birth: [''],
    email: ['',Validators.compose([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ])],
    address: [''],
    city: ['',],
    state:[''],
    sex:['',Validators.required],
    // age:['', Validators.compose([
    //   Validators.required,
    //   Validators.maxLength(3),
    //   Validators.minLength(1),
    // ])],

   // age:sessionStorage.setItem("age", this.showAge),
   phone_no:['', Validators.compose([
    Validators.required,
    Validators.maxLength(10),
    Validators.minLength(5),
  ])],
    password:['',Validators.required],
    question:[''],
    answer:[''],

    check:['',Validators.required],
    country:['',Validators.required],
    pin_no:['', Validators.compose([
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ])],
    insurance: [false]
    });
  }


  validation_messages = {
    'insurance': [
      // { type: 'required', message: 'upc is required.' },
      { type: 'minlength', message: 'insurence must be at least 1 characters long.' },
      { type: 'maxlength', message: 'insurence cannot be more than 20 characters long.' },
     // { type: 'validUsername', message: 'Your insurence has already been taken.' }
    ],
    'full_name': [
      { type: 'required', message: 'Name is required.' },
      { type: 'minlength', message: 'Name must be at least 1 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    
      'sex': [
        { type: 'required', message: 'Gender is required.' },],

        'email': [
          { type: 'required', message: 'Email id is required.' },
          { type: 'pattern', message: 'Please enter valid email' }],

    'date_of_birth': [
          { type: 'required', message: 'Date of Birth is required.' },
        ],
  
    'address': [
              { type: 'required', message: 'Address  is required.' }],

              'city': [
                { type: 'required', message: 'City  is required.' }],

                'state': [
                   { type: 'required', message: 'State  is required.' }],

                   'country': [
                    { type: 'required', message: 'Country  is required.' }],

                  //  'phone_no': [
                  //   { type: 'required', message: 'Phone no  is required.' }],
                    'phone_no': [
                      { type: 'required', message: 'Patient Id/ User Id  is required.' },
                      { type: 'maxlength', message: 'Patient Id/ User Id cannot be more than 10 digits.' },
                      { type: 'minLength', message: 'Patient Id/ User Id cannot be less than 4 digits.' },
                
                
                    ],

                    'password': [
                      { type: 'required', message: 'Password  is required.' }],
                      'pin_no': [
                        { type: 'required', message: 'pin_no  is required.' },
                        { type: 'maxlength', message: 'pin_no cannot be more than 4 digits.' },
                        { type: 'minLength', message: 'pin_no cannot be less than 4 digits.' },
    
    
                      ],

                      'question'  : [{ type: 'required', message: 'select security question is required.' }],
                      'answer': [
                        { type: 'required', message: 'answer  is required.' },
                        { type: 'maxlength', message: 'answer cannot be more than 20 digits.' },
                        { type: 'minLength', message: 'answer cannot be less than 4 digits.' },
    
    
                      ],
                      'age': [
                        { type: 'required', message: 'age  is required.' },
                        { type: 'maxlength', message: 'age cannot be more than 1 digits.' },
                        { type: 'minLength', message: 'age cannot be less than 3 digits.' },
    
    
                      ],
                  
  };

  regester() {
    if(this.age){
      const convertAge = new Date(this.age);
      
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());

      this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);


      console.log("55555", this.showAge)


    }
  console.log("age====>", this.validations_form.value.date_of_birth)
  console.log("country====>", this.validations_form.value.country)
  console.log("qqqqq====>", this.validations_form.value.question)

  //this.validations_form.value.age= this.showAge;

  //console.log("agedefine======>", this.validations_form.value.age)
 
  if(this.validations_form.value.date_of_birth==undefined ) {
    swal("Please Select Date Of Birth")
  }
  else if(this.validations_form.value.full_name=="" ||this.validations_form.value.full_name== null) {
    swal("Please Enter Full Name")
  }
  else if(this.validations_form.value.sex=="" ||this.validations_form.value.sex== null) {
    swal("Please Select Gender")
  }
  else if(this.validations_form.value.address=="" ||this.validations_form.value.address== null) {
    swal("Please Enter address")
  }
  else if(this.validations_form.value.state=="" ||this.validations_form.value.state== null) {
    swal("Please Enter state")
  }
  else if(this.validations_form.value.city=="" ||this.validations_form.value.city== null) {
    swal("Please Enter city")
  }
  else if(this.validations_form.value.country=="" ||this.validations_form.value.country== null) {
    swal("Please Select Country")
  }
  else if(this.validations_form.value.email=="" ||this.validations_form.value.email== null) {
    swal("Please Enter Email")
  }
  else if(this.validations_form.value.phone_no=="" ||this.validations_form.value.phone_no== null) {
    swal("Please Enter Phone number")
  }
  else if(this.validations_form.value.pin_no=="" ||this.validations_form.value.pin_no== null) {
    swal("Please Enter Pin Number")
  }
  else if(this.validations_form.value.question=="" ||this.validations_form.value.question== null) {
    swal("Please Select Security Question")
  }
  else if(this.validations_form.value.answer=="" ||this.validations_form.value.answer== null) {
    swal("Please Enter Answer")
  }
  else if(this.validations_form.value.password=="" ||this.validations_form.value.password== null)

  swal("Please enter admin password")
  else{
    this.auth.newpatient(this.validations_form.value).subscribe(res=>
      {
      sessionStorage.setItem("email", this.email);
      sessionStorage.setItem("age", this.showAge);
  
      console.log("10000", sessionStorage.getItem("age") )
      if(res.status=="success"){
        
          swal("Patient saved successfully")
          this.router.navigateByUrl('/login');
          this.auth.NewpatientData.next(res.response);
        if(sessionStorage.getItem("ptype")=='3'){
         
          sessionStorage.setItem("ptypepop",'3');
          
         
          
  
        }
        
       
      }
      else{
          
      }
    }, (error)=>{
      console.log(343,error);
      swal("Please Fill The Form");
    })
  }
   
 


}
 
questions(){
  this.auth.quest().subscribe(res => {
    if (res.status == "success") {
       this.questlist=res.response 
    }
  },() => {
  });
 }


countries(){
  this.auth.countryList().subscribe(res => {
    if (res.status == "success") {
       this.listOfCountries=res.response 
       console.log( 333,this.listOfCountries)
    }
  },() => {
  });
 }
 logout(){

 }
 myClick(){
   
 }

 
}
