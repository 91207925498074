import { Component, OnInit, Inject } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditpatientComponent } from '../editpatient/editpatient.component';
declare var swal;
@Component({
  selector: 'app-labedit',
  templateUrl: './labedit.component.html',
  styleUrls: ['./labedit.component.css']
})
export class LabeditComponent extends MzBaseModal  implements OnInit {
  userData: any;
  full_name: string;
  patient_id: string;
  validations_form: any;
  listOfCountries: any;
  questlist: any;
  patient_email: string;
  dfghdgbjfg: string;
  

  constructor(private router: Router, 
    private formBuilder: FormBuilder,
     public dialogRef: MatDialogRef<LabeditComponent>,
    // @Inject(MAT_DIALOG_DATA)
    protected auth: AuthService,
    protected route: ActivatedRoute,) {
    super();
   this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    this.patient_id=sessionStorage.getItem("patient_id");
    this.patient_email = this.userData.email
    console.log("22",this.userData)
    console.log(23, this.patient_email);
    
  //  this.dfghdgbjfg = JSON.parse(sessionStorage.getItem("rtyrtyrtyr"));
  //  console.log(40, this.dfghdgbjfg);
   
  }
  public data: any
  ngOnInit() {
    console.log(45,this.data);


    this.countries();
    this.questions();
    this.validations_form  =  this.formBuilder.group({
      _id:['', Validators.required],
      lab_id:['', Validators.required],
      name: ['', Validators.required],
      phone_no: ['', Validators.required],
      // speciality: ['', Validators.required],
      // labtest_required:new FormControl(false, [Validators.required]),
      address:['', Validators.required],
      city:['', Validators.required],
      state:['', Validators.required],
      country:['', Validators.required],
      question:['',Validators.required],
      answer:['',Validators.required],
      email:this.patient_email

  });

  this.userData.name = sessionStorage.getItem("name")
  this.userData.address = sessionStorage.getItem("address")
  this.userData.city = sessionStorage.getItem("city")
  this.userData.question  = sessionStorage.getItem("question")
  this.userData.state = sessionStorage.getItem("state")
  this.userData.country = sessionStorage.getItem("country")
  this.userData.email = sessionStorage.getItem("email")
  this.userData.answer = sessionStorage.getItem("answer")
  this.validations_form.patchValue(this.userData); 
      console.log(222, this.userData)
  }
  validation_messages = {
 
    'name': [
      { type: 'required', message: 'name is required.' },
      { type: 'minlength', message: 'name must be at least 1 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'lab_phone': [
      { type: 'required', message: 'phone_no is required.' },],
  
    'phone_no': [
        { type: 'required', message: 'phone is required.' }],
    'address': [
          { type: 'required', message: 'address is required.' },
        ],
  
        
        'country': [
          { type: 'required', message: 'country  is required.' }],
  
              'city': [
                { type: 'required', message: 'city  is required.' }],
                'state': [
                   { type: 'required', message: 'state  is required.' }],
                   'question': [
                    { type: 'required', message: 'question  is required.' }],
                    'answer': [
                      { type: 'required', message: 'answer  is required.' }],
                 
                  
  };


  save(){
    // console.log(123,this.labupdateForm.value);
    if(this.validations_form.value.name != '' && this.validations_form.value.address != '' && this.validations_form.value.city != '' && this.validations_form.value.state != ''){
      this.auth.labedit(this.validations_form.value).subscribe(res => {
        if (res.status == 'success') {
         sessionStorage.setItem("userData",JSON.stringify(res.response));

         console.log(99, sessionStorage.getItem("userData"))
        
         this.dialogRef.close(res.response);
         // location.reload();
        swal("Profile has been updated successfully");
       // this.auth.labeditData.next(res.response);
          console.log(39,res);
          location.reload();

         
        }
      }, console.log);
    } else {
      swal("Please fill the required fields")
    }
    
   }
   close(): void {
    this.dialogRef.close(1);
    this.userData = '';
  }
  countries(){
    this.auth.countryList().subscribe(res => {
      if (res.status == "success") {
         this.listOfCountries=res.response ;
         console.log( 333,this.listOfCountries)
      }
    },() => {
    });
   }


   questions(){
    this.auth.quest().subscribe(res => {
      if (res.status == "success") {
         this.questlist=res.response ;
      }
    },() => {
    });
   }

   
}
