import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-sign',
  templateUrl: './admin-sign.component.html',
  styleUrls: ['./admin-sign.component.css']
})
export class AdminSignComponent implements OnInit {

  loginForm: FormGroup;
  relationForm: FormGroup;
  userData: any;
  validationsAdmin_lab: FormGroup;
  validationsUser_lab: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    
  ) { 
    this.validationsAdmin_lab = this.formBuilder.group({
      admin_phone: ['', Validators.required],
      password: ['', Validators.required,],
      //admin_user_phone: ['', Validators.required],
    })
    this.validationsUser_lab = this.formBuilder.group({
      //admin_phone: ['', Validators.required],
      password: ['', Validators.required,],
      admin_user_phone: ['', Validators.required],
    })
   
  }
  
  ngOnInit() {
  }
  // login() {
  //   this.router.navigateByUrl('/admin-log');
  // }
  login(ex,formData) {
    this.auth.login(formData.value).subscribe(res => {
      if (res.status == "success") {
        this.userData=res.response;
          if(ex==1){
            sessionStorage.setItem("userData",JSON.stringify(this.userData));
            sessionStorage.setItem("patient_id",this.userData.patient_id);
            sessionStorage.setItem("full_name",this.userData.full_name);
            sessionStorage.setItem("role",this.userData.role);
            sessionStorage.setItem("phone_no",formData.value.phone_no);
            sessionStorage.setItem("country",this.userData.country);
            sessionStorage.setItem("id",this.userData._id);
            this.router.navigateByUrl('/admin-log');
          }
          if(ex==2) {
            sessionStorage.setItem("userData",JSON.stringify(this.userData));
            sessionStorage.setItem("patient_id",this.userData.patient_id);
            sessionStorage.setItem("admin_user_phone",this.userData.admin_user_phone)
            sessionStorage.setItem("role",this.userData.role);
            sessionStorage.setItem("full_name",this.userData.full_name);
            sessionStorage.setItem("phone_no",formData.value.phone_no);
            sessionStorage.setItem("country",this.userData.country);
            sessionStorage.setItem("id",this.userData._id);
            this.router.navigateByUrl('/user-role');
          }
        
          
         // admin_user_phone
          //  this.router.navigateByUrl('/login');
        
       
        
        
       // location.reload();
      
       // this.route.navigateByUrl('/list');
      }
    })
  }
}

// this.router.navigateByUrl('/admin-log');