import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  activatedRoute:any;

  constructor( private router: Router, private route: ActivatedRoute) { 
    this.route.url.subscribe((url)=>{
      this.activatedRoute = url;
    });
  }
  ngOnInit() {
 
    
  }
  logo(){
    localStorage.setItem('login','');
    location.reload();

  }
  pics()
  {
    this.router.navigate(['pics'])
  .then(() => {
    window.location.reload();
  });
  }
}
