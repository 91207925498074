import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent extends MzBaseModal  implements OnInit{

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  userData: any;
  conformForm:FormGroup;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,) {
    super();
    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    
    
   }

  ngOnInit()  {
  
  }
 
    save(e){
          this.dialogRef.close({sts:"yes"});
        }
    
     close() {
      this.dialogRef.close({sts:"no"});
    }
    
  
// listen for close event after opening the modal

    }

