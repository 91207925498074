import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {
  selectedDay: any;

  constructor() { }
  deleteUser = [
    { key: 'Name1', value: 'Name1', isSelected: true },
    { key: 'Name2', value: 'Name2', isSelected: false },
    { key: 'Name3', value: 'Name1', isSelected: true },
    { key: 'Name4', value: 'Name2', isSelected: false },
    { key: 'Name5', value: 'Name1', isSelected: true },
    { key: 'Name6', value: 'Name2', isSelected: false },
    { key: 'Name7', value: 'Name1', isSelected: true },
    { key: 'Name8', value: 'Name2', isSelected: false },
  ];
  defaultSelecteddeleteUser: string;
  ngOnInit() {
    this.defaultSelecteddeleteUser = this.deleteUser[0].value;
    console.log(this.deleteUser);
  }
  selectChangeHandler (event: any) {
    //update the ui
    this.selectedDay = event.target.value;
  }

}
