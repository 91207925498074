import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import{AuthService} from './../../services/auth.service';
import{ ActivatedRoute, Router} from '@angular/router';
declare var swal:any;
@Component({
  selector: 'app-labrating',
  templateUrl: './labrating.component.html',
  styleUrls: ['./labrating.component.css']
})
export class LabratingComponent implements OnInit {


  labratingForm: FormGroup;
  lablis: any;
  constructor(private fb: FormBuilder,
    private router: Router, 
   
    protected auth: AuthService,
    protected route: ActivatedRoute) { 
      this.hosptlrate();
    }
    
    
  ngOnInit() {
    this.createForm();

    
  }
  createForm() {
    this.labratingForm = this.fb.group({
      lab_name: ['', Validators.required],
      rating: ['',Validators.required],
      comments: ['',Validators.required]
    
});
  }
  save() {
 
    this.auth.labrating(this.labratingForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){
          this.auth.LabratingData.next(res.response);
          this.router.navigateByUrl("/medicaldetails")
        }
      }
    })
    swal("lab rating entered successfully");
  }
  hosptlrate(){

    this.auth.lablist().subscribe(res => {
      
      if (res.status == "success") {
     {
         this.lablis=res.response 
        //  this.list();
     
        
     }}
     
    },
       
 
       () => {
        

     }
     );
   }
   logo(){
     
   }

}
