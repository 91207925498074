import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient-video',
  templateUrl: './patient-video.component.html',
  styleUrls: ['./patient-video.component.css']
})
export class PatientVideoComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  openVi(){
    window.open('./assets/videos/LAB SOFTWARE DEMO.mp4');
    this.ngOnInit()
  }
  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/new-lab');
  };
}
