import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
  import{ ActivatedRoute, Router} from '@angular/router';
  import{AuthService} from './../../services/auth.service';
  declare var swal;
@Component({
  selector: 'app-forgetpsw',
  templateUrl: './forgetpsw.component.html',
  styleUrls: ['./forgetpsw.component.css']
})
export class ForgetpswComponent implements OnInit {
  public validations_form: FormGroup;
  public validations_change:FormGroup;
  resp: any;
  question: any;
  answer: any;
  ansma: string;
  sts: number;
  passma: string;
  stpass: number;
  cpass:any;
  id: any;
  _id: any;
  sw: any;
  
  constructor(private activatedRoute: ActivatedRoute,public route:Router,public auth:AuthService,public formBuilder: FormBuilder) { 

    this.activatedRoute.queryParams.subscribe(params => {
      this.id=params['id'];
   
      console.log("hi test_id",this.id ); // Print the parameter to the console. 
  });
  this.validations_form = formBuilder.group({
    phoneno:['',Validators.required],
    answer:['',Validators.required],
    password:['',Validators.required],
    confirm_password:['',Validators.required],
    
  })
  //   this.validations_form = formBuilder.group({

  //     phoneno: new FormControl('', Validators.compose([
       
  //        Validators.maxLength(20),
  //        Validators.minLength(1),
      
  //       Validators.required
  //     ])),
      
  //     answer: new FormControl('', Validators.compose([
       
  //       Validators.maxLength(20),
  //       Validators.minLength(4),
  //      //Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
  //      Validators.required
  //    ])),

  //    //user_id: ['', Validators.compose([Validators.required])],
  //  //  password: ['', Validators.compose([Validators.required])],
    


  //   password: new FormControl('', Validators.compose([
     
  //      Validators.maxLength(20),
  //      Validators.minLength(1),
    
  //     Validators.required
  //   ])),
    
  //   confirm_password: new FormControl('', Validators.compose([
     
  //     Validators.maxLength(20),
  //     Validators.minLength(6),
  //    //Validators.pattern('^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$'),
  //    Validators.required
  //  ])),

  // });

  }
  ngOnInit() {
    console.log(2200,sessionStorage.getItem("token"))
  
  
  
  }
  validation_messages = {
    'phoneno': [
      { type: 'required', message: 'phone no is required.' },
      { type: 'minlength', message: 'username/phoneno must be at least 1 characters long.' },
      { type: 'maxlength', message: 'username/phoneno cannot be more than 20 characters long.' },
    ],
    'answer': [
      { type: 'required', message: 'answer is required.' },
      { type: 'minlength', message: 'answer must be at least 4 characters long.' },
      { type: 'maxlength', message: 'username/phoneno must be at least 20 characters long.' },


     
    ],
    
    'password': [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'password must be at least 6 characters long.' },
      { type: 'maxlength', message: 'password must be at least 20 characters long.' },
    ],
    'confirm_password': [
      { type: 'required', message: 'confirm_password is required.' },
      { type: 'minlength', message: 'confirm_password must be at least 6 characters long.' },
      { type: 'maxlength', message: 'confirm_password must be at least 20 characters long.' },
    ]
  };

  verify()
  {
    
    let ts;
    if(this.id==1)
    {
      ts={"phone_no":this.validations_form.value.phoneno};
    }
    else if(this.id==2){
      ts={"docter_phone":this.validations_form.value.phoneno};


    }
    else{
      ts={"lab_phone":this.validations_form.value.phoneno};
    }
  
    this.auth.verify(ts).subscribe(res => {
    
      if (res.status == "success") {
        this.resp=res.response[0];
        this.question=this.resp.question;
        this.answer=this.resp.answer;
        this._id=this.resp._id;
        console.log(98888,this._id);

       // this.route.navigateByUrl('/list');
      } else if(res.status =="error") {
 
      }
     }, () => {
   // swal("Login failed, Login detils not exits ");
    });
  }
  
  ans(e)
  {
    console.log(233,this.validations_form.value.answer);
    if(this.answer.toString().trim()!=e)
    
    {
this.ansma="your answer doesn't match";
this.sts=0;

    }
    else{
      this.ansma="";
      this.sts=1;


    }
  }
  conpass(e)
  {
    if(this.validations_form.value.password.toString().trim()!=e)
    {
   this.passma="password doesn't match";
   this.stpass=0;

    }
    else{
      this.passma="";
      this.stpass=1;


    }
  }

  submit() {

    if(this.id==1)
    {
      this.sw={"_id":this._id,"phone_no":this.validations_form.value.phoneno,"password":this.validations_form.value.password};
      this.auth.forgotsave(this.sw).subscribe(res => {
    
        if (res.status == "success") {
          swal("Password Changed Sucessfully");
  
        
  
         this.route.navigateByUrl('/login');
        } else if(res.status =="error") {
   
        }
       }, () => {
     // swal("Login failed, Login detils not exits ");
      });
   
   
   
    }
    else if(this.id==2){
      this.sw={"_id":this._id,"docter_phone":this.validations_form.value.phoneno,"password":this.validations_form.value.password};
      this.auth.forgotsave(this.sw).subscribe(res => {
    
        if (res.status == "success") {
          swal("Password Changed Sucessfully");
  
        
  
         this.route.navigateByUrl('/login2');
        } else if(res.status =="error") {
   
        }
       }, () => {
     // swal("Login failed, Login detils not exits ");
      });

    }
    else if(this.id==3){
      this.sw={"_id":this._id,"lab_phone":this.validations_form.value.phoneno,"password":this.validations_form.value.password};

      this.auth.forgotsave(this.sw).subscribe(res => {
    
        if (res.status == "success") {
          swal("Password Changed Sucessfully");
  
        
  
         this.route.navigateByUrl('/new-lab');
        } else if(res.status =="error") {
   
        }
       }, () => {
     // swal("Login failed, Login detils not exits ");
      });

    }
  
    // this.auth.forgotsave(this.sw).subscribe(res => {
    
    //   if (res.status == "success") {
    //     swal("Password Changed Sucessfully");

      

    //    this.route.navigateByUrl('/new-lab');
    //   } else if(res.status =="error") {
 
    //   }
    //  }, () => {
   
    // });
  
}
  back()
  {
  
  }
  logout(){
    sessionStorage.clear();
    localStorage.clear();
    this.route.navigateByUrl('/new-lab');
  }
}
