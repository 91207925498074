import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hospitalreg',
  templateUrl: './hospitalreg.component.html',
  styleUrls: ['./hospitalreg.component.css']
})
export class HospitalregComponent implements OnInit {
  regesterForm: FormGroup;
  constructor(private fb: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private router: Router ) { }

  ngOnInit() {
    this. createForm();
  }
  createForm() {
    this.regesterForm = this.fb.group({
     // patient_id: ['', Validators.required],
     name: ['',Validators.required],
     email: ['',Validators.required],
     address: ['',Validators.required],
      city: ['',Validators.required],
      state:['',Validators.required],
      phone_no:['',Validators.required],
    });
  }
  regester() {
 
  this.auth.hosptlreg(this.regesterForm.value).subscribe(res=>{
    if(res.status=="success"){
      if(res.status=='success'){
        this.auth.hosptlregData.next(res.response);
        this.router.navigateByUrl('/login');
      }
    }
  })

}
}

