import { Component, OnInit, Inject, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators,FormGroup} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MaterializeAction } from 'angular2-materialize';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MzBaseModal } from 'ngx-materialize';
declare var swal;
@Component({
  selector: 'app-addperson',
  templateUrl: './addperson.component.html',
  styleUrls: ['./addperson.component.css']
})
export class AddpersonComponent extends MzBaseModal  implements OnInit {
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;

  relationForm: FormGroup;
  userData: any;
  patient_id: any;
  phone_no: string;
  age;
  showAge;
  timeDiff: any;
  dateOfBirth: any;
  showAge1: number;
  constructor(private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<AddpersonComponent>,
    @Inject(MAT_DIALOG_DATA)
      protected route: ActivatedRoute,)
   {
    super();
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
   this.patient_id= sessionStorage.getItem("patient_id");
   this.phone_no=sessionStorage.getItem("phone_no"); 
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.relationForm = this.fb.group({
      patient_id: [''],
      phone_no: [''],
      name: ['',Validators.required],
      // age:['',[Validators.required]],
      age:sessionStorage.setItem("age", this.showAge),
      date_of_birth:['',Validators.required],
      gender:['',Validators.required]

});
  }
  add() {
   

    console.log("name",this.relationForm.value.name)
    console.log("gender",this.relationForm.value.gender)

    let  dateOfBirth = this.age
    console.log("", dateOfBirth)
    

    var arr1 = dateOfBirth.split('-');
    
    console.log("", arr1);
    
    var arr2 = arr1[1].split(',');

    console.log("", arr2);


   
console.log('date: ', arr1[0]);

if(this.age){
  var convertAge = new Date(this.age);
  console.log(convertAge)
  var timeDiff = Math.abs(Date.now() - convertAge.getTime());
  console.log(1000, timeDiff)
  this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);


  console.log("55555", this.showAge)
  this.relationForm.value.age = this.showAge;

 } 

if(arr1[0] == new Date().getFullYear()){
  this.showAge1=(new Date().getMonth() + 1 - arr2[0])/10
  console.log("", this.showAge1)
  console.log(2000,this.relationForm.value.age = this.showAge1);
}


console.log('month: ', arr2[0]);
console.log('year: ', arr2[1]);
    // if(this.age){
    //   var convertAge = new Date(this.age);
    //   console.log(convertAge)
    //   var timeDiff = Math.abs(Date.now() - convertAge.getTime());
    //   console.log(1000, timeDiff)
    //   this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);


    //   console.log("55555", this.showAge)

     


    //  } 

    this.relationForm.value.patient_id=this.patient_id;

    console.log(2222,this.relationForm.value.age)
    if(this.relationForm.value.name != '' && this.relationForm.value.age != '') {
    this.auth.child(this.relationForm.value).subscribe(res=>{
      sessionStorage.setItem("age", this.showAge);
      if(res.status=="success"){
        if(res.status=='success'){
          this.auth.childData.next(res.response);
          this.dialogRef.close();
          swal("Dependent added successfully");
        }
      }
    },  () => {
      swal("person added, person details not exsits ");
     })
    } else {
      swal("Please Fill the required fields")
    }
  //window.location.reload();
  
  }
  close(): void {
    this.dialogRef.close();
 }
  

 
}
