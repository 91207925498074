
import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MzBaseModal,MzModalService } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var swal;
@Component({
  selector: 'app-conform-model',
  templateUrl: './conform-model.component.html',
  styleUrls: ['./conform-model.component.css']
})
export class ConformModelComponent extends MzBaseModal  implements OnInit {

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  userData: any;
  conformForm:FormGroup;
  status: any;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<ConformModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected route: ActivatedRoute,) {
    super();
    
    
   }

  ngOnInit()  {
  
    this.status = this.data

    console.log("", this.status);
    

  
  }
 
    save(e){
      console.log("10001",  this.dialogRef.close({sts:"yes"}))
          this.dialogRef.close({sts:"yes"});
       console.log("1000",  this.dialogRef.close({sts:"yes"}))
        }
    
     close() {
      this.dialogRef.close();
    }
    
  
// listen for close event after opening the modal

    }



   