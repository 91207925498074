import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PinModelComponent } from '../pin-model/pin-model.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MzBaseModal } from 'ngx-materialize';

@Component({
  selector: 'app-viewimage',
  templateUrl: './viewimage.component.html',
  styleUrls: ['./viewimage.component.css']
})
export class ViewimageComponent extends MzBaseModal  implements OnInit {

 
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  img: any;
  constructor(

    public dialogRef: MatDialogRef<ViewimageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected route: ActivatedRoute,private fb: FormBuilder,) {
    super();
  
 

this.img=data;
   }
 



  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
}
