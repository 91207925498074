import { Component, OnInit, Inject, Optional, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PatientlistComponent } from '../patientlist/patientlist.component';


import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal } from 'ngx-materialize';
declare var swal;
@Component({
  selector: 'app-childedit',
  templateUrl: './childedit.component.html',
  styleUrls: ['./childedit.component.css']
})
export class ChildeditComponent extends MzBaseModal implements OnInit {

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  public relationForm: FormGroup;
  patient_id: string;
  obj: any;
  patient: any;
  userData: any;
  full_name: string;
  dialogTitle: any;
  fromPage: any;
  modalCtrl: any;
  showAge1: number;
  age;
  showAge;


  constructor(public dialogRef: MatDialogRef<ChildeditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,

    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected route: ActivatedRoute,) {
    super();

    console.log("data got ====>", this.data);
    this.userData = JSON.parse(sessionStorage.getItem("userData"));
    this.full_name = sessionStorage.getItem("full_name");
    this.patient_id = sessionStorage.getItem("patient_id");
    console.log("22", this.full_name)
    //  this. patient_id=sessionStorage.getItem('patient_id');
    // this.fromPage = data.patient;
  }


  ngOnInit() {


    this.createForm();

  }
  createForm() {
    this.relationForm = this.fb.group({
      _id: [''],
      name: ['',Validators.required],
    //age: [sessionStorage.setItem("age", this.showAge)],
      gender: ['',Validators.required],
      age:sessionStorage.setItem("age", this.showAge),
      date_of_birth: ['',Validators.required]
    });
    this.relationForm.patchValue(this.data.patient);
    console.log(69, this.data.patient)
    console.log(this.data.patient.date_of_birth)
  }
  
  add(relationForm) {


    let dateOfBirth = this.relationForm.value.date_of_birth;

    console.log("", dateOfBirth)

    var arr1 = dateOfBirth.split('-');
    console.log("", arr1);
    var arr2 = arr1[1].split(',');
    console.log("", arr2);
    console.log('date: ', arr1[0]);

    if (this.relationForm.value.date_of_birth) {
      var convertAge = new Date(this.relationForm.value.date_of_birth);
      console.log(convertAge)
      var timeDiff = Math.abs(Date.now() - convertAge.getTime());
      console.log(1000, timeDiff)
      this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      console.log("55555", this.showAge)
      this.relationForm.value.age = this.showAge;
    }

    if (arr1[0] == new Date().getFullYear()) {
      this.showAge1 = (new Date().getMonth() + 1 - arr2[0]) / 10
      console.log("", this.showAge1)
      console.log(2000, this.relationForm.value.age = this.showAge1);
    }
    console.log('month: ', arr2[0]);
    console.log('year: ', arr2[1]);
    // if(this.age){
    //   var convertAge = new Date(this.age);
    //   console.log(convertAge)
    //   var timeDiff = Math.abs(Date.now() - convertAge.getTime());
    //   console.log(1000, timeDiff)
    //   this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);


    //   console.log("55555", this.showAge)




    //  } 

    this.relationForm.value.patient_id = this.patient_id;
    console.log(2222, this.relationForm.value.age)
    this.relationForm.value.patient_id = this.patient_id;

    this.auth.childedit(relationForm.value).subscribe(res => {
      sessionStorage.setItem("age", this.showAge);
      if (res.status == "success") {
        if (res.response != "") {
          //  location.reload();
          this.dialogRef.close();
          console.log("21", this.relationForm);
        }

        this.auth.childeditData.next(res.response);
        console.log(39, res);
      }
      
      swal("Dependent profile has been edited successfully");
    },
      () => {
      }
    );
  }



  close(): void {
    this.dialogRef.close();
  }


}


