import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-lab-fee',
  templateUrl: './lab-fee.component.html',
  styleUrls: ['./lab-fee.component.css']
})
export class LabFeeComponent implements OnInit {

  constructor(private auth:AuthService, private router:Router) {
    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
   }

  ngOnInit() {
  }

  lab:Object=[{
    "lid":"LAB 1",
    "lname":"One Health Lab-1",
    "lContact":"ck-LAB 1",
    "plan_selected":5000,
    "change_plan":''
  },
  {
    "lid":"LAB 2",
    "lname":"One Health Lab-2",
    "lContact":"ck-LAB 2",
    "plan_selected":8000,
    "change_plan":''
  },
  {
    "lid":"LAB 3",
    "lname":"One Health Lab-3",
    "lContact":"ck-LAB 3",
    "plan_selected":9000,
    "change_plan":''
  },
  {
    "lid":"LAB 4",
    "lname":"One Health Lab-4",
    "lContact":"ck-LAB 4",
    "plan_selected":11000,
    "change_plan":''
  },
  {
    "lid":"LAB 5",
    "lname":"One Health Lab-5",
    "lContact":"ck-LAB 5",
    "plan_selected":5000,
    "change_plan":''
  },
  {
    "lid":"LAB 6",
    "lname":"One Health Lab-6",
    "lContact":"ck-LAB 6",
    "plan_selected":8000,
    "change_plan":''
  },
  {
    "lid":"LAB 7",
    "lname":"One Health Lab-7",
    "lContact":"ck-LAB 7",
    "plan_selected":9000,
    "change_plan":''
  },
  {
    "lid":"LAB 8",
    "lname":"One Health Lab-8",
    "lContact":"ck-LAB 8",
    "plan_selected":11000,
    "change_plan":''
  },
  {
    "lid":"LAB 1",
    "lname":"One Health Lab-1",
    "lContact":"ck-LAB 1",
    "plan_selected":5000,
    "change_plan":''
  },
  {
    "lid":"LAB 2",
    "lname":"One Health Lab-2",
    "lContact":"ck-LAB 2",
    "plan_selected":8000,
    "change_plan":''
  },
  {
    "lid":"LAB 3",
    "lname":"One Health Lab-3",
    "lContact":"ck-LAB 3",
    "plan_selected":9000,
    "change_plan":''
  },
  {
    "lid":"LAB 4",
    "lname":"One Health Lab-4",
    "lContact":"ck-LAB 4",
    "plan_selected":11000,
    "change_plan":''
  },
  {
    "lid":"LAB 5",
    "lname":"One Health Lab-5",
    "lContact":"ck-LAB 5",
    "plan_selected":5000,
    "change_plan":''
  },
  {
    "lid":"LAB 6",
    "lname":"One Health Lab-6",
    "lContact":"ck-LAB 6",
    "plan_selected":8000,
    "change_plan":''
  },
  {
    "lid":"LAB 7",
    "lname":"One Health Lab-7",
    "lContact":"ck-LAB 7",
    "plan_selected":9000,
    "change_plan":''
  },
  {
    "lid":"LAB 8",
    "lname":"One Health Lab-8",
    "lContact":"ck-LAB 8",
    "plan_selected":11000,
    "change_plan":''
  }

  ]

  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   }
}
