import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MzModalService } from 'ngx-materialize';

import { MatDialog } from '@angular/material/dialog';
//import { PinModelComponent } from '../pin-model/pin-model.component';
import { ChangepassComponent } from 'src/app/changepass/changepass.component';
import { SignaturelabComponent } from 'src/app/medicaldetails/signaturelab/signaturelab.component';
import { LabeditComponent } from '../pages/labedit/labedit.component';
import { PinModelComponent } from '../pages/pin-model/pin-model.component';
@Component({
  selector: 'app-lab-home',
  templateUrl: './lab-home.component.html',
  styleUrls: ['./lab-home.component.css']
})
export class LabHomeComponent implements OnInit {

  userData: any;
  patient_id: any;
  name: any;
  resiltpin;
  pin_nos:any;
  valueOfModel: string;
  lab_phone: any;
  phone_no: any;
  id: any;
  result: any;
  reg: boolean;
  lab_id: any;
  constructor(private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService,) { 
      this.userData= JSON.parse(sessionStorage.getItem("userData"));
      console.log("this.userData" ,this.userData)
    this.lab_id=sessionStorage.getItem("lab_id");
    this.lab_phone= sessionStorage.getItem("lab_phone");
    console.log( 123,this.userData);
    if(sessionStorage.getItem("ptypepop")=="3")
    
    {
      this.pin();
    }
   }
  ngOnInit() {
    // console.log(32,this.userData);
    
  }

  openServiceModal(): void {
    const dialogRef = this.dialog.open(LabeditComponent, {
      width: '250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed'+result);
if(result!=1)
{
     this.login();
}
    });
  }

  changepass(): void {
    const dialogRef = this.dialog.open(ChangepassComponent, {
      width: '250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      alert(result);
     this.login();
    });
  }



  login() {
    sessionStorage.setItem('token', "res.response.token");
   
      this.auth.labEdit({lab_id:this.lab_id}).subscribe(res => {
        this.result=res;
        
        sessionStorage.setItem("userData",JSON.stringify(this.result.response[0]))
            this.userData=this.result.response[0];
            console.log(32323,this.result)

      
     }, () => {
 
    });
  }
 
  pin()  {
      sessionStorage.setItem("ptypepop","0");
      console.log(39, sessionStorage.getItem("ptypepop"))
       let dialogRef = this.dialog.open(PinModelComponent, {
          width: '600px',
      });
      dialogRef.afterClosed().subscribe(result => {
    
      });
  }  
  regi()
  {
    this.reg=true;
  }


  signature()  {
     let dialogRef = this.dialog.open(SignaturelabComponent, {
        width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
  
    });
} 
logout(){
  this.router.navigateByUrl('/new-lab');
}
view(){
  this.router.navigateByUrl('/viewtest');
  sessionStorage.setItem("patient_id",this.patient_id);
  console.log(39, sessionStorage.getItem("patient_id"))
}

}
