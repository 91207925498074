import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ReportTestComponent } from '../report-test/report-test.component';
import { MatDialog } from '@angular/material';
import { PrintComponent } from 'src/app/vedio/print/print.component';
import { PrescriptionComponent } from 'src/app/medicaldetails/prescription/prescription.component';
declare var swal;
@Component({
  selector: 'app-doctorvisits',
  templateUrl: './doctorvisits.component.html',
  styleUrls: ['./doctorvisits.component.css']
})
export class DoctorvisitsComponent implements OnInit {
  [x: string]: any;
  formatsDateTest: string[] = [
    'MM/dd/yyyy',
    ];
  
  patient_id: any;
  visits: any;
  userData:any;
  usersDatas:any
  searchOptions = {
    year:"",
    docter_name:"",
    child_name:""
  };
  doctlist:any;
  res: any;
  full_name: string;
  name: any;
  names:any;
  phone_no: any;
  config:any;
  years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];

  // docter_name:any;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,public dialog: MatDialog,
    protected route: ActivatedRoute) {
    this.usersDatas=JSON.parse(sessionStorage.getItem("userData"));

    console.log(46, this.usersDatas.patient_id);
    
    this.full_name=sessionStorage.getItem("full_name");
    this.patient_id= sessionStorage.getItem("patient_id");
    this.phone_no= sessionStorage.getItem("phone_no");
    this.userData=[];
      //  this.name=sessionStorage.getItem("child_name");
    this.doctorlist()
    this.patientlist();
    // this.searchOptions.year=new Date().getFullYear().toString()
    this.searchOptions.year=''
   this.Search();
      console.log("32",this.full_name)
     }

  ngOnInit() {

    window.scrollTo(0,0)
    this.visits=[];

     //this.patientlist();
     this.Search();
  }
  patientlist(){
    if(this.child_name==undefined)
    {
      this.child_name="";
    }
    
    this.auth.patientList({patient_id:this.patient_id}).subscribe(res => {  
      if (res.status == "success") {
     {
         this.visits=res.response.reverse();
         this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
          
        };
        console.log("89999", this.visits.length)     
  console.log(22,this.visits);
  this.user_name = res.response[0].user_name;
        console.log("lab_user_name====>" , this.user_name)
        
     }}
     
    },
       
 
       () => {
      swal("doctor visits, doctor detils not exits ");
     }
     );
   }
   logout(){
    this.router.navigateByUrl('/login')
  }

   pageChanged(event){
    this.config.currentPage = event;
  }

  prescription(reports): void {
    const dialogRef = this.dialog.open(PrescriptionComponent, {
      width: '250px',
      data: reports
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     // this.animal = result;
    });
  }

   doctorlist(){
    if(this.docter_name==undefined)
    {
      this.docter_name="";
    }
    this.auth.doctorlist({patient_id:this. patient_id}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         console.log(33,this.doctlist);
         this.list(); 
     }
    }, () => {
     }
     );
   }
   Search(){
   
    let date=this.searchOptions.year.split('T');

    let newDate = date[0].split('-');
    let day=newDate[1];
    let month=newDate[2];
    let year=newDate[0];

     this.searchOptions.year=year;
  
    this.auth.patientsearch({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name,docter_name:this.searchOptions.docter_name}).subscribe(res => {
      if (res.status == 'success') {
        this.visits = res.response.reverse();
        console.log(39,this.visits);
      }
    }, console.log);
   }
   
   list()
   {
      // this.labupdateForm .value.docter_id=this.docter_id;
       this.auth.findchild( {patient_id:this.patient_id}).subscribe(res => {
         
         if (res.status == "success") {
           if (res.response != "") {
          this.userData=res.response.reverse();
          
            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
           }
        
         }
       },
          
    
        //   () => {
        //  alert("Login failed, Login detils not exits ");
        // }
        );
      }
   
   
      report(tid,docid,doc_date,complete,range,child_name,timeStamp,test_date,docter_name,patient_id,patient_name,gender,age,phone_no,lab_name,lab_city,imageUrl,user_name) {
        this.doc_date=doc_date;
        this.auth.test_report({complete:complete,test_name:tid,patient_id:patient_id,docter_id:docid,doc_date:this.doc_date,range:range?range:"",child_name:child_name?child_name:"",timeStamp:timeStamp,user_name:user_name}).subscribe(res => {
          this.reports=res;
          console.log("imageUrl"+imageUrl[0]);
          if (this.reports.status == "success") {
              this.reportview({reports:this.reports.response,child_name:child_name,testname:tid,doc_date:doc_date,date:test_date,docter_name:docter_name,patient_name:patient_name,gender:gender,age:age,phone_no:phone_no,lab_name:lab_name,lab_city,sig:imageUrl,user_name:user_name});
            }
        }, (err) => {
            swal("server failed, server details not exits ");
         });
       }

       reportview(e) {
        const dialogRef = this.dialog.open(PrintComponent, {
          width: '250px',
          data: e
        });
        dialogRef.afterClosed().subscribe(result => {
  let vr=this.visits;
  this.visits=vr;


          console.log('The dialog was closed');
                     });
      
      }
      logo(){
        this.router.navigate(['/login']);
      }

  }




 