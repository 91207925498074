import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select-drop',
  templateUrl: './select-drop.component.html',
  styleUrls: ['./select-drop.component.css']
})
export class SelectDropComponent implements OnInit {
  

  constructor() { }
  defaultBindingsList = [
    { value: 1, label: 'Karthik' },
    { value: 2, label: 'Manish' },
    { value: 3, label: 'Roack'},
    { value :4, label : 'labUser'},
    { value:5 , label: 'userDetails1'},
    { value: 6 , label : 'mainPage1',},
];

  ngOnInit() {
  
  }

}
