import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal: any;

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.css']
})
export class Login2Component implements OnInit {
  loginForm: FormGroup;
  type:any;
  userData: any;
  password:"";
 
public validations_patient: FormGroup;
  public validations_docter: FormGroup;
  public validations_lab: FormGroup;
  selectitem: any;
  menu: any;
  M: any;
  // loginForm = {
  //   patient_access:"",
  //   doctor_access:"",
  //   laboratory_access:""
  // }
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    
   
    ) {
      sessionStorage.clear();

      this.selectitem=1;
  
      this.validations_patient  =  this.formBuilder.group({
        phone_no: ['', Validators.required],
        password: ['', Validators.required,],
        // phone_no: ['', Validators.required],
       
    });
    this.validations_docter  =  this.formBuilder.group({
    
       docter_phone: ['', Validators.required],
       password: ['', Validators.required,],
      // phone_no: ['', Validators.required],
    
  });
  this.validations_lab  =  this.formBuilder.group({
   
    lab_phone: ['', Validators.required],
    password: ['', Validators.required,],
    // phone_no: ['', Validators.required],
  });
     }

forgot(e)
{
  this.router.navigateByUrl('/forgetpsw?id='+e);

  
}

logn(x){
console.log(x);

}

  ngOnInit() {
  }

  login(ex,formData) {
   
    sessionStorage.setItem('token', "res.response.token");
   // location.reload();
    // this.auth.presentLoading();
    this.auth.login(formData.value).subscribe(res => {
    
      if (res.status == "success") {
        this.userData=res.response;

        if(ex==1)
        {
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
            sessionStorage.setItem("patient_id",this.userData.patient_id);
            sessionStorage.setItem("full_name",this.userData.full_name);
            sessionStorage.setItem("phone_no",formData.value.phone_no);
            sessionStorage.setItem("country",this.userData.country);
            sessionStorage.setItem("token",this.userData.token);
            this.router.navigateByUrl('/medicaldetails');
           
           this.router.navigateByUrl('/login');
        }
       
        if(ex==2)
        { 
           sessionStorage.setItem("userData",JSON.stringify(this.userData));
           sessionStorage.setItem("docter_id",this.userData.docter_id);
           sessionStorage.setItem("docter_phone",formData.value.docter_phone);
           sessionStorage.setItem("country",this.userData.country);
           sessionStorage.setItem("type",this.userData.type);
           console.log(100000,           sessionStorage.getItem("type"));
           
           sessionStorage.setItem("id",this.userData._id);
           sessionStorage.setItem("token",this.userData.token);

           sessionStorage.setItem("ptype",'2');


          //  swal("login succesfull");
            // sessionStorage.setItem("docter_id",formData.value.docter_id);
           this.router.navigateByUrl('/DoctorDataComponent');
          // this.router.navigateByUrl('/login');
        }
        if(ex==3)
        {
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
          sessionStorage.setItem("lab_phone",formData.value.lab_phone);
          sessionStorage.setItem("lab_id",this.userData.lab_id);
          sessionStorage.setItem("lab_name",this.userData.name);

          sessionStorage.setItem("token",this.userData.token);


          // swal("login succesfull");
           this.router.navigateByUrl('/Labinformation');
          // this.router.navigateByUrl('/login');
        }
        
        
       // location.reload();

       // this.route.navigateByUrl('/list');
      } if(res.status=='error')
      {
        swal(res.message);
      }
       }, (err) => {
         console.log(99,err)
  
         if(err.error.message.err=="No account exists")
         {
          swal("Account Doesn't Exist !","No Account Found With Mentioned Credentials","error");
        }
        else{

          swal("Incorrect Password","Please Check The Password, Try Again !","error");

        }

  
      });
     

    
  }
  
 
  onChange(item){
    console.log(11,item);
    this.selectitem = item.target.value;
  }

  // reg()
  // {
  //   this.router.navigateByUrl('/register');
  
  // }
  
}



// (err) => {
//   console.log(343,err);
//   swal( err.error.message.error, " no already exist");

// });