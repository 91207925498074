import { Component, OnInit, Renderer, Input, EventEmitter, Inject, Optional } from '@angular/core';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { title } from 'process';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportViewComponent } from '../../report-view/report-view.component';
declare var swal;
 //import { NgxSpinnerService } from "ngx-spinner";
import { BuiltinTypeName } from '@angular/compiler';




@Component({
  selector: 'app-labresult-update',
  templateUrl: './labresult-update.component.html',
  styleUrls: ['./labresult-update.component.css']
})

  
   export class LabresultUpdateComponent extends MzBaseModal  implements OnInit {
   
    public labupdateForm:FormGroup
    userData: any;
    patient_id: string;
    name: string;
    alllist: any;
    test_id: any=[];
    test_res: any;
    myFormGroup:FormGroup;
    read: any;
    sname:any=[];
    unit:any=[];
    method:any=[];
    range:any=[];
    val: any=[];
    bio:any=[];
    docter_id: any;
    docter_name: any;
    lab_name: any;
    _id: any;
    sts: any;
    result: any;
    child_name: any;
    checks:any=[];
    ch: any=[];
    chec: any=[];
    other_unit:any=[];
    others: any=[];
    comment:any=[];
    doc_date: any;
    test_date: any=[];
    complete: any=[];
    timeStamp: any;
    disabled: any;
    @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  age: any;
  phone_no: any;
  sex: any;
  lab_city: any;
  email: string;
  resultres: any;
  response: any;
  lab_id: string;
  visits: string;
  dateee: string;
  
  buttonDisabled: boolean = false;
  p_id: any;
  test_results: any;
  test_Results_Names: any;
  namesResult: any;
  result123: any;
  num: any[];
  newArray: any;
  users: any = [];
  testName: any=[];
  filter_fee: any;
  testNames: any=[];

  t1:any=[];
  t_id: any;
  resultnames: any;
  test2: any;
  textItems: any;
  
     constructor( public dialogRef: MatDialogRef<LabresultUpdateComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private datePipe: DatePipe,
      private fb: FormBuilder,
      private router: Router,
      protected auth: AuthService,
      // private spinner: NgxSpinnerService,
      protected activatedRoute: ActivatedRoute) {

      

  
       super();
       console.log("data got ====>", this.data);
 
        this.test_res = this.data.test_id;

        for(let i=0; i< this.test_res.length;i++)
        {
       // this.testName[i]=this.data.test_id[i].name;

        this.testName=this.data.test_id[0].name;


        console.log("testName====>", this.testName);
        }


        // breaking arrays
        
   this.test2=this.test_res;

        for(let i=0; i< this.test_res.length;i++)
        {
          if(this.test_res.length>1){

            if(this.test_res[0].complete){
              this.test_res=this.test_res;
            }else{
              this.test_res=Object.values( this.test_res).slice(1);
            }
          }else{
            this.test_res=this.test_res;

          }
  
        }

        console.log("   this.test_res @@@@>> ",   this.test_res);





        this.docter_id = this.data.docter_id;
        this.docter_name = this.data.docter_name;
        this.lab_name = this.data.lab_name
        console.log("lab====>" , this.lab_name);
        // this.lab_name = sessionStorage.getItem("lab_name");
        // this.lab_city = sessionStorage.getItem("lab_city");
        this.p_id= this.data.patient_id;
        this.lab_city = this.data.lab_city;
        console.log("this.p_id====>", this.p_id)
        console.log("this.lab_city====>", this.lab_city)

        
        this._id = this.data._id;
        console.log("5588_id" , this._id);
        sessionStorage.setItem("_id",this._id);
        this.sts = this.data.sts;
        this.child_name = this.data.child_name;
       
        this.age = this.data.age;
        this.phone_no = sessionStorage.getItem("phone_no");
        console.log("9177867", this.phone_no);
        this.sex = this.data.gender;


        let date=this.data.doc_date;
        let timeStamp=this.data.timeStamp;
        this.timeStamp=timeStamp;
        this.doc_date = date;

      this.test_results = this.data.test_name;

      console.log(10000, this.test_results);

// this.test_results =this.data.test_name

// console.log("108", this.test_results);

//       var testNames = this.data.test_name;

//       let arr = [];
//       Object.keys(testNames).map(function(key){
//         arr.push({[key]:testNames[key]})
//         return arr;
//       });

//       
//       console.log(108, this.test_Results_Names);
        console.log("hi sts this.sts", this.sts); // Print the parameter to the console. 
 
  
  
      // this.userData=JSON.parse(sessionStorage.getItem("userData"));
      // console.log("88888===>", this.userData)
      // this.patient_id=sessionStorage.getItem("patient_id")
      // console.log("98989",this.patient_id);
      // this.name=sessionStorage.getItem("full_name")
      //this.readings();
      for(let i=0;i<this.test_res;i++)
      {
       this.chec[i]=false;
       this.others[i]=false;

      }
      this.patient_id=sessionStorage.getItem("patient_id");

      console.log("this.patient-id====>", this.patient_id);
    this.phone_no=sessionStorage.getItem("phone_no")
   this.name=sessionStorage.getItem("full_name")
   this.email=sessionStorage.getItem("email");
   
   this. lab_id=sessionStorage.getItem('lab_id');
   this.visits="";
   this.dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();
     }

    ngOnInit() {
 
      //this.getfillauto();
        
      //this.getval(this._id);
      // this.auth.testUpdate({lab_id:this.lab_id,status:2,payment_status:1,_id:this._id}).subscribe(res =>{
      //   this.resultres = res;
      //   if( this.resultres .status == "success"){
          
      //    this.response=this.resultres.response;
      //    console.log("this.response" , this.response)
        
      //   }
      //  }|| this.sts==4)
      // this.auth.testProcess({lab_id:this.lab_id,status:1,payment_status:1,_id:this._id}).subscribe(res =>{
      //   this.resultres = res;
      //   if( this.resultres .status == "success"){
          
      //    this.response=this.resultres.response;
      //    console.log("this.response" , this.response)
      //    console.log(7878 , this.response[0].test_name)
         
      //   }
      //  })
   if(this.sts==1 ) //|| this.sts=='2'1
   {
    this.getval(this._id); //findbyid
    this.getfillauto(); //find
   
   }
   else{
    this.getval(this._id); //findbyid
    this.getfillauto();  //find
  
  console.log (10000000,this.getval(this._id)) 


   }
     for(let i=0;i<this.test_res;i++)
     {
      this.chec[i]=false;
     
     
    }
  
   
     
    }
  
   async sub()
    {
      console.log(this.t1);
      
      
    let flag=1;
    
  
   
      console.log('orm',JSON.stringify(this.val));
     
      if(flag==1){
      if(this.sts==5 || this.sts==4)
      {
        this.val=[];
       
        console.log(this.t1);

        
        for(let i=0; i<this.t1.length;i++){

          console.log(this.t1[i]);

          console.log("************ tests ==> ",  this.t1[i].name," - ", this.t1[i]._id);
            
      //     this.testNames[i] = this.t1[i].filter(name => name.name === this.test_res[i].name);

      //  console.log("name====>", this.test_res[i].name)
          console.log("4545540,", this.t1[i]._id);

          console.log("tststst,", this.testName[i]);
        
        
        }

        console.log(" real test ==>", this.test_res);
          


        // main cond.
        for (let i = 0; i < this.test_res.length; i++) {
          if(this.test_res.length>0){
          
          
        // if(this.test_res[0].name){
        
        for (let k = 0; k < this.t1.length; k++) {
       if( this.test_res[i].name == this.t1[k].name ){
              this.t_id = this.t1[k]._id;
              break;
           }    
          else{
          this.t_id="null";
         } 
          }

          
// //this.test_res[i].name ==
//           if( this.test_res[0].name == this.t1[index].name ){
//               this.t_id = this.t1[index]._id;
//               break;
           }    
          else{
          this.t_id="null";
         } 

         if(this.t_id!="null"){
          this.val.push({
            "_id":this.t_id,
            "name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
          "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
          "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
          method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp,});
        
        }
        else{
  
        // }
  
        this.val.push({"name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
    "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
    "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
    method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp,});
            }


        }  
          
      //  }
        
       console.log(" ???? this.t_id ==>", this.t_id);



     
      for(let i=0;i<this.test_res.length;i++)
      {
      //this.sname[i]=0;

  //     if(this.t_id!="null"){
  //       this.val.push({
  //         "_id":this.t_id,
  //         "name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
  //       "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
  //       "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
  //       method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp,});
      
  //     }
  //     else{

  //     // }

  //     this.val.push({"name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
  // "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
  // "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
  // method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp,});
  //   }
//test_id:this.test_res[i]._id,

}
       this.updateval();
       
      }

      // else {
      //   this.readsave();

        
      //   this.populate();
      // }
      else{

        // first time
        this.val=[];




        
        for(let i=0;i<this.test_res.length;i++)
        {
        //this.sname[i]=0;
  
  
        this.val.push({"name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
    "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
    "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
    method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,
    "teststs":1,"timeStamp":this.timeStamp,
   "testNames":this.test_results});
      //   "_id":this._id,
  
  
  }
          this.readsave();

        
        this.populate();
  
      }
    }
    //}

    
  }
  
  
    updateval()
    {
      let flag=1;
    
      let k;
      console.log("hello",this.val[0]);
      if(this.val[0].value == undefined){
        k=1;
        
      }
      else {
    k=0;
    console.log("hello123");
      }

      
      for(let i=k;i<this.val.length;i++)
      {
        console.log("---"+ JSON.stringify(this.val[i])+ "id  " +i);
        if(this.val[i].value== "" || this.val[i].value== undefined)
        {
          console.log(this.val[i]+ "id "+ i);
          swal("please enter all results");
          flag=0;
          break;
        }
        
         if(this.val[i].comment== "" || this.val[i].comment== undefined){
          console.log(this.comment[i]+ "id "+ i);
          swal("please enter Comment")
          flag=0;
          break;
        }
        //  if(this.val[i].other_method==""  || this.val[i].other_method== undefined){
        //   console.log(this.val[i].other_method+ "id "+ i);
        //   swal("please enter method")
        //   flag=0;
        //   break;
        // }
        // if(this.val[i].method==""  || this.val[i].method== undefined){
        //   console.log(this.val[i].method+ "id "+ i);
        //   swal("please enter method")
        //   flag=0;
        //   break;
        // }
         if(this.val[i].bio=="" || this.val[i].bio==undefined ){
          console.log(this.val[i].bio+ "id "+ i);
          swal("please enter bio range")
          flag=0;
          break;
        }
         if(this.val[i].unit=="" || this.val[i].unit== undefined ){
          console.log(this.val[i].unit+ "id "+ i);
          swal("please enter unit")
          flag=0;
          break;
        }
        
        //  else if(this.val[i].method==""){
        //   swal("Please enter method")
        //   flag=0;
        //   break;
        // } else if(this.val[i].bio==""){
        //   swal("Please enter bio range")
        //   flag=0;
        //   break;
        // }
            
        else{
          flag=1
        

  // this.val.push({"name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
  // "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
  // "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
  // method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp});
      
        }
    
      }

       
      console.log("this.val=====>", this.val);
        if(flag==1){
      //this.ngOnInit();
      
          
        if(this.sts==5 ) {
         
        
          this.auth.updateval({
            data:this.val,
          
            
            "doc_date":this.doc_date,
            "patient_id": this.p_id, 
            "lab_id":sessionStorage.getItem("lab_id"),
            "docter_id":sessionStorage.getItem("docter_id"),
            "docter_name":this.docter_name,
             other_unit:this.other_unit,
            "lab_name":this.lab_name,
            "report_id":this._id,
            "patient_name":sessionStorage.getItem("full_name"),
            "child_name":this.child_name,
            age:Number(this.age),phone_no:this.phone_no,gender:this.sex,lab_city:this.lab_city,
          }).subscribe(res => {
         
       

           console.log("response", res)
            if (res.status == "success") {
              if (res.response != "") {
             this.read=res.response;
        this.populate();
             this.close();
             //this.namesResult;
            swal("Report successfully updated ");
            //this.namesResult;
           
              }
           
            }
          },
             
       
             () => {
              //  swal("server failed, server details not exits ");
              // this.spinner.hide();
              swal("Report successfully updated ");
            // this.spinner.hide();
            this.close();
  
           }
           );
        
        }
        


        
        else {
         // this.spinner.show();
        
    
        // this.auth.updateval({
        //    data:this.val,
        //   "doc_date":this.doc_date,
        //   "patient_id": this.p_id,
        //   "lab_id":sessionStorage.getItem("lab_id"),
        //   "docter_id":sessionStorage.getItem("docter_id"),
        //   "docter_name":this.docter_name,
        //    other_unit:this.other_unit,
        //   "lab_name":this.lab_name,
        //   "report_id":this._id,
        //   "patient_name":sessionStorage.getItem("full_name"),
        // "child_name":this.child_name,age:Number(this.age),
        //    phone_no:this.phone_no,
        //    gender:this.sex,
        //    lab_city:this.lab_city,
        // }).subscribe(res => {
        
        //  console.log("response", res)
        //   if (res) {
        //     this.populate();
        //     if (res!= "") {
          
        //    this.close();

        //   swal("report successfully updated ");

         
        //     }
         
        //   }
        // },
           
     
        //    () => {
           
        //     swal("report successfully updated ");
           
        //     this.close();

        //  }
        //  );
       }
       if(this.sts==4){
        //this.spinner.show();
        this.auth.updateval({
          data:this.val,
        
          // "test name":this.test_results,
          "doc_date":this.doc_date,
          "patient_id": this.p_id, 
          "lab_id":sessionStorage.getItem("lab_id"),
          "docter_id":sessionStorage.getItem("docter_id"),
          "docter_name":this.docter_name,
           other_unit:this.other_unit,
          "lab_name":this.lab_name,
          "report_id":this._id,
          "patient_name":sessionStorage.getItem("full_name"),
          "child_name":this.child_name,
          age:Number(this.age),phone_no:this.phone_no,gender:this.sex,lab_city:this.lab_city,
        }).subscribe(res => {
         //this.spinner.hide();


         console.log("response", res)
          if (res) {
            if (res != "") {
          // this.read=res.response;
           this.close();
           //this.namesResult;
          swal("Report successfully updated ");
          //this.namesResult;
         
            }
         
          }
        },
           
     
           () => {
            //  swal("server failed, server details not exits ");
            // this.spinner.hide();
            swal("Report successfully updated ");
            // this.spinner.hide();
            this.close();


         }
         );
       }
       else {
         
       }
      }
    }

    readsave()
    {
      let flag=1;


      let k;
      if(this.val[0].value==undefined){
        k=1;
      }
      else {
    k=0;
      }
      for(let i=k;i<this.val.length;i++)
      {
        console.log("---"+ JSON.stringify(this.val[i])+ "id  " +i);
        if(this.val[i].value== "" || this.val[i].value== undefined)
        {
          console.log(this.val[i]+ "id "+ i);
          swal("please enter all results");
          flag=0;
          break;
        }
        
         if(this.val[i].comment== "" || this.val[i].comment== undefined){
          console.log(this.val[i].comment+ "id "+ i);
          swal("please enter Comment")
          flag=0;
          break;
        }
        //  if(this.val[i].other_method==""  || this.val[i].other_method== undefined){
        //   console.log(this.val[i].other_method+ "id "+ i);
        //   swal("please enter method")
        //   flag=0;
        //   break;
        // }
         if(this.val[i].bio=="" || this.val[i].bio==undefined ){
          console.log(this.val[i].bio+ "id "+ i);
          swal("please enter bio range")
          flag=0;
          break;
        }
         if(this.val[i].unit=="" || this.val[i].unit== undefined ){
          console.log(this.val[i].unit+ "id "+ i);
          swal("please enter unit")
          flag=0;
          break;
        }
        
        //  else if(this.val[i].method==""){
        //   swal("Please enter method")
        //   flag=0;
        //   break;
        // } else if(this.val[i].bio==""){
        //   swal("Please enter bio range")
        //   flag=0;
        //   break;
        // }
            
        else{
          flag=1
        

  // this.val.push({"name":this.test_res[i].name,"child_name":this.child_name,"patient_name":sessionStorage.getItem("full_name"),
  // "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
  // "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
  // method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp});
      
        }
    
      }
    
        let dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy','en-IN')).toString();
        
        if(flag==1){
          //this.spinner.show();
        this.auth.readingsave({
          data:this.val,
          "doc_date":this.doc_date,
          "test_date":dateee,"patient_id": sessionStorage.getItem("patient_id"),
          "lab_id":sessionStorage.getItem("lab_id"),"docter_id":sessionStorage.getItem("docter_id"),
          "docter_name":this.docter_name,bio:this.bio,"lab_name":this.lab_name,
          "_id":this._id,"patient_name":sessionStorage.getItem("full_name"),
          "child_name":this.child_name,age:Number(this.age),
           phone_no:this.phone_no,gender:this.sex,lab_city:this.lab_city,}).subscribe(res => {
          

       // this.spinner.hide();
        


          if (res.status == "success") {
            this.close();

            if (res.response != "") {
           this.read=res.response;
this.testdate();
           swal("Report successfully added ");
     
            }
         
          }
        },
           
     
           (err) => {
             //this.spinner.hide();

         }
         );
       }
      }

       populate()
       {
           this.auth.autoCreate({data:this.val,"lab_id":sessionStorage.getItem("lab_id"),
           "lab_name":this.lab_name,"_id":this._id}).subscribe(res => {
             
             if (res.status == "success") {
               if (res.response != "") {
              this.read=res.response;
           
               }
            
             }
           },
              
        
              () => {
                swal("server failed, server details not exits ");
            }
            );
          }
     
testdate()
{

  let dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy','en-IN')).toString();

  this.auth.editdoc({_id:this._id,test_date:dateee,status:5}).subscribe(res => {
    
    if (res.status == "success") {
      if (res.response != "") {
     this.read=res.response;

      }
   
    }
  },
     

     () => {
      //  swal("server failed, server details not exits ");
   }
   );
}

       check(e,i){
        console.log(e);
      if(e=='Other Method')
      {
       // this.ch=e.detail.checked;
        this.chec[i]=true;
      }
      else{
        this.chec[i]=false;
  
      }
     
        
      
      }
  
      checksunit(e,i){
        console.log(e);
    
      if(e=="Other_units")
      {
        this.others[i]=true;
      }
      else{
        this.others[i]=false;
  
      }
    }
  
    update(labupdateForm){
      this.labupdateForm .value.patient_id=this.patient_id;
     // this.labupdateForm .value.docter_id=this.docter_id;
      this.auth.updates(labupdateForm.value).subscribe(res => {
        
        if (res.status == "success") {
          if (res.response != "") {
           // this.nav.pop();
           this.close();

            swal("Updated successfully");
  
       console.log("21",this.labupdateForm);
           // sessionStorage.setItem("full_name",this.name);
           // this.router.navigateByUrl("/patientsearch");
          }
       
        }
      },
         
   
         () => {
       swal("server failed, server detils not exits ");
       }
       );
     }
  




  //  getResult(e){
  //   console.log(10000000000, e);
  //  }







  getval(e){
    console.log(77878, e);
    
      this.auth.readingedit({report_id:e}).subscribe(res => {
        
        if (res.status == "success") {
          if (res.response != "") {
  this.result=res.response[0].data;

  console.log("this.result====?", this.result)

  let item = this.result.filter(obj => obj.name === this.data.test_id[0].name);
 
  console.log("filter00001", item);

  console.log("Item&&Length-==.", item.length)
  // if(this.result.length>1){

  // }

 
  
  // this.test_res=this.resultnames
//   let filter_fee = this.result.filter(name => name.name === this.testName);
//  this.test_id[0]=filter_fee[0]._id;

//  console.log("test_id====>", this.testName);
//   console.log("fiterName====>", filter_fee);

 console.log("names", this.result);

  this.result123 = res.response.data;



  // let result = this.response.map(result123 => { 
  //   return { name: result123.name, avg: result123._id };
  // });
  console.log("8888880,", this.result);

  var newArray:[]=res.response.data
  console.log(55555, this.newArray);

   // console.log(454545, this.response)
  // var testArrays = this.result;

//   var first = testArrays.slice(0, indexToSplit);
// var second = testArrays.slice(indexToSplit + 1);

// console.log({first, second});
//  var namesResult = this.result;
  // console.log(55555, this.result);
  


  // var response = res.response[0].data[0]._id;
           this.testNames=[]


           for(let i=0;i<this.result.length;i++)
           {

            this.t1[i]=this.result[i];
            console.log("************ tests ==> ",  this.t1[i].name," - ", this.t1[i]._id);
           }

           //console.log("range total tests ==> ",item[0].range  );
           console.log("test_item====>",item);
              // this.textItems = item;
              // console.log("356454", this.textItems[0].name)
              // for(let i=0;i< this.textItems.length;i++ ){
              //   this.sname[i]=this.textItems[i].value;
              // }
           
//            if(item[0].range == 0){

     
          
//           for(let i=0;i<this.result.length;i++)
//             {
//               if(this.result[i].range !=0){
//  console.log("test_item====>",item);
//  this.unit[i]=this.result[i].unit
//  this.sname[i]=this.result[i].value;

//   console.log("karthik====>", this.sname[i]);
 
//   console.log(99,this.checks[i]=this.result[i].method);
//   this.checks[i]=this.result[i].method
//   this.checks[i]=this.result[i].method?this.checks[i]:"Other Method"; 
//   this.bio[i]=this.result[i].bio;
// //this.test_id[i]=this.result[i]._id;
//   //console.log(789, this.test_id[i]);
//   if(this.checks[i]=="Other Method"){
//   this.chec[i]=true;
//   this.method[i]=this.result[i].other_method;
//   this.other_unit[i]=this.result[i].other_unit;
//   this.bio[i]=this.result[i].bio;

//   console.log(212,this.comment[i]);
  
//   }

//   this.other_unit[i]=this.result[i].other_unit;
  
//   this.comment[i]=this.result[i].comment;
  
//   this.range[i]=this.result[i].range;
  
//   this.complete[i]=this.result[i].complete;
  
//   this.test_date[i]=this.result[i].test_date;
  
  
//   this.unit[i]=this.result[i].unit;
//   if(this.unit[i]=="Other_units"){
//     console.log(10000009,this.other_unit);
//     this.others[i]=true;
//   }
//             }

//           }
//         }
// else{
// //             for(let i=0;i<item.length;i++)
// //             {
            
// //  console.log("test_item====>",item);
// //  this.unit[i]=item[i].unit
// //  this.sname[i]=item[i].value;

// //   console.log("karthik====>", this.sname[i]);
 
// //   console.log(99,this.checks[i]=item[i].method);
// // this.checks[i]=item[i].method?this.checks[i]:"Other Method"; 
// // this.bio[i]=item[i].bio;
// // //this.test_id[i]=this.result[i]._id;
// //   console.log(789, this.test_id[i]);
// //   if(this.checks[i]=="Other Method"){
// //   this.chec[i]=true;
// //   this.method[i]=item[i].other_method;
// //   this.other_unit[i]=item[i].other_unit;
// //   this.bio[i]=item[i].bio;

// //   console.log(212,this.comment[i]);
// //   }

// //   this.other_unit[i]=item[i].other_unit;
  
// //   this.comment[i]=item[i].comment;
  
// //   this.range[i]=item[i].range;
  
// //   this.complete[i]=item[i].complete;
  
// //   this.test_date[i]=item[i].test_date;
  
  
// //   this.unit[i]=item[i].unit;
// //   if(this.unit[i]=="Other_units"){
// //     console.log(10000009,this.other_unit);
// //     this.others[i]=true;
// //   }
// //             }
//           //  this.auth.presentToast("updated successfully");
//           } 


// new 
for(let i1=0;i1<this.result.length;i1++)
{
  
  for(let i=0;i<this.test_res.length;i++)
  {
    //console.log(909,this.test_res[i].name);

    if(this.test_res[i].name ==this.result[i1].name)
    {
      console.log(919,this.test_res[i].name);

      this.sname[i]=this.result[i1].value;

      this.unit[i]=this.result[i1].unit
      this.comment[i]=this.result[i1].comment
      console.log(99,this.checks[i]=this.result[i1].method);
      this.checks[i]=this.result[i1].method?this.checks[i]:"Other Method";
      this.bio[i]=this.result[i1].bio;

      if(this.checks[i]=="Other Method"){
      this.chec[i]=true;
      this.method[i]=this.result[i1].other_method;
      this.other_unit[i]=this.result[i1].other_unit;
      this.bio[i]=this.result[i1].bio;
      
      
      this.other_unit[i]=this.result[i1].other_unit;
      
      
      this.range[i]=this.result[i1].range;
      
      this.complete[i]=this.result[i1].complete;
      
      
      
      this.unit[i]=this.result[i1].unit;
      if(this.unit[i]=="Other_units"){
        console.log(10000009,this.other_unit);
        this.others[i]=true;
      }
      
      
      }
     
                
    }
  }


   

// if()
// {

// }
}



// original code
//           for(let i=0;i<this.result.length;i++)
//           {
//             if(this.result[i].range !=0){
// console.log("test_item====>",item);
// this.unit[i]=this.result[i].unit
// this.sname[i]=this.result[i].value;

// console.log("karthik====>", this.sname[i]);

// console.log(99,this.checks[i]=this.result[i].method);
// this.checks[i]=this.result[i].method
// this.checks[i]=this.result[i].method?this.checks[i]:"Other Method"; 
// this.bio[i]=this.result[i].bio;
// //this.test_id[i]=this.result[i]._id;
// //console.log(789, this.test_id[i]);
// if(this.checks[i]=="Other Method"){
// this.chec[i]=true;
// this.method[i]=this.result[i].other_method;
// this.other_unit[i]=this.result[i].other_unit;
// this.bio[i]=this.result[i].bio;

// console.log(212,this.comment[i]);

// }

// this.other_unit[i]=this.result[i].other_unit;

// this.comment[i]=this.result[i].comment;

// this.range[i]=this.result[i].range;

// this.complete[i]=this.result[i].complete;

// this.test_date[i]=this.result[i].test_date;


// this.unit[i]=this.result[i].unit;
// if(this.unit[i]=="Other_units"){
//   console.log(10000009,this.other_unit);
//   this.others[i]=true;
// }
//           }

//         }
       console.log("21",res.response);
       
          }
       
        }
      },
         
   
         () => {
     //  swal("server failed, server details not exits ");
       }
       );
     }
      
      
     
     getfillauto(){
       let v=[];
      for(let i=0;i<this.test_res.length;i++)
      {
  v.push({"name":this.test_res[i].name,"child_name":this.child_name,_id:this.test_res[i]._id,
  "other_method":this.method[i]?this.method[i]:'',"complete":this.test_res[i].complete,"test_date":this.test_res[i].test_date,
  "unit":this.unit[i],"value":this.sname[i],"other_unit":this.other_unit[i],"bio":this.bio[i],comment:this.comment[i],
  method:this.checks[i],"range":this.test_res[i].range?this.test_res[i].range:0,"doc_date":this.doc_date,"timeStamp":this.timeStamp});
      }

      this.auth.autofind({data:v,"lab_id":sessionStorage.getItem("lab_id"),
      "lab_name":this.lab_name,"_id":this._id}).subscribe(res => {
        
        if (res.status == "success") {
          if (res.response != "") {
            this.result=res.response;

          
            





            
            for(let i1=0;i1<this.result.length;i1++)
            {
              
              for(let i=0;i<this.test_res.length;i++)
              {
                //console.log(909,this.test_res[i].name);

                if(this.test_res[i].name ==this.result[i1].name)
                {
                  console.log(919,this.test_res[i].name);

                  //this.sname[i]=this.result[i].value;

                  this.unit[i]=this.result[i1].unit
                  this.comment[i]=this.result[i1].comment
                  console.log(99,this.checks[i]=this.result[i1].method);
                  this.checks[i]=this.result[i1].method?this.checks[i]:"Other Method";
                  this.bio[i]=this.result[i1].bio;

                  if(this.checks[i]=="Other Method"){
                  this.chec[i]=true;
                  this.method[i]=this.result[i1].other_method;
                  this.other_unit[i]=this.result[i1].other_unit;
                  this.bio[i]=this.result[i1].bio;
                  
                  
                  this.other_unit[i]=this.result[i1].other_unit;
                  
                  
                  this.range[i]=this.result[i1].range;
                  
                  this.complete[i]=this.result[i1].complete;
                  
                  
                  
                  this.unit[i]=this.result[i1].unit;
                  if(this.unit[i]=="Other_units"){
                    console.log(10000009,this.other_unit);
                    this.others[i]=true;
                  }
                  
                  
                  }
                 
                            
                }
              }

            
               

  // if()
  // {

  // }
 }
        //    this.auth.presentToast("updated successfully");
  
         
       console.log("21",res.response);
       
          }
       
        }
      },
         
   
         (err) => {
      // swal("server failed, server details not exits ");
       }
       );
     }





close()
{
  this.dialogRef.close();
}

// manage(txt){
//   var bt = <HTMLInputElement> document.getElementById('btSubmit')
//   if(txt.value != ''){
//     bt.disabled = false;
//   } 
//   else{
//      bt.disabled = true;
//   }
// }



  
        back()
        {
         // this.nav.pop();
        }
   }