import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
declare var swal
@Component({
  selector: 'app-doctor-online',
  templateUrl: './doctor-online.component.html',
  styleUrls: ['./doctor-online.component.css']
})
export class DoctorOnlineComponent implements OnInit {

  validations_form: FormGroup;
  listOfCountries: any;
  data:any

  constructor(private fb: FormBuilder,protected auth: AuthService, public router:Router,protected route: ActivatedRoute,) {
    if( sessionStorage.getItem("docter_phone")==" " || sessionStorage.getItem("docter_phone") == undefined){
      this.router.navigateByUrl('/login2');
    }
    console.log(sessionStorage.getItem('doc_id'));
    
  }


  ngOnInit() {
    // window.scrollTo({
    //   top: 0,
    //   left: 0,
    //   behavior: 'auto'
    // });

    setTimeout(function () {
      // window.scrollTo(0,0);

      window.scrollTo(0,0);

  },50);
    // window.scrollTo(0, 0);
    this.countries();
    // this.route.queryParams.subscribe(queryParams => {
    //   //console.log(9999,queryParams);
    //   let user = JSON.parse(queryParams['user']);
    //   this.validations_form.patchValue = user;
      
    //   this.validations_form.setValue({
    //     lab_id: user.lab_id,
    //     phone_no: user.phone_no,
    //     lab_user_phone: user.lab_user_phone,
    //     email: user.email,
    //     password: user.password,
    //     status: true,
    //     user_name: user.user_name,
    //     role: user.role,
    //     _id: user._id
    //   })
    // })

    // this.route.queryParams.subscribe(q=>{
    //   this.data =JSON.parse(q['userdata']);
    //   console.log(this.data['doctor_name']);

    // })



this.data=JSON.parse(sessionStorage.getItem('userData'))
console.log(this.data);


this.createForm();

    
  }

  myClick(){}


  countries() {
    this.auth.countryList().subscribe(res => {
      if (res.status == "success") {
        this.listOfCountries = res.response
        console.log(333, this.listOfCountries)
      }
    }, () => {
    });
  }

  createForm() {
    
    this.validations_form = this.fb.group({
     
      docter_name:[this.data.docter_name,Validators.required],
      sex:[this.data.sex ,Validators.required],
      speciality :[this.data.speciality,Validators.required],
      hospital: [this.data.hospital, Validators.required],
      clinic_address:[this.data.clinic_address,Validators.required],
      email: [this.data.email, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      phone: [this.data.docter_phone, Validators.required],
      IMA_number: ['', Validators.required],
      city: [this.data.city, Validators.required],
      state: [this.data.state, Validators.required],
      country: [this.data.country, Validators.required],
     
      languages:[this.data.languages, Validators.required],
      experience:[this.data.experience, Validators.required],
      charges: ["", Validators.required], 
      university: ['', Validators.required], 
      imagePath:     [String],
      docpath:       [String],
      docter_id: sessionStorage.getItem('doc_id'),
      status: 2,
      onlineStatus: 0,
      onehealthcharges:'0',
      finalCharges:[this.data.charges, Validators.required]
      
    });


    
  }


  testid:any;
 
logout(){
  this.auth.logout();
  localStorage.setItem('login','');
  location.reload();
}


  docregister() {

    this.auth.doc(this.validations_form.value).subscribe(res => {
     
      if (res.status == "success") {
        // var uday=sessionStorage.setItem("res",res);
        // console.log(uday);

        // sessionStorage.setItem("_id",res['response._id'])
        // console.log(sessionStorage.setItem("lab_id",res.response.lab_id));
        
        //console.log(res.response['_id']);

         sessionStorage.setItem('id',res.response[0]['_id']);
         this.testid=sessionStorage.getItem('id')
        console.log(sessionStorage.getItem('id'));
        console.log(this.testid,'ck');
        
        //swal('Upload your doctor registration documents to complete the enrollement');
        //sessionStorage.setItem("email",res.email)
       //swal("You Are Just Registered!! Please Upload Documents To Complete the Process");
        this.router.navigate(['/file-upload'])
        // this.router.navigate(['DoctorDataComponent']);
        .then(() => {
          // location.reload();
        });
      } else {
        swal("Please enter valid details")
      }
    }, (error) => {
      swal('Already Online Enrolled !','','warning')
      // this.router.navigate(['/file-upload'])
    })

   
  }


}
