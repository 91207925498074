import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-patient-allergy',
  templateUrl: './patient-allergy.component.html',
  styleUrls: ['./patient-allergy.component.css']
})
export class PatientAllergyComponent implements OnInit {

  [x: string]: any;
  userData: any;
  patient_id: string;
  searchOptions = {
    year:"",
    docter_name:"",
    child_name:""
  };
  visits={
    patient_name:'',
    doctor_name:'',
    child_name:''
  }
  years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  vaccinedata: any;
  constructor(
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    private datePipe: DatePipe,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
  ) { 


    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    console.log(100, this.userData);
    this.patient_id=sessionStorage.getItem("patient_id"); 

    console.log(1001, this.patient_id);

    this.doctorlist();
    this.Search();
  }

  ngOnInit() {
    window.scrollTo(0,0)
    // this.patientVaccine();
    
  }

  pageChanged(event){
    this.config1.currentPage = event;
  }

  doctorlist(){
    if(this.docter_name==undefined)
    {
      this.docter_name="";
    }
    this.auth.doctorlistAller({patient_id:this. patient_id}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         console.log(33,this.doctlist);
         this.list(); 
     }
    }, () => {
     }
     );
   }
   Search() {
    let date=this.searchOptions.year.split('T');
    let newDate = date[0].split('-');
    let day=newDate[1];
    let month=newDate[2];
    let year=newDate[0];
    this.searchOptions.year=year;

    //  this.auth.patienthistory({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
    //   if (res.status == 'success') {
    //       this.visits = res.response.reverse();
    //       this.config1 = {
    //         itemsPerPage: 5,
    //         currentPage: 1,
    //         totalItems: this.visits.count
    //       };
    //       console.log(39,this.visits);
    //       this.labhistory();
    //   }
    // }, console.log);
    this.auth.tablesearch3({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name,docter_name:this.searchOptions.docter_name}).subscribe(res => {
      if (res.status == 'success') {


        
          // this.visits = res.response[0].docter_visits.reverse();
          //this.vaccinedata=res.response[1].required_vaccines.reverse();

         // res.response[0].data.filter(x=>{if(x.data){ let a=[]; a.push(x.data);this.allergydata=a[0];this.allergydata.reverse();this.allergydata[0].patient_name=x.patient_name,this.allergydata[0].child_name=x.child_name;console.log(this.allergydata)}})
         
          //res.response[0].allergies.filter(x=>{if(x.data){ let a=[]; a.push(x.data);this.allergydata=a[0];this.allergydata.reverse();this.allergydata[0].patient_name=x.patient_name,this.allergydata[0].child_name=x.child_name;console.log(this.allergydata)}})
         
         
          if(res.response.length==0){
            this.allergydata=''
          }
        //  else if(!res.response[0].data){
        //     this.allergydata=''
        //   }
          else
          {
            console.log(res.response);
            let ck=res.response
            var k=[]
            for(let x of ck){
              console.log(x);
              console.log(x.docter_name);
              console.log(x.data);
         var docter_name=x.docter_name     
         var patient_name=x.patient_name
         var child_name=x.child_name
              for(let a of x.data){
                console.log(a);
                a.patient_name=patient_name
                a.child_name=child_name
                a.docter_name=docter_name
                k.push(a)
                console.log(k.length,k);
                
              }
              
            }
  
            this.allergydata=k
            
          //  this.allergydata=res.response.reverse()
          }
          console.log("vcccc===>", this.allergydata.reverse())
          console.log(this.visits);

          // this.visits.patient_name=res.response[0].patient_name
          // this.visits.child_name=res.response[0].child_name
          // this.visits.doctor_name=res.response[0].docter_name
          // this.allergydata.reverse()
          console.log(this.allergydata);
          

          this.config1 = {
            itemsPerPage: 5,
            currentPage: 1,
            totalItems: this.allergydata.count
          };
          console.log(39,this.visits);
          // this.labhistory();
      }
    }, console.log);

  }



list()
{
   // this.labupdateForm .value.docter_id=this.docter_id;
    this.auth.findchild( {patient_id:this.patient_id}).subscribe(res => {
      
      if (res.status == "success") {
        if (res.response != "") {
       this.userData=res.response.reverse();
       
         // sessionStorage.setItem("full_name",this.name);
         // this.router.navigateByUrl("/patientsearch");
        }
     
      }
    },
       
 
     //   () => {
     //  alert("Login failed, Login detils not exits ");
     // }
     );
   }
   logo(){
    this.router.navigate(['/login']);
  }
}