import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MzBaseModal } from 'ngx-materialize';
import { PinModelComponent } from '../../pin-model/pin-model.component';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-docpin',
  templateUrl: './docpin.component.html',
  styleUrls: ['./docpin.component.css']
})
export class DocpinComponent extends MzBaseModal  implements OnInit  {

  pin:any;
  validations_form: FormGroup;

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  phone_no: any;
  dob: any;
  dateOfBirth: any;
  age: number;
  DateOfBirth: number;
  Dob: any;
  dtBirth: any;
  age12333: number;
  birthdate: any;
  constructor(

    public dialogRef: MatDialogRef<DocpinComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,private fb: FormBuilder,private router: Router,protected auth: AuthService,
    ) {



      
    super();
  
    this.validations_form = this.fb.group({
      phone_no:['', Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(1),
      ])],
     pin:['', Validators.compose([
       Validators.required,
       Validators.maxLength(4),
       Validators.minLength(4),
     ])],
    
     });


     
   }
 


   validation_messages = {
    'pin': [
      { type: 'required', message: 'pin_no  is required.' },
      { type: 'maxlength', message: 'pin_no cannot be more than 4 digits.' },
      { type: 'minLength', message: 'pin_no cannot be less than 4 digits.' },
    ]
    ,
    'phone_no': [
      { type: 'required', message: 'Patient Id/ User Id  is required.' },
      { type: 'maxlength', message: 'Patient Id/ User Id cannot be more than 10 digits.' },
      { type: 'minLength', message: 'Patient Id/ User Id cannot be less than 4 digits.' },


    ]
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }

  Search(){





    this.auth.pinlab({phone_no:this.phone_no,pin_no:this.pin}).subscribe(res => {
      if (res.response.length > 0) {
        console.log(111,res.response)

this.dob = res.response[0].date_of_birth;
       
console.log("000000000", this.dob)
  
  
  //this.Dob= this.dob.split("-").reverse().join("-");
  
  this.birthdate = this.dob;
  if (this.birthdate) {
  var timeDiff = Math.abs(Date.now() - new Date(this.birthdate).getTime());
  this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

  console.log("1" ,this.age)
    }





//   const today = new Date();
// let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
// this.dtBirth = date.split("-").reverse().join("-");

// this.age12333 = +(this.dtBirth) - +(this.dob)
//     alert(this.age12333)

      





          if (res.response != "") {
              let name=res.response[0];
              sessionStorage.setItem("patient_id",name.patient_id);
              sessionStorage.setItem("full_name",name.full_name);
              sessionStorage.setItem("age", this.age.toString());
              
              //console.log("101======>", sessionStorage.getItem("age"));

             sessionStorage.setItem("phone_no", name.phone_no);


              sessionStorage.setItem("gender", name.sex);
              sessionStorage.setItem("dob", name.date_of_birth.toString());
              sessionStorage.setItem("email", name.email);
              sessionStorage.setItem("address", name.address);
              sessionStorage.setItem("state", name.state);
              sessionStorage.setItem("city", name.city);  



              // sessionStorage.setItem("patient_id",this.patient_id);
              // sessionStorage.setItem("lab_phone",this.lab_phone);
              this.dialogRef.close();

              // this.router.navigate(['/patientsearch']).then(() => {
              //   window.location.reload();
              // });
                
              

              // this.router.navigateByUrl("/Labtestupdate");
              this.restrict();
          }
      } else {
        console.log("false")
            swal("please enter registered phone number or pin")
      }
    }, () => {
      swal("Login failed, Login detils not exits ");
     }
     );
   }
   restrict(){
    this.auth.checkrest({patient_id: sessionStorage.getItem("patient_id"),docter_id:sessionStorage.getItem('doc_id')}).subscribe(res => {
console.log(res);
let checkresult=res
if (checkresult['status'] == "success") {
  if (checkresult['response'] != '') {
      let restrickey=checkresult['response'][0];
      if(restrickey.restricted=="1") {
            swal("Oops, You Are Restricted !","You Are Not Authorised By Patient To See His/Her Details","warning");
      }
       else{
     this.router.navigate(['/patientsearch']).then(() => {
          });
      }
}

if (checkresult['response'] == '') {
  this.router.navigate(['/patientsearch']).then(() => {
  });
}
}
    });

    this.dialogRef.close();
   }
}
