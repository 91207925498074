import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-connect-doctor',
  templateUrl: './connect-doctor.component.html',
  styleUrls: ['./connect-doctor.component.css']
})
export class ConnectDoctorComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) { }

  ngOnInit() {
  }
  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   }

}
