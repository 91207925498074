import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MaterializeAction } from 'angular2-materialize';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NeedadocComponent } from '../needadoc/needadoc.component';
declare var swal;

@Component({
  selector: 'app-allocdoc',
  templateUrl: './allocdoc.component.html',
  styleUrls: ['./allocdoc.component.css']
})
export class AllocdocComponent extends MzBaseModal implements OnInit {



  price: any;
  dt:any;
    neddForm: FormGroup;
    lablis: any;
    patient_id: any;
    doctlist: any;
    booksByStoreID:any=[];
   
      @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  showSuccess: boolean;
  id: any;
  email_address: any;
  payer_id: any;
  purchase_units: any;
  value: any;
  country_code: any;
  fundingSource: any;
  patient_name: any;
  speciality: any;
  docter_phone_no: any;
  che: boolean;
  docter_id: any;
  alloc_time: any;
  doczoom_id:any;
  zoom_id: any;
  docter_name1: any;
  call_type: any;

      constructor(
        private fb: FormBuilder,
        private router: Router, 
       
        protected auth: AuthService,
       
        public dialogRef: MatDialogRef<NeedadocComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected route: ActivatedRoute) {
        super();

      console.log(9012,data.call_type)
  this.call_type=this.data.call_type;
      this.Assign();

        }
  
 

     
  ngOnInit() {

    this.neddForm = this.fb.group({
      docter_name: ['', Validators.required],
      patient_id:[''],
      alloc_status:[1],
      docter_id:[''],
      docter_phone_no:[''],
      doczoom_id:[''],

      doczoom_pwd:[''],
      
      _id:[''],
      alloc_time:[''],
      assignedby:['']
    });

      
    this.Assign();

  }
  

  check(e){
    console.log(e.target.checked);
  if(e.target.checked ==true){
    this.che=true;

  }
  else{
   this.che=false;
  }

  }



  close() {
    this.dialogRef.close();
  }
  save() {
    //alert(this.alloc_time);
    this.neddForm.value.patient_id=this.data.patient_id;
    this.neddForm.value._id=this.data._id;
    this.neddForm.value.assignedby=sessionStorage.getItem("id");



    this.auth.allocate(this.neddForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){

          swal("Doctor assigned successfully");
    this.dialogRef.close();

          //this.router.navigateByUrl("/medicaldetails")
        }
      }
    })
  }
 zoom()
 {
  this.dialogRef.close();

  this.router.navigateByUrl("/LazyModule/zoom");

 }
Assign(){

    this.auth.docBytype({type:1}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response;
         this.docter_name1=this.data.docter_name;
         
       this.neddForm.value.docter_name=this.data.docter_name;
this.getchild(this.docter_name1)
         console.log(33,this.doctlist);
      }
    }, (err) => {

     }
     );
}
   

getchild(e) {



 this.booksByStoreID = this.doctlist.filter(
   book => book.docter_name === e);
   this.docter_phone_no=this.booksByStoreID[0].docter_phone;
   console.log(101,this.booksByStoreID)

   console.log(2013,this.booksByStoreID[0].docter_id)
   
   this.docter_id=this.booksByStoreID[0].docter_id;

 //  this.neddForm.value.docter_id = this.docter_id;

   this.neddForm.value.docter_name=this.booksByStoreID[0].docter_name;
   this.neddForm.value.docter_phone_no=this.booksByStoreID[0].docter_phone;

   

 
 }




   getprice() {
      let value;
// if(e.target.value)
// {
//  value=e;
 
// }
// else{
//    value=e.target.value;

// }
value=this.docter_name1;
    this.booksByStoreID = this.doctlist.filter(
      book => book.docter_name === value);
      this.docter_phone_no=this.booksByStoreID[0].docter_phone;
      console.log(101,this.booksByStoreID[0].docter_phone)

      console.log(2013,this.booksByStoreID[0].docter_id)
      
      this.docter_id=this.booksByStoreID[0].docter_id;

    //  this.neddForm.value.docter_id = this.docter_id;

      this.neddForm.value.docter_name=this.booksByStoreID[0].docter_name;
      this.neddForm.value.docter_phone=this.booksByStoreID[0].docter_phone;

      

    
    }



  
   
}
