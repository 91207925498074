import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-consult-doc',
  templateUrl: './consult-doc.component.html',
  styleUrls: ['./consult-doc.component.css']
})
export class ConsultDocComponent implements OnInit {
   sts:any;
   consult:any;
  constructor(
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,public dialog: MatDialog,
    protected route: ActivatedRoute,
    public http:HttpClient
  ) { }

  ngOnInit() {

    window.scrollTo(0,0)
 // this.doctorConsult();
   
  }
  // doctorConsult(){
  //   this.http.post('http://13.233.122.108:4200/doctor_online/findonlineDoctor','').subscribe(res=>{
  //     console.log(res['response']);
  //     this.sts=res['response'];
  //     console.log("1000====>", this.sts)

     
  //   })
  // }
  logo(){
    this.router.navigate(['/login']);
}
  sptype(a){
console.log("8000",a);
var b=sessionStorage.setItem('speciality',a);
console.log("1", sessionStorage.getItem('speciality'));


  }

}
