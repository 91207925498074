import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  items = [
    {id: 1, name: 'Blood Test'},
    {id: 2, name: 'Sugar Test'},
    {id: 3, name: 'Liver Test'},
    {id: 4, name: 'Urine Test'},
    {id: 5, name: 'Kidney Test'},
    {id: 6, name: 'ECG'},
    // {id: 7, name: ''},
    // {id: 8, name: 'ReactJs'},
  ];

  selected = [
    {id: 2, name: 'Blood Test'},
    {id: 4, name: 'Urine Test'}
  ];

 
  
  constructor() { 
    
  
  }

  ngOnInit() {
   
  }

}
