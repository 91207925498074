import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Inject, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ViewimageComponent } from 'src/app/pages/viewimage/viewimage.component';
import { ConformModelComponent } from 'src/app/pages/patient/conform-model/conform-model.component';
import { ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SignaturePad }  from 'angular2-signaturepad/signature-pad';
import { DsigndeleteComponent } from 'src/app/dsigndelete/dsigndelete.component';
declare var swal;
@Component({
  selector: 'app-signature-doctor',
  templateUrl: './signature-doctor.component.html',
  styleUrls: ['./signature-doctor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureDoctorComponent),
      multi: true,
    },
  ],
})

export class SignatureDoctorComponent extends MzBaseModal implements OnInit{


  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  @ViewChild (SignaturePad,{static: false}) public signaturePad: SignaturePad;

  sname:any;
  result: any;
  imagesurl: any[];
  lab_id: any;
  images: string | Blob;
  userData: any;
  phone_no: string;
  id: any;
  constructor(public dialogRef: MatDialogRef<SignatureDoctorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private modalService: MzModalService,

    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected activatedRoute: ActivatedRoute) {


    super();
    this.lab_id= sessionStorage.getItem("lab_id");
    this.id= sessionStorage.getItem("id");

  //  document.addEventListener('touchstart', handler, {passive: true});
     }
  ngOnInit() {
    document.addEventListener("touchstart", function(e) {
      e.preventDefault(); // does nothing since the listener is passive
    }, {
      passive: true
    });
    this.getimg(this.id);
  
  }


  public options: Object = {};

  public _signature: any = null;

  public propagateChange: Function = null;

  get signature(): any {
    return this._signature;
  }

  set signature(value: any) {
    this._signature = value;
    console.log('set signature to ' + this._signature);
    console.log('signature data :');
    console.log(this.signaturePad.toData());
    this.propagateChange(this.signature);

  }

  public writeValue(value: any): void {
    if (!value) {
      return;
    }
    this._signature = value;
    this.signaturePad.fromDataURL(this.signature);
  }

  // public registerOnChange(fn: any): void {
  //   this.propagateChange = fn;
  // }

  // public registerOnTouched(): void {
  //   // no-op
  // }

  // public ngAfterViewInit(): void {
  //   this.signaturePad.clear();
  // }

  // public drawBegin(): void {
  //   console.log('Begin Drawing');
  // }

  public drawComplete(): void {
    this.signature = this.signaturePad.toDataURL('image/png', 0.5);
  }

  public clear(): void {
    this.signaturePad.clear();
    this.signature = '';
  }






  close()
  {
    this.dialogRef.close();
  }
  selectImage(event){
    if(event.target.files.length>0){
      const file= event.target.files[0];
      this.images = file;
    }
  }
  dataURLToBlob(dataURL) {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }




  
  onSubmit(){

    if(this.imagesurl.length > 0)
         {
          swal("please delete Previous Signature");

         }
         else{
           if(this.images ==undefined && this.signature ==undefined){
            swal("please add signature");
              }
           
          
let blob;
    const formData = new FormData();
    console.log("images",this.images);
    if(this.images!=undefined){
      formData.append('file', this.images);

    }
    else{
       blob = this.dataURLToBlob(this.signature);

      formData.append('file', blob);

    }

    console.log("inn",this.signature);
    formData.append('id', this.id);
    this.auth.doctorsignature(formData, this.id).subscribe(
   
            (res) =>{
              this.images="";
              swal("Uploaded Successfully");
              this.getimg(this.id);
              this.images="";

            } ,
            (err) => console.log(err) 
       );
      //  location.reload()
       }
      }
       getimg(labid) {
        // location.reload();
         this.auth.Doctorsigimages({_id:labid}).subscribe(async(res) => {
           
           this.result=res;
           console.log("Console Doc Images...!", this.result);
           if (this.result.status == "success") {
             this.result=this.result.response;
             this.imagesurl=[];
            for(let obj of this.result[0].imagPath)
             {
               console.log(sessionStorage.getItem('baseUrl')+obj);
               await this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
             }
           }
          }, () => {
           swal("Server failed");
     
         });
       }

       view(img): void {
        const dialogRef = this.dialog.open(ViewimageComponent, {
          width: '100px',
          data:img
    
        });
        dialogRef.afterClosed().subscribe(result => {
       
          console.log(result.sts)
         
       
        //  this.login();
        });
      }
      deleteupload(id,url)
      {
        this.auth.docsigdelet({_id:id,imagePath:url}).subscribe(res => {
          this.result=res;
          if (this.result.status == "success") {
            this.result=this.result.response;
            
            this.getimg(this.id);

            this.close()

            swal('Signature Deleted !')
          }
         }, (err) => {
          swal("Server failed");
    
        });
      }
      confirmdeleat(id,url): void {
        const dialogRef = this.dialog.open(DsigndeleteComponent, {
          width: '80px',
          height:'80px'
          
        });
        dialogRef.afterClosed().subscribe(result => {
       
          console.log(result.sts)
          if(result.sts=="yes")
          {
           this.deleteupload(id,url)
          }
       
        });
      }
      drawBegin(){
        
      }
}
