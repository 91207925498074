import { Component, OnInit, Optional, Inject, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MzBaseModal } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';

@Component({
  selector: 'app-report-test',
  templateUrl: './report-test.component.html',
  styleUrls: ['./report-test.component.css']
})
export class ReportTestComponent extends MzBaseModal  implements OnInit {

  result: any;
  testname: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;

  constructor( public dialogRef: MatDialogRef<ReportTestComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected activatedRoute: ActivatedRoute,) { 

    super();
  //   this.activatedRoute.queryParams.subscribe(params => {
  //     this.result = JSON.parse(params['reports']);
  //     this.testname=params['testname'];
   
  //     console.log("hi test_id",this.result ); // Print the parameter to the console. 
  // });
  this.result=this.data.reports.reverse();
  this.testname=this.data.testname;

  }

  ngOnInit() {
  }
  
  close()
  {
    this.dialogRef.close();

  }

}
