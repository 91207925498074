import { Component, OnInit, Input, EventEmitter, Optional, Inject } from '@angular/core';
import { MaterializeAction } from 'angular2-materialize';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MzModalService, MzBaseModal } from 'ngx-materialize';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-editassign',
  templateUrl: './editassign.component.html',
  styleUrls: ['./editassign.component.css']
})
export class EditassignComponent extends MzBaseModal  implements OnInit {

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  sname:any;
    arr=[];
    patient_id: any;
    response: any;
    names: any;
    userData: any;
    reason:any;
    child_name: any;
    test_name:any=[];
    testAssignForm: FormGroup;
    child: any;
    dropdownSettings:{};
   full_name: string;
  selectedItems: any=[];
  result: any;
  imagesurl: any[];
  doc_date: any;
  reports: any;
  visits: any;
  test_res: any;
  datas: any;

    

    constructor(public dialogRef: MatDialogRef<EditassignComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private modalService: MzModalService,

      private fb: FormBuilder,
      private router: Router,
      protected auth: AuthService,
      protected activatedRoute: ActivatedRoute) {


      super();
         this.userData=JSON.parse(sessionStorage.getItem("userData"));
         this.patient_id=sessionStorage.getItem("patient_id")
         this.full_name=sessionStorage.getItem("full_name")
         console.log(22,this.patient_id)
         this.testNames();
         this.childNameDropDwon();

         this.dropdownSettings = {
          singleSelection: false,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
       };
    
       
       this.datas  = data;
       }
       
 
  ngOnInit() {
    this.testAssignForm  =  this.fb.group({
      labtest_required: [true],
      test_name:[[]],
      patient_name:[''],
      child_name: ['',Validators.required],
      age:[''],
      gender:[''],
      phone_no:[''],
      _id:['']

      });
      this.testAssignForm.patchValue(this.datas.test_id);
      this.child_name=this.datas.test_id.child_name;
      this.selectedItems=this.datas.test_id.test_name
  }
  showname()
  {
    this.sname=1;
    
  }
  save() {
    let gender;
    let age;
    let phone;
if(this.child_name!="" && this.child_name!=undefined)
{
    let myItem = this.child.find(item => item.name === this.child_name);
    gender=myItem.gender;
    phone=sessionStorage.getItem("phone_no");
    age=myItem.age;
}
else{
  age=sessionStorage.getItem("age");
  phone=sessionStorage.getItem("phone_no");
  gender=sessionStorage.getItem("sex");
}


    if(this.testAssignForm.value.test_name  == ''){
      swal("Please fill the details")

    } else {
      
      for(let obj of this.selectedItems)
      {
        let myItem =this.names.find(s => s.name === obj.name);
        this.arr.push(myItem)
        console.log('arr:', this.arr);
      }
      this.testAssignForm.value.test_name=this.arr;
    
      this.testAssignForm.value.gender=gender;
      this.testAssignForm.value.age=Number(age);
      this.testAssignForm.value.phone_no=phone;
      this.testAssignForm.value.patient_name= sessionStorage.getItem("full_name");


      this.testAssignForm.value.patient_id=this.patient_id;
      this.testAssignForm.value.docter_id="";
      this.auth.editdoc(this.testAssignForm.value).subscribe(res => {
        if (res.status == "success") {
          if (res.response != "") {
          var res= res.response;
           this.dialogRef.close(res);
          }
        }
      });
     }

    }
  
  testNames(){
    this.auth.totalTestNames({patient_id:this.patient_id}).subscribe(res => {
        this.response = res;
      if(this.response) {
          this.names = this.response.response;
          console.log(98,this.names)
      } else {
        swal("Server error")
      }
    })
  }
  childNameDropDwon() {
    this.auth.findchild({patient_id:this.patient_id}).subscribe(res => {
      this.child=res;
      if(res.response) {
          this.child = this.child.response;
      } else {
        swal("Server error")
      }
    })
   }
   onItemSelect(event) {
     
    
  }




  onSelectAll(items: any) {
    console.log(items);
  }

  close()
  {
    this.dialogRef.close();
  }
  


  
}
