import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lsigndelete',
  templateUrl: './lsigndelete.component.html',
  styleUrls: ['./lsigndelete.component.css']
})
export class LsigndeleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
