import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { title } from 'process';

declare var swal;
@Component({
  selector: 'app-requesthid',
  templateUrl: './requesthid.component.html',
  styleUrls: ['./requesthid.component.css']
})
export class RequesthidComponent implements OnInit {

  isPopupOpened=true;
  userData: any;
  full_name: string;
  patient_id: string;
  visits: any;
  modal: any;
  _id: any;
 response:any;
 private show = true
  config: { itemsPerPage: number; currentPage: number; totalItems: number ; };
  param: string;
  convertObject: any;
  qparams: any;

  constructor(private router: Router,
    public dialog: MatDialog,
  private formBuilder: FormBuilder,
  protected auth: AuthService,
  protected route: ActivatedRoute,
 ) {

  // if( sessionStorage.getItem("docter_phone")==" " || sessionStorage.getItem("docter_phone") == undefined){
  //   this.router.navigateByUrl('/login2');
  // }
    this.visits=[];
     this.patient_id= sessionStorage.getItem("patient_id");
     this.patientlist();
     //console.log(222,this.router.getCurrentNavigation().extras.state.example);
   }

  ngOnInit() { 
    window.scrollTo(0,0)
    this.patientlist();
    this.show = true; 

    if(localStorage.getItem("payment_sts")=="1"){

        console.log(201, "helloo")


        setTimeout(()=>{ 
          swal({
            title: 'NOTICE !',
            text : "Please re-visit the same page in 10 minutes, to start the consultation with the doctor by clicking on meeting link."
          })
          localStorage.setItem('payment_sts', '0');
         }, 2000);
        

    }
    
    // this.auth.childremoveData.subscribe(data => {
    //   let ind = this.visitslist.findIndex(patient => patient._id === data._id);
    //   if(ind !== null) {
    //     this.visitslist.splice(ind, 1);
    //   }
    // });

  //   setTimeout(function(){ 
    
  // swal("Here's a message!")
  //   }, 2000)

  this.route.queryParams.subscribe(queryParams =>{
//     if(queryParams==queryParams){
//       swal("Meeting Schedule ! Pls Wait For 10 Mins")

// history.pushState(null, null, location.href);
// queryParams={}
//     }
    console.log(queryParams);
    this.qparams = queryParams;
    console.log(JSON.stringify(queryParams.status));
    this.param = JSON.stringify(queryParams.status)
    this.convertObject = JSON.parse(this.param)
    console.log( this.convertObject);
    // this.param = JSON.stringify(queryParams.status._id)
    // localStorage.setItem('popState',queryParams.status._id)
  })
  // if(this.convertObject){
  //   console.log(201, "helloo")
  //   swal({
  //     title: 'NOTICE !',
  //     text : "Please re-visit the same page within 10 minutes to check the schedule time of your meeting and meeting link."
  //   })
  //   sessionStorage.setItem('popState', 'true');
  // }

  // if (sessionStorage.getItem('dontLoad') == null){
  
  //   swal({
  //     title: 'NOTICE !',
  //     text : "Please re-visit the same page within 10 minutes to check the schedule time of your meeting and meeting link."
  //   })
  //   sessionStorage.setItem('dontLoad', 'true');
  // }
  }
  patientlist(){

    this.auth.findrequestid({patient_id:this.patient_id}).subscribe(res => {
      
      if (res.status == "success") {
     {
     
         this.visits=res.response.reverse(); 
        //  console.log("32",res.response[0]._id);
        //  this.userData=res.response;

        //  sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //    sessionStorage.setItem("patient_id",this.userData.patient_id);
         
        //    this.userData=JSON.parse(sessionStorage.getItem("userData"));
        //    this.userData= this.userData[0];
        //    console.log(76,this.userData.patient_id)

        this.config= {
          itemsPerPage: 6,
          currentPage: 1,
          totalItems: this.visits.count
      
        }
     
     }}

    },
  
 
    //    () => {
    //   swal("Login failed, Login detils not exits ");
    //  }
     );

   }
  
   pageChanged(event){
    this.config.currentPage = event;
  }


  // deletepatient(patient): void {

  //   const dialogRef = this.dialog.open(ChilddeleteComponent, {
  //     //  height: '100px',
  //     //  width: '100px',
  //     width:'10px',
  //      panelClass: 'my-dialog-container',
  //     data :{patient}
  //   });
    
  //   dialogRef.afterClosed().subscribe(result => {
  //   this.patientlist();
  //   });


  
  // }
    
// this.auth.childremove( {_id:patient._id}).subscribe(res => {
         
//       if (res.status == "success") {
//         this.auth.childremoveData.next(res.response);
//         if (res.response != "") {

   
//         }
     
//       }
//     },
       
 
//        () => {
//       alert("Login failed, Login detils not exits ");
//      }
//      );
zoom(zoom_id,zoom_pwd,patient_name)
{
  sessionStorage.setItem("zoom_id",zoom_id);
  sessionStorage.setItem("zoom_pwd",zoom_pwd);
  sessionStorage.setItem("zoom_name",patient_name);
console.log(zoom_id,zoom_pwd,patient_name)
 this.router.navigateByUrl("/LazyModule/zoom")

}
logo(){
  localStorage.setItem('login','');
  location.reload();
// this.auth.logout()
// this.router.navigate(['/login2'])
}


  }
