import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-user-plan',
  templateUrl: './user-plan.component.html',
  styleUrls: ['./user-plan.component.css']
})
export class UserPlanComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

a:Number;
  basic(a){
console.log(this.a=a);
if(a===1){
console.log("BASIC");
//swal('YOU HAVE SELECTED BASIC PLAN !');

this.auth.plan("BASIC");
}
else if(a===2){
  console.log("STANDARD");
  //swal('YOU HAVE SELECTED STANDARD PLAN !');
  
this.auth.plan("STANDARD");
}
else if(a===3){
  console.log("PREMIUM");
  //swal('YOU HAVE SELECTED PREMIUM PLAN !');
  
this.auth.plan("PREMIUM");
}
else{
  console.log("NOPLAN");
}

this.router.navigate(['lab-reg']);

  }
}
