import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demovideo',
  templateUrl: './demovideo.component.html',
  styleUrls: ['./demovideo.component.css']
})
export class DemovideoComponent implements OnInit {

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }
  openVi(){
    window.open('./assets/videos/Video Call Consultation.mp4');
    this.ngOnInit()
  }
  logo(){
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
