import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

declare var swal;

@Component({
  selector: 'app-doc-request',
  templateUrl: './doc-request.component.html',
  styleUrls: ['./doc-request.component.css']
})
export class DocRequestComponent implements OnInit {

  constructor(public http:HttpClient, public auth:AuthService, private fb: FormBuilder, private router:Router) {
    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
   }

  a:Object[] | any=[];
  b:Object={};
  baseurl:any
  ngOnInit() {
this.baseurl=this.auth.baseUrl
    this.auth.doc_find().subscribe((data)=>{
//'http://192.168.1.50:4100/doctor_online/findall',''

  this.a=data['response'].reverse()
      console.log(data['response']);
 for(let x of this.a){

        console.log(x);
      
      }
console.log(this.a);

      })
  }

  app(){
    sessionStorage.setItem('docapprove','1')
  }


  //Backend Approve
  Approve_mail(e,_id,email,sts,text) {
  
    this.auth.doc_approved({_id:_id,email:email,status:sts,text:text}).subscribe(res =>{
    console.log(_id);
      
    console.log("email", email);

    swal("You have Approved the request !");
    window.location.reload();
    //  if(res.status == "Email Sent To User"){
    //  }
    })
   }

   //backend decline
Decline_mail(text) {
   
  this.auth.doc_approved({status:0,text:text,_id:this.b['_id'],email:this.b['email']}).subscribe(res =>{
        console.log("email :", this.b['email']);
 
        console.log("Declined Text :", text);
        
        swal("You have Declined the request !");
        window.location.reload();
      //  if(res.status == "Email Sent To User"){
        
      //  }
      })
     }

 
    //popup
     Decline2_mail(_id,email){

      this.b={};
      console.log(this.b);
      
     console.log(_id,email);
       this.b[_id]=_id;
       this.b['email']=email;
      console.log(this.b);
      
     }


//Logout     
logout(){
  
  localStorage.clear();

sessionStorage.clear();

this.router.navigate(['/admin-sign'])

 }


 new(i){
  window.open(this.baseurl+'/labdoc/fileinfo/'+i)
}
    }





