import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-payment-overdue',
  templateUrl: './payment-overdue.component.html',
  styleUrls: ['./payment-overdue.component.css']
})
export class PaymentOverdueComponent implements OnInit {
  due_date: Date;
  paid_date: Date;

  constructor(private auth:AuthService, private router:Router) {
    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/backend');
    }
   }
lab:any;
res:any={};
config = {
  itemsPerPage: 0,
  currentPage: 0,
  totalItems:0
};


  ngOnInit() {
    this.auth.pymntOdreports().subscribe((res)=>{
      console.log(res);
      
      this.lab=res['response'].reverse();

      this.config = {
        itemsPerPage: 15,
        currentPage: 1,
        totalItems: this.lab.count
      };
// this.config.totalItems=this.lab.count
      
    })
  }


  pageChanged(event){
    this.config.currentPage = event;
  }



  procespy(_id,a,b,c,d,e,f){

console.log(_id,a,b,c);
this.res._id=_id;
this.res.lab_id=a;
this.res.plan=b;
this.res.lab_name=c;
this.res.order_id=d;
this.res.test_use=e;
this.res.signature=f;

this.auth.lab_user_gst({lab_id:this.res.lab_id}).subscribe(res=>{ 
  console.log(res);
  if (this.res['plan'] =='BASIC') {
    this.res.amount=1180;
  }

  if (this.res['plan'] =='STANDARD') {
    this.res.amount=2360;
  }

  if (this.res['plan'] =='PREMIUM') {
    this.res.amount=3540;
  }
  console.log(this.res.plan);
  
})





var today = new Date();
// var dd = String(today.getDate()).padStart(2, '0');
// var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
// var yyyy = today.getFullYear();
this.paid_date = today;
console.log(this.paid_date);
//duedate function
var date = new Date();
        	   var dueDate = new Date().setDate(date.getDate()+30)
        	   var newDate = new Date(dueDate)
             this.due_date = newDate
             console.log(this.due_date)
console.log(this.res);

// this.auth.labedit({_id:this._id,payment_status:true}).subscribe(res => {
//   if (res.status == 'success') {
  }

  logout(){
  
    // localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/backend'])
 
   }

   Decline_mail1(a){ console.log(a); this.res.test_use=a};


   Decline_mail(){

    this.auth.labPayment({lab_id:this.res.lab_id,lab_name:this.res.lab_name,plan:this.res.plan,amount:this.res.amount,order_id:this.res.order_id,paid_date:this.paid_date,due_date:this.due_date,payment_status:1,signature:this.res.signature,test_use:this.res.test_use}).subscribe(res=>{
      console.log(res);

    if(res){
      this.auth.labedit({_id:this.res._id,payment_status:true,payment_date:this.paid_date}).subscribe(res => {
        console.log(res);
        swal('Payment Processed Successfully').then(()=>{
          //location.reload(); 
        })
       })
    }
    
    })


   }

}
