import { Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Optional } from '@angular/core';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal } from 'ngx-materialize';
import { AuthService } from 'src/app/services/auth.service';
import { PrintComponent } from 'src/app/vedio/print/print.component';
declare var swal;
@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.css']
})
export class PrescriptionComponent  extends MzBaseModal  implements OnInit {
  [x: string]: any;
  result: any;
  id: any;
  testname: any;
  imagesurl:any;
  sign:any;
  result1:any;
  docsign:any;
  _id:any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  idfgdfd: any;
  patientReport:any
  constructor( public dialogRef: MatDialogRef<PrescriptionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected activatedRoute: ActivatedRoute,
    public dialog: MatDialog
    ) { 

      

  
    super();
        this.result =data;

        console.log(44, this.result.test_name);
        
        this.idfgdfd = data._id
        console.log(2000,this.idfgdfd)
        // if(data.docsign !== undefined){
          this.docsign = this.auth.baseUrl + data.docsign;
        // }else{
          // this.docsign = this.auth.baseUrl + data.imageUrl;
        // }
        
        console.log("hi test_id",this.result,"sign", this.docsign , this._id); 
        // Print the parameter to the console. 
        this.patientReport=sessionStorage.getItem("patientdata01");

        console.log(55, this.patientReport);
        

    }

   

  ngOnInit() {
    this.id= sessionStorage.getItem("id");
    this.getimg(this.id);
  }
 
  getimg(labid) {
    // location.reload();
     this.auth.Doctorsigimages({_id:labid}).subscribe(async(res) => {
       
       this.result1=res;
      
       if (this.result1.status == "success") {
         this.result1=this.result1.response;
         this.imagesurl=[];
        for(let obj of this.result1[0].imagPath)
         {
           console.log(sessionStorage.getItem('baseUrl')+obj);
           await this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
         }
       }
       this.sign = this.result1[0].imagPath[0];
       this.sign=(this.auth.baseUrl+this.sign).trim();
        console.log("Console Doc Images prescription...!", this.result1[0].imagPath[0]);
      }, () => {
       swal("Server failed");
 
     });
   }
   close()
   {
     this.dialogRef.close();
 
   }
   report(tid,docid,doc_date,complete,range,child_name,timeStamp,test_date,docter_name,patient_id,patient_name,gender,age,phone_no,lab_name,lab_city,imageUrl,user_name) {
    this.doc_date=doc_date;
    this.auth.test_report({complete:complete,test_name:tid,patient_id:patient_id,docter_id:docid,doc_date:this.doc_date,range:range?range:"",child_name:child_name?child_name:"",timeStamp:timeStamp,user_name:user_name}).subscribe(res => {
      this.reports=res;
      console.log("imageUrl"+imageUrl[0]);
      if (this.reports.status == "success") {
          this.reportview({reports:this.reports.response,child_name:child_name,testname:tid,doc_date:doc_date,date:test_date,docter_name:docter_name,patient_name:patient_name,gender:gender,age:age,phone_no:phone_no,lab_name:lab_name,lab_city,sig:imageUrl,user_name:user_name});
        }
    }, (err) => {
        swal("server failed, server details not exits ");
     });
   }
   reportview(e) {
    const dialogRef = this.dialog.open(PrintComponent, {
      width: '250px',
      data: e
    });
    dialogRef.afterClosed().subscribe(result => {
let vr=this.visits;
this.visits=vr;


      console.log('The dialog was closed');
                 });
  
  }
}

