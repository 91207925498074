import { Component, OnInit, Renderer } from '@angular/core';
import {MatDialogModule, MatDialog} from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { MzBaseModal } from 'ngx-materialize';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { RestrictdocmodelComponent } from '../patientfolder/restrictdocmodel/restrictdocmodel.component';
import { EditpatientComponent } from '../editpatient/editpatient.component';
declare var swal;
@Component({
  selector: 'app-rest-doctor',
  templateUrl: './rest-doctor.component.html',
  styleUrls: ['./rest-doctor.component.css']
})
export class RestDoctorComponent implements OnInit {

rest:any=[];
  userData: any;
  patient_id: string;
  phone_no: string;
  id: any;
  images: any;
  url: string;
  usersdata: any;
  list: any;
  
result:any;
  resdio: any;
  response: any;
  openServiceModal: any;
  a: string;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public form_val : FormBuilder,
    protected auth: AuthService,  private renderer: Renderer) {

      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.patient_id= sessionStorage.getItem("patient_id");
      this.phone_no= sessionStorage.getItem("phone_no");
      
      this.id= this.userData._id;
      this.images = this.userData.imagePath;
      this.url=  sessionStorage.getItem('baseUrl');
      this.userData= this.userData;
      console.log(11,this.userData)
     this.docterList();
 }
  ngOnInit() {
   // this.lists();
   location.reload()
    console.log( this.patient_id)
  }
  

  docterList() {
      this.auth.docterlist({patient_id:this.patient_id}).subscribe(res => {
          if (res.status == "success") {
            this.list=res.response;
              console.log(32, this.list);
              swal("Docter is restricted")
          }
       }, () => {
           swal("Server failed");
      });
    }
    res(docter_id,docter_name)  {
        const dialogRef = this.dialog.open(RestrictdocmodelComponent, {
          width: '250px',
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed'+JSON.stringify(result));
          this.resdio=result;
       if(this.resdio!=undefined){
          this.resti(this.resdio.restrict,this.resdio.comment,this.patient_id,docter_name,docter_id);
       }
      });
    }  

resti(restrict,comment,patient_id,docter_name,docter_id) {

     this.auth.restrict({restricted:restrict,comment:comment,patient_id:patient_id,docter_name:docter_name,docter_id:docter_id}).subscribe(resp => {
       this.response=resp;
       console.log(1111, this.response.response.restricted)
       if (this.response.response.restricted == "0") {
            swal("Docter is Unrestricted")
            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
       } else {
          swal("Doctor is Restricted")
       }
     },  (err) => {
         swal("Server failed");
      }
      );
    }








  
       Confirm(sts,docter_id) {
  
         if(sts==1)
         {
          this.a="restrict";
          // swal("Restricted Successfully");
          this.openServiceModal(sts,docter_id);
         }
         else{
          this.a="unrestrict";
          this.openServiceModal(sts,docter_id);
         }
      
      }
  
  //     openServiceModal(sts,docter_id): void {
  //       const dialogRef = this.dialog.open(ConformModelComponent, {
  //         width: '250px',
          
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
       
  //         console.log(result.sts)
  //         if(result.sts=="yes")
  //         {
  //          this.rest(sts,docter_id)
  //         }
       
  //       //  this.login();
  //       });
  //     }
  
  
  //      rest(sts,docter_id)
  //      {
  //       // this.userData='';
  //         // this.labupdateForm .value.docter_id=this.docter_id;
  //          this.auth.restrict( {patient_id:this.patient_id,docter_id:docter_id,restricted:sts}).subscribe(res => {
  //            this.result=res;
  //            if (this.result.status == "success") {
  //             swal("Action applied succesfully");
  
  //                 this.usersdata=this.result.response;
  //                  this.list();
  //               // sessionStorage.setItem("full_name",this.name);
  //               // this.router.navigateByUrl("/patientsearch");
             
  //            }
  //          },() => {
  //            swal("server failed, server details not exits ");
  //           });
  //         }
  
  
        
  // }
  logo(){
    localStorage.setItem('login','');
    location.reload();

  }
}
