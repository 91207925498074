import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-labreports',
  templateUrl: './labreports.component.html',
  styleUrls: ['./labreports.component.css']
})
export class LabreportsComponent implements OnInit {

  constructor(private router:Router, private auth:AuthService) 
  
  {

    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
    
   }

  ngOnInit() {

  }

  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   }

}
