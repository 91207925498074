import { Component, OnInit } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-changepass',
  templateUrl: './changepass.component.html',
  styleUrls: ['./changepass.component.css']
})

  export class ChangepassComponent extends MzBaseModal  implements OnInit {
    userData: any;
    full_name: string;
    patient_id: string;
    validations_form: any;
    validations_pa: any;
    validations_doc: any;

    listOfCountries: any;
    questlist: any;
  ptype: string;
  sw:any=[];
  _id: any;
  resultres: any;
  phone_no: any;
  
    constructor(private router: Router, 
      private formBuilder: FormBuilder,
       public dialogRef: MatDialogRef<ChangepassComponent>,
      // @Inject(MAT_DIALOG_DATA)
      protected auth: AuthService,
      protected route: ActivatedRoute,) {
      super();
      this.userData= JSON.parse(sessionStorage.getItem("userData"));
      // this.userData=sessionStorage.getItem("userData");
      this.full_name=sessionStorage.getItem("full_name");
      this.patient_id=sessionStorage.getItem("patient_id");
      console.log("22",this.userData)

      this.phone_no = this.userData.lab_phone;

      console.log("phone0000", this.phone_no);
     this.ptype= sessionStorage.getItem("ptype");

     console.log(this.ptype);

    }
   
    ngOnInit() {
  
      this.validations_form  =  this.formBuilder.group({
       
        old_password:['', Validators.required],
        new_password:['', Validators.required],
        cpassword: ['', Validators.required],
        phoneno:['']
      
    });
   
    }
    validation_messages = {
   
      'password': [
        { type: 'required', message: 'password is required.' },
        { type: 'minlength', message: 'password must be at least 1 characters long.' },
        //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
      ],
      'cpassword': [
        { type: 'required', message: 'Confirm password is required.' },],
        'new_password': [
          { type: 'required', message: 'new password is required.' },],
      
     
                    
    };
  
  
  
      submit() {
if(this.validations_form.value.new_password==this.validations_form.value.cpassword)
{
  //"_id":sessionStorage.getItem('id'),
        if(this.ptype=='3')
        {
          this.sw={"lab_phone":this.phone_no,"old_password":this.validations_form.value.old_password,"new_password":this.validations_form.value.new_password};
        console.log("this.sw", this.sw);

        this.auth.labadminpassword(this.sw).subscribe(res => {
          
          // this.auth.labadminpassword(this.sw).subscribe(res=>{

            this.resultres = res;
          if ( this.resultres.status == "success") {
            swal("Password updated successfully");

            this.dialogRef.close();

          
    
          } else if(this.resultres.status =="error") {
     
          }
         }, (err) => {
          swal("Old password does not match ");
        });
        
        }
         if(this.ptype=='2'){
          // this.sw={"_id":sessionStorage.getItem('id'),"docter_phone":sessionStorage.getItem('docter_phone'),"old_password":this.validations_form.value.old_password,"new_password":this.validations_form.value.new_password};
          this.sw={"_id":sessionStorage.getItem('id'),"docter_phone":sessionStorage.getItem('docter_phone'),"new_password":this.validations_form.value.new_password,"old_password":this.validations_form.value.old_password};
          this.auth.docadminpassword(this.sw).subscribe(res => {
          
            // this.auth.labadminpassword(this.sw).subscribe(res=>{
  
              this.resultres = res;
            if ( this.resultres.status == "success") {
              swal("Password updated successfully");
  
              this.dialogRef.close();
  
            
      
            } else if(this.resultres.status =="error") {
       
            }
           }, (err) => {
            swal("Old password does not match ");
          });
    
        }
        if(this.ptype=='1'){
          this.sw={"_id":sessionStorage.getItem('id'),"phone_no":sessionStorage.getItem('phone_no'),"new_password":this.validations_form.value.new_password,"old_password":this.validations_form.value.old_password};
          this.auth.patientpassword(this.sw).subscribe(res => {
          
            // this.auth.labadminpassword(this.sw).subscribe(res=>{
  
              this.resultres = res;
            if ( this.resultres.status == "success") {
              swal("Password updated successfully");
  
              this.dialogRef.close();
  
            
      
            } else if(this.resultres.status =="error") {
       
            }
           }, (err) => {
            swal("Old password does not match ");
          });
    
        }
      
        

      }
      else{
        swal("password does not match");

      }
     }
     close(): void {
      this.dialogRef.close();
    }
  
     
  }
  