import { Component, OnInit, Input, EventEmitter, Optional, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MzBaseModal } from 'ngx-materialize';
declare var swal;
@Component({
  selector: 'app-labview',
  templateUrl: './labview.component.html',
  styleUrls: ['./labview.component.css']
})
export class LabviewComponent extends MzBaseModal  implements OnInit{
  userData: any;
  full_name: string;
  patient_id: string;
  obj: any;
  value: any;
  testname: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;

  constructor(public dialogRef: MatDialogRef<LabviewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected route: ActivatedRoute,) { 


 
    super();
    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    this.patient_id= sessionStorage.getItem("patient_id");
    this.list();

  }

  ngOnInit() {
    
      this.obj = this.data.test_id;
     this.testname = this.data.testname;
 

  }
 
  list()
  {
      this.auth.findchild( {patient_id:this. patient_id}).subscribe(res => {
        
        if (res.status == "success") {
          if (res.response != "") {
         this.userData=res.response;
         
           // sessionStorage.setItem("full_name",this.name);
           // this.router.navigateByUrl("/patientsearch");
          }
       
        }
      },
         
   
         () => {
        swal("Server failed, Server details not exits ");
       }
       );
  }
  
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}

  
close()
{
  this.dialogRef.close();
}
}
