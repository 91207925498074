import { Component, OnInit } from '@angular/core';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangepassComponent } from '../changepass/changepass.component';
import { LabeditComponent } from '../pages/labedit/labedit.component';
import { PinModelComponent } from '../pages/pin-model/pin-model.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-lab-profile',
  templateUrl: './lab-profile.component.html',
  styleUrls: ['./lab-profile.component.css']
})
export class LabProfileComponent implements OnInit {
  websiteList: any = ['1 - 3 : 1000 Rs', '4 - 10 : 2000 Rs', '11 - 25 : 3000 Rs','others']
  userData: any;
  // lab_id: string;
  // lab_phone: string;
  // result: Object;


 
  patient_id: any;
  name: any;
  resiltpin;
  pin_nos:any;
  valueOfModel: string;
  lab_phone: any;
  phone_no: any;
  id: any;
  result: any;
  reg: boolean;
  lab_id: any;
  resultres: any;
  response: any;
  public show:boolean = false;
  public buttonName:any = 'Change Plan';
  plan: any;
  labname: any;
  address: any;
  labId: any;
  city: any;
  state: any;
  country: any;
  secureQuestion: any;
  emailId: any;
  sec_Answer: any;
  adminPhone: any;
 
  constructor(
      public dialog: MatDialog,
    private router: Router,
  
    protected auth: AuthService,
    protected route: ActivatedRoute,
  ) { 
    this.userData= JSON.parse(sessionStorage.getItem("userData"));
    console.log("789",this.userData);
    this.lab_id=sessionStorage.getItem("lab_id");
    this.lab_phone= sessionStorage.getItem("lab_phone");
    console.log( 123,this.userData);

    this.plan = sessionStorage.getItem("plan")
    console.log(8888, this.plan)
    if(sessionStorage.getItem("ptypepop")=="3"){
      this.pin();
    }
  }
  

  ngOnInit() {
    this.Lab_gst();
  }
  Lab_gst(){
    this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res=>{ 
       this.resultres = res;
      

      if( this.resultres .status == "success"){
        
       
       this.response=this.resultres.response[0].plan;
       this.plan = this.resultres.response[0].plan;
       this.labname = this.resultres.response[0].name;
       this.address = this.resultres.response[0].address;
       this.labId = this.resultres.response[0].lab_id;
       this.city = this.resultres.response[0].city;
       this.state = this.resultres.response[0].state;
       this.country = this.resultres.response[0].country;
       this.secureQuestion = this.resultres.response[0].question;
       this.emailId = this.resultres.response[0].email
       this.sec_Answer = this.resultres.response[0].answer;
       this.adminPhone = this.resultres.response[0].phone_no
       console.log("plan===>", this.plan )
       console.log(this.response);
      sessionStorage.setItem("name", this.labname)
      sessionStorage.setItem("address", this.address)
      sessionStorage.setItem("city", this.city)
      sessionStorage.setItem("question", this.secureQuestion)
      sessionStorage.setItem("state", this.state);
      sessionStorage.setItem("country", this.country)
      sessionStorage.setItem("email", this.emailId)
      sessionStorage.setItem("answer", this.sec_Answer)
      }
    })
  }
  openServiceModal(): void {
    const dialogRef = this.dialog.open(LabeditComponent, {
      width: '250px', 
      data:{
        labId:this.labId

      }
  
      
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed'+result);
   
if(result!=1)
{
     this.login();
}
    });
  }
  changepass(): void {
    const dialogRef = this.dialog.open(ChangepassComponent, {
      width: '2250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //alert(result);
     this.login();
    });
  }

  login() {
    sessionStorage.setItem('token', "res.response.token");
    console.log(7899,this.lab_id);
      this.auth.labEdit({lab_id:this.lab_id}).subscribe(res => {
        this.result=res;
        sessionStorage.setItem("userData",JSON.stringify(this.result.response[0]))
            this.userData=this.result.response[0];
            console.log(32323,this.result)

      
     }, () => {
 
    });
  }
  pin()  {
    sessionStorage.setItem("ptypepop","0");
     let dialogRef = this.dialog.open(PinModelComponent, {
        width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
  
    });
}
toggle() {
  this.show = !this.show;

  // CHANGE THE NAME OF THE BUTTON.
  if(this.show)  
    this.buttonName = "Change Plan";
  // else
  //   this.buttonName = "Show";
}
// createForm() {
//   this.validations_form = this.fb.group({
//     plan : ['',Validators.required],
//   });
// }
// createlabForm() {
//   this.validations_form = this.fb.group({
//     plan : ['',Validators.required],
//   });
// }
changeWebsite(e) {
  console.log(e.target.value);
}
//logout
logout(){
  sessionStorage.clear();
  localStorage.clear();
  this.router.navigateByUrl('/new-lab');
}


changePlan(){
  this.router.navigateByUrl('/userplan-edit');
}
}
