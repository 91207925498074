import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators, } from '@angular/forms';
import{AuthService} from './../../services/auth.service';
import{ ActivatedRoute, Router} from '@angular/router';
import { MatDialog } from '@angular/material';
import { TermsServiceComponent } from 'src/app/terms-service/terms-service.component';
import { PrivacyPolicyComponent } from 'src/app/privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from 'src/app/disclaimer/disclaimer.component';
declare var swal;
@Component({
  selector: 'app-doctorform',
  templateUrl: './doctorform.component.html',
  styleUrls: ['./doctorform.component.css']
})
export class DoctorformComponent implements OnInit {
  validations_form: FormGroup;
  listOfCountries: any;
  questlist: any;
  doctlist: any;
  userName = '';
  constructor(private formBuilder: FormBuilder,
    private router: Router, 
    // private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    public dialog: MatDialog) {
      this.questions();
      this.countries();
       this.doctorlist();
     }

  ngOnInit() {
    this.createForm();
    // document.addEventListener('DOMContentLoaded', function() {
    //   var elems = document.querySelectorAll('select');
    // });
  }
  validation_messages = {
   
    'docter_name': [
      { type: 'required', message: 'name is required.' }      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'languages': [
      { type: 'required', message: 'Languages is required.' }      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'age': [
      { type: 'required', message: 'age is required.' },],

    'password': [
       { type: 'required', message: 'password is required.' }],
    'date_of_birth': [
          { type: 'required', message: 'date_of_birth is required.' },
        ],
        'email': [
          { type: 'required', message: 'email id is required.' },
          { type: 'pattern', message: 'please enter valid email' }],

           'speciality': [
            { type: 'required', message: 'speciality is required.' }
           ],
           'docter_phone': [
            { type: 'required', message: 'phone number is required.' },
            { type: 'maxlength', message: 'phone number cannot be more than 10 digits.' },
                        { type: 'minlength', message: 'phone number cannot be less than 4 digits.' },
           ],
           
    'clinic_address': [
              { type: 'required', message: 'address  is required.' }],
              'city': [
                { type: 'required', message: 'city  is required.' }],
                'state': [
                   { type: 'required', message: 'state  is required.' }],

                   'country': [
                    { type: 'required', message: 'country  is required.' }],

                      'hospital': [
                    { type: 'required', message: 'hospital  is required.' }],
                    'sex': [
                      { type: 'required', message: 'gender  is required.' }],
                      'question'  : [{ type: 'required', message: ' security question is required.' }],
                      // 'zoom_id'  : [{ type: 'required', message: 'zoom id is required.' }],

                      
                      'answer': [
                        { type: 'required', message: 'answer  is required.' },
                        { type: 'maxlength', message: 'answer cannot be more than 20 digits.' },
                        { type: 'minlength', message: 'answer cannot be less than 4 digits.' },
    
    
                      ],
                  
  };
  createForm() {
    this.validations_form = this.formBuilder.group({

      docter_name: ['', Validators.required],
      sex: ['', Validators.required],
      speciality: ['', Validators.required],
      email: ['',Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      clinic_address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country:['', Validators.required],
      hospital: ['', Validators.required],
      docter_phone: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(5)])],
      password: ['', Validators.required],
      question:['',Validators.required],
      answer:['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(4),
      ])],
      languages:['',Validators.required],
      experience:[''],
      type:'1',
      charges: "0",

  });
  }

  regester() {
    if(this.validations_form.value.docter_name != '' && this.validations_form.value.email != '' && this.validations_form.value.city != '') {
      this.auth.doctor(this.validations_form.value).subscribe(res=>{
        if(res.status=="success"){
          if(res.status=='success'){
            this.auth.NewdoctorData.next(res.response);
            swal("Doctor registered successfully");
            this.router.navigateByUrl('/login');
          }
        }
        
      },(error)=>{
        console.log(343,error);
        swal("phone no already exist");
      })
    } else {
      swal("Please fill the requied fields")
    } 
   
 }
 keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;

  let inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode != 8 && !pattern.test(inputChar)) {
    event.preventDefault();
  }
}

doctorlist(){

  this.auth.spetiality().subscribe(res => {
    
    if (res.status == "success") {
   
       this.doctlist=res.response 
       console.log(33,this.doctlist);
   }
  }, () => {
   }
   );
 }

countries(){
  this.auth.countryList().subscribe(res => {
    if (res.status == "success") {
       this.listOfCountries=res.response 
       console.log( 333,this.listOfCountries)
    }
  },() => {
  });
 }

questions(){
  this.auth.quest().subscribe(res => {
    if (res.status == "success") {
       this.questlist=res.response ;
    }
  },() => {
  });
 }

 //terms nd conditions
 openDialog() {
  const dialogRef = this.dialog.open(TermsServiceComponent, {
    panelClass: 'my-custom-dialog-class'
  });
  
}
privacyPolicy(){
  const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
    panelClass: 'my-custom-dialog-class'
  });
}
openDisclaimar(){
  const dialogRef = this.dialog.open(DisclaimerComponent, {
    panelClass: 'my-custom-dialog-class'
  });
}
}