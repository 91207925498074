import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {MatTableDataSource} from '@angular/material/table';
declare var swal;
export interface PeriodicElement {
  user_name: string;
  lab_user_phone: number;
  // weight: number;
  // symbol: string; 
}
@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {
  // 'lab_user_phone'
  displayedColumns: string[] = ['user_name', 'lab_user_phone','Edit','Delete'];
  lab_user_phone: string;
  phone_no: string;
  username: any;
  user_name: string;
  usernames: any;
  lab_user_phones: any;
  user_names: any;
  lab_id: string;
  _id: any;
  id: any;
  newItem: string;
   ELEMENT_DATA: PeriodicElement[]=[]
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
 
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(
    private router: Router,
  private formBuilder: FormBuilder,
  protected auth: AuthService,
  protected route: ActivatedRoute,
  
    //sessionStorage.setItem("lab_id",this.userData.lab_id);
  
    ) { 
      this.lab_id=sessionStorage.getItem("lab_id");
    this.lab_create();
     console.log(this.lab_id);
     if( sessionStorage.getItem("lab_phone")==" " || sessionStorage.getItem("lab_phone") == undefined){
      this.router.navigateByUrl('/new-lab');
    }
  }
  
  ngOnInit() {
  
  }
  lab_create(){
    this.auth.lab_userFind({lab_id:this.lab_id}).subscribe(res => {
     if(res.status == "success"){
       this.user_names=res.response;
       this.dataSource = new MatTableDataSource<PeriodicElement>(this.user_names);
     }
    })
  }
  edituser(user) {
    console.log(9909,user);
    this.router.navigate(['lab-edit-user'],{queryParams: {"user": JSON.stringify(user)} });
    // lab-edit-user
    //user-add
  }
  
    // deleteuser(user){
    //   swal({
    //     title: "Are you sure you want to do this ?",
       
    //     dangerMode: true,
    //     })
    //     .then(willDelete => {
    //     if (willDelete) {
    //       swal("Deleted!", "Your imaginary file has been deleted!", "success");
    //       location.reload();
          
    //     }
        
    //     });
    //   this.auth.lab_userremove({_id:user._id}).subscribe(res => {
    //     if (res.status == "success") {
    //       if (res.response == "success") {   
    //         if (res.response != "") {
    //          console.log(22, this.lab_user_phone);
    //       }
    //     }
    //     }
    //   })
    // }

    deleteuser(user){
      swal({
        title: "Do You Want to Delete This?",
        // text: "Are you sure that you want to leave this page?",
        // icon: "warning",
        dangerMode: true,
        buttons: {
          confirm: true,
          cancel: true,
        
        }
        })
        .then(confirm => {
        if (confirm===true) {
          swal("Deleted!");
          
  
          this.auth.lab_userremove({_id:user._id}).subscribe(res => {
            if (res.status == "success") {
              if (res.response == "success") {   
                if (res.response != "") {
                 console.log(22, this.lab_user_phone);
              }
            }
            }
            location.reload();
          })
  
          
          
        }
  
        else{
          swal("NOT DELETED !");
        }
        
        });
      
    }
  
  search(user){
    this.auth.lab_userSearch({user_name:this.user_name}).subscribe(res => {
      if (res.status == "success") {
        if (res.response == "success") {   
          if (res.response != "") {
           console.log(2222,this.user_name);
        }
      }
      }
    })
  }
  logout= function () {
    sessionStorage.clear();
  localStorage.clear();
    this.router.navigateByUrl('/new-lab');
};
}
