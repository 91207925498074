import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';

declare var swal;
export interface PeriodicElement {
  user_name: string;
  admin_user_phone: number;
  // weight: number;
  // symbol: string; 
}
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  displayedColumns: string[] = ['user_name', 'admin_user_phone','Edit','Delete'];
  admin_user_phone: string;
  admin_user_phones: any;
  user_name: string;
  user_names: any;
  _id: any;
  id: any;
  newItem: string;
   ELEMENT_DATA: PeriodicElement[]=[]
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  relationship: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
  ) {
     this.lab_create();
     if( sessionStorage.getItem("phone_no")==" " || sessionStorage.getItem("phone_no") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
   }

   

  ngOnInit() {
  }
  lab_create(){
    this.auth.lab_admin_userFind().subscribe(res => {
      console.log("i am hrere")
     if(res.status == "success"){
       this.user_names=res.response;
       this.dataSource = new MatTableDataSource<PeriodicElement>(this.user_names);
     }
    })
  }
  edituser(user){
    console.log(9909,user);
    this.router.navigate(['lab-user-edit'],{queryParams: {"user": JSON.stringify(user)} });
  }
  deleteuser(user){
    swal({
      title: "Are you sure you want to do this ?",
      // text: "Are you sure that you want to leave this page?",
      // icon: "warning",
      dangerMode: true,
      buttons: {
        confirm: true,
        cancel: true,
      
      }
      })
      .then(confirm => {
      if (confirm===true) {
        swal("Deleted!", "Your imaginary file has been deleted!", "success");
        

        this.auth.lab_admin_userRemove({_id:user._id}).subscribe(res => {
          if (res.status == "success") {
            if (res.response == "success") {   
              if (res.response != "") {
               console.log(22, this.admin_user_phone);
            }
          }
          }
          location.reload();
        });

        
        
      }

      else{
        swal("NOT DELETED !","You have cancelled the operation !", "warning");
      }
      
      });
    
  }

  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   }
}
