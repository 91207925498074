import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal } from 'ngx-materialize';
import { ConformModelComponent } from '../pages/patient/conform-model/conform-model.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-callcomplete',
  templateUrl: './callcomplete.component.html',
  styleUrls: ['./callcomplete.component.css']
})
export class CallcompleteComponent extends MzBaseModal  implements OnInit {

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  userData: any;
  conformForm:FormGroup;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<ConformModelComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,) {
    super();
    
    
   }

  ngOnInit()  {
 
  }
 
    save(e){
      console.log("10001",  this.dialogRef.close({sts:"yes"}))
          this.dialogRef.close({sts:"yes"});
       console.log("1000",  this.dialogRef.close({sts:"yes"}))
        }
    
     close() {
      this.dialogRef.close();
    }
    
  
// listen for close event after opening the modal

}
