import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { from } from 'rxjs';
import { TestModelComponent } from 'src/app/pages/labModel/assignTest/test-model.component';
import { LabresultUpdateComponent } from 'src/app/pages/labModel/labresult-update/labresult-update.component';
import { AuthService } from 'src/app/services/auth.service';

declare var swal;
interface Student {

  id: Number;

  date: String;

  dName: String;

  patient: String;

  Test : String;

  Status : String;

  test : String;

}

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  userData: any;
  patient_id: string;
  name: string;
  phone_no: string;
  child_name: any;
  child: any;
  visits: any;
  patData: any;
  labData: string;
  result: any;
  test_res: string;
  searchText = '';
   years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  searchOptions = {
    year:"",
    child_name:""
   };

  chidData: Object;
  lablist: any;
  test_name: any;
  doc_date: any;
  reports: any;
  dateee: any;
  lab_id: any;
  config:any;
  email: string;
  resultres: any;
  response: any;
  private _id: string;
  patient_data: any;
  printdata: any;
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private datePipe: DatePipe,
  ) {
    this.patient_id=sessionStorage.getItem("patient_id")
    this.phone_no=sessionStorage.getItem("phone_no")
   this.name=sessionStorage.getItem("full_name")
   this.email=sessionStorage.getItem("email");
   
   this. lab_id=sessionStorage.getItem('lab_id');
   this.visits="";
   this.dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();


  //  console.log("object" , this.router.getCurrentNavigation().extras.state.htmlprint);

  //   this.printdata = this.router.getCurrentNavigation().extras.state.htmlprint;
    this.patient_data = JSON.parse(sessionStorage.getItem("user-payment"));
     console.log("patient_data" , this.patient_data);
   
  
  }

  ngOnInit() {
    this.patientData();
//     setTimeout(function(){
//       var r=confirm("Do you want Print ?");
// if (r==true)
//   {
//   var x="You pressed OK!";
//    window.print();
//   }
// else
//   {
//   var x="You pressed Cancel!";
//   }
//       ; 
//     }, 8000);
//   }
  }
 

  patientData(){
    this.auth.patientList({patient_id:this.patient_id}).subscribe(res => {
      console.log("878787", this.patient_id)
        // if(res.response!="" ) {
        //     this.visits = res.response.reverse();
        //     this.config = {
        //       itemsPerPage: 5,
        //       currentPage: 1,
        //       totalItems: this.visits.count
        //     };
        // } 
        this.resultres = res;
        if( this.resultres .status == "success"){
          
         let response=this.resultres.response;
         this.response=response.filter(obj=>obj._id==this.patient_data._id)
        console.log("this.response", this.response);
        
       
        }
      })
   }
  //  students: Student[] =[

  //   {

  //     id: 120,

  //     date: "16-04-2021",
      
  //     dName : "Dr. Mangla",
  //     patient : "Abhishek",

  //     Test : "Cardiac Blood Tests",

  //     Status : "Received",

  //     test : "Processing"

  //   },

  //   {

  //     id: 121,

  //     date: "17-04-2021",
      
  //     dName : "Dr Brilliant",
  //     patient : "Mr John",

  //     Test : "Total protein",

  //     Status : "Received",

  //     test : "Processing"

  //   },

  //   {

  //     id: 122,

  //     date: "18-04-2021",
      
  //     dName : "Dr. Naresh Trehan",
  //     patient : "Hello Usr",

  //     Test : "Blood Tests for Arthritis",

  //     Status : "Received",

  //     test : "Processing"

  //   },

  //   {

  //     id: 123,

  //     date: "19-04-2021",
      
  //     dName : "Dr. Mangla",
  //     patient : "Abhishek",

  //     Test : "Cardiac Blood Tests",

  //     Status : "Received",

  //     test : "Processing"

  //   },

  //   {
  //     id: 124,

  //     date: "20-04-2021",
      
  //     dName : "Dr. Mangla",
  //     patient : "Abhishek",

  //     Test : "Cardiac Blood Tests",

  //     Status : "Received",
      
  //     test : "Processing"

  //   },

  // ]
  print(obj){
    // var x=1;
    // console.log(1);
    // obj.x=1;
    // console.log(obj.x);
    
    
     this.router.navigate(['user-payment'], { state: { obj: obj } });
  }
  

//   print(cmpName) {
//     // let printContents = document.getElementById(this.printdata).innerHTML;
//     // let originalContents = document.body.innerHTML;

//     document.body.innerHTML = this.printdata;

//     window.print();

//     //document.body.innerHTML = this.printdata;
// }

  }
