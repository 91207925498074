import { Component, OnInit,} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';
//import { NgxSpinnerService } from 'ngx-spinner';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { DatePipe } from '@angular/common';
declare var swal;
@Component({
  selector: 'app-patientsearch',
  templateUrl: './patientsearch.component.html',
  styleUrls: ['./patientsearch.component.css']
})
export class PatientsearchComponent implements OnInit {
  userData: any = [];
  t1:any=[];
  full_name: string;
  patient_id: string;
  name: any;
  names: any;
  patientupdateForm: FormGroup;
  vaccineForm:FormGroup;
  child_name: any;
  visits: any;
  docter_id: string;
  doctername: string;
  docter_name: string;
  response: any;
  arr = [];
  test_name: any = [];
  dropdownList = [];
  selectedItems: any[];
  dropdownSettings = {};
  docname: any;
  che: boolean;
  activatedRoute: any;
  id: any;
  result1: any;
  sign: any;
  imagesurl: any;
  userDrop: any;
  e: any;
  age: any;
  gender: any;
  sex: string;
  email: string;
  userDetails: void;
  phone_no: string;
  date_of_birth: string;
  address: string;
  state: string;
  city: string;
  vacdata:any
  vaccinename: any;
  doses:any;
  ena:any;
  date:any;
  changeVac: any;
  count1: any=[];
  doses1: any;
  allerc:any=[{
    Drug:'',
    substance:''
  }]
  allergyForm: FormGroup;
  dosk: { child_name: any; date: any; details: any; docter_id: any; dose_no: any; number_of_doses: any; patient_id: any; patient_name: any; vaccine_name: any; };
  testName: any;
  testList: any;
  testUser: any;
  name2: any[];
  

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    public datePipe:DatePipe
  ) {

    if( sessionStorage.getItem("docter_phone")==" " || sessionStorage.getItem("docter_phone") == undefined){
      this.router.navigateByUrl('/login2');
    }


    this.route.url.subscribe((url) => {
      this.activatedRoute = url;
    });

    this.userData = JSON.parse(sessionStorage.getItem("userData"));

    //console.log(this.userData);
    
    
    this.patient_id = sessionStorage.getItem("patient_id");

    this.full_name = sessionStorage.getItem("full_name");



    console.log("patient", this.full_name);
//this.child_name=this.full_name
    // this.age = sessionStorage.getItem("age");
    // console.log("age", this.age);

    // this.phone_no = sessionStorage.getItem("phone_no");
    // console.log("phone_no", this.phone_no);

    // this.email = sessionStorage.getItem("email");
    // console.log("email", this.email);

    // this.sex = sessionStorage.getItem("sex");
    // console.log("sex", this.sex);

    // this.date_of_birth = sessionStorage.getItem("date_of_birth");
    // console.log("date_of_birth", this.date_of_birth);

    // this.address = sessionStorage.getItem("address");
    // console.log("address", this.address);

    // this.city = sessionStorage.getItem("city");
    // console.log("state", this.city);
    

    //this.phone_no = sessionStorage.getItem("phone_no");
    // this.gender = sessionStorage.getItem("sex");
    // this.email = sessionStorage.getItem("email");
    this.docter_id = this.userData.docter_id;

    this.docter_name = this.userData.docter_name;
    console.log('doc Id: ', this.docter_name)
   this.userDetails = JSON.parse(sessionStorage.getItem("userData"));
   console.log("userDetails", this.userDetails);

    this.list();
    this.testNames();
    console.log(23, this.patient_id);
    this.che = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: '_id',
      textField1: 'range',

      textField: 'name',

      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true
    };

  }

  ngOnInit() {
    this.date=this.datePipe.transform(Date.now(), 'd-M-yyyy'),

    this.patientupdateForm = this.formBuilder.group({
      blood_pressure: ['', Validators.required],
      comments: ['', Validators.required],
      medicine_prescribed: ['', Validators.required],
     // labtest_required: [false, Validators.required],
      labtest_required:new FormControl(false, [Validators.required]),
      patient_id: ['', Validators.required],
      docter_id: ['', Validators.required],
      reason: ['', Validators.required],
      docter_name: [this.docter_name],
      child_name: ['', Validators.required],
      payment_status : ['0',Validators.required],
      patient_name: [''],
      test_name: [[]],
      age: [''],
      gender: [''],
      phone_no: [''],
      docsign: ['']

    });



    this.docname = this.userData.docter_name;
    this.id = sessionStorage.getItem("id");
    console.log("7878787", this.id);
    this.getimg(this.id);

  //  if(){

  //  }

    // this.patientupdateForm.value.patient_id= sessionStorage.getItem("this.patient_id");
    //  this.patientupdateForm.value.patient_id=this.userData.patient_id;



    // this.vaccineForm = this.formBuilder.group({
    //   hepatitisb:[''],
    //   dtpa:[''],
    //   polio:[''],
    //   prevnar:[''],
    //   hib:[''],
    //   mmr:[''],
    //   varicella:[''],
    //   covid19:[''],
    //   hepatitisa:[''],
    //   hpv:[''],
    //   menactra:[''],
    //   meningitis:[''],
    //   bcg:[''],
    //   typhoid:[''],
    //   tdtdpa:[''],

    //   patient_id:this.patient_id,
    //   patient_name:this.full_name,
    //   date:new Date(),
    //   docter_id:this.docter_id,
    //   child_name: this.userDrop,
    //   vaccine_name:[[]],
    //   number_of_doses:[[]],
    //   number_of_doses_completed:1
      
    // });







    this.vaccineForm = this.formBuilder.group({
      vaccine_name:['', Validators.required],
      number_of_doses:[''],
      // doses:[''],
      patient_id:this.patient_id,
      // patient_name:[''],
      patient_name:this.full_name,
      date:this.date,
      docter_id:this.docter_id,
      docter_name:this.docter_name,
      child_name: [''],
      //vaccine_name:[[]],
      // number_of_doses:['',]
      dose_no:[''],
      details:'',
      year:new Date().getFullYear().toString()
      
    });

    
    this.allergyForm = this.formBuilder.group({
      child_name: [''],
      patient_name:this.full_name,
      patient_id:this.patient_id,
      date:this.date,
      docter_name:this.docter_name,
      docter_id:this.docter_id,
      year:new Date().getFullYear().toString(),
      type_of_allergy:['', Validators.required],
      name:['', Validators.required],
      severity:['', Validators.required],
      
    });
    

  //   this.vaccineForm.setErrors({required: true});
  // this.vaccineForm.valueChanges.subscribe((newValue) => {
  //   if (newValue.hepatitisb === true || newValue.dtpa === true || newValue.polio === true || newValue.prevnar === true || newValue.hib === true || newValue.mmr === true ||
  //     newValue.varicella === true || newValue.covid19 === true || newValue.hepatitisa === true || newValue.hpv === true || newValue.menactra === true || newValue.meningitis === true ||
  //     newValue.bcg === true || newValue.typhoid === true || newValue.tdtdpa === true
      
  //     ) {
  //     this.vaccineForm.setErrors(null);
  //   } else {
  //     this.vaccineForm.setErrors({required: true});
  //   }
  // });
  }


  allergy(a){

    if(this.child_name){
      this.allergyForm.value['child_name']=this.child_name
      console.log(a.value);
    }

    a.value.data=[{
      type_of_allergy:a.value.type_of_allergy,
      name:a.value.name,
      severity:a.value.severity,
      date:this.date
    }]
    this.auth.allergycreate(a.value).subscribe(res=>{
      console.log(res);
    })
console.log(a.value);
// this.allerc=a.value

swal('','Details saved successfully','success')

setTimeout(() => {
  //this.ngOnInit();
  location.reload();  
}, 2500);

//location.reload();
  }


  vacc(vaccineForm){
    this.vacdata=vaccineForm.value
    console.log(vaccineForm.value);
   
//

if(this.vacdata['vaccine_name']=="hepatitis b"){
console.log('hep');
vaccineForm.value['number_of_doses']=4

if(this.child_name){
  vaccineForm.value['child_name']=this.child_name
  console.log('ho child');
  
}

if(this.child_name==''){
  vaccineForm.value['patient_name']=this.full_name
  console.log('ho parent');
  
}

console.log(vaccineForm.value['patient_name']);

}

//


if(this.vacdata['vaccine_name']=="dtpa"){
  console.log('dtpa');
  vaccineForm.value['number_of_doses']=5

  if(this.child_name){
    vaccineForm.value['child_name']=this.child_name
    console.log('ho child');
    
  }
  
  if(this.child_name==''){
    vaccineForm.value['patient_name']=this.full_name
    console.log('ho parent');
    
  }
  
  console.log(vaccineForm.value['patient_name']);
  }

  //

  if(this.vacdata['vaccine_name']=="polio"){
    console.log('polio');

    // alert('');
    vaccineForm.value['number_of_doses']=4

    if(this.child_name){
      vaccineForm.value['child_name']=this.child_name
      console.log('ho child');
      
    }
    
    if(this.child_name==''){
      vaccineForm.value['patient_name']=this.full_name
      console.log('ho parent');
      
    }
    
    console.log(vaccineForm.value['patient_name']);
    }

    //

    if(this.vacdata['vaccine_name']=="prevnar"){
      console.log('prevnar');
      vaccineForm.value['number_of_doses']=4
      vaccineForm.value['child_name']=this.child_name
console.log(vaccineForm.value['child_name']);
      }

      if(this.vacdata['vaccine_name']=="hib"){
        console.log('prevnar');
        vaccineForm.value['number_of_doses']=4

        if(this.child_name){
          vaccineForm.value['child_name']=this.child_name
          console.log('ho child');
          
        }
        
        if(this.child_name==''){
          vaccineForm.value['patient_name']=this.full_name
          console.log('ho parent');
          
        }
        
        console.log(vaccineForm.value['patient_name']);
        }

        //

        if(this.vacdata['vaccine_name']=="mmr"){
          console.log('prevnar');
          vaccineForm.value['number_of_doses']=2

          if(this.child_name){
            vaccineForm.value['child_name']=this.child_name
            console.log('ho child');
            
          }
          
          if(this.child_name==''){
            vaccineForm.value['patient_name']=this.full_name
            console.log('ho parent');
            
          }
          
          console.log(vaccineForm.value['patient_name']);
          }

          //

          if(this.vacdata['vaccine_name']=="varicella"){
            console.log('prevnar');
            vaccineForm.value['number_of_doses']=2

            if(this.child_name){
              vaccineForm.value['child_name']=this.child_name
              console.log('ho child');
              
            }
            
            if(this.child_name==''){
              vaccineForm.value['patient_name']=this.full_name
              console.log('ho parent');
              
            }
            
            console.log(vaccineForm.value['patient_name']);
            }

            //

            if(this.vacdata['vaccine_name']=="covid-19"){
              console.log('prevnar');
              vaccineForm.value['number_of_doses']=2

              if(this.child_name){
                vaccineForm.value['child_name']=this.child_name
                console.log('ho child');
                
              }
              
              if(this.child_name==''){
                vaccineForm.value['patient_name']=this.full_name
                console.log('ho parent');
                
              }
              
              console.log(vaccineForm.value['patient_name']);
              }

              //

              if(this.vacdata['vaccine_name']=="hepatitis a"){
                console.log('prevnar');
                vaccineForm.value['number_of_doses']=2

                if(this.child_name){
                  vaccineForm.value['child_name']=this.child_name
                  console.log('ho child');
                  
                }
                
                if(this.child_name==''){
                  vaccineForm.value['patient_name']=this.full_name
                  console.log('ho parent');
                  
                }
                
                console.log(vaccineForm.value['patient_name']);
                }

                //

                if(this.vacdata['vaccine_name']=="hpv"){
                  console.log('prevnar');
                  vaccineForm.value['number_of_doses']=3

                  if(this.child_name){
                    vaccineForm.value['child_name']=this.child_name
                    console.log('ho child');
                    
                  }
                  
                  if(this.child_name==''){
                    vaccineForm.value['patient_name']=this.full_name
                    console.log('ho parent');
                    
                  }
                  
                  console.log(vaccineForm.value['patient_name']);
                  }

                  //

                  if(this.vacdata['vaccine_name']=="menactra"){
                    console.log('prevnar');
                    vaccineForm.value['number_of_doses']=2

                    if(this.child_name){
                      vaccineForm.value['child_name']=this.child_name
                      console.log('ho child');
                      
                    }
                    
                    if(this.child_name==''){
                      vaccineForm.value['patient_name']=this.full_name
                      console.log('ho parent');
                      
                    }
                    
                    console.log(vaccineForm.value['patient_name']);
                    }

                    //

                    if(this.vacdata['vaccine_name']=="meningitis"){
                      console.log('prevnar');
                      vaccineForm.value['number_of_doses']=2

                      if(this.child_name){
                        vaccineForm.value['child_name']=this.child_name
                        console.log('ho child');
                        
                      }
                      
                      if(this.child_name==''){
                        vaccineForm.value['patient_name']=this.full_name
                        console.log('ho parent');
                        
                      }
                      
                      console.log(vaccineForm.value['patient_name']);
                      }
//
                      if(this.vacdata['vaccine_name']=="bcg"){
                        console.log('prevnar');
                        vaccineForm.value['number_of_doses']=1

                        if(this.child_name){
                          vaccineForm.value['child_name']=this.child_name
                          console.log('ho child');
                          
                        }
                        
                        if(this.child_name==''){
                          vaccineForm.value['patient_name']=this.full_name
                          console.log('ho parent');
                          
                        }
                        
                        console.log(vaccineForm.value['patient_name']);
                        }

                        //

                        if(this.vacdata['vaccine_name']=="typhoid"){
                          console.log('prevnar');
                          vaccineForm.value['number_of_doses']=1

                          if(this.child_name){
                            vaccineForm.value['child_name']=this.child_name
                            console.log('ho child');
                            
                          }
                          
                          if(this.child_name==''){
                            vaccineForm.value['patient_name']=this.full_name
                            console.log('ho parent');
                            
                          }
                          
                          console.log(vaccineForm.value['patient_name']);
                          }


                          //

                          if(this.vacdata['vaccine_name']=="td/tdpa"){
                            console.log('prevnar');
                            vaccineForm.value['number_of_doses']=1

                            if(this.child_name){
                              vaccineForm.value['child_name']=this.child_name
                              console.log('ho child');
                              
                            }
                            
                            if(this.child_name==''){
                              vaccineForm.value['patient_name']=this.full_name
                              console.log('ho parent');
                              
                            }
                            
                            console.log(vaccineForm.value['patient_name']);
                            }



    //vaccineForm.value['vaccine_name']=
    // let a=vaccineForm.value['dtpa']
    // console.log(a);
    
    
    // this.auth.vaccine(vaccineForm.value).subscribe((res)=>{
    //   console.log(res);
      
    // });
    
    //swal('');
        
            // swal({
        //   content: {
        //     element: "input",
        //     attributes: {
        //       placeholder: "Type your password",
        //       type: "password",
        //     },
        //   },
        // });

        // if(vaccineForm.value['']){

        // }

        this.auth.vaccine(vaccineForm.value).subscribe(res=>{
          

          this.dosk={
          child_name: vaccineForm.value.child_name,
          date: vaccineForm.value.date,
          details: vaccineForm.value.details,
          docter_id: vaccineForm.value.docter_id,
          dose_no: vaccineForm.value.dose_no,
          number_of_doses: vaccineForm.value.number_of_doses,
          patient_id: vaccineForm.value.patient_id,
          patient_name: vaccineForm.value.patient_name,
          vaccine_name: vaccineForm.value.vaccine_name
          }

      
          console.log(this.dosk);



          console.log(res);  
          this.doses=res['response']
          this.doses1=res['response'].sub_vaccine_name;
          console.log(this.doses);
          
          // this.doses.disabled=true

          this.count1=res['response'].data
         // this.count1={"vaccine_id":res['response']._id}
          console.log(this.count1);
// for(let x in this.count1){
// console.log(x);
// this.count1=<HTMLInputElement>document.getElementById('x')
// this.count1.disabled = true
// // this.count1=x;

// }

console.log(this.count1);


          this.doses={
            "_id": res['response']._id,
            "data":[]
          }

         console.log(this.doses);

         this.ngOnInit();
          // if(res['status']==400){
          //   swal("","Vaccine Already Taken","success");
          // }
          // if(res["status"]=='success'){
          //   swal("", "Vaccine Details are saved succesfully !", "success");

          // }
        
            // setInterval(()=>{
            //   location.reload();
            // },2000)
            
        },error=>{
          console.log('error',error);
          swal("","Vaccine Not Taken !","warning")
        })
        
    
      }
    


      // vacc1(vaccineForm){

      //   if(this.vacdata['vaccine_name']=="oralpolio"||"impolio"){
      //     console.log('polio');
      //     vaccineForm.value['number_of_doses']=4
      
      //     if(this.child_name){
      //       vaccineForm.value['child_name']=this.child_name
      //       console.log('ho child');
            
      //     }
          
      //     if(this.child_name==''){
      //       vaccineForm.value['patient_name']=this.full_name
      //       console.log('ho parent');
            
      //     }
          
      //     console.log(vaccineForm.value['patient_name']);
      //     }
      // }

refresh(){
location.reload()
this.router.navigate(['/patientsearc'])
this.ngOnInit()
}


editDose(a){
console.log(a);

swal("Edit Dose Details", {
  content: {
    element:"input",
    attributes: {
      placeholder: "CHANGE DOSE DETAILS",
      
      style:"text-align:center;font:bold;",
      
    },
   
}



})
// .then((value) => {


//   if(value!==(""|| null)){
//     console.log(value, a, this.doses._id);
//     let b={
//       "vaccine_id":this.doses._id,"_id":a,"details":value
//     }

//     this.auth.vaccine_edit(b).subscribe((res)=>{
//       console.log(res);

//       this.count1=res['response'].data
      
//       swal("Details Updated Successfully !",`${value}`,"success");

//  this.ngOnInit()
//             // setInterval(()=>{
//             //   location.reload();
//             // },5000)
     
//     })


//   }

//   else {
//     swal("Operation Update Cancelled","Not Updated","warning")
//   }

// });

// swal({
//   title: 'Download Notes',
//     html:"<input type='text'>",
//   confirmButtonText: 'Save',
// })
.then(value => {
  console.log(value);
  
    if(value){
      console.log(value, a, this.doses._id);
      let b={
        "vaccine_id":this.doses._id,"_id":a,"details":value
      }
  
      if(b.details!==null || ''){
        this.auth.vaccine_edit(b).subscribe((res)=>{
          console.log(res);
    
          this.count1=res['response'].data
          
          swal("Details Updated Successfully !",`${value}`,"success");
    
     this.ngOnInit()
                // setInterval(()=>{
                //   location.reload();
                // },5000)
         
        })
      }
  
  
  
    }
  
    else{
      swal("Operation Update Cancelled","Data Wasn't Not Updated","warning")
    }
  
  });

}


delDose(a){
  console.log(a);
  swal({
    title: "Are you sure you want to do this ?",
    // text: "Are you sure that you want to leave this page?",
    // icon: "warning",
    dangerMode: true,
    buttons: {
      confirm: true,
      cancel: true,
    
    }
    })
    .then(confirm => {
    if (confirm===true) {
     
      let b={
        "vaccine_id":this.doses._id,"_id":a,
      }

      this.auth.vaccine_remove(b).subscribe(res => {
        // if (res.status == "success") {
        //   if (res.response == "success") {   
        //     if (res.response != "") {
        //      console.log(22, this.admin_user_phone);
        //   }
        // }
        // }
        this.ngOnInit()
        console.log(res);
        this.count1=res['response'].data

        swal("Deleted!", "Your imaginary file has been deleted!", "success");
        // location.reload();
      });

      
      
    }

    else{
      swal("NOT DELETED !","You have cancelled the operation !", "warning");
    }
    
    });
}



// dos(a){

//   this.doses.data.push(a.value)

//   console.log(this.doses);

//   if(this.doses1==''){
//     this.doses.sub_vaccine_name=a.value['vaccine_name'];
//   }

//   else {
//     this.doses.sub_vaccine_name=this.doses1;
//   }

// console.log(a.value);
// this.auth.vaccine_dose(this.doses).subscribe(res=>{
//   console.log(res);

//             if(res["status"]=='success'){
//             swal("", "Vaccine Details are saved succesfully !", "success");

//             setInterval(()=>{
//              location.reload();
//             },1000)

//           }
// })
// //this.ngOnInit()
// }




dos(a){

  
  console.log(this.doses1);
  

  

  if(this.doses1 == null || this.count1.length==0) {
    this.doses['sub_vaccine_name'] = a.value['vaccine_name'];
  }

  else {
    this.doses.sub_vaccine_name=this.doses1;
  }




console.log(a.value);

console.log(this.doses.sub_vaccine_name);



if(this.changeVac == "polio" && a.value.vaccine_name=="" && this.doses1==""){
  swal("Choose Type Of Polio","Type Of Polio Is Required","warning");

  a.value.vaccine_name=""
  this.doses1=""
  this.ngOnInit()
}

else if(a.value.dose_no == "" || a.value.details == ""){

swal("Enter All Fileds","Dose Number & Details Are Required","warning");

this.ngOnInit()
}


else {
  this.doses.data.push(a.value)
  console.log(this.doses);
  this.auth.vaccine_dose(this.doses).subscribe(res=>{
  console.log(res);

            if(res["status"]=='success'){
            swal("", "Vaccine Details are saved succesfully !", "success");

            setInterval(()=>{
             location.reload();
            },2500)

            this.doses.data=[]
              

          }
console.log(this.dosk);

this.auth.vaccine(this.dosk).subscribe(res=>{
  this.count1=res['response'].data
  this.doses1=res['response'].sub_vaccine_name
})

          
})

this.ngOnInit()

}
this.doses1=""
a.value.vaccine_name=""

this.ngOnInit()

}

tab(changeVac){
this.changeVac=changeVac
console.log(changeVac);
}
















//check box-->code
//   vacc(vaccineForm){
// this.vacdata=vaccineForm.value


// for(let x in vaccineForm.value){
//   //console.log(x);
  
// if(vaccineForm.value[x]===true){
//   //this.vaccinename=x;

// vaccineForm.value['vaccine_name']=x;
//   console.log(x);
//   console.log(vaccineForm.value);
  
// // vaccineForm.value['vaccine_name']='';
// // console.log(vaccineForm.value);
// }

// // else if((vaccineForm.value['dtpa']===true)&&(vaccineForm.value[x]===true)){
// //   this.vaccinename=x
// // console.log('dtpa',x);

// // }


// // else if((vaccineForm.value['polio']===true)&&(vaccineForm.value[x]===true)){
// //   this.vaccinename=x;
// // console.log('polio',x);

// // }


// // else if((vaccineForm.value['prevnar']===true)&&(vaccineForm.value[x]===true)){
// //   this.vaccinename=x;
// // console.log('prevnar',x);

// // }
// }









// // for(let x in this.vacdata){
// //   //console.log(this.vacdata);
  
// // if(vaccineForm.value[x]===true){
// // //console.log(x);

// // if(x=='hepatitisb'){
// // console.log('im hepatitisb vcc',x);
// // this.vaccinename=x
// // this.doses=4
// // console.log(this.vaccinename,this.doses);

// // vaccineForm.value['vaccine_name']=x
// // console.log(vaccineForm.value);

// // // this.auth.vaccine(vaccineForm.value).subscribe((res)=>{
// // //   console.log(res);
  
// // // });
// // }

// // if(x=='dtpa'){
// //   console.log('im dtpa',x);
// //   this.vaccinename=x
// //   this.doses=15
// // console.log(this.vaccinename,this.doses);

// // vaccineForm.value['vaccine_name']=x
// // console.log(vaccineForm.value);


// // // this.auth.vaccine(vaccineForm.value).subscribe((res)=>{
// // //   console.log(res);
  
// // // });
// //   }

// // }
// // }
// // let a=vaccineForm.value['dtpa']
// // console.log(a);


// // this.auth.vaccine(vaccineForm.value).subscribe((res)=>{
// //   console.log(res);
  
// // });

// //swal('');
//     swal("", "Vaccine Details are saved succesfully !", "success");
//         // swal({
//     //   content: {
//     //     element: "input",
//     //     attributes: {
//     //       placeholder: "Type your password",
//     //       type: "password",
//     //     },
//     //   },
//     // });

//   }






























  getimg(labid) {
    // location.reload();
    this.auth.Doctorsigimages({ _id: labid }).subscribe(async (res) => {

      this.result1 = res;

      if (this.result1.status == "success") {
        this.result1 = this.result1.response;
        this.imagesurl = [];
        for (let obj of this.result1[0].imagPath) {
          this.patientupdateForm.value.docsign = obj;
          console.log(sessionStorage.getItem('baseUrl') + obj);
          await this.imagesurl.push({ imag: this.auth.baseUrl + obj, id: sessionStorage.getItem('id'), imagPath: obj });
        }
      }
      this.sign = this.result1[0].imagPath[0];
      //this.sign=(this.auth.baseUrl+this.sign).trim();
    }, () => {
      swal("Server failed");

    });
  }




  save(patientupdateForm) {
    console.log("form data", this.patientupdateForm.value.docsign, this.sign);
    this.patientupdateForm.value.docsign = this.sign;
    console.log("this.sign===>" ,  this.patientupdateForm.value.docsign)
    this.patientupdateForm.value.patient_id = this.patient_id;
    this.patientupdateForm.value.phone_no = sessionStorage.getItem("phone_no");
    this.patientupdateForm.value.age = this.age;
    this.patientupdateForm.value.gender = this.gender;
    this.patientupdateForm.value.docter_id = sessionStorage.getItem("docter_id");
    // this.patientupdateForm.value.testName=this.selectedItems;
    this.patientupdateForm.value.test_name = this.arr;


    //this.patientupdateForm.value.child_name = this.child_name;
    this.patientupdateForm.value.child_name = this.child_name;

    this.patientupdateForm.value.patient_name = sessionStorage.getItem("patient_name");
    this.patientupdateForm.value.docter_name = this.docname;
    this.patientupdateForm.value.patient_name = sessionStorage.getItem("full_name");
    console.log("90", this.patientupdateForm.value)

    if (this.che == true && this.patientupdateForm.value.test_name == undefined) {
      swal('Please select test name');
    }
    else if (this.patientupdateForm.value.reason == "") {
      swal('Please enter all the details');

    }
    else if (this.patientupdateForm.value.medicine_prescribed == "") {

      swal('Please enter all the details');


    }
    else if (this.patientupdateForm.value.comments == "") {

      swal('Please enter all the details');
     
    }


    else {
      let age;
      let phone;
      let gender;
      // if (this.child_name != "" && this.child_name != undefined) {
      //   let myItem = this.userData.find(item => item.name === this.child_name);
      //   gender = myItem.gender;
      //   phone = sessionStorage.getItem("phone_no");
      //   age = myItem.age;

      // }
      // else {
      //   age = Number(sessionStorage.getItem("age"));
      //   phone = sessionStorage.getItem("phone_no");
      //   gender = sessionStorage.getItem("sex");
      // }


      console.log('88788event:', this.selectedItems);


      this.patientupdateForm.value.labtest_required = this.che;
      if (this.patientupdateForm.value.labtest_required == true) {
        for (let obj of this.selectedItems) {
          let myItem = this.names.find(s => s.name === obj.name);
          this.arr.push(myItem)
          console.log('89899999arr:', this.arr);
        }

        this.patientupdateForm.value.test_name = this.arr;
      }

      // this.patientupdateForm.value.gender = gender;
      // this.patientupdateForm.value.age = age;
      // this.patientupdateForm.value.phone_no = phone;

      this.auth.doctorvisit(patientupdateForm.value).subscribe(res => {

        if (res.status == "success") {
          if (res.response != "") {
            swal("", "Patient details are saved successfully !", "success");

            // swal("Successfully patient report updated")
            this.router.navigateByUrl("/patientMedicalSearch")

            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
          }
        }
        else {
          swal("Please Fill the required fields")
        }
      }, () => {

        swal(" server failed");
        // swal("", "Vaccine Details are saved succesfully !", "success");

      });
      


    }
  }




  list() {
    this.auth.findchild({ patient_id: this.patient_id, }).subscribe(res => {
      if (res.status == "success") {
        if (res.response != "") {
          this.userDrop = res.response;
          console.log("this.userData =>", this.userDrop);
          //sessionStorage.setItem("userData",this.userData);
        }
      } else {
        swal("Server error")
      }
    });
  }

  view() {
    this.router.navigate(['patientMedicalSearch'], { state: { patient_id: this.patient_id } });
  }


  check(e) {
    console.log(e.target.checked);
    if (e.target.checked == true) {
      this.che = true;
    }
    else {
      this.che = false;
    }
    this.patientupdateForm.value.labtest_required = this.che;

  }



  testNames() {
    this.auth.totalTestNames({ patient_id: this.patient_id }).subscribe(res => {
      this.response = res;
      if (this.response) {
        this.names = this.response.response;
        console.log(98, this.names)

        if(this.response){
          this.names = this.response.response;
          var arr=[]
          for(let x of this.names){
            if(x.name=="C-Reactive Protein and Heart Disease Risk" || x.name=="Glucose (mg/dl)"){
console.log(x.name)
            }

            else {
              arr.push(x)
            }
          }
          this.name2 = arr
          console.log(this.names);
          
        }


      // this.testList = this.names

      // for(var i=0; i<this.testList.length;i++){
      //   this.testName=this.testList.name;

      //   console.log(1332, this.testName);
      //   this.t1[i]=this.testList[i];
      //   console.log("************ tests ==> ",  this.t1[i].name," - ", this.t1[i]._id);

      //   if(this.t1[i].name=="C-Reactive Protein and Heart Disease Risk"){
      //    this.testUser = this.t1[i].name

      //    console.log(1341, this.testUser);
         
          
      //   }

        
      // }
      //
      
      












      } else {
        swal("Server error")
      }
    })
  }
  onItemSelect(event) {
    console.log('event:', this.selectedItems);

  }

  logo() {
    localStorage.setItem('login','');
    location.reload();
this.auth.logout()

  }
  iconChange(e) {
    if(e==0) {
    this.che==true;
    } 
    else {
      this.che==false;
    }
  }


 //onchange function
 childName(e){

  this.age = sessionStorage.getItem("age");
  this.gender = sessionStorage.getItem("gender");
  this.date_of_birth = sessionStorage.getItem("date_of_birth");
  this.email = sessionStorage.getItem("email");
  this.address = sessionStorage.getItem("address");
  this.phone_no = sessionStorage.getItem("phone_no");
  // this.sex= sessionStorage.getItem("gender");
  this.date_of_birth= sessionStorage.getItem("dob");
  // this.email= sessionStorage.getItem("email");
  // this.address= sessionStorage.getItem("address");
  this.state= sessionStorage.getItem("state");
  this.city= sessionStorage.getItem("city"); 



  this.ena=true;
   console.log(8989, e.target.value);
  this.child_name = e.target.value; 
   this.patientupdateForm.value.child_name = e.target.value;

   console.log("child_name",  e.target.value)
   let val = e.target.value;

    let a = this.userDrop.filter(
      (v)=> v.name === val);
      console.log("898989", a)
      if(a!=''){
     this.age=a[0].age,
     this.gender=a[0].gender;
     this.date_of_birth= a[0].date_of_birth;
     this.email= sessionStorage.getItem("email");
        this.phone_no= sessionStorage.getItem("phone_no"); 
        this.state= sessionStorage.getItem("state");
        this.city= sessionStorage.getItem("city");  
        this.address = sessionStorage.getItem("address");
      }
  
      else{
      this.age = sessionStorage.getItem("age");
      this.gender = sessionStorage.getItem("gender");
      this.date_of_birth = sessionStorage.getItem("date_of_birth");
      this.email = sessionStorage.getItem("email");
      this.address = sessionStorage.getItem("address");
      this.phone_no = sessionStorage.getItem("phone_no");

      // this.sex= sessionStorage.getItem("gender");
      this.date_of_birth= sessionStorage.getItem("dob");
      // this.email= sessionStorage.getItem("email");
      // this.address= sessionStorage.getItem("address");
      this.state= sessionStorage.getItem("state");
      this.city= sessionStorage.getItem("city"); 
      }

  }

  alert(){
    swal("Select Patient Name First","See At Left Side Of Page","warning");
  }
  
}
