import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-lab-user-edit',
  templateUrl: './lab-user-edit.component.html',
  styleUrls: ['./lab-user-edit.component.css']
})
export class LabUserEditComponent implements OnInit {
  relationForm: FormGroup;
  che: boolean;
  role: any;
  LabAccount_results: any;
  ActivateDoctor_results: any;
  ProcessPayments_results: any;
  //FeeSetup: boolean;
  // Reports: boolean;
  // BlockAccount: boolean;
  // ConnectDoctor: boolean;
  isLabAccount: boolean;
  isLabAccount_result: string;
  isActivateDoctor: boolean;
  isActivateDoctor_result: string;
  isProcessPayments: boolean;
  isProcessPayments_result: string;
  isReports: boolean;
  isReports_result: string;
  isFeeSetup: boolean;
  isFeeSetup_result: string;
  isBlockAccount: boolean;
  isBlockAccount_result: string;
  isConnectDoctor: boolean;
  isConnectDoctor_result: string;
  Reports_results: any;
  BlockAccount_results: any;
  ConnectDoctor_results: any;
  FeeSetup_results: any;
  email: any;
  admin_user_phone: any;
  user_name: any;
  _id: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
  ) { 
    this.relationForm = this.fb.group({
      //lab_id: ['', Validators.required],
      user_name: ['', Validators.required],
      admin_user_phone: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      status: [true, Validators.required],
      // role:["k", "karthi","","",""],
      role: [[]],
      _id: []
    });
    // this.relationForm = this.fb.group({
   
    //   admin_user_phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    //   user_name: ['', Validators.required],
    //   email: ['', Validators.compose([
    //     Validators.required,
    //     Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    //   ])],
    //   status: [true, Validators.required],
    //   role: [[]],
    // })
  }

  ngOnInit() {
    this.createForm();
    this.route.queryParams.subscribe(queryParams =>{
      let user = JSON.parse(queryParams['user']);
      this.relationForm.patchValue = user;
      
      //console.log(9999,user.user_name)
      this.relationForm.setValue({
        email:user.email,
        status: true,
        role: user.role,
        _id: user._id,
        admin_user_phone: user.admin_user_phone,
        user_name : user.user_name,
        //lab_user_phone: user.lab_user_phone,
        //user_name: Admin_user.user_name,
      
        // admin_user_phone: user.admin_user_phone,
        // email: user.email,
        // password: user.password,
        // status: true,
        // user_name: user.user_name,
        // role: user.role,
      })
      this.role = user.role;
      console.log(1599,this.role);
      if (this.role && this.role.length > 0) {
        this.role.forEach(element => {
          if (element == "Activate Lab Account") {
            this.LabAccount_results = true;
          }
          if (element == "Activate Online Doctor Account") {
            this.ActivateDoctor_results = true;
            console.log('ActivateDoctor_results', this.ActivateDoctor_results);
          }
          if (element == "Process Payments") {
            this.ProcessPayments_results = true;
            console.log('ProcessPayments_results', this.ProcessPayments_results);
          }
          if (element == "Fee Setup") {
            this.FeeSetup_results = true;
            console.log('FeeSetup_results', this.FeeSetup_results);
          }
          if (element == "Reports") {
            this.Reports_results = true;
            console.log('Reports_results', this.Reports_results);
          }
          if (element == "Block Account") {
            this.BlockAccount_results = true;
            console.log('BlockAccount_results', this.BlockAccount_results);
          }
          if (element == "Connect Doctor Online") {
            this.ConnectDoctor_results = true;
            console.log('ConnectDoctor_results', this.ConnectDoctor_results);
          }
        });
      }
    })
  }
  createForm() {
   
  }
  LabAccount(e) {
    console.log(78888, e.target.checked);
    if (e.target.checked == true) {
      this.isLabAccount = true;
      this.isLabAccount_result = "Activate Lab Account";
      this.relationForm.value.role.push(this.isLabAccount_result);
      // console.log()
    } else {
      this.isLabAccount = false;
      this.isLabAccount_result = "Activate Lab Account";
      this.relationForm.value.role.pop(this.isLabAccount_result);
      this.isLabAccount_result = "";
    }
  }
  ActivateDoctor(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isActivateDoctor = true;
      this.isActivateDoctor_result = "Activate Online Doctor Account";
      this.relationForm.value.role.push(this.isActivateDoctor_result);
      // console.log()
    } else {
      this.isActivateDoctor = false;
      this.isActivateDoctor_result = "Activate Online Doctor Account";
      this.relationForm.value.role.pop(this.isActivateDoctor_result);
      this.isActivateDoctor_result = "";
    }
  }
  ProcessPayments(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isProcessPayments = true;
      this.isProcessPayments_result = "Process Payments";
      this.relationForm.value.role.push(this.isProcessPayments_result);
      // console.log()
    } else {
      this.isProcessPayments = false;
      this.isProcessPayments_result = "Process Payments";
      this.relationForm.value.role.pop(this.isProcessPayments_result);
      this.isProcessPayments_result = "";
    }
  }
  Reports(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isReports= true;
      this.isReports_result = "Reports";
      this.relationForm.value.role.push(this.isReports_result);
      // console.log()
    } else {
      this.isReports = false;
      this.isReports_result = "Reports";
      this.relationForm.value.role.pop(this.isReports_result);
      this.isReports_result = "";
    }
  }
  FeeSetup(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isFeeSetup= true;
      this.isFeeSetup_result = "Fee Setup";
      this.relationForm.value.role.push(this.isFeeSetup_result);
      // console.log()
    } else {
      this.isFeeSetup = false;
      this.isFeeSetup_result = "Fee Setup";
      this.relationForm.value.role.pop(this.isFeeSetup_result);
      this.isFeeSetup_result = "";
    }
  }
  BlockAccount(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isBlockAccount= true;
      this.isBlockAccount_result = "Block Account";
      this.relationForm.value.role.push(this.isBlockAccount_result);
      // console.log()
    } else {
      this.isBlockAccount = false;
      this.isBlockAccount_result = "Block Account";
      this.relationForm.value.role.pop(this.isBlockAccount_result);
      this.isBlockAccount_result = "";
    }
  }
  ConnectDoctor(e){
    console.log(7888, e.target.checked);
    if (e.target.checked == true) {
      this.isConnectDoctor= true;
      this.isConnectDoctor_result = "Connect Doctor Online";
      this.relationForm.value.role.push(this.isConnectDoctor_result);
      // console.log()
    } else {
      this.isConnectDoctor = false;
      this.isConnectDoctor_result = "Connect Doctor Online";
      this.relationForm.value.role.pop(this.isConnectDoctor_result);
      this.isConnectDoctor_result = "";
    }
  }
  add(relationForm){
    
    this.auth.lab_admin_userEdit(relationForm.value).subscribe(res =>{
      if (res.status == "success") {
        if (res.response != "") {
          //  location.reload();
          // this.dialogRef.close();
          console.log("21", this.relationForm);
        }

        this.auth.childeditData.next(res.response);
        console.log(39, res);
      }
      swal("Update Successfully");
       this.router.navigateByUrl('/user-edit');
    })
  }
  logout(){
    swal({
      title: 'Successfully Logged Out',
      text: 'Redirecting...',
      timer: 3000,
      // buttons: true,
  })
  .then(() => {
    this.router.navigateByUrl('/user-edit');
  });
  } 

}
