import { Component, OnInit, Inject, Input, EventEmitter, Optional } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { LabresultUpdateComponent } from '../labresult-update/labresult-update.component';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { ViewimageComponent } from '../../viewimage/viewimage.component';
import { ResultLabComponent } from '../../result-lab/result-lab.component';
declare var swal;

@Component({
  selector: 'app-test-model',
  templateUrl: './test-model.component.html',
  styleUrls: ['./test-model.component.css']
  
})
export class TestModelComponent extends MzBaseModal  implements OnInit {
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  sname:any;
    arr=[];
    patient_id: any;
    response: any;
    names: any;
    userData: any;
    reason:any;
    child_name: any;
    test_name:any=[];
    testAssignForm: FormGroup;
    child: any;
    dropdownSettings:{};
   full_name: string;
  selectedItems: any=[];
  result: any;
  imagesurl: any[];
  doc_date: any;
  reports: any;
  visits: any;
  test_res: any;
  name2: any[];

    

    constructor(public dialogRef: MatDialogRef<LabresultUpdateComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private modalService: MzModalService,

      private fb: FormBuilder,
      private router: Router,
      protected auth: AuthService,
      protected activatedRoute: ActivatedRoute) {


      super();
         this.userData=JSON.parse(sessionStorage.getItem("userData"));
         this.patient_id=sessionStorage.getItem("patient_id")
         this.full_name=sessionStorage.getItem("full_name")
         console.log(22,this.patient_id)
         this.testNames();
         this.childNameDropDwon();

         this.getimg(this.patient_id);
         
         this.dropdownSettings = {
          singleSelection: false,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
       };
      
       }
       
 
  ngOnInit() {
    this.testAssignForm  =  this.fb.group({
      labtest_required: [true],
      test_name: [[]],
      patient_name:[''],
      child_name: ['',Validators.required],
      age:[''],
      gender:[''],
      phone_no:[''],
      docter_name:[''],
      
      });
  }
  showname()
  {
    this.sname=1;
    
  }
  save() {
    let gender;
    let age;
    let phone;
if(this.child_name!="" && this.child_name!=undefined)
{
    let myItem = this.child.find(item => item.name === this.child_name);
    gender=myItem.gender;
    phone=sessionStorage.getItem("phone_no");
    age=myItem.age;
}
else{
  age=sessionStorage.getItem("age");
  phone=sessionStorage.getItem("phone_no");
  gender=sessionStorage.getItem("sex");
}



    if(this.testAssignForm.value.test_name == '' || this.sname!=1){
      swal("Please fill the details")

    } else {
      
      for(let obj of this.selectedItems)
      {
        let myItem =this.names.find(s => s.name === obj.name);
        this.arr.push(myItem)
        console.log('arr:', this.arr);
      }
      this.testAssignForm.value.test_name=this.arr;
    
      this.testAssignForm.value.gender=gender;
      this.testAssignForm.value.age=Number(age);
      this.testAssignForm.value.phone_no=phone;
      this.testAssignForm.value.patient_name= sessionStorage.getItem("full_name");


      this.testAssignForm.value.patient_id=this.patient_id;
      this.testAssignForm.value.docter_id="";
      this.auth.doctorVisits(this.testAssignForm.value).subscribe(res => {
        if (res.status == "success") {
          if (res.response != "") {
          var res= res.response;
           this.dialogRef.close(res);
          }
        }
        //location.reload();
      });
     }

    }
  
  testNames(){
    this.auth.totalTestNames({patient_id:this.patient_id}).subscribe(res => {
        this.response = res;
        if (this.response) {
          this.names = this.response.response;
          console.log(98, this.names)
  
          if(this.response){
            this.names = this.response.response;
            var arr=[]
            for(let x of this.names){
              if(x.name=="C-Reactive Protein and Heart Disease Risk" || x.name=="Glucose (mg/dl)"){
  console.log(x.name)
              }
  
              else {
                arr.push(x)
              }
            }
            this.name2 = arr
            console.log(this.names);
            
          }
  
  
        // this.testList = this.names
  
        // for(var i=0; i<this.testList.length;i++){
        //   this.testName=this.testList.name;
  
        //   console.log(1332, this.testName);
        //   this.t1[i]=this.testList[i];
        //   console.log("************ tests ==> ",  this.t1[i].name," - ", this.t1[i]._id);
  
        //   if(this.t1[i].name=="C-Reactive Protein and Heart Disease Risk"){
        //    this.testUser = this.t1[i].name
  
        //    console.log(1341, this.testUser);
           
            
        //   }
  
          
        // }
        //
        
        
  
  
  
  
  
  
  
  
  
  
  
  
        } else {
        swal("Server error")
      }
    })
  }
  childNameDropDwon() {
    this.auth.findchild({patient_id:this.patient_id}).subscribe(res => {
      this.child=res;
      if(res.response) {
          this.child = this.child.response;
      } else {
        swal("Server error")
      }
    })
   }
   onItemSelect(event) {
     
    
  }


  getimg(patientid) {
    // location.reload();
     this.auth.getimages(patientid).subscribe(res => {
       this.result=res;
       if (this.result.status == "success") {
         this.result=this.result.response;
         this.imagesurl=[];
         for(let obj of this.result[0].imagPath)
         {
           this.imagesurl.push({imag:'http://18.206.155.160:4100'+obj,id:sessionStorage.getItem('id'),imagPath:obj});
         }
       }
      }, () => {
       swal("Server failed");
 
     });
   }



  onSelectAll(items: any) {
    console.log(items);
  }

  close()
  {
    this.dialogRef.close();
  }
  


  
}
