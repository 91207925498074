import { Component, OnInit , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators,FormGroup} from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MaterializeAction } from 'angular2-materialize';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MzBaseModal } from 'ngx-materialize';
//import { truncate } from 'fs';
declare var swal;
@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit  {
  // [x: string]: any;
  relationForm: FormGroup;
  lab_id: any;
  phone_no: any;
  email: any;
  lab_user_phone: any;
  isviewResult: boolean;
  v_result: string;
  iseditResult: boolean;
  e_result: string;
  isapproveResult: boolean;
  a_result: string;
  isBilling: boolean;
  billing: string;
  isReports: boolean;
  reports: string;
  che: boolean;
  // role:[];
  role: string;
  userData: any;
  r_data: string[];
  name: string;

  constructor(private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute) {
      this.relationForm = this.fb.group({
        // lab_id: ['',Validators.required],
        user_name: ['',Validators.required],
        lab_user_phone: ['',Validators.required],
        // email: ['',Validators.compose([
        //   Validators.required,
        //   Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        // ])],
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phone_no: ['',Validators.required],
        password: ['',Validators.required],
        lab_name: sessionStorage.getItem("lab_name"),
        
        lab_address: sessionStorage.getItem("lab_city"),
        status: [true ,Validators.required],
        //check:['',Validators.required]
        //  role:[[]],
         
      });
      this.lab_id= sessionStorage.getItem("lab_id");
    // this.lab_create();
     console.log(this.lab_id);

      
     }
     
     
  ngOnInit() {
    this.createForm();
  
   




    this.route.queryParams.subscribe(queryParams => {
      //console.log(9999,queryParams);
     let user = queryParams['user'];
     this.relationForm.patchValue=JSON.parse(user);
     this.relationForm.value.phone_no=JSON.parse(user).phone_no;

     console.log(9099,JSON.parse(user).phone_no);
     //this.relationForm.patchValue(user);
     this.phone_no=JSON.parse(user).phone_no;
     this.email=JSON.parse(user).email;
     this.lab_user_phone=JSON.parse(user).lab_user_phone;
     console.log(8989, this.lab_user_phone);
    //  this.relationForm.value._id =JSON.parse(user)._id
    //  console.log(90000,JSON.parse(user)._id);
   }); 
   
   
  }
  
  
  createForm(){
  
  }
  add() {
    
   
    this.relationForm.value.lab_id=this.lab_id;
    console.log(8888,this.lab_id)

    //console.log("dfjghdfjg", this.relationForm.value.role);
    this.r_data = [this.v_result, this.e_result, this.a_result, this.billing, this.reports];
    console.log(108, this.r_data.length);

    let i=0;
    for (let j=0;j<this.r_data.length;j++){
      console.log(114, this.r_data.length );
      
if(this.r_data[j] !=null ){
i++;
 //break;
}
    }
    console.log(119, i)
    if(i>0){
    this.relationForm.value.role=JSON.stringify(this.r_data);
    // this.relationForm.value.role=JSON.stringify(this.relationForm.value.role);
    console.log(" ***this.r_data====>", this.r_data);

      console.log("***role====>", this.relationForm.value.role);

     
    this.auth.lab_create(this.relationForm.value).subscribe(res=>{
      if(res.status=="success"){
        // sessionStorage.setItem("lab_address",this.userData.city)
        // sessionStorage.setItem("lab_name",this.userData.name);
          swal("User added successfully");
           //location.reload();

        // }
      }   



      if(res.error=='error')
      {
        swal(res.error.message,"","error");
      }
    },
    (err)=>{
      console.log(99,err)

      console.log(err.error.message.error);
      console.log(153,err.error.message.errmsg);
      

      if(err.error.message.error== 'Plan Limit Exceded'){
        swal('Cannot add  new user , already reached to your user limits');
      }
      if(err.error.message.errmsg=='E11000 duplicate key error collection: one_health.labusers index: lab_user_phone_1 dup key: { lab_user_phone: 22330 }'){
        swal('User id already exist')
      }
      if(err.error.message.errmsg=='E11000 duplicate key error collection: one_health.labusers index: email_1 dup key: { email: "ghfghfg@gmail.com" }'){
        swal('Email already exist')
      }
    }
    // (err) => {
    //   swal("Cannot add  new user , already reached to your user limits ");
      
    // },
    )
    }
  else {
   swal("Please Select One Role")
  }
}

    
    //  () => {
    // swal("User added, User details not exsits ");
    
    // }
     
    // }
    //  else {
    //   swal("Please Fill the required fields")
    // }
  
  
  // viewCheck(e) {
  //    console.log(55555,e.target.checked);
  //   // console.log('isviewResult ==>', e.detail.checked);
  //   if (e.target.checked ==true) {
  //     this.isviewResult = true;
  //     this.v_result ="viewResult";
  //     this.relationForm.value.role.push(this.v_result);
  //     // console.log()
  //   } else {
  //     this.isviewResult = false;
  //     this.v_result ="";
  //   }
  // }
  // editCheck(e) {
  //   // console.log('iseditResult ==>', e.detail.checked);
  //   console.log(55555,e.target.checked);
  //   if (e.target.checked ==true) {
  //     this.iseditResult = true;
  //     this.e_result ="Edit Result";
  //     this.relationForm.value.role.push(this.e_result);
  //   } else {
  //     this.iseditResult = false;
  //     this.e_result ="";
  //   }
  // }

  // approveCheck(e) {
  //   // console.log('isapproveResult ==>', e.detail.checked);
  //   console.log(55555,e.target.checked);
  //   if (e.target.checked ==true) {
  //     this.isapproveResult = true;
  //     this.a_result ="Approve Result";
  //     this.relationForm.value.role.push(this.a_result);
  //   } else {
  //     this.isapproveResult = false;
  //     this.a_result ="";
  //   }
  // }

  // billingCheck(e) {
  //   // console.log('isBilling ==>', e.detail.checked);
  //   console.log(55555,e.target.checked);
  //   if (e.target.checked ==true) {
  //     this.isBilling = true;
  //     this.billing ="Billing"
  //     this.relationForm.value.role.push(this.billing);
  //   } else {
  //     this.isBilling = false;
  //     this.billing ="";
  //   }
  // }

  // reportCheck(e) {
  //   // console.log('isReports ==>', e.detail.checked);
  //   console.log(55555,e.target.checked);
  //   if (e.target.checked ==true) {
  //     this.isReports = true;
  //     this.reports ="Reports"
     
  //   } else {
  //     this.isReports = false;
  //     this.reports ="";
  //   }
  // }
  // check(e){
  //   console.log(e.target.checked);
  // if(e.target.checked ==true){
  //   this.che=true;

  // }
  // else{
  //  this.che=false;
  // }

  // }

  
  viewCheck1(e){
    console.log(78888,e.target.checked);
    // console.log('isviewResult ==>', e.detail.checked);
    if (e.target.checked ==true) {
      this.isviewResult = true;
      this.v_result ="viewResult";
      this.relationForm.value.role.push(this.v_result);
      // console.log()
    } else {
      this.isviewResult = false;
      this.v_result ="viewResult";
       this.relationForm.value.role.pop(this.v_result);
      this.v_result ="";
    }
  }
  editCheck1(e) {
    // console.log('iseditResult ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.iseditResult = true;
      this.e_result ="Edit Result";
      this.relationForm.value.role.push(this.e_result);
    } else {
      this.iseditResult = false;
      this.e_result ="Edit Result";
     this.relationForm.value.role.pop(this.e_result);
      this.e_result ="";
    }
  }
  approveCheck1(e) {
    // console.log('isapproveResult ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.isapproveResult = true;
      this.a_result ="Approve Result";
     this.relationForm.value.role.push(this.a_result);
    } else {
      this.isapproveResult = false;
      this.a_result ="Approve Result";
     this.relationForm.value.role.pop(this.a_result);
      this.a_result ="";
    }
  }
  billingCheck1(e) {
    // console.log('isBilling ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.isBilling = true;
      this.billing ="Billing"
      this.relationForm.value.role.push(this.billing);
    } else {
      this.isBilling = false;
      this.billing ="Billing"
    this.relationForm.value.role.pop(this.billing);
      this.billing ="";
    }
  }
  reportCheck1(e) {
    // console.log('isBilling ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.isReports = true;
      this.reports ="Reports"
      this.relationForm.value.role.push(this.reports);
    } else {
      this.isReports = false;
      this.reports ="Reports"
      this.relationForm.value.role.pop(this.reports);
      this.reports ="";
    }
  }
  logout(){
    sessionStorage.clear();
  localStorage.clear();
    this.router.navigateByUrl('/new-lab');
  }
  }
  // this.relationForm.value._id=this._id;
  


