import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-service',
  templateUrl: './terms-service.component.html',
  styleUrls: ['./terms-service.component.css']
})
export class TermsServiceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  // websiteHyperlink(){
  //   window.location.href = 'https://onehealthforall.com/pricing';
  // }
  websiteHyperlink(){
    
  }
}
