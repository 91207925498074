import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var swal;
@Component({
  selector: 'app-set-pass',
  templateUrl: './set-pass.component.html',
  styleUrls: ['./set-pass.component.css']
})
export class SetPassComponent implements OnInit {
  relationForm: FormGroup;
  lab_id: string;
  phone_no: any;
  email: any;
  lab_user_phone: any;
  _id: any;
  userData: any;
  activatedRoute: any;
  id: any;

  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) {
    this.relationForm = this.fb.group({
      lab_user_phone: ['',Validators.required],
      password: ['',Validators.required],
    });
   }

  ngOnInit() {
  }
  update(relationForm){
    console.log(95555, this.relationForm.value)
    this.auth.lab_newPassword(this.relationForm.value).subscribe(res => {
      if(res.status == "success"){
        swal("password updated Sucessfully");
        this.router.navigateByUrl('/new-lab');
      // this.router.navigateByUrl('/new-lab');
       
      }
      
       })
  }
  logout(lab){
    swal({
      title: 'Password Changed Successfully !!!😊',
      text: 'Redirecting...',
      icon: 'success',
      timer: 4000,
      buttons: true,
  })
  .then(() => {
    this.router.navigateByUrl('/new-lab');
  });
  } 
  }


