import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-doc-fee',
  templateUrl: './doc-fee.component.html',
  styleUrls: ['./doc-fee.component.css']
})
export class DocFeeComponent implements OnInit {
  a: any;
  b:any;

  constructor(private auth:AuthService,private router:Router) {
    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
   }

   EditCharges1(id,charges){
console.log(charges,id);
this.b={
  id:id,
  // charges:Number(charges)
  charges:+(charges)
}
console.log(this.b);

   }


   editCharges(onehealthcharges){
     console.log(onehealthcharges);
console.log((+onehealthcharges)+(+this.b.charges));
let finalCharges=(+onehealthcharges)+(+this.b.charges);


if(onehealthcharges!==''||null){
  this.auth.editonecharges({_id:this.b.id,charges:this.b.charges,onehealthcharges:onehealthcharges,finalCharges:finalCharges}).subscribe(res=>{
    console.log(res);
    swal('One Health Charges Updated','','success')
    this.ngOnInit()
    })
}

else {
  swal("Enter Charges","Invalid values","warning");
}


   }

  ngOnInit() {


  
      this.auth.doc_find().subscribe((data)=>{
        //'http://192.168.1.50:4100/doctor_online/findall',''
        
          this.a=data['response'].reverse()
              console.log(data['response']);
         for(let x of this.a){
        
                console.log(x);
              
              }
        console.log(this.a);
        
              })

              

  }


  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   }
   logo(){
     
   }

}
