import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ApproveComponent } from '../approve/approve.component';
import { TestModelComponent } from '../pages/labModel/assignTest/test-model.component';
import { EditassignComponent } from '../pages/labModel/editassign/editassign.component';
import { LabresultUpdateComponent } from '../pages/labModel/labresult-update/labresult-update.component';
import { AuthService } from '../services/auth.service';
import { UserPrintComponent } from '../user-print/user-print.component';
import { PrintComponent } from '../vedio/print/print.component';
declare var swal;
@Component({
  selector: 'app-approve-test',
  templateUrl: './approve-test.component.html',
  styleUrls: ['./approve-test.component.css']
})
export class ApproveTestComponent implements OnInit {
arr:any;
  userData: any;
  patient_id: string;
  name: string;
  phone_no: string;
  child_name: any;
  child: any;
  visits: any;
  patData: any;
  labData: string;
  result: any;
  test_res: string;
  searchText = '';
   years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  searchOptions = {
    year:"",
    child_name:""
   };

  chidData: Object;
  lablist: any;
  test_name: any;
  doc_date: any;
  reports: any;
  dateee: any;
  lab_id: any;
  config:any;
  email: string;
  resultres: any;
  response: any;
  private _id: string;
  patient_data: any;
  names: any;
  imagesurl:any;
  sign: any;
  sig: any;
  result1: any;
  id: any;
  labid: any;
  personData: string;
  imagePath: string;
  lab_name: any;
  city: string;
  userLabname: any;
  labAddressName: any;
  labcity1: any;
  lab_city: any;
  lab_city12: any;
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private datePipe: DatePipe,
  ) {
    this.patient_id=sessionStorage.getItem("patient_id")
    console.log("45454" ,this.patient_id);
    this.phone_no=sessionStorage.getItem("phone_no")
   this.name=sessionStorage.getItem("full_name")
   this.email=sessionStorage.getItem("email");
   this. lab_id=sessionStorage.getItem('lab_id');
   console.log("78787878787 id", this.lab_id);
   this._id =sessionStorage.getItem("_id");
   console.log("78787878",this._id)
  

  //  this.name =  sessionStorage.getItem("name");

  //  console.log("name**********", this.name)

  //  this.city=sessionStorage.getItem("lab_city");

  //  console.log("city====>", this.city)
  //  this.personData=JSON.parse(sessionStorage.getItem(this.userData));
  // this.personData= sessionStorage.getItem("userData");
  //  console.log("545454" , this.personData);
  //  this.imagePath=sessionStorage.getItem("imagePath");
  //  this.imagePath=this.imagePath;
  //  console.log("images==>", this.imagePath)
  //  let image = this.imagePath;

  
  // this.arr = image.split(',')
  //  console.log("images==>" , this.arr);
  
   console.log("8979-id", this._id);
   

   this.visits="";
   this.dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();
  //  if( sessionStorage.getItem("lab_phone")==" " || sessionStorage.getItem("lab_phone") == undefined){
  //   this.router.navigateByUrl('/login');
  // }

   }

  ngOnInit() {
    this.labAddress();
    this.auth.testApprove({lab_id:this.lab_id,status:2,payment_status:1,_id:this._id}).subscribe(res =>{
     
      this.resultres = res;
      console.log("8989_id",this.resultres._id);
      if( this.resultres.status == "success"){
        
       this.response=this.resultres.response;
       console.log("this.response" , this.response)

       this.visits=res.response.reverse();
         this.config = {
          itemsPerPage: 3,
          currentPage: 1,
          totalItems: this.visits.count
        };  
        console.log(22,this.visits);
       //console.log(res.json())
         //console.log({res.lab_id})
       // this.Contact_Name=res.response;
       // this.dataSource = new MatTableDataSource<PeriodicElement>(this.Contact_Names);
      }
     })

    //this.getimg(this.id)
  }
  labAddress() {
    this.auth.lab_user_gst({ lab_id: this.lab_id }).subscribe(res => {

      this.resultres = res;

      if (this.resultres.status == "success") {

        this.response = this.resultres.response[0].gst;
        this.userLabname = this.resultres.response[0].name;
        this.labAddressName = this.resultres.response[0].address;
        console.log(157, this.labAddressName);
        
        //  console.log(132,    this.userLabname );

        //  this.Gst= this.response.gst;
        //  console.log("testname===>", this.response.gst)
        //  console.log(this.response);
        //  this.Gst = this.response.gst;
        //  sessionStorage.setItem('gst',this.response);

        //  this.Gst = Number(sessionStorage.getItem("gst"));
        //  console.log("this.gst=====>", this.Gst)
        //  console.log('45454', this.response);
      }
    })
  }


  assignTest() {
    const dialogRef = this.dialog.open(TestModelComponent, {
      width: '250px',
      data: ''
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.search();
      this.details(result);
    });
  
  }
  editassignTest(item) {
    const dialogRef = this.dialog.open(EditassignComponent, {
      width: '250px',
      data: {
        test_id: item
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.search();
    });
  
  }

  search(){
   
    this.auth.patient_history({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
      if (res.status == 'success') {
       
        this.visits = (res.response).reverse() ;
     //  this.labhistory();
        console.log(39,this.visits);
        this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };
       
      }
      else{
        this.visits=[];
      }
    }, console.log);
   }
   details(e) {
       
    console.log("e  ==>  ", e)
    this.listbyid(e.test_name,e._id,e.status,e.docter_name,sessionStorage.getItem('name'),e.child_name,e.comment,e.date,e.timeStamp,e.gender,e.age,e.phone_no,this.labAddressName,this.userLabname);
console.log(225, e.test_name,e._id,e.status,e.docter_name,sessionStorage.getItem('name'),e.child_name,e.comment,e.date,e.timeStamp,e.gender,e.age,e.phone_no,this.labAddressName,this.userLabname);


}
listbyid(
  test_id,list_id,
  sts,docter_name,
  lab_name,child_name,
  comment,doc_date,
  timeStamp,gender,
  age,phone_no,
  lab_city,
  name) {
  // this.labupdateForm .value.docter_id=this.docter_id;

  console.log("city==>" ,this.city);
  //console.log("lab_city==>" ,this.lab_city12);
   this.auth.testbyid( {test:test_id}).subscribe(res => {
     if (res.status == "success") {
       if (res.response != "") {
      this.test_res=res.response;
      console.log("test_id",this.test_res)
      console.log("city",this.lab_city)
     // this.router.navigate(['/LabresultUpdateComponent'],{ queryParams:{test_id:JSON.stringify(this.test_res),_id:list_id,sts:sts,"docter_name":docter_name,"lab_name":lab_name,"child_name":child_name,comment:comment,"doc_date":doc_date,"timeStamp":timeStamp}});
       console.log({
        "lab_name":this.userLabname,
       });
       
     this.view({
         test_id:this.test_res,_id:list_id,sts:sts,
        "docter_name":docter_name,
        "lab_name":this.userLabname,
        "child_name":child_name,comment:comment,
        "doc_date":doc_date,"timeStamp":timeStamp,
        gender:gender,age:age,
        phone_no:phone_no,
        "lab_city":this.lab_city});
        console.log("this-labName====>" ,this.lab_city)
     }
     }
   }, () => {
        swal("Server failed, Server details not exits ");
    });
    console.log({
      "lab_name":this.userLabname,
     });
   }
   

   view(e) {
     const dialogRef = this.dialog.open(LabresultUpdateComponent, {
       width: '250px',
       data: e
     });
 
     dialogRef.afterClosed().subscribe(result => {
      this.search();
     });
   
   }

  //  report(e) {
  //   console.log("report data==>",e)
    
   
  //  }


   report(patient_id,
    tid,docid,doc_date,
    complete,range,child_name,
    timeStamp,test_date,docter_name,
    patient_name,gender,
    age,phone_no,
    lab_name,sig,_id,
    status,
    sign,
    lab_city,
    name) {
    console.log(265,
      patient_id,tid,docid,doc_date,complete,
      range,child_name,timeStamp,test_date,docter_name,
      patient_id,patient_name,
      gender,
      age,phone_no,
      lab_name,
      sig,sign,name,
      lab_city)
    
    this.doc_date=doc_date;
    console.log(279,lab_city);
    console.log(2899,lab_city);

    this.labcity1=lab_city
    
    //this.imagePath=imagePath;
    console.log("87878==>",this.imagePath);

    this.auth.test_report({complete:complete,
      test_name:tid,patient_id:patient_id,
      docter_id:docid,
      doc_date:this.doc_date,
      range:range?range:"",child_name:child_name?child_name:"",
      timeStamp:timeStamp,
      arr:this.arr,phone_no:phone_no,
      lab_city:this.lab_city,
      // lab_city,
      name:this.name}).subscribe(res => {
      this.reports=res;
       if (this.reports.status == "success") {
        console.log("545454==>",this.lab_name)
        console.log("this.reports", this.reports)
        console.log("this._id", this._id)
        console.log("this.sig", this.sig)
        console.log("this.lab_city12", this.lab_city12)

          this.reportview({_id:_id,
            reports:this.reports.response,
            child_name:child_name,
            testname:tid,
            doc_date:doc_date,
            date:test_date,
            docter_name:docter_name,
            patient_name:patient_name,
            gender:gender,
            age:age,
            phone_no:phone_no,
            lab_name:this.userLabname,
            sig:sig,status:status,
            sign:sign,arr:this.arr,patient_id:patient_id,
            lab_city:this.labAddressName,
            });
          // arr:this.arr
          //arr:this.arr
   
        }
    }, (err) => {
        swal("server failed, server details not exits ");
     });
   }

   reportview(e) {
//PrintComponent
    const dialogRef = this.dialog.open(PrintComponent, {
      width: '250px',
      data: e
   
    });
    dialogRef.afterClosed().subscribe(result => {
let vr=this.visits;
this.visits=vr;
console.log("visits", this.visits);


      console.log('The dialog was closed');
                 });
  
  }

  
  logout(){
  //   sessionStorage.clear();
  // localStorage.clear();
  this.router.navigateByUrl('/login');
  }
  getimg(labid) {
    // location.reload();


  this.auth.sigimages({_id:labid}).subscribe(res => {
       console.log('hello');
       
       this.result=res;
       if (this.result.status == "success") {
         this.result=this.result.response;
         this.imagesurl=[];
         for(let obj of this.result[0].imagPath)
         {
           console.log(sessionStorage.getItem('baseUrl')+obj);
           this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
         }
       }
      }, () => {
       swal("Server failed");
 
     });
   }



   approve(e){
    let dialogRef1 = this.dialog.open(ApproveComponent, {
      width:'600px',
    });
    dialogRef1.afterClosed().subscribe(result =>{
      if(result.sig!=0){
        let sig = result.sig;
        this.approvesig(sig,e);
      }
    });
  }
  approvesig(sig,e){
    this.auth.approvesig({_id:e,approval:1,imageUrl:sig}).subscribe(res => {
        this.response = res;
      if(this.response) {
          this.names = this.response.response;
          location.reload();
          console.log(98,this.names)
      } else {
        swal("Server error")
      }
    })
  }


  pageChanged(event){
    this.config.currentPage = event;
  }
}

  //approve sign









// }

  // getimg(labid) {
  //   // location.reload();
  //    this.auth.sigimages({_id:labid}).subscribe(res => {
      
  //      this.result=res;
       
  //      if (this.result.status == "success") {
  //        this.result=this.result.response;
        
  //        this.imagesurl=[];
  //        for(let obj of this.result[0].imagPath)
  //        {
  //          console.log(sessionStorage.getItem('baseUrl')+obj);
  //          this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
  //        }
  //      }
  //     }, () => {
  //      swal("Server failed");
 
  //    });
  //}