import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { PrintComponent } from 'src/app/vedio/print/print.component';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

import { Injectable } from '@angular/core';
import { ApproveComponent } from 'src/app/approve/approve.component';

declare var swal;
@Component({
  selector: 'app-userviewtest',
  templateUrl: './userviewtest.component.html',
  styleUrls: ['./userviewtest.component.css']
})
export class UserviewtestComponent implements OnInit {

  firstDate: any;

  labupdateForm: FormGroup;
  userData: any;
  patient_id: string;
  name: string;
  phone_no: string;
  child_name: any;
  child: any;
  visits: any;
  patData: any;
  labData: string;
  result: any;
  test_res: string;
  years: any = [{ year: 2019 }, { year: 2020 }, { year: 2021 }, { year: 2022 }, { year: 2023 }, { year: 2024 }, { year: 2025 }, { year: 2026 }, { year: 2027 }, { year: 2028 }];


  chidData: Object;
  lablist: any;
  test_name: any;
  doc_date: any;
  reports: any;
  dateee: any;
  lab_id: any;
  config: any;
  secondDate: any;
  selectedItems: any = [];
  names: any;
  number: any;
  arr: any = [];
  testAssignForm: any;
  resultres: any;
  response: any;

  user_name: any;
  dropdownSettings: {};
  dropdownSettings1: {};
  dropdownSettings2: {};

  docter_name: any;
  doctlist: any;
  wild: any;
  patientlist: any;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy'
    // other options are here...
  };
  myDpOptions1: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy'
    // other options are here...
  };
  city: string;
  name1: any;
  lab_name: any;
  //resultres: Object;
  lab: any;
  userLabname: any;
  labAddressName: any;
  userName: any;
  userResult: any;
  user1212121: any;

  constructor(private router: Router, private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private _date: DatePipe) {
    this.userData = JSON.parse(sessionStorage.getItem("userData"));

    console.log("userData======>", this.userData);
    //console.log("user_name======>", this.userData.user_name);
    this.patient_id = sessionStorage.getItem("patient_id")

    console.log("45454545======>", this.patient_id);
    this.phone_no = sessionStorage.getItem("phone_no");

    console.log(9888, this.phone_no);
    this.name = sessionStorage.getItem("full_name");
    console.log("thititt====>", this.name);
    this.lab_id = sessionStorage.getItem('lab_id');

    console.log("45454545======>", this.lab_id);
    this.visits = "";



    // this.name1 = sessionStorage.getItem("lab_name");

    // console.log("name**********", this.name1)




    this.city = sessionStorage.getItem("lab_address");

    console.log("city====>", this.city)
    //  this.dateee= new Date().toISOString().split('T')[0];
    this.dateee = (this.datePipe.transform(Date.now(), 'd-M-yyyy', 'en-IN')).toString();


    console.log(22, this.patient_id)
    this.list();
    this.testNames();
    this.doctorlist();
    this.SearchForPatientNames();
    console.log(229, this.chidData)


    this.dropdownSettings = {
      singleSelection: true,
      idField: '_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: true,
      idField: 'patient',
      textField: 'patient_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettings2 = {
      singleSelection: true,
      idField: 'name',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  ngOnInit() {
    this.patientData();
    this.list();
    this.viewTestDatas();
   this. labAddress();
    //  sessionStorage.setItem('num','0');
    //  for(let i=1;i==Number(sessionStorage.getItem('num'));i--)
    //  {
    //   this.patientData();

    //    sessionStorage.setItem('num','1');

    //  }
    


  }

  labAddress() {
    this.auth.lab_user_gst({ lab_id: this.lab_id }).subscribe(res => {

      this.resultres = res;

      if (this.resultres .status == "success") {

        this.response = this.resultres.response[0].gst;
        this.userLabname = this.resultres.response[0].name;
        this.labAddressName = this.resultres.response[0].address;
        console.log(157, this.labAddressName);
        
        //  console.log(132,    this.userLabname );

        //  this.Gst= this.response.gst;
        //  console.log("testname===>", this.response.gst)
        //  console.log(this.response);
        //  this.Gst = this.response.gst;
        //  sessionStorage.setItem('gst',this.response);

        //  this.Gst = Number(sessionStorage.getItem("gst"));
        //  console.log("this.gst=====>", this.Gst)
        //  console.log('45454', this.response);
      }
    })
  }

  viewTestDatas() {

    this.auth.viewTestData({ lab_id: this.lab_id }).subscribe(res => {
      if (res.status == 'success') {
              
        this.visits = (res.response).reverse();
        //  this.labhistory();
        console.log(39, this.visits);

       
        // console.log(39,this.visits[0].patient_id)
        // this.patient_id = this.visits[0].patient_id
        this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };
      //  this.phone_no = res.response[0].phone_no;
       
      //   console.log("phone Number====>", this.phone_no)

        // this.user_name = res.response[0].user_name;
        // console.log("user_name====>", this.user_name)

      }
      else {
        this.visits = [];
      }
    }, () => {
      swal("Server failed, Server details not exists");
    });

  }
  list() {
    this.auth.findchild({ patient_id: this.patient_id, phone_no: this.phone_no, child_name: this.child_name }).subscribe(res => {
      if (res.status == "success") {
        if (res.response != "") {
          this.userData = res.response;
        }
      }
    });
  }
  //
  patientData() {
    this.auth.patientList({ patient_id: this.patient_id }).subscribe(res => {
      if (res.response != "") {
        this.userResult = res.response

        console.log(255,this.userResult)
        this.visits = res.response.reverse();
        this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };

      // let item = this.userResult.filter(x => x.id == this.user_name);
      // console.log(264, item)
      }
      this.lab_name = res.response.lab_name;
      this.userName = res.response.user_name;
 
      console.log(22, this.lab_name);


    })
  }








  testData(test_id, list_id, sts, docter_name, lab_name, child_name, comment, doc_date) {
    // this.labupdateForm .value.docter_id=this.docter_id;
    this.auth.testValues({ test: test_id }).subscribe(res => {
      this.result = res;
      if (this.result.status == "success") {
        if (this.result.response != "") {
          this.test_res = this.result.response;
          console.log("test_id", this.test_res)
          //  this.router.navigateByUrl("/patientsearch");
          this.router.navigate(['/LabTestReport'], { queryParams: { test_id: JSON.stringify(this.test_res), _id: list_id, sts: sts, "docter_name": docter_name, "lab_name": lab_name, "child_name": child_name, comment: comment, "doc_date": doc_date } });
        }
      }
    }, () => {
      swal("Server failed, Server detils not exits ");
    });
  }


  //  Search() {
  //      this.searchOptions.name;
  //      this.searchOptions.yearData;
  //      this.assignTest();
  //      console.log(323232,this.searchOptions.name,this.searchOptions.yearData)
  //  }

  testNames() {
    this.auth.totalTestNames({ patient_id: this.patient_id }).subscribe(res => {
      this.response = res;
      if (this.response) {
        this.names = this.response.response;
        console.log(98, this.names)
      } else {
        swal("Server error")
      }
    })
  }

  search(phone_no) {
    if (this.docter_name != '' && this.docter_name != undefined) {
      this.docter_name == this.docter_name;
    }
    else {
      this.docter_name = "";
    }
    this.arr = [];
    for (let obj of this.selectedItems) {
      let myItem = this.names.find(s => s.name === obj.name);
      this.arr.push(myItem)
      console.log('arr:', this.arr);
    }
    let a = this.arr;
    let a1;
    if (a[0]) {
      a1 = a[0].name;
    }

    else {
      a1 = '';
    }
    let w = ''
    if (this.wild == undefined || this.wild == '') {
      w = '';
    }
    else {
      w = this.wild[0].patient;

    }
    let doc = ''
    if (this.docter_name == undefined || this.docter_name == '') {
      doc = '';
    }
    else {
      doc = this.docter_name[0];

    }
    // alert(this.firstDate.singleDate.jsDate);
    //  let fi=JSON.stringify(this.firstDate.singleDate.jsDate);
    // console.log(104,fi);

    this.auth.searchWithDates({
      firstDate: this.firstDate.singleDate.jsDate
      , secondDate: this.secondDate.singleDate.jsDate, phone_no :phone_no, lab_id: this.lab_id, test_name: a1, patient_name: w, docter_name: doc
    }).subscribe(res => {
      if (res.status == 'success') {

        this.visits = (res.response).reverse();
        //  this.labhistory();
        this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };
        if (res.response == []) {
          this.visits = '';
        }
        // if(w.includes('(p)'))
        // {
        //   alert(w.includes('(p)'));
        //  let s= w.replace('(p)','');
        // this.visits = this.visits.filter(
        //   book => book.child_name === '').reverse() ;

        //   console.log(39,this.visits);

        // }
      }
      else {
        this.visits = [];
      }
    }, console.log);

  }

  labhistory() {
    this.auth.labhistory({ firstDate: this.firstDate, secondDate: this.secondDate }).subscribe(res => {
      if (res.status == 'success') {
        this.lablist = res.response;

      }
      this.lablist = res.response;
    }, console.log);
  }



  doctorlist() {

    this.auth.totalDocters().subscribe(res => {

      if (res.status == "success") {
        let arr = res.response;
        let arrs = [];
        for (let obj of arr) {
          if (obj.name != '' && obj.name != undefined) {
            arrs.push({ name: obj.name });

          }

        }
        this.doctlist = arrs;
        console.log(33, arrs);
        this.list();
      }
    }, () => {
    }
    );
  }
  SearchForPatientNames() {
    this.auth.SearchForPatientNames({ lab_id: this.lab_id }).subscribe(res => {
      if (res.status == 'success') {
        let arr = res.response;
        let arrs = [];
        for (let obj of arr) {
          if (obj.patient != '(c)' && obj.patient != 'undefined(p)') {
            arrs.push({ patient_name: obj.patient_name, patient: obj.patient });

          }

        }
        this.patientlist = arrs;
      }
    }, console.log);
  }


  report(tid, docid, doc_date, complete, range, child_name, timeStamp, test_date, docter_name, patient_id, patient_name, gender, age, phone_no, lab_name, lab_city, sig,  user_name) {
    // city, name,
    console.log(450, tid, docid, doc_date, complete, range, child_name, timeStamp, test_date, docter_name, patient_id, patient_name, gender, age, phone_no, lab_name,lab_city,sig,user_name);
    
    this.doc_date = doc_date;
    this.user1212121 = user_name;
    console.log(450, this.user1212121)
    this.auth.test_report({ complete: complete, test_name: tid, patient_id: patient_id, docter_id: docid, doc_date: this.doc_date, range: range ? range : "", child_name: child_name ? child_name : "", timeStamp: timeStamp, city: this.city, name: this.lab_name, user_name:user_name }).subscribe(res => {
      this.reports = res;
      if (this.reports.status == "success") {
        this.reportview({ 
          reports: this.reports.response, 
          child_name: child_name, 
          testname: tid, doc_date: doc_date,
           date: test_date, docter_name: docter_name, 
           patient_name: patient_name, 
           gender: gender, age: age, 
           phone_no: phone_no, 
           lab_name:lab_name,
           lab_city: lab_city, 
           sig: sig, 
           city: this.city, 
           user_name: user_name });
      }
    }, (err) => {
      swal("server failed, server details not exits ");
    });
  }

  reportview(e) {
    const dialogRef = this.dialog.open(PrintComponent, {
      width: '250px',
      data: e
    });
    dialogRef.afterClosed().subscribe(result => {
      let vr = this.visits;
      this.visits = vr;


      console.log('The dialog was closed');
    });

  }


  pageChanged(event) {
    this.config.currentPage = event;
  }




  logo() {
    localStorage.setItem('login', '');
    location.reload();

  }
  //   approve(): void {
  //     const dialogRef = this.dialog.open(ApproveComponent, {
  //       width: '250px', 
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log('The dialog was closed'+result);
  // if(result!=1)
  // {
  //      this.viewTestDatas();
  // }
  //     });
  //   }
  approve(e) {
    let dialogRef1 = this.dialog.open(ApproveComponent, {
      width: '600px',
    });
    dialogRef1.afterClosed().subscribe(result => {
      if (result.sig != 0) {
        let sig = result.sig;
        this.approvesig(sig, e);
      }
    });
  }

  approvesig(sig, e) {
    this.auth.approvesig({ _id: e, approval: 1, imageUrl: sig }).subscribe(res => {
      this.response = res;
      if (this.response) {
        this.names = this.response.response;
        location.reload();
        console.log(98, this.names)
      } else {
        swal("Server error")
      }
    })
  }
 
    logout(){
      this.router.navigate(['/new-lab']);
    }
  

}



