import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import{ ActivatedRoute, Router} from '@angular/router';
import{AuthService} from './../../services/auth.service';
import { MatDialog } from '@angular/material';
import { AlertComponent } from '../patient/alert/alert.component';
declare var $: any;
declare var swal;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  type:any;
  userData: any;
  password:any;
 
public validations_patient: FormGroup;
  public validations_docter: FormGroup;
  public validations_lab: FormGroup;

  selectitem: any;
  menu: any;
  M: any;
  patient_id: any;

  // loginForm = {
  //   patient_access:"",
  //   doctor_access:"",
  //   laboratory_access:""
  // }
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
        public dialog: MatDialog,

   
    ) {
      sessionStorage.clear();

      this.selectitem=1;
  
      this.validations_patient  =  this.formBuilder.group({
        phone_no: ['', Validators.required],
        password: ['', Validators.required,],
        // phone_no: ['', Validators.required],
       
    });
    this.validations_docter  =  this.formBuilder.group({
    
       docter_phone: ['', Validators.required],
       password: ['', Validators.required,],
      // phone_no: ['', Validators.required],
    
  });
  this.validations_lab  =  this.formBuilder.group({
   
    lab_phone: ['', Validators.required],
    password: ['', Validators.required,],
    // phone_no: ['', Validators.required],
  });
     }

forgot(e)
{
  this.router.navigateByUrl('/forgetpsw?id='+e);

  
}

  ngOnInit() {
     
  }

  login(ex,formData) {
   
    sessionStorage.setItem('token', "res.response.token");

    this.auth.login(formData.value).subscribe(res => {
    
      if (res.status == "success") {
        this.userData=res.response;

        if(ex==1)
        {

          sessionStorage.setItem("patientdata01","001")

          console.log(92,   sessionStorage.getItem("patientdata01"));
          
            sessionStorage.setItem("userData",JSON.stringify(this.userData));

            console.log(91, JSON.parse(sessionStorage.getItem("userData")));
            
            sessionStorage.setItem("patient_id",this.userData.patient_id);
           
            sessionStorage.setItem("full_name",this.userData.full_name);
            sessionStorage.setItem("phone_no",formData.value.phone_no);
            sessionStorage.setItem("country",this.userData.country);
            sessionStorage.setItem("id",this.userData._id);
            sessionStorage.setItem("ptype",'1');
            sessionStorage.setItem("token",this.userData.token);
            this.router.navigateByUrl('/medicaldetails');
           
          //  this.router.navigateByUrl('/login');
        }
       
        if(ex==2)
        { 
        
           sessionStorage.setItem("userData",JSON.stringify(this.userData));
           sessionStorage.setItem("_id",JSON.stringify(this.userData._id));
           sessionStorage.setItem("docter_id",this.userData.docter_id);
           sessionStorage.setItem("docter_phone",formData.value.docter_phone);
           sessionStorage.setItem("country",this.userData.country);
          //  swal("login succesfull");
            // sessionStorage.setItem("docter_id",formData.value.docter_id);
            this.router.navigateByUrl('/DoctorDataComponent');
          // this.router.navigateByUrl('/login');
        }
        if(ex==3)
        {
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
          sessionStorage.setItem("lab_phone",formData.value.lab_phone);
          sessionStorage.setItem("lab_id",this.userData.lab_id);
          sessionStorage.setItem("lab_name",this.userData.name);
          sessionStorage.setItem("email",this.userData.email);
          

          // swal("login succesfull");
           this.router.navigateByUrl('/Labinformation');
          // this.router.navigateByUrl('/login');
        }
        
        
       // location.reload();

       // this.route.navigateByUrl('/list');
      } if(res.status=='error')
      {
        swal(res.message,"","error");
      }
     
       }, (err) => {
         console.log(99,err.statusText);

         if(err.statusText== "Unknown Error")
         {
           console.log(err);
           alert("Server/Network Failed ! ");
        swal("Server/Network Failed ! ","Contact Support","error");

         }
  
         if(err.error.message.err=="No account exists")
        {
          swal("Account Doesn't Exist !","No Account Found With Mentioned Credentials !","error");
        }
        if(err.error.message.err=="Password does not match"){

          swal("Incorrect Password","Please Check The Password, Try Again !","error");
        }
        // if(err){
        //   console.log(err,3223);
          
        //   
        // }
  
      });
     

    
  }
  
 
  onChange(item){
    console.log(11,item);
    this.selectitem = item.target.value;
  }

  // reg()
  // {
  //   this.router.navigateByUrl('/register');
  
  // }
  need(): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '250px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.sts=='no')
      {
        this.router.navigateByUrl('/registration');

      }
      console.log('The dialog was closed');
    });
  }

  reg(){

     this.router.navigateByUrl('/registration');


  }

  logn(a){
console.log(a);
if(1){

}
  }
}



// (err) => {
//   console.log(343,err);
//   swal( err.error.message.error, " no already exist");

// });