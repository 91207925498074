import { Component, OnInit } from '@angular/core';
//import { MalihuScrollbarModule, MalihuScrollbarService } from 'ngx-malihu-scrollbar';
import { HttpClient } from "@angular/common/http";
import { LabSignComponent } from '../lab-sign/lab-sign.component';
import { MatDialog } from '@angular/material/dialog';
import { SignaturelabComponent } from '../medicaldetails/signaturelab/signaturelab.component';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
// import { products } from "assets/text.json";
@Component({
  selector: 'app-lab-login',
  templateUrl: './lab-login.component.html',
  styleUrls: ['./lab-login.component.css']
})
export class LabLoginComponent implements OnInit {
  products: Object;
  result: any;
 
  constructor(
    private httpClient: HttpClient,
    //private mScrollbarService: MalihuScrollbarService,
    public dialog: MatDialog,
    private fb: FormBuilder,private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) {

    if( sessionStorage.getItem("lab_phone")==" " || sessionStorage.getItem("lab_phone") == undefined){
      this.router.navigateByUrl('/new-lab');
    }
   }
   
  // ngAfterViewInit() {
  //   this.mScrollbarService.initScrollbar('#myElementId', { axis: 'y', theme: 'dark-thick', scrollButtons: { enable: true } });
  // }
   
  // ngOnDestroy() {
  //   this.mScrollbarService.destroy('#myElementId');
  // }

  // public scrollbarOptions = { axis: 'yx', theme: 'minimal-dark' };
  
  ngOnInit() {
    
  //   $(document).ready(function () {
  //     $("#sidebar").mCustomScrollbar({
  //         theme: "minimal"
  //     });

  //     $('#dismiss, .overlay').on('click', function () {
  //         $('#sidebar').removeClass('active');
  //         $('.overlay').removeClass('active');
  //     });

  //     $('#sidebarCollapse').on('click', function () {
  //         $('#sidebar').addClass('active');
  //         $('.overlay').addClass('active');
  //         $('.collapse.in').toggleClass('in');
  //         $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  //     });
  // });
  // this.httpClient.get("assets/text.json").subscribe(data =>{
  //   console.log(data);
  //   this.products = data;
  // })
}
signature()  {
  let dialogRef = this.dialog.open(SignaturelabComponent, {
     width: '600px',
 });
 dialogRef.afterClosed().subscribe(result => {

 });
} 
redirect(){
  this.router.navigateByUrl('/update-prices');
}
logo() {
  sessionStorage.clear();
  //localStorage.clear();
  this.router.navigateByUrl('/new-lab');
};
  }
 

