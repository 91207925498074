import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})
export class SearchfilterPipe implements PipeTransform {

  transform(response: any, searchText:string) {
    
    // if(!response || !searchText){
    //   return response;
     
    // } 
    // else{
    //   console.log("hello")
    // }
    if( !response || !searchText){
      return response;
    }
     if(response==""){
      alert("hello")
    }
    
    return response.filter(obj =>
      obj.ref_id.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
  }

}
