import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from  './../../services/auth.service';
import { DatePipe } from '@angular/common';
import { ReportEditComponent } from '../report-edit/report-edit.component';
import { PerceptionEditComponent } from '../doctor/perception-edit/perception-edit.component';

import {  MatDialog} from '@angular/material/dialog';
import { ReportViewComponent } from '../report-view/report-view.component';
import { ReportTestComponent } from '../report-test/report-test.component';
import { PrintComponent } from 'src/app/vedio/print/print.component';
import { PrescriptionComponent } from 'src/app/medicaldetails/prescription/prescription.component';

declare var swal;
@Component({
  selector: 'app-patientmedhistory',
  templateUrl: './patientmedhistory.component.html',
  styleUrls: ['./patientmedhistory.component.css']
})
export class PatientmedhistoryComponent implements OnInit {
  formatsDateTest: string[] = [
    'MM/dd/yyyy',
    ];
  patient_id: any;
  visits: any;
  reports:any;
  searchOptions = {
    year:"",
    child_name:""
   };
  lablist: any;
  userData: any;
  child_name:any;
  full_name: string;
  phone_no: any;
  years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  lablis: any;
  doc_date: any;
  dateee: any;
  docter_id: any;
  doc: any;
  config1 = {
    itemsPerPage: 0,
    currentPage: 0,
    totalItems: 0
  };


  vaccinedata: any;
  user_name: any;
  lab_name: any;
  lab_city: any;
  allergydata: any;





  doctervisits: any=[];
  labvisits:any=[];
  medication:any=[];
  config2: { itemsPerPage: number; currentPage: number; totalItems: any; };
  config3: { itemsPerPage: number; currentPage: number; totalItems: any; };
  config4: { itemsPerPage: number; currentPage: number; totalItems: any; };
  config5: { itemsPerPage: number; currentPage: number; totalItems: any; };



  spinner: any;
  AdminLabName: any;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    private datePipe: DatePipe,
    protected route: ActivatedRoute,
    public dialog: MatDialog,) { 
      

      if( sessionStorage.getItem("docter_phone")==" " || sessionStorage.getItem("docter_phone") == undefined){
        this.router.navigateByUrl('/login2');
      }

      
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.full_name=sessionStorage.getItem("full_name");
      this.docter_id=sessionStorage.getItem("docter_id");
      this.patient_id=sessionStorage.getItem("patient_id"); 
              this.patientlisting();
              this.labhistory();

              this.Search();
              this.patientlist();
              this.vacc();
             this.doc=0;
      console.log('222', this.patient_id);
         this.userData=[];
        // this.patientlisting();
        this.list();
        this.dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();
        console.log("dateis",this.dateee)

  }

  ngOnInit() {
    this.visits=[];
  }

  tab(e)
  {
this.doc=e;
  }

  vacc(){
    this.auth.vaccine_get({patient_id:this.patient_id}).subscribe(res=>{

this.vaccinedata=res['response'].reverse(); 
console.log(this.vaccinedata);
// this.visits=res;

    })
  }
  
  patientlist(){
    if(this.child_name==undefined) {
      this.child_name="";
     }
      this.auth.patientList({patient_id:this.patient_id,patient_child:this.child_name}).subscribe(res => {
        if (res.status == "success") {
            // sessionStorage.setItem("patient_id",this.userData[0].patient_id);
        //  this.name=res.response[0].full_name;


        
        this.config1 = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };
        this.visits=res.response.reverse(); 
        this.user_name = res.response[0].user_name;
        // this.AdminLabName = res.response[0].lab_name;
        this.lab_city = res.response[0].lab_city;
        // console.log("lab-name====>", this.AdminLabName)
        console.log("lab_user_name====>" , this.user_name)
        

        }
      },() => {
        swal("patient medical detils not exits ");
      });
   }

   pageChanged(event){
    this.config1.currentPage = event;
  }
   pageChanged1(event){
    this.config2.currentPage = event;
  }
  pageChanged2(event){
    this.config3.currentPage = event;
  }
  pageChanged3(event){
    this.config4.currentPage = event;
  }
  pageChanged4(event){
    this.config5.currentPage = event;
  }

   patientlisting() {
    this.auth.labupdate({patient_id:this.patient_id}).subscribe(res => {
      if (res.status == "success") {
         this.lablist=res.response;
      //  this.name=res.response[0].full_name;
      //   console.log(24,this.lablist);
       }
      }, () => {
      swal("Server error ");
       });
      this.list();
   }

  
   Search() {
     this.spinner=''
      let date=this.searchOptions.year.split('T');
      let newDate = date[0].split('-');
      let day=newDate[1];
      let month=newDate[2];
      let year=newDate[0];
      this.searchOptions.year=year;

      //  this.auth.patienthistory({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
      //   if (res.status == 'success') {
      //       this.visits = res.response.reverse();
      //       this.config1 = {
      //         itemsPerPage: 5,
      //         currentPage: 1,
      //         totalItems: this.visits.count
      //       };
      //       console.log(39,this.visits);
      //       this.labhistory();
      //   }
      // }, console.log);
      this.auth.patient_history1({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
        if (res.status == 'success') {

          this.doctervisits=[]
          this.labvisits=[]
          this.medication=[]
          this.vaccinedata=[]
          this.allergydata=[]


          res.response[0].docter_visits.filter(x=>{if(x.docter_name!==""){ this.doctervisits.push(x); console.log(x)}}).reverse();
          // res.response[0].docter_visits.filter(x=>{if(x.labtest_required == true){ this.labvisits.push(x); console.log(x)}}).reverse();
          res.response[0].docter_visits.filter(x=>{if(x.labtest_required == true&&!(x.payment_status=='0' && x.docter_name=='')){ this.labvisits.push(x); console.log(x)}}).reverse();
          res.response[0].docter_visits.filter(x=>{if(x.docter_name!== ''){ this.medication.push(x); console.log(x)}}).reverse();
          res.response[1].required_vaccines.filter(x=>{if(!(x.data.length==0)){ this.vaccinedata.push(x); console.log(x)}}).reverse(); 
         //res.response[2].allergies.filter(x=>{if(x.data){ let a=[]; a.push(x.data);this.allergydata=a[0];this.allergydata[0].patient_name=x.patient_name,this.allergydata[0].child_name=x.child_name;console.log(this.allergydata) }}).reverse();
         
         
         console.log(res.response[2].allergies);
         let ck=res.response[2].allergies
var k=[]
         for(let x of ck){
           console.log(x);
           var docter_name=x.docter_name     
           var patient_name=x.patient_name
           var child_name=x.child_name
           for(let a of x.data){
             console.log(a);
             a.patient_name=patient_name
             a.child_name=child_name
             a.docter_name=docter_name
             k.push(a)
             console.log(k.length,k);
             
           }
           
         }

         this.allergydata=k
         
         this.doctervisits.reverse();
         this.labvisits.reverse();
         this.medication.reverse();
         this.vaccinedata.reverse();
         this.allergydata.reverse();



          
          this.spinner=true

            // this.visits = res.response[0].docter_visits.reverse();
            // this.vaccinedata=res.response[1].required_vaccines.reverse();
            // this.allergydata=res.response[2].allergies.reverse();
            
            console.log(this.visits);
            console.log(this.allergydata);





            this.config1 = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.doctervisits.count
              
            };

            this.config2 = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.labvisits.count
              
            };

            this.config3 = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.medication.count
              
            };

            this.config4 = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.vaccinedata.count
              
            };

            this.config5 = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.allergydata.count
              
            };
            // this.labhistory();
        }
      }, console.log);
 
    }


 
   labhistory(){
    let date=this.searchOptions.year.split('T');
    let newDate = date[0].split('-');
    let day=newDate[1];
    let month=newDate[2];
    let year=newDate[0];
     this.searchOptions.year=year;
  
    this.auth.labhistory({year:this.searchOptions.year,patient_id:this.patient_id,phone_no:this.phone_no, child_name:this.searchOptions.child_name}).subscribe(res => {
      if (res.status == 'success') {
       
        this.lablist = res.response.reverse();
        console.log(39,this.lablist);

      //     this.config = {
      //   itemsPerPage: 5,
      //   currentPage: 1,
      //   totalItems: this.lablist.count
      // };
      } 
      //this.lablist = res.response;
    }, console.log);
   }

  //  pageChanged(event){
  //   this.config.currentPage = event;
  // }
  //  view(labobj) {
  //   this.router.navigate(['labview'], { state: { obj: JSON.stringify(labobj) } });
  //  }
   list() {
      // this.labupdateForm .value.docter_id=this.docter_id;
       this.auth.findchild({patient_id:this. patient_id,}).subscribe(res => {
         if (res.status == "success") {
           if (res.response != "") {
          this.userData=res.response;
          console.log(29,this.userData);
            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
           } }
       });
      }


    



      // report(tid,docid,doc_date,complete,range,child_name,timeStamp)  { 
      //      this.doc_date=doc_date;
      //      // this.labupdateForm .value.docter_id=this.docter_id;
      //       this.auth.test_report({complete:complete,test_name:tid,patient_id:this.patient_id,docter_id:docid,doc_date:this.doc_date,range:range?range:"",child_name:child_name?child_name:"",timeStamp:timeStamp}).subscribe(res => {
      //       this.reports=res;
      //        if(this.reports.status == "success") {
      //         //  this.router.navigate(['/ReportTestComponent'],{ queryParams:{reports:JSON.stringify(this.reports.response),testname:tid}});
      //           console.log(343,this.reports)
      //           this.reportview(this.reports,tid);
      //        }
      //     },   (err) => {
      //          swal("Server failed,try again");
      //      });
      //  }
     
      prescription(reports): void {
        const dialogRef = this.dialog.open(PrescriptionComponent, {
          width: '250px',
          data: reports
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', reports);
         // this.animal = result;
        });
      }
       view(labobj): void {
        const dialogRef = this.dialog.open(ReportViewComponent, {
          width: '250px',
          data: {test_id:labobj}
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
         // this.animal = result;
        });
      }




      editdc(item): void {
        const dialogRef = this.dialog.open(ReportEditComponent, {
          width: '250px', 
          data: {
            test_id: item
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.labhistory();
          // this.search();
        });
      }

      perceptionEdit(item): void {
        const dialogRef = this.dialog.open(PerceptionEditComponent, {
          width: '250px', 
          data: {
            test_id: item
          }
         
        });
      
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.labhistory();
         
          this.Search();
        });
      }


      logo(){
        localStorage.setItem('login','');
        location.reload();
        this.auth.logout()
      }

      
      report(
        tid,docid,doc_date,complete,
        range,child_name,timeStamp,test_date,
        docter_name,patient_id,patient_name,gender,
        age,phone_no,lab_name,
        lab_city,
        sig,user_name,
        city,name1) {
        this.doc_date=doc_date;
        // name:this.lab_name
        this.auth.test_report({
          complete:complete,test_name:tid,
          patient_id:patient_id,docter_id:docid,
          doc_date:this.doc_date,
          range:range?range:"",child_name:child_name?child_name:"",
          timeStamp:timeStamp,
          user_name:user_name,lab_city,lab_name}).subscribe(res => {
          this.reports=res;
          
          if (this.reports.status == "success") {

            this.reportview({
              reports:this.reports.response,
              child_name:child_name,
              testname:tid,doc_date:doc_date,
              date:test_date,docter_name:docter_name,
              patient_name:patient_name,
              gender:gender,
              age:age,
              phone_no:phone_no,sig:sig,
              user_name:user_name,
              lab_city,
              lab_name : lab_name
            });
            // lab_name:this.lab_name
          }
      }, (err) => {
          swal("server failed, server details not exits ");
       });
     }

       reportview(e) {
        const dialogRef = this.dialog.open(PrintComponent, {
          width: '250px',
          data: e
        });
        dialogRef.afterClosed().subscribe(result => {
  let vr=this.visits;
  this.visits=vr;


          console.log('The dialog was closed');
                     });
      
      }

  }

