import { Component, OnInit, Renderer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MzModalService } from 'ngx-materialize';
import { ConformModelComponent } from '../../patient/conform-model/conform-model.component';
import { ViewimageComponent } from '../../viewimage/viewimage.component';
declare var swal;
@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements OnInit {
  result: any;
  imagesurl: any[];
  patient_id: any;
  images: string | Blob;
  userData: any;
  phone_no: string;
  id: any;

  constructor(private router: Router,
    public dialog: MatDialog,
    // private formBuilder: FormBuilder,
    public form_val : FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService,
    private renderer: Renderer) { 
      this.patient_id= sessionStorage.getItem("patient_id");
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.patient_id= sessionStorage.getItem("patient_id");
      this.phone_no= sessionStorage.getItem("phone_no");
      this.id= this.userData._id;
    }

  ngOnInit() {
    this.getimg(this.patient_id)
  }
  getimg(patientid) {
    // location.reload();
     this.auth.getimages(patientid).subscribe(res => {
       this.result=res;
       if (this.result.status == "success") {
         this.result=this.result.response;
         this.imagesurl=[];
         for(let obj of this.result[0].imagPath)
         {
           this.imagesurl.push({imag:'http://18.206.155.160:4100'+obj,id:sessionStorage.getItem('id'),imagPath:obj});
         }
       }
      }, () => {
       swal("Server failed");
 
     });
   }


   selectImage(event){
    if(event.target.files.length>0){
      const file= event.target.files[0];
      this.images = file;
    }
  }



 
  view(img): void {
    const dialogRef = this.dialog.open(ViewimageComponent, {
      width: '100px',
      data:img

    });
    dialogRef.afterClosed().subscribe(result => {
   
      console.log(result.sts)
     
   
    //  this.login();
    });
  }

 



  
}
