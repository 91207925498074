import { Component, OnInit, Input, EventEmitter, Optional, Inject } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportTestComponent } from '../report-test/report-test.component';
import { MzBaseModal } from 'ngx-materialize';
declare var swal;

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent extends MzBaseModal  implements OnInit {
  result: any;
  id: any;
  testname: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;

  constructor( public dialogRef: MatDialogRef<ReportViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected activatedRoute: ActivatedRoute) { 

  
    super();
        this.result =data.test_id;
        this.testname = data.testname;
    
        console.log("hi test_id",this.result ); // Print the parameter to the console. 
 

    }

  ngOnInit() {
  
  }
  getval(e){
    this.auth.readingedit({report_id:e}).subscribe(res => { 
      if (res.status == "success") {
        if (res.response != "") {
          this.result=res.response[0].data;
          console.log("21",res.response);  } }
    }, () => {
     });
   }

   close()
   {
     this.dialogRef.close();
 
   }
}

