import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
declare var swal;
declare var Razorpay: any; 

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {

  public payPalConfig ? : IPayPalConfig;
  public show:boolean = false;
  public buttonName:any = 'Show';
  userData: any;
  lab_id: string;
  lab_phone: string;
  plan:any;
  amt:any;
  month: string;
  Gst: number;
  totalPrice: number;
  AfterGst: number;
  total: any;
  gst: string;
  showSuccess: boolean;
  fundingSource: any;
  pay_id: any;
  currency: string;
  lab_name: any;
  order_id: any;
  paid_date: any;
  id: any;
  email_address: any;
  payer_id: any;
  country_code: any;
  purchase_units: any;
  value: any;
  orderId: any;
  resultres: any;
  response: any;
  today: string;
  newDate: string;
  date: Date;
  due_date: Date;
  success: any;

  send_date=new Date();
   formattedDate : any;
  private _id: any;
  monthName: string;
  signature: any;
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) {
    this.userData= JSON.parse(sessionStorage.getItem("userData"));
    console.log("789",this.userData);
    this.lab_id=sessionStorage.getItem("lab_id");
    console.log("454545", this.lab_id);
    this.lab_name= sessionStorage.getItem("name");
    console.log("labPhone===>", this.lab_name);

    this._id = this.userData._id;
    console.log(4545, this._id);
    this.currency='INR'

    // this.gst= sessionStorage.getItem("gst");
    // console.log("gst===>", this.gst)
    console.log( 123,this.userData.plan);
    this.plan=this.userData.plan

    if (this.plan =='STANDARD') {
       this.amt=2000;
       if(this.success){
        this.amt=0;
       }
       
    }
    if (this.plan =='PREMIUM') {
       this.amt=3000;
       if(this.success){
        this.amt=0;
       }
    }
    if (this.plan =='BASIC') {
      this.amt=1000;
      if(this.success){
        this.amt=0;
       }
   }
   var  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
var d = new Date();
var monthName=months[d.getMonth()]; // "July" (or current month)
this.monthName = months[d.getMonth()];
console.log("45454" , monthName);
console.log("dfdfgdfg" , this.monthName);

// this.Gst = Number(sessionStorage.getItem("gst"));
this.Gst = 18;
  //let addPrice = 0;
  //this.totalPrice = addPrice;
      this.AfterGst = ((this.totalPrice/100)*this.Gst)+this.totalPrice;

      this.total = ((this.amt/100)*this.Gst)+this.amt
      console.log("4545454", this.total);

      var months = [ "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December" ];
      this.month = months[d.getMonth()+1]
      console.log("The current month is " + this.month);

      // var arr = "2012-07-01 00:00:00.0".split("-");
      // var months = [ "January", "February", "March", "April", "May", "June",
      //     "July", "August", "September", "October", "November", "December" ];
      // var month_index =  parseInt(arr[0],10) + 1;
      // this.month = months[month_index]
      // console.log("The current month is " + this.month);


      //getdate function

      var today = new Date();
// var dd = String(today.getDate()).padStart(2, '0');
// var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
// var yyyy = today.getFullYear();


this.paid_date = today;
console.log(this.paid_date);


//duedate function
var date = new Date();
        	   var dueDate = new Date().setDate(date.getDate()+30)
        	   var newDate = new Date(dueDate)
             this.due_date = newDate
             console.log(this.due_date)




             this.send_date.setMonth(this.send_date.getMonth()+1);
     this.formattedDate=this.send_date.toISOString().slice(0,10);
 console.log("4545454this date====>",this.formattedDate);






   }

   
  ngOnInit() {
    //this.save();
    this.Lab_gst();
  }
  
  Lab_gst(){
    this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res=>{ 
      this.resultres = res;

      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].payment_status;
       this.plan = this.resultres.response[0].plan;
      //  this.gst = this.resultres.response.gst;
       console.log(1000, this.response)
       console.log(this.response);
      //  console.log('45454', this.response);


      }
      if(this.response == true){
        this.amt=0;
      }
    })
  }

//logout
logout(){
  sessionStorage.clear();
  localStorage.clear();
  this.router.navigateByUrl('/new-lab');
}


  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.show)  
      this.buttonName = "Hide";
    else
      this.buttonName = "Show";
  }
  // console.log("addPrice",addPrice);
  //     this.totalPrice = addPrice;
  //      this.AfterGst = ((this.totalPrice/100)*this.Gst)+this.totalPrice;
  
  save(){
    this.auth.labPayment({lab_id:this.lab_id,lab_name:this.lab_name,amount:this.total,plan:this.plan,order_id:this.order_id,paid_date:this.paid_date,due_date:this.due_date,payment_status:1,signature:'online'}).subscribe(res=>{
    //   this.result = res;
    //   if(this.result.status=="success"){
    //     if(this.result.status=='success'){

    //       swal("Request Sent successfully");
  

    

    //     }
    //   }
    // })
    
    this.resultres = res;
  

    this.success=this.resultres.status
  
    if( this.resultres.status == "success"){
        
      // this.amt=0;

     this.response=this.resultres.response;
     //this.ngOnInit();
      
    //  console.log(this.amt);
     this.ngOnInit();
     
     
    //  console.log("this.response" ,this.resultres.status)
    //  swal("Payment successfully");
    // location.reload();
    }
    
   })


   
   this.auth.labedit({_id:this._id,payment_status:true,payment_date:this.paid_date}).subscribe(res => {
    if (res.status == 'success') {
   
    
    
     // location.reload();
  
   // this.auth.labeditData.next(res.response);
      console.log(39,res);

     
    }
  }, console.log);
  }


  //razorPay
  private initConfig(): void {
    this.payPalConfig = {
    currency: 'INR',
    clientId: 'AUGMf1BRyf-Bn5iQCart6y_dNtTY9RXW6u2rS2e2hN7GZZmNxbXcWYjLkMseYYRnGnJkir4Ugq-3iKA6',
    createOrderOnClient: (data) => <ICreateOrderRequest>{
      intent: 'CAPTURE',
      purchase_units: [
        {
          amount: {
            currency_code: 'INR',
            // value: this.price,
            value:this.total,
            breakdown: {
              item_total: {
                currency_code: 'INR',
                value:this.total,
              }
            }
          },
          items: [
             {
              name: 'Enterprise Subscription',
              quantity: '1',
              category: 'DIGITAL_GOODS',
              unit_amount: {
                currency_code: 'INR',
                value:this.total,
              },
            }
          ]
        }
      ]
    },
    advanced: {
      commit: 'true'
    },
    style: {
      label: 'paypal',
      layout: 'vertical'
    },
    onApprove: (data, actions) => {
      console.log('onApprove - transaction was approved, but not authorized', data, actions);
      actions.order.get().then(details => {
        console.log('onApprove - you can get full order details inside onApprove: ', details);


this.id=details.id;
this.email_address=details.payer.email_address;
this.payer_id=details.payer.payer_id;
this.country_code=details.payer.address.country_code;
this.purchase_units=details.purchase_units[0].amount.currency_code;
this.value=details.purchase_units[0].amount.value;


// this.patient_name=sessionStorage.getItem('full_name');

// this.neddForm.value.email_address=this.email_address;
// this.neddForm.value.payer_id=this.payer_id;
// this.neddForm.value.country_code=this.country_code;
// this.neddForm.value.purchase_units=this.purchase_units;
// this.neddForm.value.value=this.value;
// this.neddForm.value.fundingSource=this.fundingSource;

console.log(909,{'email':this.email_address,'payer_id':this.payer_id,'country_code':this.country_code,'value':this.value});


       this.save();
      });
    },
    onClientAuthorization: (data) => {
      console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
      this.showSuccess = true;
    },
    onCancel: (data, actions) => {
      console.log('OnCancel', data, actions);
    },
    onError: err => {
      console.log('OnError', err);
    },
    onClick: (data, actions) => {
      console.log('onClick', data, actions);

      this.fundingSource=data.fundingSource;


    },
  };
  }







  razorPayOpen(){
      
    let data={
      'amount': this.total*100,
      'name': 'One Health',
      'description': 'Charges towards consultation',
      'image': 'assets/images/oneLogo copy.jpg',
      'currency': this.currency,
      'notes': {},
      'prefill': {
        'name': this.userData.contact_name,
        'email': this.userData.email,
        'contact': this.userData.lab_phone
    },
      'signature':this.signature,
      'order_id':this.order_id,
      'payer_id': this.pay_id
    }

    this.auth.createOrder(data).subscribe(res=>{
      console.log(res);
      options.order_id=res['orderId']
      this.order_id=res
  })

    let options:any = {
      // 'key': 'rzp_test_pCNbE9R46AoFLA',
      'key': 'rzp_live_6HFl2nN6F0Wtoa',
      //'key': 'rzp_live_yR5j1iobfY7fA2', rzp_test_zslkbtdsPPe4Uy, rzp_test_Ot0R6daXxZepqx 
      'amount': this.total*100,
      'name': 'One Health',
      'description': 'Charges towards consultation',
      'image': 'assets/images/oneLogo copy.jpg',
      'currency': this.currency,
      'notes': {},
      'prefill': {
        'name': this.userData.contact_name,
        'email': this.userData.email,
        'contact': this.userData.lab_phone
    },
      // 'order_id':'',
      handler:((response) => {
        this.pay_id = response.razorpay_payment_id;
  
        // this.userData.value.email_address='';
        this.order_id = response.razorpay_payment_id;
        this.signature = response.razorpay_signature;
       // let signature = payment_id.razorpay_signature;
  
       //this.userData.value.payer_id=orderId;
       this.country_code='IND';
       this.purchase_units=this.currency;
       this.fundingSource="RazorePay";

       
       let verify={
        "razorpay_order_id":response.razorpay_order_id ,
        "razorpay_payment_id":response.razorpay_payment_id,
        "razorpay_signature":response.razorpay_signature
            }
       this.auth.signatureVerify(verify).subscribe(res=>console.log(res))
        this.save();
  
     })
  }
        console.log(options);
        setTimeout(() => {
         let rzp1 = new Razorpay(options)
         rzp1.open();
        }, 1000);
    
}


//   razorPayOpen(){
//     let options:any = {
//         // 'key': 'rzp_test_pCNbE9R46AoFLA',rzp_live_yR5j1iobfY7fA2
//         'key': 'rzp_live_6HFl2nN6F0Wtoa',
//         'amount': this.total*100,
//         'name': 'One Health',
//         'description': 'Charges towards consultation',
//         'image': 'assets/images/oneLogo copy.jpg',
//         'currency': this.currency,
//         'notes': {},
//         'prefill': {
//             'name': this.userData.contact_name,
//             'email': this.userData.email,
//             'contact': this.userData.lab_phone
//         }
//     };
    
//     options.handler = ((response) => {
//       this.pay_id = response.razorpay_payment_id;

//       // this.userData.value.email_address='';
//       this.order_id = response.razorpay_payment_id;
//      // let signature = payment_id.razorpay_signature;

//      //this.userData.value.payer_id=orderId;
//      this.country_code='IND';
//      this.purchase_units=this.currency;
//      this.fundingSource="RazorePay";
//       this.save();

//    });
//    let rzp1 = new Razorpay(options);
//    rzp1.open();
  
// }
}
