import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-lab-doc-approve',
  templateUrl: './lab-doc-approve.component.html',
  styleUrls: ['./lab-doc-approve.component.css']
})
export class LabDocApproveComponent implements OnInit {
  a:Object[] | any=[];
  b:Object={};
  baseurl:any
  docapprove:any
  labapprove: any;
  response: any;
  constructor(public http:HttpClient, public auth:AuthService, private router:Router) {
    if(sessionStorage.getItem('docapprove') == null && sessionStorage.getItem('labapprove') == null){
      this.router.navigateByUrl('/admin-log')
          }
   }

  ngOnInit() {
    this.baseurl=this.auth.baseUrl;
    this.docapprove=sessionStorage.getItem('docapprove');
    this.labapprove=sessionStorage.getItem('labapprove');
    console.log(this.docapprove);
    
    if(this.docapprove==1){
      sessionStorage.removeItem('labapprove')
  
      this.auth.doc_find().subscribe((data)=>{
        //'http://192.168.1.50:4100/doctor_online/findall',''
        
          this.a=data['response'].reverse()
              console.log(data['response']);
         for(let x of this.a){
        
                console.log(x);
              
              }
        console.log(this.a);
        
              })

              
    }

    if(this.labapprove==2){

      sessionStorage.removeItem('docapprove')
      this.auth.lab_find().subscribe(res =>  {
     
        if(res['status'] == "success"){
         this.response=res['response'].reverse();
   
         this.baseurl=this.auth.baseUrl;
        }
       })
    }
    sessionStorage.removeItem('labapprove')
    sessionStorage.removeItem('docapprove')

console.log(sessionStorage.getItem('docapprove') , sessionStorage.getItem('labapprove'));

      }
      logo(){
        
      }
}
