import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
declare var swal;
@Component({
  selector: 'app-blocklab-user',
  templateUrl: './blocklab-user.component.html',
  styleUrls: ['./blocklab-user.component.css']
})
export class BlocklabUserComponent implements OnInit {
  lab_id:any;
  response:any;
  constructor(private auth:AuthService, private http:HttpClient,private router:Router) { 

    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
  }

  ngOnInit() {

  }

a:Boolean;
c:Object=[];
d:any;

button:Boolean | String="";

//"_id":"","status":null,"datesta":null

get(da){
  this.a=true;
  console.log( this.a);
  this.auth.blocklab_find(da).subscribe((data)=>{
    console.log(data);

    //this.c=data.response
       this.c=data['response'] //iferror follow this 


  if(this.c[0]._id){
    console.log(this.c[0]);
    console.log(this.c[0]._id);
    console.log(this.c[0].lab_id);


    console.log(this.c[0]);
    if(this.c[0].date < "15-04-2021"){
      this.c[0].datesta=false;
      this.button=this.c[0].status;
    }
    else {
      this.c[0].datesta=true;
      this.button=this.c[0].status;
    }
  }

  // else{
  //   this.d="NO DATA FOUND FOR USER ID!";
  //   console.log(this.d);
    
  // }
    
 
//
    // //Below only code for /lab/find api but can use with above api as well
    // for(let x in data.response){
    //  // console.log(data.response[x]);
      
    //   if(da.lab_id===data.response[x].lab_id){
    //     console.log(da);
        
    //     this.c=data.response[x];
    //     console.log(this.c);
    //     this.d._id=this.c._id;
    //     this.d.status=this.c.status;
    //     console.log(this.d);

    //     if(this.c.date>"20-04-2021"){
    //       this.d.datesta=false;
    //       this.button=this.d.status;
    //     }
    //     else {
    //       this.d.datesta=true;
    //       this.button=this.d.status;
    //     }
      
    //   }


  // Below code for /lab/findlabid api
    //  if(da.lab_id===data.response[0].lab_id){
    //   console.log(da);
      
    //   this.c=data.response[0];
    //   console.log(this.c);
    //   this.d._id=this.c._id;
    //   this.d.status=this.c.status;
    //   console.log(this.d);
    //   if(this.c.date > "15-04-2021"){
    //     this.d.datesta=false;
    //     this.button=this.d.status;
    //   }
    //   else {
    //     this.d.datesta=true;
    //     this.button=this.d.status;
    //   }
    
    // }
   

  

      })
}


  bloc(){

      if(this.c[0].status===true){
        this.c[0].status=false;
        this.auth.bloc_lab(this.c[0]).subscribe((data)=>{
          console.log(data);
          
        console.log(this.c[0].status);
        this.button=this.c[0].status;
        swal("ÜSER HAS BEEN BLOCKED SUCCESFULLY !");
        // setTimeout(()=>{
        //   window.location.reload();
        // },1000)
        })
      }
    
      else{
        this.c[0].status=true;
        
        console.log(this.c[0]);
    
        this.auth.bloc_lab(this.c[0]).subscribe((data)=>{
          console.log(data);
          
        console.log(this.c[0].status);
        this.button=this.c[0].status;
        swal("ÜSER HAS BEEN UN - BLOCKED SUCCESFULLY !");
        // setTimeout(()=>{
        //   window.location.reload();
        // },1000)
        })
        
      }
  }
 
 
 
  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   } 

}

//USED FOR TESTING AND TRIAL ERROR CHECK OF CODE BELOW



  //http://192.168.1.50:4100/lab/findid
      
      // for(let x in data){
      //   data[x].lab_id
      //   //console.log(data[x].id);
    
      //     if(this.a.lab_id===data[x].lab_id){
      //       this.c=data[x]
      //       console.log(this.c);
      //     }
      
      // }




        // for(let x in data){
    //   data[x].lab_id
    //   console.log(data[x].id);
  
    //     if(this.a.lab_id===data[x].lab_id){
         
    //       console.log(data[x]);
    //       console.log(this.a.lab_id);
    //       console.log(data);
    //     }
    // }
   




  // if(this.d.status===false){
  //   this.d.status=true;
  //   this.http.post('http://192.168.1.50:4100/lab/edit',this.d).subscribe((data)=>{
  //     console.log(data);
      
  //   console.log(this.d.status);
    
  //   })
  // }
    




    // if(da.lab_id!==data.response[0].lab_id){
    //   console.log(da);
      
    //   this.c=data.response[0];
    //   console.log(this.c);
    //   this.d._id=this.c._id;
    //   this.d.status=this.c.status;
    //   console.log(this.d);
    //   if(this.c.date>"20-04-2021"){
    //     this.d.datesta=false;
    //     this.button=this.d.status;
    //   }
    //   else {
    //     this.d.datesta=true;
    //     this.button=this.d.status;
    //   }
    
    // }
  
    // else {
    //   this.c.lab_id="";
    //   this.d._id=0;
    //   this.d.status="";
    // }