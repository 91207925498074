import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { SignatureDoctorComponent } from 'src/app/medicaldetails/signature-doctor/signature-doctor.component';

@Component({
  selector: 'app-docnoti',
  templateUrl: './docnoti.component.html',
  styleUrls: ['./docnoti.component.css']
})
export class DocnotiComponent implements OnInit {


  isPopupOpened=true;
  userData: any;
  full_name: string;
  patient_id: string;
  visits: any;
  modal: any;
  _id: any;
 response:any;
  config: { itemsPerPage: number; currentPage: number; totalItems: any; };
  activatedRoute: any;

  constructor(private router: Router,
    public dialog: MatDialog,
  private formBuilder: FormBuilder,
  protected auth: AuthService,
  protected route: ActivatedRoute) {
 
    
     this.patient_id= sessionStorage.getItem("patient_id");
     this.patientlist();
     this.route.url.subscribe((url)=>{
      this.activatedRoute = url;
    });
   }

  ngOnInit() { 
  
  }
  signature()  {
    let dialogRef = this.dialog.open(SignatureDoctorComponent, {
       width: '600px',
   });
   dialogRef.afterClosed().subscribe(result => {
 
   });
}
  patientlist(){

    this.auth.notification({docter_id:sessionStorage.getItem("docter_id")
  }).subscribe(res => {
      
      if (res.status == "success") {
     {
     
         this.visits=res.response.reverse(); 
        //  console.log("32",res.response[0]._id);
        //  this.userData=res.response;

        //  sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //    sessionStorage.setItem("patient_id",this.userData.patient_id);
         
        //    this.userData=JSON.parse(sessionStorage.getItem("userData"));
        //    this.userData= this.userData[0];
        //    console.log(76,this.userData.patient_id)

        this.config= {
          itemsPerPage: 6,
          currentPage: 1,
          totalItems: this.visits.count
      
        }
     
     }}

    },
  
 
    //    () => {
    //   swal("Login failed, Login detils not exits ");
    //  }
     );

   }
  
   pageChanged(event){
    this.config.currentPage = event;
  }

  // deletepatient(patient): void {
    
  //   const dialogRef = this.dialog.open(ChilddeleteComponent, {
  //     //  height: '100px',
  //     //  width: '100px',
  //     width:'10px',
  //      panelClass: 'my-dialog-container',
  //     data :{patient}
  //   });
    
  //   dialogRef.afterClosed().subscribe(result => {
  //   this.patientlist();
  //   });


  
  // }
    
// this.auth.childremove( {_id:patient._id}).subscribe(res => {
         
//       if (res.status == "success") {
//         this.auth.childremoveData.next(res.response);
//         if (res.response != "") {

   
//         }
     
//       }
//     },
       
 
//        () => {
//       alert("Login failed, Login detils not exits ");
//      }
//      );
zoom(zoom_id,zoom_pwd)
{
  sessionStorage.setItem("zoom_id",zoom_id);
  sessionStorage.setItem("zoom_pwd",zoom_pwd);
  
  console.log(zoom_id, zoom_pwd);
  

 this.router.navigateByUrl("/LazyModule/zoom")

}
logo(){
  localStorage.setItem('login','');
  location.reload();

} 
  }
