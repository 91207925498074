import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ConformModelComponent } from '../conform-model/conform-model.component';
import {MatDialogModule, MatDialog} from '@angular/material/dialog';
import { switchAll } from 'rxjs/operators';
declare var swal;
@Component({
  selector: 'app-unrestrict',
  templateUrl: './unrestrict.component.html',
  styleUrls: ['./unrestrict.component.css']
})
export class UnrestrictComponent implements OnInit {

  patient_id: any;
  result: any;
  usersdata: any;
  a: string;
  restdc:any=[];
  list:any;

  constructor(public auth:AuthService, public dialog: MatDialog,private router: Router, ) { 
    this.patient_id=sessionStorage.getItem('patient_id');

  }

  ngOnInit() {
    window.scrollTo(0,0);
        this.docterList();
      

  }

     Confirm(sts,docter_id) {
     
       if(sts==1)
       {
        this.a="restrict";
        // swal("Restricted Successfully");
        this.openServiceModal(sts,docter_id);
       }
       else{
        this.a="unrestrict";
        this.openServiceModal(sts,docter_id);
       }
    
    }

    openServiceModal(sts,docter_id): void {
      const dialogRef = this.dialog.open(ConformModelComponent, {
        width: '10px',
        data :{'sts':sts}
        
      });
      dialogRef.afterClosed().subscribe(result => {
     
        console.log(result.sts)
        if(result.sts=="yes")
        {
         this.rest(sts,docter_id)
        }
     
      //  this.login();
      });
    }


     rest(sts,docter_id)
     {
      // this.userData='';
        // this.labupdateForm .value.docter_id=this.docter_id;
         this.auth.restrict( {patient_id:this.patient_id,docter_id:docter_id,restricted:sts}).subscribe(res => {
           this.result=res;
           if (this.result.status == "success") {
            //swal("Restricted Successfully");

                this.usersdata=this.result.response;
                 this.lists();
                 this.docterList();
           
           
           }
           if(this.result.response.restricted == " 1"){
             swal("Restricted Successfully")
           }
           if(this.result.response.restricted == " 0 "){
            swal("Unrestricted Successfully")
          }
         },() => {
           swal("server failed, server details not exits ");
          });
        }

        docterList() {
          this.auth.docterlist({patient_id:this.patient_id}).subscribe(res => {
              if (res.status == "success") {
                this.list=res.response;
                  console.log(32, this.list)
                  this. lists();
              }
           }, () => {
               swal("Server failed");
          });
        }

        lists()
        {
        
            this.auth.ristlist( {patient_id:this. patient_id}).subscribe(res => {
              this.result=res;
              if (this.result.status == "success") {
           
               this.usersdata=this.result.response;

               console.log(108, this.usersdata)
               this.restdc=[];

    let k=this.list.length;
    for(let i1=0;i1<k;i1++)
    {
      if(this.usersdata=="" || this.usersdata==undefined)
      {
      this.restdc.push({"restricted":"0","docter_name":this.list[i1].docter_name,"docter_id":this.list[i1].docter_id,"comment":this.list[i1].comment});
      }

else{
      for(let i=0;i<(this.list).length;i++)
      {
        if(this.usersdata[i])
        {
    if(this.usersdata[i].docter_id == this.list[i1].docter_id)
    {
      if(this.usersdata[i].restricted=="0")
      {
        this.restdc.push({"restricted":"0","docter_name":this.list[i1].docter_name,"docter_id":this.list[i1].docter_id});
      }
      else{

        this.restdc.push({"restricted":"1","docter_name":this.list[i1].docter_name,"docter_id":this.list[i1].docter_id});

      } 
    }
    }
    else{
       //this.restdc.push({"restricted":"0","docter_name":this.list[i1].docter_name,"docter_id":this.list[i1].docter_id});
    
  if (this.restdc.find((test) => test.docter_name === this.list[i1].docter_name) === undefined) {

    this.restdc.push({"restricted":"0","docter_name":this.list[i1].docter_name,"docter_id":this.list[i1].docter_id});

  }
    }
    
      }

    }
    
    }
    
              }
            },
               
         
               (err) => {
              swal("server failed, server details not exits ");
             }
             );
           }  
           
           logo(){
            this.router.navigate(['/login']);
          }
}

