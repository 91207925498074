import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal,MzModalService } from 'ngx-materialize';
import { Action } from 'rxjs/internal/scheduler/Action';
declare var swal;

@Component({
  selector: 'app-childdelete',
  templateUrl: './childdelete.component.html',
  styleUrls: ['./childdelete.component.css']
})
export class ChilddeleteComponent extends MzBaseModal implements OnInit {
  
  remove() {
    throw new Error("Method not implemented.");
  }
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;

  modalref: any;
  patient: any;
  patient_id: string;
  userData: any;
  visits: any;
  _id: any;
  id: any;
  modalCtrl: any;
  

  constructor(private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
 
    public dialogRef: MatDialogRef<ChilddeleteComponent>,@Inject(MAT_DIALOG_DATA) public data:any,
    protected route: ActivatedRoute,) {
    
    super();
    
    console.log(555, data);
    this.id=data.patient._id;
    
  
  }

  ngOnInit() {
   
    //  this.delete();
    // swal({
    //   title: "Are you sure?",
    //   text: "You will not be able to recover this imaginary file!",
    //   icon: 'warning',
    //   dangerMode: true,
    //   buttons: {
    //     cancel: "close",
    //     delete: 'Yes, Delete It'
    //   }
    // }).then(function (willDelete) {
    //   if (willDelete) {
      
    //     swal("Poof! Your imaginary file has been deleted!", {
    //       icon: "success",
    //     });
    //   } else {
    //     swal("Your imaginary file is safe", {
    //       title: 'Cancelled',
    //       icon: "error",
    //     });
    //   }
     
    // });
    
    //   this.patient_id= sessionStorage.getItem("patient_id");
    
   
  }


   

  delete(){
    
    this.auth.childremove({_id:this.id}).subscribe(res => {
      if (res.status == "success") {
        if (res.response == "success") {   
          if (res.response != "") {
         console.log(22,  this.patient_id);
        }
      }
          this.dialogRef.close();
         this.auth.childremoveData.next(res.response);
        swal("profile has been deleted");
       
     
      }
    },

       () => {
      alert("Login failed, Login detils not exits ");
     }
     );
    
  }
  
  close(): void {
    this.dialogRef.close();
  }
  

}
