import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MzBaseModal } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
declare var swal;
declare var Razorpay: any; 

@Component({
  selector: 'app-needadoc',
  templateUrl: './needadoc.component.html',
  styleUrls: ['./needadoc.component.css']
})
export class NeedadocComponent extends MzBaseModal implements OnInit {
  public payPalConfig ? : IPayPalConfig;


  price: any;

    neddForm: FormGroup;
    lablis: any;
    docter_name: any;
    patient_id: any;
    doctlist: any;
    booksByStoreID:any=[];
   
      @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  showSuccess: boolean;
  id: any;
  email_address: any;
  payer_id: any;
  purchase_units: any;
  value: any;
  country_code: any;
  fundingSource: any;
  patient_name: any;
  doctspec: any;
  docter_id: any;
  pay_id: any;
  currency: string;
  patientname: string;

      constructor(
        private fb: FormBuilder,
        private router: Router, 
       
        protected auth: AuthService,
       
        public dialogRef: MatDialogRef<NeedadocComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected route: ActivatedRoute) {
        super();
        this.patient_id= sessionStorage.getItem("patient_id");
        this.patientname= sessionStorage.getItem("full_name");

        this.currency='INR'
      this.Assign();
  
        }
  
 

     
  ngOnInit() {

    this.initConfig();

    this.createForm();

    
  }
  createForm() {
    this.neddForm = this.fb.group({
     
      call_type: ['',Validators.required],
      reason: ['',Validators.required],
      charges:[''],
      patient_id:[''],
      patient_name:[this.patientname],
      alloc_status:[0],
      date:Date.now(),
      fundingSource:[''],
      payer_id: [''],
      country_code:[''],
      docter_name:[''],
      docter_id:[this.docter_id],



    });
  }
  close() {
    this.dialogRef.close();
  }
  save() {
    this.neddForm.value.patient_id=this.patient_id;
    this.auth.needadoc(this.neddForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){

          swal("Request Sent successfully");
          this.router.navigateByUrl("/needbyid")

    this.dialogRef.close();

        }
      }
    })
  }

  Assign(){
    
    this.auth.docBytype({type:1}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         console.log(33,this.doctlist);
      }
    }, (err) => {

     }
     );
}

  
   
   getprice(e) {
     this.Assign()
    this.booksByStoreID = this.doctlist.filter(
      book => book.docter_name === e.target.value);

console.log(101,this.booksByStoreID)
      let i=this.booksByStoreID[0].charges.split(' ');
      this.price=i[1]
      console.log(99,this.price)
this.docter_id=this.booksByStoreID[0].docter_id;
     // this.neddForm.value.docter_id=this.booksByStoreID[0].docter_id;

      this.neddForm.value.docter_name=this.booksByStoreID[0].docter_name;
      this.neddForm.value.docter_phone=this.booksByStoreID[0].docter_phone;
    }



  
    private initConfig(): void {
      this.payPalConfig = {
      currency: 'INR',
      clientId: 'AUGMf1BRyf-Bn5iQCart6y_dNtTY9RXW6u2rS2e2hN7GZZmNxbXcWYjLkMseYYRnGnJkir4Ugq-3iKA6',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'INR',
              value: this.price,
              breakdown: {
                item_total: {
                  currency_code: 'INR',
                  value: this.price
                }
              }
            },
            items: [
               {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'INR',
                  value: this.price,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);


 this.id=details.id;
 this.email_address=details.payer.email_address;
 this.payer_id=details.payer.payer_id;
 this.country_code=details.payer.address.country_code;
 this.purchase_units=details.purchase_units[0].amount.currency_code;
 this.value=details.purchase_units[0].amount.value;


 this.patient_name=sessionStorage.getItem('full_name');

 this.neddForm.value.email_address=this.email_address;
 this.neddForm.value.payer_id=this.payer_id;
 this.neddForm.value.country_code=this.country_code;
 this.neddForm.value.purchase_units=this.purchase_units;
 this.neddForm.value.value=this.value;
 this.neddForm.value.fundingSource=this.fundingSource;
 this.neddForm.value.patient_name=this.patient_name;


 




 console.log(909,{'email':this.email_address,'payer_id':this.payer_id,'country_code':this.country_code,'value':this.value});


          this.save();
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);

        this.fundingSource=data.fundingSource;


      },
    };
    }


    razorPayOpen(){
      let options:any = {
          'key': 'rzp_test_pCNbE9R46AoFLA',
          'amount': this.price*100,
          'name': 'One Health',
          'description': 'Charges towards consultation',
          'image': '',
          'currency': this.currency,
          'notes': {},
          'prefill': {
              'name': sessionStorage.getItem('full_name'),
              'email': sessionStorage.getItem('email'),
              'contact': sessionStorage.getItem('phone_no')
          }
      };

     options.handler = ((response) => {
        this.pay_id = response.razorpay_payment_id;

        this.neddForm.value.email_address='';
        let orderId = response.razorpay_payment_id;
       // let signature = payment_id.razorpay_signature;
  
        this.neddForm.value.payer_id=orderId;
        this.neddForm.value.country_code='IND';
        this.neddForm.value.purchase_units=this.currency;
        this.neddForm.value.fundingSource="RazorePay";
        this.save();

     });
     let rzp1 = new Razorpay(options);
     rzp1.open();
}
}
