import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var swal;
@Component({
  selector: 'app-lab-edit-user',
  templateUrl: './lab-edit-user.component.html',
  styleUrls: ['./lab-edit-user.component.css']
})
export class LabEditUserComponent implements OnInit {
  relationForm: FormGroup;
  lab_id: string;
  phone_no: any;
  email: any;
  lab_user_phone: any;
  isviewResult: boolean;
  v_result: string;
  iseditResult: boolean;
  e_result: string;
  isapproveResult: boolean;
  a_result: string;
  isBilling: boolean;
  billing: string;
  isReports: boolean;
  reports: string;
  che: boolean;
  role: any;
  dialogRef: any;
  view_results: any;
  edit_results: any;
  approve_results: any;
  Billing: any;
  Reports: any;
  user_name: any;
  _id: any;
  r_data: string[];


  constructor(
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) {
    this.relationForm = this.fb.group({
      
      lab_id: ['', Validators.required],
      user_name: ['', Validators.required],
      lab_user_phone: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      phone_no: ['', Validators.required],
      password: ['', Validators.required],
      status: [true, Validators.required],
      // role:["k", "karthi","","",""],
      role: [[]],
      _id: []
    });
    this.lab_id = sessionStorage.getItem("lab_id");
    // this.lab_create();
     console.log(955555, this.lab_id);





  }

  ngOnInit() {
    this.createForm();
    this.route.queryParams.subscribe(queryParams => {
      //console.log(9999,queryParams);
      let user = JSON.parse(queryParams['user']);

      console.log(10000, user)
      this.relationForm.patchValue = user;
      
      this.relationForm.setValue({
        lab_id: user.lab_id,
        phone_no: user.phone_no,
        lab_user_phone: user.lab_user_phone,
        email: user.email,
        password: user.password,
        status: true,
        user_name: user.user_name,
        role: user.role,
        _id: user._id
      })
      // this.relationForm.value.phone_no = user.phone_no;
      // console.log(9099, JSON.parse(user).phone_no);
      // //this.relationForm.patchValue(user);
      // this.relationForm.value.email = user.email;
      // this.relationForm.value.lab_user_phone = user.lab_user_phone;
      // this.relationForm.value.user_name = user.user_name;
      this.role = user.role;
      this.role = JSON.parse(user.role);
      console.log("4555555====>", this.role)
      //  let view_results =this.role[0];
      //  let edit_results =this.role[1];
      //  let approve_results =this.role[2];
      //  let Billing =this.role[3];
      //  let Reports =this.role[4];

      // this._id = user._id
      // console.log(90000, JSON.parse(user)._id)
      console.log(1599,this.role);
      if (this.role && this.role.length > 0) {
        this.role.forEach(element => {
          if (element == "viewResult") {
            this.view_results = true;
            console.log(9011, this.view_results);
          }
          if (element == "Edit Result") {
            this.edit_results = true;
            console.log(9011, this.edit_results);
          }
          if (element == "Approve Result") {
            this.approve_results = true;
            console.log(9011, this.approve_results);
          }
          if (element == "Billing") {
            this.Billing = true;
            console.log(9011, this.Billing);
          }
          if (element == "Reports") {
            this.Reports = true;
            console.log(9011, this.Reports);
          }
        });
      }

      // if (view_results == "view results") {
      //   this.view_results = true;
      //   console.log(9011, this.view_results);
      // }
      // if (edit_results == "edit results") {
      //   this.edit_results = true;
      //   console.log(9011, this.edit_results);
      // }
      // if (approve_results == "approve results") {
      //   this.approve_results = true;
      //   console.log(9011, this.approve_results);
      // }
      // if (Billing == "Billing") {
      //   this.Billing = true;
      //   console.log(9011, this.Billing);
      // }
      // if (Reports == "Reports") {
      //   this.Reports = true;
      //   console.log(9011, this.Reports);
      // }
    });

  }
  createForm() {

  }
  
  add(relationForm) {


    // console.log("'val ==> ", this.view_results); 

    if(this.view_results){
      this.v_result="viewResult";
    }else{
      this.v_result="";
    }

    if(this.edit_results){
      this.e_result="Edit Result";
    }else{
      this.e_result="";
    }

    if(this.approve_results){
     this.a_result="Approve Result";
    }else{
     this.a_result="";
    }

    if(this.Billing){
      this.billing="Billing";
     }else{
      this.billing="";
     }
     
    if(this.Reports){
      this.reports="Reports";
     }else{
      this.reports="";
     }


     console.log(" ***Prev ====>",  this.v_result,  this.e_result,this.a_result, this.billing,this.reports);


    this.relationForm.value.lab_id = this.lab_id,
      console.log(5545, this._id);
    //console.log(90770, relationForm.value)
    // relationForm.value.role=JSON.stringify(relationForm.value.role);
    // console.log("45454545",relationForm.value.role)

    this.r_data = [this.v_result, this.e_result, this.a_result, this.billing, this.reports];
    this.relationForm.value.role=JSON.stringify(this.r_data);
    // this.relationForm.value.role=JSON.stringify(this.relationForm.value.role);
    console.log(" ***this.r_data====>", this.r_data);
    this.auth.lab_useredit(relationForm.value).subscribe(res => {
      if (res.status == "success") {
        if (res.response != "") {
          //  location.reload();
          // this.dialogRef.close();
          console.log("21", this.relationForm);
        }

        this.auth.childeditData.next(res.response);
        console.log(10000000000, res);
      }
      // this.dialogRef.close();
   
      swal("Updated Successfully");
      this.router.navigateByUrl('/edit-user');
       
    },
      () => {
        
      }
    );
  }


  viewCheck1(e){
    console.log(78888,e.target.checked);
    // console.log('isviewResult ==>', e.detail.checked);
    if (e.target.checked ==true) {
      this.isviewResult = true;
      this.v_result ="viewResult";
     // this.relationForm.value.role.push(this.v_result);
      // console.log()
    } else {
      this.isviewResult = false;
      this.v_result ="viewResult";
      //this.relationForm.value.role.pop(this.v_result);
      this.v_result ="";
    }
  }
  editCheck1(e) {
    // console.log('iseditResult ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.iseditResult = true;
      this.e_result ="Edit Result";
    //  this.relationForm.value.role.push(this.e_result);
    } else {
      this.iseditResult = false;
      this.e_result ="Edit Result";
       //this.relationForm.value.role.pop(this.e_result);
      this.e_result ="";
    }
  }
  approveCheck1(e) {
    // console.log('isapproveResult ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.isapproveResult = true;
      this.a_result ="Approve Result";
      //  this.relationForm.value.role.push(this.a_result);
    } else {
      this.isapproveResult = false;
      this.a_result ="Approve Result";
      //this.relationForm.value.role.pop(this.a_result);
      this.a_result ="";
    }
  }
  billingCheck1(e) {
    // console.log('isBilling ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.isBilling = true;
      this.billing ="Billing"
      // this.relationForm.value.role.push(this.billing);
    } else {
      this.isBilling = false;
      this.billing ="Billing"
     //this.relationForm.value.role.pop(this.billing);
      this.billing ="";
    }
  }
  reportCheck1(e) {
    // console.log('isBilling ==>', e.detail.checked);
    console.log(55555,e.target.checked);
    if (e.target.checked ==true) {
      this.isReports = true;
      this.reports ="Reports"
      // this.relationForm.value.role.push(this.reports);
    } else {
      this.isReports = false;
      this.reports ="Reports"
      //this.relationForm.value.role.pop(this.reports);
      this.reports ="";
    }
  }
  logout(){
    this.router.navigateByUrl('/new-lab');
  }

}

