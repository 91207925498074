import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit {
  role: any;
  ActivateLab: string;
  ActivateOnlineDoctor: string;
  ProcessPayments: string;
  FeeSetup: string;
  Reports: string;
  BlockAccount: string;
  ConnectDoctorOnline: string;
  
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) {
    this.role = sessionStorage.getItem("role")
    console.log(87878,this.role )
    
     console.log(99999,this.role.includes("Activate Lab Account"))
     if (this.role.includes("Activate Lab Account")) {
      this.ActivateLab  = "Activate Lab Account";
    }
    else {
      this.ActivateLab = "";
    }
    if (this.role.includes("Activate Online Doctor Account")) {
      this.ActivateOnlineDoctor = "Activate Online Doctor Account";
    }
    else {
      this.ActivateOnlineDoctor = "";
    }
    if (this.role.includes("Process Payments")) {
      this.ProcessPayments = "Process Payments";
      // this.router.navigateByUrl('/Labinformation');
    }
    else {
      this.ProcessPayments = "";
    }
    if (this.role.includes("Fee Setup")) {
      this.FeeSetup = "Fee Setup";
    }
    else {
      this.FeeSetup = "";
    }
    if (this.role.includes("Reports")) {
      this.Reports = "Reports";
    }
    else {
      this.Reports = "";
    }
    if (this.role.includes("Block Account")) {
      this.BlockAccount = "Block Account";
    }
    else {
      this.BlockAccount = "";
    }
    if (this.role.includes("Connect Doctor Online")) {
      this.ConnectDoctorOnline = "Connect Doctor Online";
    }
    else {
      this.ConnectDoctorOnline = "";
    }
   }

  ngOnInit() {
  }
  logout(){
    this.router.navigateByUrl('/admin-sign');
  } 
}
