import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {jsPDF} from 'jspdf';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { switchAll } from 'rxjs/operators';
import { MatDialogConfig } from '@angular/material/dialog';
import { UserPaymentDialougeComponent } from '../user-payment-dialouge/user-payment-dialouge.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LabadminpaymentComponent } from '../labadminpayment/labadminpayment.component';

declare var swal;

@Component({
  selector: 'app-user-payment',
  templateUrl: './user-payment.component.html',
  styleUrls: ['./user-payment.component.css']
})
export class UserPaymentComponent implements OnInit {
  resultres: any;
  response: any;
  test_fee: number;
  patient_id: string;
  phone_no: string;
  name: string;
  lab_id: any;
  visits: any;
  config:any;
  user_test: any;
  filterresult: any[];
  // filterresult: any[];
  value: any;
   sum: any;
  formatPrice: any;
  totalPrice: number;
  Gst: number;
  AfterGst: number;
  @ViewChild('pdfTable', {static: false}) pdfTable: ElementRef;
  el: any;
  labData: any;
  lab_name: any;
  payment_status: any;
  fee: any;
  labName: string;
  user_name: any;
  _id: any;
  disabled: boolean;
  lab_phon: any;
  labid: any;
  lab_phone: any;
  lab_phone_number: any;
  lab_user_data: string;
  labAddress: any;
  number: any;
  adminAddress: any;
  adminLabName: any;
  karthik: string;
  obj: any;
  phone_Number: any;
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
  ) { 
    this.patient_id=sessionStorage.getItem("patient_id");
    this.phone_no=sessionStorage.getItem("phone_no");
    this.name=sessionStorage.getItem("full_name");
    this.lab_id=sessionStorage.getItem('lab_id');
    this.labName=sessionStorage.getItem('name');
    console.log("name",this.labName);

    this.visits="";
    

     this.user_test = JSON.parse(sessionStorage.getItem("user-payment"));
     console.log("patient_data" , this.user_test);
     this._id=this.user_test._id;
   
  }

  ngOnInit() {
   

    

    sessionStorage.getItem("userData");


    console.log("ladAdmin/user" , JSON.parse(sessionStorage.getItem("userData")));



     this.labData=JSON.parse(sessionStorage.getItem("userData"));

     console.log("this.labData===>", this.labData)
    

    //  this.number = this.labData.phone_no;
    //  console.log("45666==>", this.number)

    //  this.adminAddress = this.labData.address;

    //  console.log("454545===>", this.adminAddress)

    //  this.karthik = sessionStorage.getItem("lab_name");

     console.log('sessionStorage.getItem("lab_name")===>', sessionStorage.getItem("lab_name"))
    //  this.labAddress = this.labData.lab_address;
    //  console.log("labAddress====>", this.labAddress);
      
    //  this.labAddress = this.labData.address;
    //  console.log("labAddress==>",this.labAddress);
    //  this.lab_phone = sessionStorage.getItem("lab_phone");
    //  console.log("lab_phone_number===>", this.lab_phone);

    //  this.name = sessionStorage.getItem("name");
    //  console.log("this.name", this.name)

     this.labid=sessionStorage.getItem("lab_id");

    console.log("98988lab_id======>", this.labid)
 
     
    

    // this.name = sessionStorage.getItem("lab_name");
    this.patientData();
     
      




    this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res=>{ 
      this.resultres = res;

      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].gst;
       
       console.log("this===>", this.response)
       this.Gst= this.resultres.response[0].gst;
       this.adminLabName = this.resultres.response[0].name;
       this.adminAddress = this.resultres.response[0].address;
       this.phone_Number = this.resultres.response[0].phone_no;
       console.log("this.phone_Number====>", this.phone_Number)
       console.log("this.adminAddress====>", this.adminAddress)
       console.log("this.lab_name====>", this.adminLabName)
       console.log("testname===>", this.response.gst)
       console.log(this.response);
       this.Gst = this.response.gst;
       sessionStorage.setItem('gst',this.response);

       this.Gst = Number(sessionStorage.getItem("gst"));
       console.log("this.gst=====>", this.Gst)
      //  console.log('45454', this.response);
      }
    })

    
    this.auth.test_fee_find({lab_id:this.labid}).subscribe(res => {
      this.resultres = res;
      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].data;
       //this.response=this.resultres.response[0].data;
        console.log("this.response==>", this.response);

       console.log("testname===>",  this.response[0].data)
   
       let user_fee=[];
       
        // this.Gst = Number(sessionStorage.getItem("gst"));
       let addPrice = 0;

        for(let obj of this.user_test.test_name){
        console.log("obj", obj)
        let filter_fee = this.response.filter(fee => fee.name === obj.name);
         // console.log("filter_fee", filter_fee)

          addPrice = addPrice + Number(filter_fee[0].test_fee);
          console.log("add===>", addPrice)
          user_fee.push({name:obj.name,fee:filter_fee[0].test_fee})
          console.log("add===>", obj.name + "name",filter_fee[0].test_fee)
          //console.log("test feee"+filter_fee[0].test_fee);

        }
     // console.log("addPrice",addPrice);
      this.totalPrice = addPrice;
       this.AfterGst = ((this.totalPrice/100)*this.Gst)+this.totalPrice;


      this.filterresult = user_fee;
        
      // console.log("45454545", this.filterresult);
    ///  console.log("user_fee", user_fee)
      


       }
  

       
  
     
    })
  }
   
    

  
  // add(filterresult){  
  //   this.value=filterresult  
  //   for(let j=0;j<filterresult;j++){  
  //        this.sum+= this.value[j].fee;  
  //        }  
  //       }
  patientData(){
    this.auth.patientList({patient_id:this.patient_id}).subscribe(res => {
      console.log("878787", this.patient_id)
        // if(res.response!="" ) {
        //     this.visits = res.response.reverse();
        //     this.config = {
        //       itemsPerPage: 5,
        //       currentPage: 1,
        //       totalItems: this.visits.count
        //     };
        // } 
        this.resultres = res;
        if( this.resultres .status == "success"){
          
         this.response=this.resultres.response;
        //  let response = this.response.filter( obj=> obj === this._id);
        // console.log("this.patient_id", this._id);
        
       
        }
      })
}
payment_Status1(obj) : void {
  // document.getElementById("button2").disabled = false;
  (<HTMLInputElement>document.getElementById("button2")).disabled = false;
  //this.router.navigateByUrl('/payment-status');
  // let printContents = document.getElementById(cmpName).innerHTML;
  // let originalContents = document.body.innerHTML;

  // document.body.innerHTML = printContents;


  // document.body.innerHTML = originalContents;
  //   this.router.navigate(['payment-status'], { state: { obj: this.user_test } });
  //sessionStorage.getItem("lab_name") for old labname
  
  
    const dialogRef = this.dialog.open(LabadminpaymentComponent, {
      width: '650px',
      data: {state: { obj: this.user_test }},
       disableClose: true });
    this.auth.patientEdit({lab_id:this.lab_id,
      test_fee:this.AfterGst,
      lab_name:this.adminLabName,
      status:1,
      payment_status:1,
      _id:this._id}).subscribe(res =>{
      console.log("this.AfterGst", this._id);
     })

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      (<HTMLInputElement>document.getElementById("button1")).disabled = true;
      (<HTMLInputElement>document.getElementById("button3")).disabled = true;
    });
 

}
cancel(e){
  localStorage.clear();
  this.router.navigate(['/Labtestupdate']);
}

cancel1(e){
  localStorage.clear();
  this.router.navigate(['/Labtestupdate']);
}

disablesecondbutton() {
  // document.getElementById("button1").disabled = true;
  swal({
    title: "Do You Want Print?",
    // text: "Once deleted, you will not be able to recover this imaginary file!",
    // icon: "warning",
    buttons: true,
    confirmButtonText: 'Yes',
 cancelButtonText: "No",
    dangerMode: true,
    
  })
  .then((willDelete) => {
    if (willDelete) {
     window.print();
    } else {
      localStorage.clear();
      
      this.router.navigate(['/Labtestupdate']);
    }
  });
}

logout(){
  localStorage.clear();
  this.router.navigate(['/new-lab']);
}


}
