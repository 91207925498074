import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-consult-reports',
  templateUrl: './consult-reports.component.html',
  styleUrls: ['./consult-reports.component.css']
})
export class ConsultReportsComponent implements OnInit {
  searchText: any;
  Copy: any;
  doc:any
  docrep:any;
  totalamt: any;


  pymntdate:any

  config = {
    itemsPerPage: 12,
    currentPage: 1,
    totalItems:0
  };
  
  config1 = {
    itemsPerPage: 0,
    currentPage: 0,
    totalItems:0
  };


  constructor(private router:Router, private auth:AuthService) 
  {

    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }

    this.pymntdate=new Date()
    console.log(this.pymntdate);
    console.log();

   }
   
   search() {
    let term = this.searchText;
    if(term){
      this.Copy = this.doc.filter(function(tag) {

        console.log(tag);
        
        
          return tag.docter_name.indexOf(term)>=0;

    }); 
    }

    else {
      return this.Copy=this.doc
    }
console.log(this.Copy);

}



sear(a,b) {

console.log(a,b);

let startDate = new Date(a);
 let endDate = new Date(b);
 let id =sessionStorage.getItem('docter_id')
 this.auth.searchwithdates2(id,startDate,endDate).subscribe(res=>{
console.log(res);

this.docrep=res['response']


this.totalamt=0;

this.config1 = {
  itemsPerPage: 5,
  currentPage: 1,
  totalItems: this.docrep.count
};
      for(let x of this.docrep){
        this.totalamt=+x.charges+this.totalamt
console.log(x.charges);

      }
      console.log(this.totalamt);
 })

//  console.log(this.docrep);
 
 //let selectedMembers = this.docrep.filter(m =>{ new Date(m.date*1000) > startDate && new Date(m.date*1000) < endDate;console.log(new Date(m.date*1000).toISOString().split('T')[0])
 //})

// console.log(selectedMembers);


}


  ngOnInit() {

    this.auth.processcompleted1().subscribe(res=>{
      console.log(res);
      this.doc=res['response']
      this.Copy=this.doc
  
      this.config = {
        itemsPerPage: 12,
        currentPage: 1,
        totalItems: this.Copy.count
      };
    })


    // setTimeout(()=>{
    //   for(let x of this.Copy){
    //     console.log(x.docter_id);
        
    //     // this.onlineconsultationrepo(x.docter_id)
    //   }
    // },2500)

  }


  pageChanged(event){
    this.config.currentPage = event;
  }



  onlineconsultationrepo(id){
    sessionStorage.setItem('docter_id',id)

    console.log(sessionStorage.getItem('docter_id'));
    
    this.auth.onlineconsultationrepo(id).subscribe(res=>{
      console.log(res);
      this.docrep=res['response']


this.totalamt=0;

this.config1 = {
  itemsPerPage: 5,
  currentPage: 1,
  totalItems: this.docrep.count
};
      for(let x of this.docrep){
        this.totalamt=+x.charges+this.totalamt
console.log(x.charges);

      }
      console.log(this.totalamt);
      
    })

// this.ngOnInit()
  }

  pageChanged1(event){
    this.config1.currentPage = event;
  }

  logout(){
  
    localStorage.clear();
  
  sessionStorage.clear();

this.router.navigate(['/admin-sign'])
 
   }




lod(){

  console.log();
  this.ngOnInit()
    }


    processComplete(){
      
    }


}
