import { Component, OnInit, Renderer } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MzModalService } from 'ngx-materialize';
// import { EditpatientComponent } from '../editpatient/editpatient.component';
// import { AddpersonComponent } from '../addperson/addperson.component';
import {MatDialogModule, MatDialog} from '@angular/material/dialog';
// import { ConformModelComponent } from '../patient/conform-model/conform-model.component';
// import { ViewimageComponent } from '../viewimage/viewimage.component';
import { ChangepassComponent } from 'src/app/changepass/changepass.component';
import { ConformModelComponent } from '../pages/patient/conform-model/conform-model.component';
import { ViewimageComponent } from '../pages/viewimage/viewimage.component';
import { AddpersonComponent } from '../pages/addperson/addperson.component';
import { EditpatientComponent } from '../pages/editpatient/editpatient.component';
declare var swal;

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.css']
})
export class PatientProfileComponent implements OnInit {
  userData: any;
  address: any;
  age: any;
  city: any;
  date_of_birth: any;
  email: any;
  full_name: any;
  insurance: any;
  patient_id: any;
  sex: any;
  state: any;
  password:any;
  phone_no: string;
  images;
  id;
  imagePath;
  _id;
  url:any;
  result: any;
  imagesurl: any=[];
  baseurl: any;
  
  constructor(private router: Router,
    public dialog: MatDialog,
    // private formBuilder: FormBuilder,
    public form_val : FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService,




    private renderer: Renderer) {

      // var userData = userData.data.userData;
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.patient_id= sessionStorage.getItem("patient_id");
      this.phone_no= sessionStorage.getItem("phone_no");
      this.id= this.userData._id;
      this.images = this.userData.imagePath;
      this.url=  sessionStorage.getItem('baseUrl');
      this.userData= this.userData;
      console.log(11,this.userData)
      this.baseurl=sessionStorage.getItem('url');

     this. getimg(this.patient_id);
 }
//  var newData = userData.data.userList;form_val
    ngOnInit() {
      window.scrollTo(0,0)
    }
  
  openServiceModal(): void {
    const dialogRef = this.dialog.open(EditpatientComponent, {
      width: '250px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     this.login();
    });
  }

  login() {
    sessionStorage.setItem('token', "res.response.token");
   // location.reload();
   
    this.auth.editaft(this.userData).subscribe(res => {

    
      if (res.status == "success") {
        this.userData=res.response[0];
        sessionStorage.setItem("userData",JSON.stringify(this.userData));
        sessionStorage.setItem("patient_id",this.userData.patient_id);
      }
     }, () => {
      swal("Server failed");

    });
  }

  add(): void {
    const dialogRef = this.dialog.open(AddpersonComponent, {
      width: '150px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
     this.login();
    });
  }

       
        patient(){
          this.router.navigateByUrl('/patientlist');
        }
        logout(){
          this.router.navigate(['/login']);
        }


        selectImage(event){
          if(event.target.files.length>0){
            const file= event.target.files[0];
            this.images = file;
          }
        }

        onSubmit(){
          const formData = new FormData();
          formData.append('file', this.images);
          formData.append('id', this.id);
          this.auth.uploadImage(formData, this.id).subscribe(
                  (res) =>{
                    this.images="";
                    swal("Uploaded Succesfully");
                    this.getimg(this.patient_id);
                    this.images="";

                  } ,
                  (err) => console.log(err) 
             );
            //  location.reload()
             }
 


             getimg(patientid) {
              // location.reload();
               this.auth.getimages(patientid).subscribe(res => {
                 this.result=res;
                 if (this.result.status == "success") {
                   this.result=this.result.response;
                   this.imagesurl=[];
                   for(let obj of this.result[0].imagPath)
                   {
                     this.imagesurl.push({imag:'http://18.206.155.160:4100'+obj,id:sessionStorage.getItem('id'),imagPath:obj});
                   }
                 }
                }, () => {
                 swal("Server failed");
           
               });
             }

             confirmdeleat(id,url): void {
              const dialogRef = this.dialog.open(ConformModelComponent, {
                width: '100px',
                
              });
              dialogRef.afterClosed().subscribe(result => {
             
                console.log(result.sts)
                if(result.sts=="yes")
                {
                 this.deleteupload(id,url)
                }
             
              //  this.login();
              });
            }
            view(img): void {
              const dialogRef = this.dialog.open(ViewimageComponent, {
                width: '100px',
                data:img

              });
              dialogRef.afterClosed().subscribe(result => {
             
                console.log(result.sts)
               
             
              //  this.login();
              });
            }
        


         
             deleteupload(id,url)
             {
               this.auth.getimgdeleate({_id:id,imagePath:url}).subscribe(res => {
                 this.result=res;
                 if (this.result.status == "success") {
                   this.result=this.result.response;
                   this.imagesurl=[];
                   this.getimg(this.patient_id);
                 }
                }, (err) => {
                 swal("Server failed");
           
               });
             }

             changepass(): void {
              const dialogRef = this.dialog.open(ChangepassComponent, {
                width: '250px', 
              });
              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
               this.login();
              });
            }

            openNav() {
              document.getElementById("mySidenav").style.width = "250px";
            }
            
            closeNav() {
              document.getElementById("mySidenav").style.width = "0";
            }


            // logout(){
            //   this.router.navigate(['/login']);
            // }
}
