import { Inject, Optional } from '@angular/core';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { ConformModelComponent } from 'src/app/pages/patient/conform-model/conform-model.component';
import { ViewimageComponent } from 'src/app/pages/viewimage/viewimage.component';
import { AuthService } from 'src/app/services/auth.service';

declare var swal;
@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.css']
})
export class ApproveComponent extends MzBaseModal  implements OnInit {
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  sname:any;
  result: any;
  imagesurl: any[];
  lab_id: any;
  images: string | Blob;
  userData: any;
  phone_no: string;
  id: any;
  signature:any
  adddoctorlistForm: FormGroup;
  

    constructor(public dialogRef: MatDialogRef<ApproveComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      private modalService: MzModalService,

      private fb: FormBuilder,
      private router: Router,
      protected auth: AuthService,
      protected activatedRoute: ActivatedRoute) {


      super();
      this.lab_id= sessionStorage.getItem("lab_id");
      this.id= sessionStorage.getItem("id");
      console.log("321321", this.id)

       }
       
   
  
     
       ngOnInit() {
        
         this.getimg(this.id)
       }
       getimg(labid) {
         // location.reload();
          this.auth.sigimages({_id:labid}).subscribe(res => {
            
            this.result=res;
            if (this.result.status == "success") {
              this.result=this.result.response;
              this.imagesurl=[];
              for(let obj of this.result[0].imagPath)
              {
                console.log(sessionStorage.getItem('baseUrl')+obj);
                this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj,obj:obj});
              }
            }
           }, () => {
            swal("Server failed");
      
          });
        }
     
     
        selectImage(event){
         if(event.target.files.length>0){
           const file= event.target.files[0];
           this.images = file;
         }
       }
       logout(){
         this.router.navigateByUrl('/login')
       }
     
     
        
     
     
      
        
     
       
       approve(e)
        {
          if(e==1)
          {
            if(this.signature!=undefined && this.signature !='')
            {
              this.dialogRef.close({sig:this.signature});

            }
            else{
              swal("please select signature");
            }

          }
          else{
            this.dialogRef.close({sig:0});

          }
        }
        
      
     }
     