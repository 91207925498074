import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

declare var swal;
@Component({
  selector: 'app-patient-medicine-prescribe',
  templateUrl: './patient-medicine-prescribe.component.html',
  styleUrls: ['./patient-medicine-prescribe.component.css']
})
export class PatientMedicinePrescribeComponent implements OnInit {


  [x: string]: any;
  formatsDateTest: string[] = [
    'MM/dd/yyyy',
    ];
  
  patient_id: any;
  visits: any;
  userData:any;
  usersDatas:any
  searchOptions = {
    year:"",
    docter_name:"",
    child_name:""
  };
  doctlist:any;
  res: any;
  full_name: string;
  name: any;
  names:any;
  phone_no: any;
  config:any;
  years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];

  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,public dialog: MatDialog,
    protected route: ActivatedRoute) {
    this.usersDatas=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    this.patient_id= sessionStorage.getItem("patient_id");
    this.phone_no= sessionStorage.getItem("phone_no");
    this.userData=[];
      //  this.name=sessionStorage.getItem("child_name");
    this.doctorlist();

    this.patientlist();
    // this.list();
    this.searchOptions.year=''
   this.Search();
      console.log("32",this.full_name)
     }

  ngOnInit() {
    window.scrollTo(0,0)
  }
  patientlist(){
    if(this.child_name==undefined)
    {
      this.child_name="";
    }
    
    this.auth.patientList({patient_id:this.patient_id}).subscribe(res => {  
      if (res.status == "success") {
     {
         this.visits=res.response.reverse();
         this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
          
        };
        console.log("89999", this.visits.length)     
  console.log(22,this.visits);
  this.user_name = res.response[0].user_name;
        console.log("lab_user_name====>" , this.user_name)
        
     }}
     
    },
       
 
       () => {
      swal("doctor visits, doctor detils not exits ");
     }
     );
   }
  doctorlist(){
    if(this.docter_name==undefined)
    {
      this.docter_name="";
    }
    this.auth.doctorlist({patient_id:this. patient_id}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         console.log(33,this.doctlist);
         this.list(); 
     }
    }, () => {
     }
     );
   }
   Search(){
   
    let date=this.searchOptions.year.split('T');

    let newDate = date[0].split('-');
    let day=newDate[1];
    let month=newDate[2];
    let year=newDate[0];
     this.searchOptions.year=year;
  
    this.auth.patientsearch({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name,docter_name:this.searchOptions.docter_name}).subscribe(res => {
      if (res.status == 'success') {
       this.visits = res.response.reverse();
       // console.log(39,this.visits);
      }
    }, console.log);
   }
   
   list()
   {
      // this.labupdateForm .value.docter_id=this.docter_id;
       this.auth.findchild( {patient_id:this.patient_id}).subscribe(res => {
         
         if (res.status == "success") {
           if (res.response != "") {
          this.userData=res.response.reverse();
          
            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
           }
        
         }
       },
          
    
        //   () => {
        //  alert("Login failed, Login detils not exits ");
        // }
        );
      }
      logo(){
        this.router.navigate(['/login']);
      }
      pageChanged(event){
        this.config.currentPage = event;
      }
}
