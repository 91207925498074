import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var swal: any;
@Component({
  selector: 'app-new-lab',
  templateUrl: './new-lab.component.html',
  styleUrls: ['./new-lab.component.css']
})
export class NewLabComponent implements OnInit {

  loginForm: FormGroup;
  type:any;
  userData: any;
  password:"";
  // relationForm: FormGroup;
  lab_id: any;
  phone_no: any;
  email: any;
  lab_user_phone: any;
  public validations_labAdmin: FormGroup;
  public validations_lab: FormGroup;
  selectitem: any;
  menu: any;
  M: any;
  relationForm: FormGroup;
  submitted: boolean;
  imagePath: any;
  //password:any;

  // loginForm = {
  //   patient_access:"",
  //   doctor_access:"",
  //   laboratory_access:""
  // }
  constructor(private fb: FormBuilder,private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,) {


      this.lab_id=sessionStorage.getItem("lab_id");
      console.log(6666,this.lab_id)
   
      sessionStorage.clear();

      this.selectitem=1;
  
   
    this.validations_labAdmin =  this.formBuilder.group({
      lab_id: ['', Validators.required],
      //lab_user_phone: ['',Validators.required],
      password: ['', Validators.required,],
      lab_phone: ['',Validators.required,],
     
    
  });
  this.validations_lab  =  this.formBuilder.group({
   
    lab_id: ['', Validators.required],
    password: ['', Validators.required],
   
    lab_user_phone: ['',Validators.required],
    //lab_id: ['', Validators.required],
    //user_name: ['',Validators.required],
   
    //phone_no: ['',Validators.required],
    //password: ['',Validators.required],
    //status: [true ,Validators.required],
    // lab_user_phone: ['',Validators.required],
    // lab_id: ['', Validators.required],
    // lab_user_phone: ['',Validators.required],
    // phone_no: ['', Validators.required],
  });
     }
  
     

forgot(e)
{
  this.router.navigateByUrl('/forgetpsw?id='+e);
}


  ngOnInit() {
  }

  logn(x){
    console.log(x);
    
  }

  login(ex,formData) {
   
    sessionStorage.setItem('token', "res.response.token");
   // location.reload();
    // this.auth.presentLoading();
    this.auth.login(formData.value).subscribe(res => {
      
      //sessionStorage.setItem("lab_user_phone",res.data.lab_user_phone)
      //sessionStorage.setItem("email",res.data.email)
    

      if (res.status == "success") {
        this.userData=res.response;
        console.log("this.userData", this.userData.imagePath);

        // if(ex==1)
        // {
        //   sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //     sessionStorage.setItem("patient_id",this.userData.patient_id);
        //     sessionStorage.setItem("full_name",this.userData.full_name);
        //     sessionStorage.setItem("phone_no",formData.value.phone_no);
        //     sessionStorage.setItem("country",this.userData.country);
        //     sessionStorage.setItem("token",this.userData.token);
        //     this.router.navigateByUrl('/medicaldetails');
           
        //   //  this.router.navigateByUrl('/login');
        // }
       
        // if(ex==2)
        // { 
        //   sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //   sessionStorage.setItem("docter_id",this.userData.docter_id);
        //    sessionStorage.setItem("docter_phone",formData.value.docter_phone);
        //    sessionStorage.setItem("country",this.userData.country);
        //    sessionStorage.setItem("token",this.userData.token);
        //    // sessionStorage.setItem("docter_id",formData.value.docter_id);
        //    this.router.navigateByUrl('/DoctorDataComponent');
        //   // this.router.navigateByUrl('/login');
        // }
        if(ex==1)
        {
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
          console.log("Data", this.userData);
          sessionStorage.setItem("patient_id",this.userData.patient_id);
          //sessionStorage.setItem("lab_phone",formData.value.lab_phone);
          sessionStorage.setItem("lab_id",this.userData.lab_id);
          
          sessionStorage.setItem("imagePath",this.userData.imagePath);
          console.log("6666==>",this.userData.imagePath)
          sessionStorage.setItem("lab_name",this.userData.name);
          sessionStorage.setItem("lab_phone", this.userData.lab_phone)
          //lab_user_phone
          sessionStorage.setItem("full_name",this.userData.name);
          sessionStorage.setItem("_id", this.userData._id);
          sessionStorage.setItem("gst", this.userData.gst);
          sessionStorage.setItem("lab_address",this.userData.address);
          sessionStorage.setItem("lab_city",this.userData.city);
          console.log("cityyyyyy =>> ",    this.userData.city);
          //console.log("cityyyyyy =>> ",     sessionStorage.getItem("name"));
          //sessionStorage.setItem("lab_city",this.userData.lab_address);
          sessionStorage.setItem("token",this.userData.token);
          sessionStorage.setItem("lab_name",this.userData.name);

          console.log("lab_name =>> ",     sessionStorage.getItem("lab_name"));
          sessionStorage.setItem("role",this.userData.role);

          sessionStorage.setItem("ptype",'3');



          sessionStorage.setItem("id",this.userData._id);
          //console.log('id', this.userData._id)
          //  this.router.navigateByUrl('/Labinformation');
          this.router.navigateByUrl('/lab-login')
          // this.router.navigateByUrl('/login');
        }
        if(ex==2)
        {
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
          //sessionStorage.setItem("lab_phone",formData.value.lab_phone);
         // sessionStorage.setItem("imagePath",this.userData.imagePath);
          sessionStorage.setItem("imageUrl",this.userData.imagePath);
          console.log("6666==>",sessionStorage.getItem("imageUrl"))
          sessionStorage.setItem("lab_id",this.userData.lab_id);
          console.log(6666,this.userData)
          sessionStorage.setItem("lab_user_phone", this.userData.lab_user_phone)
          // sessionStorage.setItem("lab_name",this.userData.lab_name);
          // console.log("lab_name1233333 =>> ",     sessionStorage.getItem("lab_name"));
          sessionStorage.setItem("lab_address",this.userData.lab_address);
          sessionStorage.setItem("full_name",this.userData.name);
          sessionStorage.setItem("lab_city",this.userData.city);
          console.log("cityyyyyy =>> ",    this.userData.city);
          console.log("cityyyyyy =>> ",     sessionStorage.getItem("name"));
          sessionStorage.setItem("user_name",this.userData.user_name);
          sessionStorage.setItem("token",this.userData.token);
          sessionStorage.setItem("gst", this.userData.gst);
          sessionStorage.setItem("role",this.userData.role);

          sessionStorage.setItem("ptype",'3');



          sessionStorage.setItem("id",this.userData._id);
          console.log("6666==>",this.userData._id)
          //  this.router.navigateByUrl('/Labinformation');
          this.router.navigateByUrl('/lab-role-page')
          // this.router.navigateByUrl('/login');
        }
        
        
       // location.reload();

       // this.route.navigateByUrl('/list');
      } if(res.status=='error')
      {
        swal(res.message);
      }
       },

       (err) => {
        console.log(99,err)

        console.log(err.error.message.error);

        if(err.error.message.error=='Documents are missing'){
         //swal('Documents Are Missing');
         console.log(err.error.message.data1.lab_id);
         console.log(err.error.message.data1._id);
         sessionStorage.setItem("id", err.error.message.data1._id);
         sessionStorage.setItem("lab_id", err.error.message.data1.lab_id);
         this.router.navigate(['/file-upload'])
        }
        
        if(err.error.message.err=="No account exists")
        {
         swal("User Not Found");
       }
       if(err.error.message.error=="Your Accout has been not in active state")
       {
        swal("Your Accout has been not in active state Contact Support");
      }
      if(err.error.message.error=="Password does not match...")
       {
        swal("Password does not match...");
      }

     
       
   //  else{
   //   swal("Invalid Password. Please Try Again");
   //  }
 
     }
       
    //    (err) => {
    //      console.log(99,err)
  
    //      if(err.error.message.err=="No account exists")
    //      {
    //       swal("User Not Found");
    //     }
    //  else{
    //   swal("Invalid Password. Please Try Again");

    //  }
  
    //   }
      
      );
     

    
  }
  
  opn(){
    this.submitted = true;
    if(this.submitted = true){
      this.router.navigateByUrl('/lab-role-page');
    }
  }
  onChange(item){
    console.log(11,item);
    this.selectitem = item.target.value;
  }

  // reg()
  // {
  //   this.router.navigateByUrl('/register');
  
  // }
  
}



// (err) => {
//   console.log(343,err);
//   swal( err.error.message.error, " no already exist");

// });