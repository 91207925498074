import { Component, OnInit, Optional, Inject, Input, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MzBaseModal } from 'ngx-materialize';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ReportViewComponent } from '../report-view/report-view.component';
import { FormBuilder } from '@angular/forms';
import { MaterializeAction } from 'angular2-materialize';
import { PrintComponent } from 'src/app/vedio/print/print.component';
@Component({
  selector: 'app-result-lab',
  templateUrl: './result-lab.component.html',
  styleUrls: ['./result-lab.component.css']
})
export class ResultLabComponent extends MzBaseModal  implements OnInit {

  result: any;
  testname: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  rdata: any;

  constructor(public dialogRef: MatDialogRef<ReportViewComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
   
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected activatedRoute: ActivatedRoute,
    
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
 
    protected route: ActivatedRoute,) { 

    super();
    this.rdata=data;
      this.result = this.data.reports;
      this.testname=this.data.testname;
      
   

  }

  ngOnInit() {

  
  }
  close()
   {
     this.dialogRef.close();
 
   }

   
   reportview() {
    const dialogRef = this.dialog.open(PrintComponent, {
      width: '250px',
      data: this.rdata
    });
    dialogRef.afterClosed().subscribe(result => {
// let vr=this.visits;
// this.visits=vr;


      console.log('The dialog was closed');
                 });
  
  }


}

