import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { switchAll } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChildeditComponent, } from '../childedit/childedit.component';
import { ChilddeleteComponent } from '../childdelete/childdelete.component';
import { AddpersonComponent } from '../addperson/addperson.component';
// declare var swal;
@Component({
  selector: 'app-patientlist',
  templateUrl: './patientlist.component.html',
  styleUrls: ['./patientlist.component.css']
})
export class PatientlistComponent implements OnInit {
  
isPopupOpened=true;
  userData: any;
  full_name: string;
  patient_id: string;
  visits: any;
  modal: any;
  visitslist: any[]=[];
  _id: any;
 response:any;
  login: any;

  constructor(private router: Router,
    public dialog: MatDialog,
  private formBuilder: FormBuilder,
  protected auth: AuthService,
  protected route: ActivatedRoute) {
 
    
     this.patient_id= sessionStorage.getItem("patient_id");
    this.patientlist();
    
   }
   

  ngOnInit() {

    window.scrollTo(0,0)
    // this.auth.childremoveData.subscribe(data => {
    //   let ind = this.visitslist.findIndex(patient => patient._id === data._id);
    //   if(ind !== null) {
    //     this.visitslist.splice(ind, 1);
    //   }
    // });
  }
  patientlist(){

    this.auth.findchild({patient_id:this.patient_id}).subscribe(res => {
      
      if (res.status == "success") {
     {
     
         this.visits=res.response; 
        //  console.log("32",res.response[0]._id);
        //  this.userData=res.response;

        //  sessionStorage.setItem("userData",JSON.stringify(this.userData));
        //    sessionStorage.setItem("patient_id",this.userData.patient_id);
         
        //    this.userData=JSON.parse(sessionStorage.getItem("userData"));
        //    this.userData= this.userData[0];
        //    console.log(76,this.userData.patient_id)
     
     }}

    },
  
 
    //    () => {
    //   swal("Login failed, Login detils not exits ");
    //  }
     );

   }
  
   editpatient(patient): void {
    
    const dialogRef = this.dialog.open(ChildeditComponent, {
      height: '1500px',
      width: '1500px',
      data :{patient}
    });
 
    
    dialogRef.afterClosed().subscribe(result => {
       this.patientlist();
      console.log('The dialog was closed');

    });
    
  }

  deletepatient(patient): void {
    
    const dialogRef = this.dialog.open(ChilddeleteComponent, {
      //  height: '100px',
      //  width: '100px',
      width:'10px',
       panelClass: 'my-dialog-container',
      data :{patient}
    });
    
    dialogRef.afterClosed().subscribe(result => {
    this.patientlist();
    });


  
  }
    
// this.auth.childremove( {_id:patient._id}).subscribe(res => {
         
//       if (res.status == "success") {
//         this.auth.childremoveData.next(res.response);
//         if (res.response != "") {

   
//         }
     
//       }
//     },
       
 
//        () => {
//       alert("Login failed, Login detils not exits ");
//      }
//      );
 
logo(){
  localStorage.setItem('login','');
  location.reload();

}
add(): void {
  const dialogRef = this.dialog.open(AddpersonComponent, {
    width: '550px',
  });
  dialogRef.afterClosed().subscribe(result => {
    this.patientlist();
    console.log('The dialog was closed');
   //this.login();
  });
}
// login() {
//   sessionStorage.setItem('token', "res.response.token");
//  // location.reload();
 
//   this.auth.editaft(this.userData).subscribe(res => {

  
//     if (res.status == "success") {
//       this.userData=res.response[0];
//       sessionStorage.setItem("userData",JSON.stringify(this.userData));
//       sessionStorage.setItem("patient_id",this.userData.patient_id);
//     }
//    }, () => {
//     swal("Server failed");

//   });
// }

// logo(){
//   this.router.navigate(['/login']);
// }
  }
