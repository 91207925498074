import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adminallrecord',
  templateUrl: './adminallrecord.component.html',
  styleUrls: ['./adminallrecord.component.css']
})
export class AdminallrecordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
