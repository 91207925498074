import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-feesetup',
  templateUrl: './feesetup.component.html',
  styleUrls: ['./feesetup.component.css']
})
export class FeesetupComponent implements OnInit {

  constructor(public auth:AuthService,private router:Router) {
    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/admin-sign');
    }
   }
  ngOnInit() {
  }

  
 logout(){
  
  localStorage.clear();

sessionStorage.clear();

this.router.navigate(['/admin-sign'])

 }
}
