import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dtrdemo',
  templateUrl: './dtrdemo.component.html',
  styleUrls: ['./dtrdemo.component.css']
})
export class DtrdemoComponent implements OnInit {

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
  }
  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login2');
  };

}
