import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators,FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-adddoctor',
  templateUrl: './adddoctor.component.html',
  styleUrls: ['./adddoctor.component.css']
})
export class AdddoctorComponent implements OnInit {
  userData: any;
  patient_id: string;
  phone_no: string;
  adddoctorlistForm: FormGroup;
  relationForm: any;

  constructor(private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected route: ActivatedRoute, 
   ) {
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
   this.patient_id= sessionStorage.getItem("patient_id");
   this.phone_no=sessionStorage.getItem("phone_no"); 
  }

  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.adddoctorlistForm = this.fb.group({
      docter_id: ['',Validators.required],
      phone_no: ['',Validators.required],
      name: ['',Validators.required],
      age: ['',Validators.required],
      gender:['',Validators.required],
     

});
  }
  add() {
    this.adddoctorlistForm.value.patient_id=this.patient_id;
    this.auth.doctorlist(this.adddoctorlistForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){
          this.auth.doctorlistData.next(res.response);
          swal("doctor added, doctorlist  exits ");
        }
      }
    },  () => {
      swal("person added, persondetils not exits ");
     })
  
  }
  close()
  {
    
  }
}