import { Component, OnInit } from '@angular/core';
import { FormBuilder , FormGroup,Validators} from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MzModalService } from 'ngx-materialize';
import{AuthService} from '../services/auth.service';
//import { DocpinComponent } from './docpin/docpin.component';
import { DocpinComponent } from '../pages/doctor-data/docpin/docpin.component';
import { EditdoctorComponent } from '../pages/editdoctor/editdoctor.component';
//import { PatientsearchComponent } from '../pages/patientsearch/patientsearch.component';
declare var swal;
@Component({
  selector: 'app-patient-update-id',
  templateUrl: './patient-update-id.component.html',
  styleUrls: ['./patient-update-id.component.css']
})
export class PatientUpdateIdComponent implements OnInit {
  name: any;
  patient_id: any;
  userData:any;
  display: string;
  docter_phone: any;
  phone_no: any;
  modals: any;
  pinno: any;
  resiltpin: any;
  docter_id;
  checkresult: any;
  restrickey: any;
  pin: string;
  types: any;
  message: string;
  activatedRoute: any;

  // restric: any;


  constructor(
 
    private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService) 
    { 
      
           this.userData=JSON.parse(sessionStorage.getItem("userData"));
           this.patient_id= sessionStorage.getItem("patient_id");
           this.docter_phone= sessionStorage.getItem("docter_phone");
           this.types= sessionStorage.getItem("type");
           console.log( this.types);
  // this.types = '1';
  console.log( 123,this.docter_phone);
  this.userData= this.userData;
  console.log( 123,this.userData);

 this.route.url.subscribe((url)=>{     
   this.activatedRoute = url;
 });
}
  ngOnInit() {
    // alert("hello world");
  }
  change()
  {
   if((this.phone_no).length>10){
this.message="Patient Id / User Id cannot be more than 10 digits."
   } 
   
       else{
        this.message='';
       }
  }
  
  Search(){
    
    this.auth.patientcheck({phone_no:this.phone_no}).subscribe(res => {
        if (res.response.length > 0 ) {
              if (res.response != "") {
                  this.name=res.response[0];
                  sessionStorage.setItem("full_name",this.name.full_name);
                  sessionStorage.setItem("patient_id",this.name.patient_id);
                  sessionStorage.setItem("age", this.name.age)
                  sessionStorage.setItem("phone_no",String(this.name.phone_no))
                  sessionStorage.setItem("sex",this.name.sex)
    
                  this.pinno=this.name.pin_no;
                  this.restric(this.name.patient_id, this.docter_phone
                    // this.userData.docter_id
                    );
              }
          } else {
            swal("Please enter registered phone number")
          }
    }, () => {
         swal("Login failed, Login details not exits ");
     }
     );
   }


    // 
  restric(patient_id,docter_id){
    this.pin='0';
    this.auth.checkrest({patient_id:patient_id,docter_id:docter_id}).subscribe(res => {
      this.checkresult=res;
      if (this.checkresult.status == "success") {
                if (this.checkresult.response != '') {
                      this.restrickey=this.checkresult.response[0];
                    if(this.restrickey.restricted=="1") {
                          swal("you are restricted to see the patient details");
                    } else{
                          this.pins();
                          sessionStorage.setItem("full_name",this.name.full_name);
                          sessionStorage.setItem("patient_id",this.name.patient_id);
                    }
              } else{
                  this.pins();
              }
      }
    });
   }

   pins() {
    let dialogRef = this.dialog.open(DocpinComponent, {
      height: '400px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
          if(result.pin==this.pinno) { 
            this.router.navigateByUrl('/patientsearch');
            // this.router.navigateByUrl("/patientsearch", { skipLocationChange: true });
          }
          else{
          swal("pin not matched");
        }   
      });
}
  // restric(patient_id: any, docter_id: any) {
  //   throw new Error('Method not implemented.');
  // }
  //Doctor Update
   getdata() {
        sessionStorage.setItem('token', "res.response.token");
        this.auth.getdoctor({docter_id:this.userData.docter_id}).subscribe(res => {
          console.log('111',this.userData.docter_id)
      
            if (res.status == "success") {
                    this.userData=res.response[0];
                    sessionStorage.setItem("userData",JSON.stringify(this.userData));
                    sessionStorage.setItem("patient_id",this.userData.patient_id);
                   
      
              }
           }, () => {
                this.auth.presentToast("Server failed");
          });
  }

}
