import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { EditPriceComponent } from '../edit-price/edit-price.component';
import { MatDialogModule, MatDialog} from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
declare var swal;
@Component({
  selector: 'app-update-prices',
  templateUrl: './update-prices.component.html',
  styleUrls: ['./update-prices.component.css']
})
export class UpdatePricesComponent implements OnInit {
  //name: string;
  _id: string;
  Record: any;
  value: any;
  testname: any;
  price: any;
  doc:any=[];
  isShown: boolean;
  resultres: any;
  response: any;
  test_fee: any;
  currentVal = "";
  userData: any;
  lab_id: string;
  lab_phone: string;
  gst_id: string;
  Gst: number;
  gst: string;
  resultresGst: Object;
  filter_fee: any;
  labid: any;
  public toggleButton: boolean = false;
  userName = '';
  searchText: any;
  name2: any[];
  names: any;
  constructor( 
    private fb: FormBuilder,private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    public dialog: MatDialog,
    
  ) {
    this.userData= JSON.parse(sessionStorage.getItem("userData"));
    console.log("789",this.userData);
    this.labid=sessionStorage.getItem("lab_id");

    console.log("98988lab_id======>", this.labid)
    this.lab_phone= sessionStorage.getItem("lab_phone");
    this.gst_id = sessionStorage.getItem("_id");
    console.log("gst_id", this.gst_id);

    this.gst = sessionStorage.getItem("gst");
    console.log("gst", this.gst);
    console.log( 123,this.userData);
  

    this.Gst =10;
    this.Lab_gst();
    
  }

  ngOnInit() {
    this.auth.test_fee_find({lab_id:this.labid}).subscribe(res => {
      this.resultres = res;
     
      if( this.resultres .status == "success"){
        
        this.response=this.resultres.response[0].data;
      
       console.log("testname===>", this.response[0].data)
       console.log(this.response);

       if(this.response){
        this.response=this.resultres.response[0].data;
        var arr=[]
        for(let x of this.response){
          if(x.name=="C-Reactive Protein and Heart Disease Risk" || x.name=="Glucose (mg/dl)"){
console.log(x.name)
          }

          else {
            arr.push(x)
          }
        }
        this.name2 = arr
        console.log(this.names);
        
      }





      //  console.log('45454', this.response);
      }
    })


    this.auth.lab_gst({id:this.gst_id}).subscribe(res=>{
      this.resultresGst = res;
      console.log("45gst====>", this.resultresGst)
    })

   
  }
  Lab_gst(){
    this.auth.lab_user_gst({lab_id:this.labid}).subscribe(res=>{ 
      this.resultres = res;

      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].gst;
       this.gst = this.resultres.response[0].gst;
       console.log("testname===>", this.gst )
       console.log(this.response);
      //  console.log('45454', this.response);
      }
    })
  }
  updateValue() {
  
    
      this.auth.test_fee_edit({_id:this._id,test_fee:this.price,lab_id:this.labid}).subscribe(res =>{   //edit api
      this.price = this.response.price;
      // this.testname = this.response.testname;
      this.testname = this.response.name;
      console.log("45454", this.price);
      
      this.ngOnInit();
      console.log(this.ngOnInit())

      swal("Price Updated Successfully");
      setTimeout(() => {
        location.reload();
      }, 2500);
      //location.reload();
      // this.router.navigate(['/update-prices'])
      //location.reload();
  
    })

   
   }
   editValue(_id, testname, price){
    this._id = _id;
    
    this.testname = testname;
   
    this.price = price;
    console.log("454545" , this.price);
   }
 
   toggleShow() {

    this.isShown = ! this.isShown;
    
    }
  // updateValue(){
  //   this.auth.test_fee_edit({res._id}).subscribe(res =>{
  //     console.log(res);
  //   })
  // }
  // public name:any;
  //  str='500';
  // toggleShow() {
  //   this.isShown = ! this.isShown;
  // }
  // changeLabelName() {
  //   this.name = this.str;
  //   console.log(this.name);
  // } 
  gstValue(val){
         this.toggleButton = false

   this.gst = val;
   console.log("1545", this.gst , this.gst_id),
    
   


   this.auth.labedit({_id:this.gst_id,gst:this.gst}).subscribe(res => {
    if (res.status == 'success') {
     sessionStorage.setItem('gst',res.response.gst);
     this.gst = sessionStorage.getItem("gst");

     // location.reload();
     swal("Gst Updated Successfully");
   // this.auth.labeditData.next(res.response);
      console.log(39,res);
    
      setTimeout(() => {
        window.location.reload();
      }, 3000);
 
      // location.reload();
     
    }
  });
   
  
      // this.router.navigate(['/update-prices'])
    
  }
  logout(){
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/new-lab');
  }

}



