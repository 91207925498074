import { Component, Inject, OnInit, Optional } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-user-payment-dialouge',
  templateUrl: './user-payment-dialouge.component.html',
  styleUrls: ['./user-payment-dialouge.component.css']
})
export class UserPaymentDialougeComponent implements OnInit {
  userData: any;
  patient_id: string;
  name: string;
  phone_no: string;
  child_name: any;
  child: any;
  visits: any;
  patData: any;
  labData: string;
  result: any;
  test_res: string;
  searchText = '';
   years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  searchOptions = {
    year:"",
    child_name:""
   };

  chidData: Object;
  lablist: any;
  test_name: any;
  doc_date: any;
  reports: any;
  dateee: any;
  lab_id: any;
  config:any;
  email: string;
  resultres: any;
  response: any;
  private _id: string;
  patient_data: any;
  printdata: any;
  constructor(public dialogRef: MatDialogRef<UserPaymentDialougeComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    protected auth: AuthService,
  ) {
    this.patient_id=sessionStorage.getItem("patient_id")
    this.phone_no=sessionStorage.getItem("phone_no")
   this.name=sessionStorage.getItem("full_name")
   this.email=sessionStorage.getItem("email");
   
   this. lab_id=sessionStorage.getItem('lab_id');
   this.visits="";
   //this.dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();


  //  console.log("object" , this.router.getCurrentNavigation().extras.state.htmlprint);

  //   this.printdata = this.router.getCurrentNavigation().extras.state.htmlprint;
    this.patient_data = JSON.parse(sessionStorage.getItem("lab-userpayment"));
     console.log("patient_data" , this.patient_data);
   }

  ngOnInit() {
    this.patientData();
  }


  patientData(){
    this.auth.patientList({patient_id:this.patient_id}).subscribe(res => {
      console.log("878787", this.patient_id)
        // if(res.response!="" ) {
        //     this.visits = res.response.reverse();
        //     this.config = {
        //       itemsPerPage: 5,
        //       currentPage: 1,
        //       totalItems: this.visits.count
        //     };
        // } 
        this.resultres = res;
        if( this.resultres .status == "success"){
          
         let response=this.resultres.response;
         this.response=response.filter(obj=>obj._id==this.patient_data._id)
        console.log("this.response", this.response);
        
       
        }
      })
   }
   closeDialog() {
    this.dialogRef.close();
  }
  
}
