import { Component, OnInit, Renderer, Input, EventEmitter, Inject } from '@angular/core';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { title } from 'process';
import { Router, ActivatedRoute } from '@angular/router';
declare var swal;
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var swal;



@Component({
  selector: 'app-restrictdocmodel',
  templateUrl: './restrictdocmodel.component.html',
  styleUrls: ['./restrictdocmodel.component.css']
})
export class RestrictdocmodelComponent extends MzBaseModal  implements OnInit{
  
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  userData: any;
  full_name: string;
  patient_id: string;
  relationForm:FormGroup;
  modalCtrl: any;
  sex:any;
  pin_no:any;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<RestrictdocmodelComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,) {
    super();
   
    console.log("22",this.userData)
    
   }

  ngOnInit()  {
    this.relationForm  =  this.formBuilder.group({
     
      restrict: ['', Validators.required],
      comment: ['', Validators.required],
    
  }); 
  this.relationForm.patchValue(this.userData); 
  }
 
    save(){
      console.log(123,this.relationForm.value);
       this.auth.editpatient(this.relationForm.value).subscribe(res => {
        if (res.status == 'success') {
          sessionStorage.setItem("userData",res.response);
          this.dialogRef.close();

          swal("Edit Success");
            console.log(39,res);
        
          
        }
      }, console.log);
     }
     close(e): void {
      this.dialogRef.close(e.value);
    }
    
  
// listen for close event after opening the modal

    }

