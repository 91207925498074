import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent} from './pages/login/login.component';
import{ AuthGuard} from  './services/auth.guard';
import{ RegistrationComponent} from './pages/registration/registration.component';
import{ DoctorformComponent } from './pages/doctorform/doctorform.component';
import{ LabformComponent} from './pages/labform/labform.component';
import{MedicaldetailsComponent} from './pages/medicaldetails/medicaldetails.component';
import{DoctorvisitsComponent} from './pages/doctorvisits/doctorvisits.component';
import{LabvisitsComponent} from './pages/labvisits/labvisits.component';
import{LabviewComponent} from './pages/labview/labview.component';
import { DoctorratingComponent } from './pages/doctorrating/doctorrating.component';
import{LabratingComponent} from './pages/labrating/labrating.component';
import{HospitalratingComponent } from './pages/hospitalrating/hospitalrating.component';
import{ForgetpswComponent } from './pages/forgetpsw/forgetpsw.component';
import{DoctorDataComponent} from './pages/doctor-data/doctor-data.component';
import{PatientsearchComponent} from './pages/patientsearch/patientsearch.component';
import{PatientmedhistoryComponent} from './pages/patientmedhistory/patientmedhistory.component';
import{LabinformationComponent} from './pages/labinformation/labinformation.component';
import { LabtestresultComponent } from './pages/labtestresult/labtestresult.component';
import { AddpersonComponent } from './pages/addperson/addperson.component';
import { PatientlistComponent } from './pages/patientlist/patientlist.component';
import { HospitalregComponent } from './pages/hospitalreg/hospitalreg.component';
import { AdddoctorComponent } from './pages/adddoctor/adddoctor.component';
import{LogoutComponent} from './pages/logout/logout.component';
import { RestDoctorComponent } from './pages/rest-doctor/rest-doctor.component';
import { LabresultUpdateComponent } from './pages/labModel/labresult-update/labresult-update.component';
import { TestModelComponent } from './pages/labModel/assignTest/test-model.component';
import {  ReportTestComponent } from './pages/report-test/report-test.component';
import { ReportViewComponent } from './pages/report-view/report-view.component';
import { ResultLabComponent } from './pages/result-lab/result-lab.component';
import { UnrestrictComponent } from './pages/patient/unrestrict/unrestrict.component';
import { Login2Component } from './pages/login2/login2.component';
import { Login3Component } from './pages/login3/login3.component';
import { PicsComponent } from './medicaldetails/pics/pics.component';
import { NeedadocComponent } from './vedio/needadoc/needadoc.component';
import { ServicesComponent } from './vedio/services/services.component';
import { RequesthidComponent } from './vedio/requesthid/requesthid.component';
import { AllocComponent } from './vedio/alloc/alloc.component';
import { LoginstaffComponent } from './pages/loginstaff/loginstaff.component';
import { DocnotiComponent } from './vedio/docnoti/docnoti.component';
import { AdminallrecordComponent } from './vedio/adminallrecord/adminallrecord.component';
import { DocsComponent } from './pages/labtestresult/docs/docs.component';
import { ViewtestComponent } from './pages/labtestresult/viewtest/viewtest.component';
import { PatientUpdateIdComponent } from 'src/app/patient-update-id/patient-update-id.component';
import { LabLoginComponent } from './lab-login/lab-login.component';
import { LabRegComponent } from './lab-reg/lab-reg.component';
import { NewLabComponent } from './new-lab/new-lab.component';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { SelectDropComponent } from './select-drop/select-drop.component';
import { UserAddComponent } from './user-add/user-add.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LabSignComponent } from './lab-sign/lab-sign.component';
import { LabEditUserComponent } from './lab-edit-user/lab-edit-user.component';
import { LabHomeComponent } from './lab-home/lab-home.component';
import { LabProfileComponent } from './lab-profile/lab-profile.component';
import {MatTabsModule} from '@angular/material/tabs';
import { LabRolePageComponent } from './lab-role-page/lab-role-page.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { SetPassComponent } from './set-pass/set-pass.component';
import { UserChanePasswordComponent } from './user-change-password/user-chane-password.component';
import { LabUserOtpComponent } from './lab-user-otp/lab-user-otp.component';
import { AdminSignComponent } from './labAdmin/admin-sign/admin-sign.component';
import { AdminLogComponent } from './labAdmin/admin-log/admin-log.component';
import { LabUserComponent } from './labAdmin/lab-user/lab-user.component';
import { UserEditComponent } from './labAdmin/user-edit/user-edit.component';
import { LabUserEditComponent } from './labAdmin/lab-user-edit/lab-user-edit.component';
import { UserRoleComponent } from './labAdmin/user-role/user-role.component';
import { ConnectDoctorComponent } from './labAdmin/connect-doctor/connect-doctor.component';
import { DoctorOnlineComponent } from './doctor-online/doctor-online.component';
import { LabRequestComponent } from './labAdmin/lab-request/lab-request.component';
import { UpdatePricesComponent } from './update-prices/update-prices.component';
import { UserBillingComponent } from './user-billing/user-billing.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { UserPlanComponent } from './user-plan/user-plan.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { PaymentStatusComponent } from './user-payment/payment-status/payment-status.component';
import { ProcessTestComponent } from './lab-home/process-test/process-test.component';
import { ApproveTestComponent } from './approve-test/approve-test.component';
import { ReportsComponent } from './labAdmin/reports/reports.component';
import { LabreportsComponent } from './labAdmin/labreports/labreports.component';
import { FilterReportsComponent } from './labAdmin/filter-reports/filter-reports.component';
import { PaymentOverdueComponent } from './labAdmin/payment-overdue/payment-overdue.component';
import { ConsultReportsComponent } from './labAdmin/consult-reports/consult-reports.component';
import { BlocklabUserComponent } from './labAdmin/blocklab-user/blocklab-user.component';
import { FeesetupComponent } from './labAdmin/feesetup/feesetup.component';
import { DocFeeComponent } from './labAdmin/doc-fee/doc-fee.component';
import { LabFeeComponent } from './labAdmin/lab-fee/lab-fee.component';
import { ProcessCompletedComponent } from './labAdmin/process-completed/process-completed.component';
import { DocRequestComponent } from './labAdmin/doc-request/doc-request.component';
import { UploadDocComponent } from './upload-doc/upload-doc.component';
import { UserApprovalComponent } from './user-approval/user-approval.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UserplanEditComponent } from './userplan-edit/userplan-edit.component';
import { ViewTestPageComponent } from './view-test-page/view-test-page.component';
import { UserProcessComponent } from './user-process/user-process.component';
import { UserPinModelComponent } from './user-pin-model/user-pin-model.component';
import { UserLabTestUpdateComponent } from './user-lab-test-update/user-lab-test-update.component';
import { LabUserpaymentComponent } from './lab-userpayment/lab-userpayment.component';
import { LabadminpaymentComponent } from './labadminpayment/labadminpayment.component';
import { UnregistrationComponent } from './unregistration/unregistration.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DocprofileComponent } from './docprofile/docprofile.component';
import { UnregisterPatientComponent } from './unregister-patient/unregister-patient.component';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { PatientVaccineComponent } from './patient-vaccine/patient-vaccine.component';
import { PatientAllergyComponent } from './patient-allergy/patient-allergy.component';
import { PatientMedicinePrescribeComponent } from './patient-medicine-prescribe/patient-medicine-prescribe.component';
import { ConsultDocComponent } from './consult-doc/consult-doc.component';
import { PloginComponent } from './plogin/plogin.component';
import { DtrStatusComponent } from './dtr-status/dtr-status.component';
import { DsigndeleteComponent } from './dsigndelete/dsigndelete.component';
import { LsigndeleteComponent } from './lsigndelete/lsigndelete.component';
import { LabDocApproveComponent } from './lab-doc-approve/lab-doc-approve.component';
import { TermsServiceComponent } from './terms-service/terms-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PatientPopupComponent } from './patient-popup/patient-popup.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CallcompleteComponent } from './callcomplete/callcomplete.component';
import { PatientVideoComponent } from './patient-video/patient-video.component';
import { DemovideoComponent } from './demovideo/demovideo.component';
import { DtrdemoComponent } from './dtrdemo/dtrdemo.component';
import { UserviewtestComponent } from './userviewtest/userviewtest.component';
//import { PatientLoginComponent } from './patient-login/patient-login.component';
// import { UserApprovalComponent } from './user-approval/user-approval.component';

const routes: Routes = [{
  path: "",
  redirectTo: "/login",
  pathMatch: "full"
}, {
  path: "login",
  component: LoginComponent,
},
{path:"LazyModule",
 loadChildren: './vedio/lazy.module#LazyModule'} ,
//loadChildren: () => import('./vedio/lazy.module').then(mod => mod.LazyModule) },

{
  path: "registration",
  component: RegistrationComponent,

},
{
  path: "doctorform",
  component: DoctorformComponent,

},
{
  path: "labform",
  component: LabformComponent,
 
},
{
  path: "medicaldetails",
  component: MedicaldetailsComponent,
 canActivate:[AuthGuard]
 
},
{
  path: "doctorvisits",
  component:  DoctorvisitsComponent,
 canActivate:[AuthGuard]

},
{
  path: "labvisits",
  component:  LabvisitsComponent,
canActivate:[AuthGuard]

},
{
  path: "labview",
  component:LabviewComponent,
  canActivate:[AuthGuard]

},
{
  path: "doctorrating",
  component:DoctorratingComponent,
//  canActivate:[AuthGuard]

},
{
  path: "labrating",
  component:LabratingComponent,
//  canActivate:[AuthGuard]

},
{
  path: "login2",
  component:Login2Component,

},
{
  path: "hospitalrating",
  component:HospitalratingComponent,
 canActivate:[AuthGuard]

},
{
  path: "forgetpsw",
  component: ForgetpswComponent ,
 
},
{
  path: "DoctorDataComponent",
  component: DoctorDataComponent ,
 canActivate:[AuthGuard]

},

{
  path: "patientsearch",
  component: PatientsearchComponent,
 canActivate:[AuthGuard]

},
{
  path: "patientMedicalSearch",
  component: PatientmedhistoryComponent,
   canActivate:[AuthGuard]

},
{
  path:"Labinformation",
  component:LabinformationComponent,
 canActivate:[AuthGuard]

},
{
  path:"Labtestupdate",
  component:LabtestresultComponent,
  canActivate:[AuthGuard]

},
{
  path:"addperson",
  component:AddpersonComponent,
  canActivate:[AuthGuard]
 
},
{
  path:"patientlist",
  component:PatientlistComponent,
  canActivate:[AuthGuard]
 
},
{
  path:"hsptlreg",
  component:HospitalregComponent,
 canActivate:[AuthGuard]
},

//add doctor list
{
  path:"adddoctor",
  component:AdddoctorComponent ,
 canActivate:[AuthGuard]
},
{
path: "logout",
component: LoginComponent,
},
{
  path: "logg",
  component: LogoutComponent,
  },

{
  path: "rest_doctor",
  component: RestDoctorComponent,
 canActivate:[AuthGuard]
},{

    path:"LabresultUpdateComponent",
    component:LabresultUpdateComponent,
    canActivate:[AuthGuard]

  },
  {
    path:"assignTest",
    component:TestModelComponent,
    canActivate:[AuthGuard]

  },{
      path:"ReportTestComponent",
      component: ReportTestComponent,
      canActivate:[AuthGuard]

  },
  {
    path:"ReportView",
    component: ReportViewComponent,
    canActivate:[AuthGuard]

  },
  {
    path:"LabTestReport",
    component:ResultLabComponent,
    canActivate:[AuthGuard]

  }, 
  {
    path:'Unrestrict',
    component:UnrestrictComponent
  },
  {
    path: "login3",
    component: Login3Component,
    },

    {
      path: "pics",
      component: PicsComponent,
        canActivate:[AuthGuard]
      },
      {
        path: "needadoc",
        component: NeedadocComponent,
          canActivate:[AuthGuard]
        },

        {
          path: "services",
          component: ServicesComponent,
            canActivate:[AuthGuard]
          },
          
        {
          path: "needbyid",
          component: RequesthidComponent,
            canActivate:[AuthGuard]
          },
    
          {
            path: "alloc",
            component: AllocComponent,
              canActivate:[AuthGuard]
            },
            {
              path: "staffadmin",
              component: LoginstaffComponent,
              },
   
              {
                path: "Notifications",
                component: DocnotiComponent,
                  canActivate:[AuthGuard]
                },
                {
                  path: "adminall",
                  component: AdminallrecordComponent,
                    canActivate:[AuthGuard]
                  },
                  {
                    path: "docs",
                    component: DocsComponent,
                      canActivate:[AuthGuard]
                    },
                    {
                      path: "viewtest",
                      component: ViewtestComponent,
                        canActivate:[AuthGuard]
                      },
                      {
                        path: "patient-update-id",
                        component: PatientUpdateIdComponent,
                        canActivate:[AuthGuard]
                      },
                      {
                        path: "lab-login",
                        component: LabLoginComponent,
                        canActivate:[AuthGuard]
                      },
                      {
                        path: "lab-reg",
                        component: LabRegComponent,
                        // canActivate: [AuthGuard]
                      },
                      {
                        path: "new-lab",
                        component : NewLabComponent,
                        //canActivate : [AuthGuard]
                      },
                      {
                        path: "user-maintenance",
                        component : UserMaintenanceComponent,
                        canActivate : [AuthGuard]
                      },
                      {
                        path : "add-user",
                        component : AddUserComponent,
                        canActivate : [AuthGuard]
                      },
                      {
                         path: "delete-user",
                         component:DeleteUserComponent,
                         canActivate : [AuthGuard]
                      },
                      {
                        path: "edit-user",
                        component:EditUserComponent,
                        canActivate : [AuthGuard]
                      },
                      {
                        path: "select-drop",
                        component:SelectDropComponent,
                        canActivate : [AuthGuard]
                      },
                       {
                         path: "user-add",
                         component: UserAddComponent,
                         canActivate : [AuthGuard]
                       },
                       {
                         path: "lab-sign",
                         component:LabSignComponent
                       },
                       {
                         path: "lab-edit-user",
                         component:LabEditUserComponent
                       },
                       {
                         path: "lab-home",
                         component:LabHomeComponent
                       },
                       {
                           path:"lab-profile",
                           component:LabProfileComponent
                       },
                       {
                         path:"lab-role-page",
                         component:LabRolePageComponent,
                         //children:[{path:'approve-test',component:ApproveTestComponent}]
                       },
                       {
                         path:"reset-password",
                         component:ResetPasswordComponent
                       },
                       {
                         path:"set-pass",
                         component:SetPassComponent
                       },
                       {
                         path:"user-chane-password",
                         component :UserChanePasswordComponent
                       },
                       {
                         path: "lab-user-otp",
                         component: LabUserOtpComponent,
                  
                       },
                       {
                         path: "backend",
                         component: AdminSignComponent,
                       },
                      {
                        path : "admin-log",
                        component : AdminLogComponent
                      },
                      {
                        path : "lab-user",
                        component : LabUserComponent
                      },
                      {
                        path : "user-edit",
                        component : UserEditComponent
                      },
                      {
                        path:"lab-user-edit",
                        component: LabUserEditComponent
                      },
                      {
                         path:"user-role",
                         component:UserRoleComponent
                      },
                      {
                        path:"connect-doctor",
                        component:ConnectDoctorComponent
                      },
                      {
                        path:"doctor-online",
                        component:DoctorOnlineComponent
                      },
                      {
                        path:"lab-request",
                        component:LabRequestComponent
                      },
                      {
                        path:"update-prices",
                        component:UpdatePricesComponent
                      },
                      {
                        path:"user-billing",
                        component:UserBillingComponent
                      },
                      {
                        path:"payment-info",
                        component:PaymentInfoComponent
                      },
                      {
                         path:"user-plan",
                         component:UserPlanComponent
                      },

                      {
                        path:"user-payment",
                        component:UserPaymentComponent
                      },
                      {
                        path:"payment-status",
                        component:PaymentStatusComponent
                      },
                      {
                        path:"process-test",
                        component:ProcessTestComponent,
                    
                      },
                      {
                        path:"approve-test",
                        component:ApproveTestComponent,
                       
                      },
                      {
                        path:"reports",
                        component:ReportsComponent
                      },
                      {
                        path:"labreports",
                        component:LabreportsComponent
                      },
                      
                      {
                        path:"filter-reports",
                        component:FilterReportsComponent
                      },
                      {
                        path:"payment-overdue",
                        component:PaymentOverdueComponent
                      },
                      {
                        path:"consult-reports",
                        component:ConsultReportsComponent
                      },
                      {
                        path:"blocklab-user",
                        component:BlocklabUserComponent
                      },
                      {
                        path:"feesetup",
                        component:FeesetupComponent
                      },
                      {
                        path:"doc-fee",
                        component:DocFeeComponent
                      },
                      {
                        path:"lab-fee",
                        component:LabFeeComponent
                      },
                      {
                        path:"process-completed",
                        component:ProcessCompletedComponent
                      },
                      {
                        path:"doc-request",
                        component:DocRequestComponent
                      },
                       {
                        path:"upload-doc",
                        component:UploadDocComponent
                      },
                      {
                        path:"user-approval",
                        component:UserApprovalComponent
                      },
                      {
                        path:"file-upload",
                        component:FileUploadComponent,
                       // canActivate:[AuthGuard]
                      },
                      {
                        path:"userplan-edit",
                        component:UserplanEditComponent
                      },
                      // {
                      //   path:"view-test-page",
                      //   component : ViewTestPageComponent
                      // },
                      {
                        path:"user-process",
                        component : UserProcessComponent
                      },
                      {
                        path:"user-pin-model",
                        component : UserPinModelComponent
                      },
                      {
                        path:"user-lab-test-update",
                        component : UserLabTestUpdateComponent
                      },
                      {
                        path:"lab-userpayment",
                        component : LabUserpaymentComponent
                      },
                      {
                        path:"labadminpayment",
                        component : LabadminpaymentComponent
                      },
                      {
                        path:"unregistration",
                        component : UnregistrationComponent
                      },
                      {
                        path:"notifications",
                        component : NotificationsComponent
                      },
                      {
                        path:"docprofile",
                        component : DocprofileComponent
                      },
                      {
                        path:"unregister-patient",
                        component : UnregisterPatientComponent
                      },
                      {
                        path:"patient-profile",
                        component : PatientProfileComponent
                      },
                      {
                        path:"patient-vaccine",
                        component : PatientVaccineComponent
                      },
                      {
                        path:"patient-allergy",
                        component : PatientAllergyComponent
                      },
                      {
                        path:"patient-medicine-prescribe",
                        component : PatientMedicinePrescribeComponent
                      },
                      {
                        path:"consult-doc",
                        component : ConsultDocComponent
                      },
                      {
                        path:"plogin",
                        component : PloginComponent
                      },
                      {
                        path:"dtr-status",
                        component : DtrStatusComponent
                      },
                      {
                        path:"dsigndelete",
                        component:DsigndeleteComponent
                      },
                      {
                        path:"lsigndelete",
                        component:LsigndeleteComponent
                      },
                      {
                        path:"lab-doc-approve",
                        component:LabDocApproveComponent
                      },
                      {
                        path:"terms-service",
                        component:TermsServiceComponent
                      },
                      {
                        path:"privacy-policy",
                        component:PrivacyPolicyComponent
                      },
                      {
                        path:"disclaimer",
                        component:DisclaimerComponent
                      },
                      {
                        path:"callcomplete",
                        component:CallcompleteComponent
                      },
                      {
                        path:"patient-video",
                        component:PatientVideoComponent
                      },
                      {
                        path:"demovideo",
                        component:DemovideoComponent
                      },
                      {
                        path:"dtrdemo",
                        component:DtrdemoComponent
                      },
                      {
                        path:"userviewtest",
                        component:UserviewtestComponent
                      }
                     
                     
                     


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

