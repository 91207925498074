import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

declare var swal;
@Component({
  selector: 'app-userplan-edit',
  templateUrl: './userplan-edit.component.html',
  styleUrls: ['./userplan-edit.component.css']
})
export class UserplanEditComponent implements OnInit {

  userData:any
  lab_id:any
  a:Number
  planButton: any;
  resultres: any;
  response: any;
  plan: any;
  constructor(
    public router:Router,
    protected auth: AuthService,
    protected route: ActivatedRoute
  ) {


        this.userData= JSON.parse(sessionStorage.getItem("userData"));

        console.log("11111110" ,this.userData.plan)
     //console.log("5454", this.userData.plan);
    this.lab_id=sessionStorage.getItem("lab_id");




    this.planButton = this.userData.plan;

    console.log("this.planButton", this.planButton)
   }

   ngOnInit() {
     this.Lab_gst();
  }

  Lab_gst(){
    this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res=>{ 
       this.resultres = res;
      

      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].plan;
       this.plan = this.resultres.response[0].plan;
       console.log("plan===>", this.plan )
       console.log(this.response);
      
      }
    })
  }

  change(a){


    console.log(this.a=a);
if(a===1){
console.log("BASIC");
//swal('YOU HAVE SELECTED BASIC PLAN !');

this.userData.plan="BASIC";
}
else if(a===2){
  console.log("STANDARD");
  //swal('YOU HAVE SELECTED STANDARD PLAN !');
  //document.getElementById("btnSubmit").disabled = true

//   var element = <HTMLInputElement> document.getElementById("btnSubmit");
// element.disabled = true;
  
  var element = this.userData.plan="STANDARD"

  console.log(element)
}
else if(a===3){
  console.log("PREMIUM");
  //swal('YOU HAVE SELECTED PREMIUM PLAN !');
  
  this.userData.plan="PREMIUM";
}
else{
  console.log("NOPLAN");
}



this.auth.labedit(this.userData).subscribe(res => {
  if (res.status == 'success') {
   sessionStorage.setItem("userData",JSON.stringify(res.response));
  
  //  this.dialogRef.close(res.response);
   // location.reload();
  swal('Your plan has changed successfully !!');
  this.router.navigate(['lab-profile']);
 // this.auth.labeditData.next(res.response);
    console.log(39,res);
  }
 
})
//this.router.navigate(['lab-profile']);
// this.

  }
logout() {
  sessionStorage.clear();
  localStorage.clear();
  this.router.navigateByUrl('/new-lab');
}
 

}
