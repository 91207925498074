import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { switchAll } from 'rxjs/operators';
declare var swal:any;
var  $:any;
@Component({
  selector: 'app-doctorrating',
  templateUrl: './doctorrating.component.html',
  styleUrls: ['./doctorrating.component.css']
})

export class DoctorratingComponent implements OnInit {
  doctorratingForm: FormGroup;
  docter_name: any;
  doctlist: any;
  constructor(private fb: FormBuilder,
    private router: Router, 
   
    protected auth: AuthService,
    protected route: ActivatedRoute) {
      this.doctorlist();
     }
    
  ngOnInit() {
    this.createForm();
  }
  createForm() {
    this.doctorratingForm = this.fb.group({
      docter_name: ['', Validators.required],
      rating: ['',Validators.required],
      comments: ['',Validators.required]
    
});
  }
  save() {
 
    this.auth.doctorrating(this.doctorratingForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){
          this.auth.DoctorratingData.next(res.response);
          this.router.navigateByUrl("/medicaldetails")
          
        }
      }
    
    })
  swal("doctor rating succesfully added");
  }
  doctorlist(){
    if(this.docter_name==undefined)
    {
      this.docter_name="";
    }
    this.auth.doctorlist().subscribe(res => {
      
      if (res.status == "success") {
     {
         this.doctlist=res.response 
         
     
        
     }}
     
    },
       
 
       () => {
        

     }
     );
   }
   logo(){
     
   }
}

