import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  constructor(public http:HttpClient , public auth:AuthService) { }

  counter:any;
  b:any;

  ngOnInit() {
  }

  openNotify(){

  }

  deleteNotify(_id,sts){
console.log(_id);
console.log(sts);
this.http.post(this.auth.baseUrl+'/notify/edit',{id:_id,status:sts}).subscribe((res)=>{
console.log(res);

})
  }


  logout(){
 this.auth.logout();
  }

  x=setInterval(()=>{
    console.log('hello !');
  
    this.http.post(this.auth.baseUrl+'/notify/find','').subscribe((res)=>{
    console.log(res['response'].length);
    this.counter=res['response'].length
    if(this.counter==0){
      this.counter='' ;
    }
    else{
      this.counter=res['response'].length
      this.b=res['response'].reverse()
      
    }

    })
  },2000);

  ngOnDestroy(){
clearInterval(this.x)
  }

}
