import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { SignaturelabComponent } from '../medicaldetails/signaturelab/signaturelab.component';

import { HttpClient } from "@angular/common/http";
import { LabSignComponent } from '../lab-sign/lab-sign.component';


declare var swal;
@Component({
  selector: 'app-lab-role-page',
  templateUrl: './lab-role-page.component.html',
  styleUrls: ['./lab-role-page.component.css']
})
export class LabRolePageComponent implements OnInit {
  // userData: any;
  role: any;
  viewResult: string;
  EditResult: string;
  ApproveResult: string;
  Billing: string;
  Reports: string;

  constructor(private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialog: MatDialog,
    protected route: ActivatedRoute) {
    this.role = sessionStorage.getItem("role")
    console.log(87878,this.role )
    
    
    console.log(99999,this.role.includes("viewResult"))

      if (this.role.includes("viewResult")) {
        this.viewResult = "viewResult";
      }
      else {
        this.viewResult = "";
      }
      if (this.role.includes("Edit Result")) {
        this.EditResult = "Edit Result";
      }
      else {
        this.EditResult = "";
      }
      if (this.role.includes("Approve Result")) {
        this.ApproveResult = "Approve Result";
        // this.router.navigateByUrl('/Labinformation');
      }
      else {
        this.ApproveResult = "";
      }
      if (this.role.includes("Billing")) {
        this.Billing = "Billing";
      }
      else {
        this.Billing = "";
      }
      if (this.role.includes("Reports")) {
        this.Reports = "Reports";
      }
      else {
        this.Reports = "";
      }
    }

  ngOnInit() {
  }
  forgot(e)
{
  this.router.navigateByUrl('/forgetpsw?id='+e);
}
logout(){
  swal({
    title: 'Successfully Logged Out',
    text: 'Redirecting...',
    icon: 'success',
    timer: 4000,
    // buttons: true,
})
.then(() => {
  this.router.navigateByUrl('/new-lab');
});
} 

signature()  {
  let dialogRef = this.dialog.open(SignaturelabComponent, {
     width: '600px',
 });
 dialogRef.afterClosed().subscribe(result => {

 });
} 
}
