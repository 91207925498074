import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';


import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal } from 'ngx-materialize';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;
@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.css']
})
export class ReportEditComponent  extends MzBaseModal  implements OnInit {

 
  userData: any;
  full_name: string;
  patient_id: string;
  testReportUpdate:FormGroup;
  docname: any;
  docter_id: any;
  reason: any;
  ch: any;
  editdc: any;
  datas: any;
  port: any;
  usersData: any;
  child_name:any;
  dropdownSettings:{};
  response: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  names: any;
  selectedItems: any;
  che: any;
  status: any;
  constructor(
   
    public dialogRef: MatDialogRef<ReportEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    protected route: Router,private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder,
    public auth:AuthService) {

    super();
  
    

      this. datas="";
    
       
        this.editdc  = data;
        console.log( 99,this.editdc ); 
 
        if(this.editdc != undefined)
        {
          this. datas=this.editdc;
          console.log("hi test_id",this.datas.test_id );
         
        }
        
        // Print the parameter to the console. 

    this.testReportUpdate  =  this.formBuilder.group({
    
        blood_pressure: ['', Validators.required],
        comments: ['', Validators.required],
        medicine_prescribed: ['', Validators.required],
        labtest_required: [false, Validators.required],
        child_name: ['',Validators.required],
        patient_id:['', Validators.required],
        docter_id:['', Validators.required],
        docter_name:[this.docname],
        reason:['', Validators.required],
        test_name:[''],
        _id:['']

     });

     this.dropdownSettings = {
          singleSelection: false,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
       };


      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.full_name=sessionStorage.getItem("full_name");
      this.patient_id=sessionStorage.getItem("patient_id");
      this.docname=this.userData.docter_name;
      this.docter_id=this.userData.docter_id;

      this.list();

    }
  

  
  history()
  {
    this.route.navigateByUrl('/patentmedicalhistory');
  }
  
  ngOnInit() {

    this.che="false";
    this. data="";
    console.log("i2n patc", this.datas.test_id.test_name);
    if(this.datas!="")  {


      this.testReportUpdate.patchValue(this.datas.test_id);
      // this.testReportUpdate.value.test_name=this.datas.test_id.test_name.name;
      this.port=this.datas.test_id.test_name;
      this.child_name=this.datas.test_id.child_name;
      this.selectedItems=this.datas.test_id.test_name
      console.log(101,this.datas.test_id.labtest_required)

         this.status=this.datas.test_id.status
 console.log("this.sts=====>", this.status);
 


      if( this.datas.test_id.labtest_required==true){
        this.che="true";
        console.log(101,this.che)
      }
    
     
    // this.testReportUpdate.patchValue(JSON.parse(this.datas).test_id);

    }
  }
  save(patientupdateForm){
    this.testReportUpdate.value.patient_id=this.patient_id;
    this.testReportUpdate.value.docter_id=sessionStorage.getItem("docter_id");

    if(this.testReportUpdate.value.labtest_required==true && this.testReportUpdate.value.test_name==undefined){
           swal('Please select test name');
     } else if(this.testReportUpdate.value.reason=="" || this.testReportUpdate.value.comments ==""){
                swal('Please enter all details');   
     } else if(this.testReportUpdate.value.blood_pressure=="" || this.testReportUpdate.value.medicine_prescribed ==""){
                swal('Please enter all details');  
     } else {
         this.testReportUpdate.value.child_name=this.child_name;
         this.auth.editdoc(patientupdateForm.value).subscribe(res => {
            if (res.status == "success") {
              if (res.response != "") {
                  swal("updated successfully");
                  window.location.reload();
                  this.route.navigateByUrl('/patientMedicalSearch');
                  // this.modalController.dismiss() //this.dismiss()
        
            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
              }
            }
        }, (err) => {
            swal("server failed, server details not exits ");
       });
   }
  }

  //  view(){
  //   this.route.navigateByUrl('/patentmedicalhistory',);
  //  }
   list() {
      console.log("in patch");
      this.auth.findchild({patient_id:this. patient_id}).subscribe(res => {
         if (res.status == "success") {
            this.usersData=res.response;
           // sessionStorage.setItem("full_name",this.name);
           // this.router.navigateByUrl("/patientsearch");
            this.search();
         }
       }, () => {
          this.auth.presentToast("server failed, server detils not exits ");
       });
     }

    //  check(e){
    //    console.log(e);
    //      this.ch=e.target.checked;
    //      console.log(66,this.ch);
    //  }

     check(e){
      console.log(e.target.checked);
    if(e.target.checked==true){
        this.che="true";
    }
    else{
     this.che="false";
    }
    }

  
    
    testbyid(){
      this.auth.testbyid().subscribe(res => {
        if (res.status == "success") {
          if (res.response != "") {
            this.testNames=res.response;
          }
        }
      },() => {
          swal("Login failed, Login detils not exits ");
       });
     }
   search(){
       this.auth.lab_tests().subscribe(res => {
          if (res.status == "success") {
            if (res.response != "") {
            this.testNames=res.response;
            }
          }
       }, () => {
          swal("Login failed, Login detils not exits ");
      });
     }
    
    onItemSelect(item: any) {
      console.log(item);
    }
    onSelectAll(items: any) {
      console.log(items);
    }

    testNames(){
      this.auth.totalTestNames({patient_id:this.patient_id}).subscribe(res => {
          this.response = res;
        if(this.response) {
          this.names = this.response.response;
          console.log(98,this.names)
       } else {
        swal("Server error")
       }
      })
  }
  close()
   {
     this.dialogRef.close();
 
   }
}
