import { Component, OnInit } from '@angular/core';
import { LabSignComponent } from '../lab-sign/lab-sign.component';
import { MatDialog } from '@angular/material/dialog';
import { SignaturelabComponent } from '../medicaldetails/signaturelab/signaturelab.component';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var swal;
@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.css']
})
export class UserMaintenanceComponent implements OnInit {
  result: any;

  constructor(  public dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute) { 
      if( sessionStorage.getItem("lab_phone")==" " || sessionStorage.getItem("lab_phone") == undefined){
        this.router.navigateByUrl('/new-lab');
      }
    }

  ngOnInit() {
  }


  signature()  {
    let dialogRef = this.dialog.open(SignaturelabComponent, {
       width: '600px',
   });
   dialogRef.afterClosed().subscribe(result => {
 
   });
} 
logout(){
  
  sessionStorage.clear();
  localStorage.clear();

  this.router.navigateByUrl('/new-lab');

} 
}
