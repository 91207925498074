import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule  } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ForgetpswComponent } from './pages/forgetpsw/forgetpsw.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { DoctorformComponent } from './pages/doctorform/doctorform.component';
import { LabformComponent } from './pages/labform/labform.component';
import { MedicaldetailsComponent } from './pages/medicaldetails/medicaldetails.component';
import { DoctorvisitsComponent } from './pages/doctorvisits/doctorvisits.component';
 import{ AuthGuard} from  './services/auth.guard';
import { LabvisitsComponent } from './pages/labvisits/labvisits.component';
import{LabviewComponent} from './pages/labview/labview.component';
import { DoctorratingComponent } from './pages/doctorrating/doctorrating.component';
import { LabratingComponent } from './pages/labrating/labrating.component';
import { HospitalratingComponent } from './pages/hospitalrating/hospitalrating.component';
import { DoctorDataComponent } from './pages/doctor-data/doctor-data.component';
import { PatientsearchComponent } from './pages/patientsearch/patientsearch.component';
import { PatientmedhistoryComponent } from './pages/patientmedhistory/patientmedhistory.component';
import { LabinformationComponent } from './pages/labinformation/labinformation.component';
import { LabtestresultComponent } from './pages/labtestresult/labtestresult.component';
import { EditpatientComponent } from './pages/editpatient/editpatient.component';
import {ScrollingModule} from '@angular/cdk/scrolling';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MzButtonModule, MzInjectionModule, MzModalModule, MzBaseModal } from 'ngx-materialize';
import { EditdoctorComponent } from './pages/editdoctor/editdoctor.component';
import { LabeditComponent } from './pages/labedit/labedit.component';
import { RatingModule } from 'ng-starrating';
import { AddpersonComponent } from './pages/addperson/addperson.component';
import { PatientlistComponent } from './pages/patientlist/patientlist.component';
import { HospitalregComponent } from './pages/hospitalreg/hospitalreg.component';
import { AdddoctorComponent } from './pages/adddoctor/adddoctor.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import {MatDialogModule} from '@angular/material/dialog';
import { ChildeditComponent } from './pages/childedit/childedit.component';
import { ChilddeleteComponent } from './pages/childdelete/childdelete.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PinModelComponent } from './pages/pin-model/pin-model.component';
import { RestDoctorComponent } from './pages/rest-doctor/rest-doctor.component';
import { MatCheckbox,MatDatepickerModule, DateAdapter, MatNativeDateModule, MatRadioButton, MatRadioModule, MatTableModule, MatSortModule } from '@angular/material';
import { RestrictdocmodelComponent } from './pages/patientfolder/restrictdocmodel/restrictdocmodel.component';
import { LabresultUpdateComponent } from './pages/labModel/labresult-update/labresult-update.component';
import { DatePipe } from '@angular/common';
import { TestModelComponent } from './pages/labModel/assignTest/test-model.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { ReportTestComponent } from './pages/report-test/report-test.component';
import { ReportEditComponent } from './pages/report-edit/report-edit.component';
import { ReportViewComponent } from './pages/report-view/report-view.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ResultLabComponent } from './pages/result-lab/result-lab.component';
import { UnrestrictComponent } from './pages/patient/unrestrict/unrestrict.component';
import { ConformModelComponent } from './pages/patient/conform-model/conform-model.component';
import { PerceptionEditComponent } from './pages/doctor/perception-edit/perception-edit.component';
import { Login2Component } from './pages/login2/login2.component';
import { Login3Component } from './pages/login3/login3.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { ViewimageComponent } from './pages/viewimage/viewimage.component';
import { PicsComponent } from './medicaldetails/pics/pics.component';
import { NeedadocComponent } from './vedio/needadoc/needadoc.component';
import { ServicesComponent } from './vedio/services/services.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertComponent } from './pages/patient/alert/alert.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxCheckboxModule } from 'ngx-checkbox';
import { RequesthidComponent } from './vedio/requesthid/requesthid.component';
import { AllocComponent } from './vedio/alloc/alloc.component';
import { AllocdocComponent } from './vedio/allocdoc/allocdoc.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LoginstaffComponent } from './pages/loginstaff/loginstaff.component';
import {HashLocationStrategy, Location, LocationStrategy} from '@angular/common';
//import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";
import { DocnotiComponent } from './vedio/docnoti/docnoti.component';
import { AdminallrecordComponent } from './vedio/adminallrecord/adminallrecord.component';
import { ChangepassComponent } from './changepass/changepass.component';
import { DocsComponent } from './pages/labtestresult/docs/docs.component';
import { ViewtestComponent } from './pages/labtestresult/viewtest/viewtest.component';
import { DocpinComponent } from './pages/doctor-data/docpin/docpin.component';
import { PrintComponent } from './vedio/print/print.component';
import {NgxPrintModule} from 'ngx-print';
import { EditassignComponent } from './pages/labModel/editassign/editassign.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import {MatSelectModule} from '@angular/material/select';
import { PrescriptionPipe } from './medicaldetails/prescription.pipe';
import { PrescriptionComponent } from './medicaldetails/prescription/prescription.component';
import { HeaderComponent } from './header/header.component';
import { SignaturelabComponent } from './medicaldetails/signaturelab/signaturelab.component';
import { ApproveComponent } from './approve/approve.component';
import { SignatureDoctorComponent } from './medicaldetails/signature-doctor/signature-doctor.component';

import { SignaturePadModule } from 'angular2-signaturepad';
import { PatientUpdateIdComponent } from './patient-update-id/patient-update-id.component';
import { LabLoginComponent } from './lab-login/lab-login.component';
// import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { LabRegComponent } from './lab-reg/lab-reg.component';
import { NewLabComponent } from './new-lab/new-lab.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { UserMaintenanceComponent } from './user-maintenance/user-maintenance.component';
import { AddUserComponent } from './add-user/add-user.component';
import { DeleteUserComponent } from './delete-user/delete-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { SelectDropComponent } from './select-drop/select-drop.component';
import { UserAddComponent } from './user-add/user-add.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LabSignComponent } from './lab-sign/lab-sign.component';
import { LabEditUserComponent } from './lab-edit-user/lab-edit-user.component';
import { MatDialogModule } from '@angular/material';
import { SearchUserNamePipe } from './search-user-name.pipe';
import { LabHomeComponent } from './lab-home/lab-home.component';
import { LabProfileComponent } from './lab-profile/lab-profile.component';
import { LabRolePageComponent } from './lab-role-page/lab-role-page.component';
import { NgxMatIntlTelInputModule  } from "ngx-mat-intl-tel-input";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SetPassComponent } from './set-pass/set-pass.component';
import { UserChanePasswordComponent } from './user-change-password/user-chane-password.component';
import { LabUserOtpComponent } from './lab-user-otp/lab-user-otp.component';
import { LabAdminComponent } from './lab-admin.component';
import { AdminSignComponent } from './labAdmin/admin-sign/admin-sign.component';
import { AdminLogComponent } from './labAdmin/admin-log/admin-log.component';
import { LabUserComponent } from './labAdmin/lab-user/lab-user.component';
import { UserEditComponent } from './labAdmin/user-edit/user-edit.component';
import { LabUserEditComponent } from './labAdmin/lab-user-edit/lab-user-edit.component';
import { UserRoleComponent } from './labAdmin/user-role/user-role.component';
import { ConnectDoctorComponent } from './labAdmin/connect-doctor/connect-doctor.component';
import { DoctorOnlineComponent } from './doctor-online/doctor-online.component';
import { LabRequestComponent } from './labAdmin/lab-request/lab-request.component';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { UpdatePricesComponent } from './update-prices/update-prices.component';
import { UserBillingComponent } from './user-billing/user-billing.component';
import { PaymentInfoComponent } from './payment-info/payment-info.component';
import { UserPlanComponent } from './user-plan/user-plan.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { PaymentStatusComponent } from './user-payment/payment-status/payment-status.component';
import { ProcessTestComponent } from './lab-home/process-test/process-test.component';
import { ApproveTestComponent } from './approve-test/approve-test.component';
import { ReportsComponent } from './labAdmin/reports/reports.component';
import { LabreportsComponent } from './labAdmin/labreports/labreports.component';
import { PaymentOverdueComponent } from './labAdmin/payment-overdue/payment-overdue.component';
import { FilterReportsComponent } from './labAdmin/filter-reports/filter-reports.component';
import { ConsultReportsComponent } from './labAdmin/consult-reports/consult-reports.component';
import { BlocklabUserComponent } from './labAdmin/blocklab-user/blocklab-user.component';
import { FeesetupComponent } from './labAdmin/feesetup/feesetup.component';
import { DocFeeComponent } from './labAdmin/doc-fee/doc-fee.component';
import { LabFeeComponent } from './labAdmin/lab-fee/lab-fee.component';
import { ProcessCompletedComponent } from './labAdmin/process-completed/process-completed.component';
import { DocRequestComponent } from './labAdmin/doc-request/doc-request.component';
import { UploadDocComponent } from './upload-doc/upload-doc.component';
import { UserApprovalComponent } from './user-approval/user-approval.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { UserplanEditComponent } from './userplan-edit/userplan-edit.component';
import { UserPrintComponent } from './user-print/user-print.component';
import { UserPaymentDialougeComponent } from './user-payment-dialouge/user-payment-dialouge.component';
import { ViewTestPageComponent } from './view-test-page/view-test-page.component';
import { UserProcessComponent } from './user-process/user-process.component';
import { SearchfilterPipe } from './searchfilter.pipe';
import { UserPinModelComponent } from './user-pin-model/user-pin-model.component';
import { UserLabTestUpdateComponent } from './user-lab-test-update/user-lab-test-update.component';
import { LabUserpaymentComponent } from './lab-userpayment/lab-userpayment.component';
import { TestNamePipe } from './test-name.pipe';
import { LabadminpaymentComponent } from './labadminpayment/labadminpayment.component';
import { UnregistrationComponent } from './unregistration/unregistration.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DocprofileComponent } from './docprofile/docprofile.component';
import { UnregisterPatientComponent } from './unregister-patient/unregister-patient.component';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { PatientVaccineComponent } from './patient-vaccine/patient-vaccine.component';
import { PatientAllergyComponent } from './patient-allergy/patient-allergy.component';
import { PatientMedicinePrescribeComponent } from './patient-medicine-prescribe/patient-medicine-prescribe.component';
import { ConsultDocComponent } from './consult-doc/consult-doc.component';
import { PloginComponent } from './plogin/plogin.component';
import { DtrStatusComponent } from './dtr-status/dtr-status.component';
import { DsigndeleteComponent } from './dsigndelete/dsigndelete.component';
import { LsigndeleteComponent } from './lsigndelete/lsigndelete.component';
import { LabDocApproveComponent } from './lab-doc-approve/lab-doc-approve.component';
import { TermsServiceComponent } from './terms-service/terms-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PatientPopupComponent } from './patient-popup/patient-popup.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CallcompleteComponent } from './callcomplete/callcomplete.component';
import { PatientVideoComponent } from './patient-video/patient-video.component';
import { DemovideoComponent } from './demovideo/demovideo.component';
import { DtrdemoComponent } from './dtrdemo/dtrdemo.component';
import { UserviewtestComponent } from './userviewtest/userviewtest.component';
//import { PatientLoginComponent } from './patient-login/patient-login.component';
// import { UserApprovalComponent } from './user-approval/user-approval.component';

// import { SelectDropDownModule } from 'ngx-select-dropdown'
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import {BsDropdownModule} from "ngx-bootstrap/dropdown";
// import{MaterializeModule} from 'angular2-materialize';
const appRoutes: Routes = [
  {path: 'login', component: LoginComponent}
]
@NgModule({
  declarations: [
    // DialogOverviewExampleDialog,
    // LabSignComponent,
    SignaturelabComponent,
    AppComponent,
    LoginComponent,
    ForgetpswComponent,
    RegistrationComponent,
    DoctorformComponent,
    LabformComponent,
    MedicaldetailsComponent,
    DoctorvisitsComponent,
    LabvisitsComponent,
    LabviewComponent,
    DoctorratingComponent,
    LabratingComponent,
    HospitalratingComponent,
    DoctorDataComponent,
    PatientsearchComponent,
    PatientmedhistoryComponent,
    LabinformationComponent,
    LabtestresultComponent,
    EditpatientComponent,
    EditdoctorComponent,
    LabeditComponent,
    AddpersonComponent,
    PatientlistComponent,
    HospitalregComponent,
    AdddoctorComponent,
    ChildeditComponent,
    ChilddeleteComponent,
    LogoutComponent,
    PinModelComponent,
    RestDoctorComponent,
    RestrictdocmodelComponent,
    LabresultUpdateComponent,
    TestModelComponent,
    ReportTestComponent,
    ReportEditComponent,
    ReportViewComponent,
    ResultLabComponent,
    UnrestrictComponent,
    ConformModelComponent,
    PerceptionEditComponent,
    Login2Component,
    Login3Component,
    ViewimageComponent,
    PicsComponent,
    NeedadocComponent,
    ServicesComponent,
    AlertComponent,
    RequesthidComponent,
    AllocComponent,
    AllocdocComponent,
    LoginstaffComponent,
    DocnotiComponent,
    AdminallrecordComponent,
    ChangepassComponent,
    DocsComponent,
    ViewtestComponent,
    DocpinComponent,
    PrintComponent,
    EditassignComponent,
    PrescriptionPipe,
    PrescriptionComponent,
    HeaderComponent,
    SignaturelabComponent,
    ApproveComponent,
    SignatureDoctorComponent,
    PatientUpdateIdComponent,
    LabLoginComponent,
    LabRegComponent,
    NewLabComponent,
    UserMaintenanceComponent,
    AddUserComponent,
    DeleteUserComponent,
    EditUserComponent,
    SelectDropComponent,
    UserAddComponent,
    LabSignComponent,
    LabEditUserComponent,
    SearchUserNamePipe,
    LabHomeComponent,
    LabProfileComponent,
    LabRolePageComponent,
    ResetPasswordComponent,
    SetPassComponent,
    UserChanePasswordComponent,
    LabUserOtpComponent,
    LabAdminComponent,
    AdminSignComponent,
    AdminLogComponent,
    LabUserComponent,
    UserEditComponent,
    LabUserEditComponent,
    UserRoleComponent,
    ConnectDoctorComponent,
    DoctorOnlineComponent,
    LabRequestComponent,
    UpdatePricesComponent,
    UserBillingComponent,
    PaymentInfoComponent,
    UserPlanComponent,
    UserPaymentComponent,
    PaymentStatusComponent,
    ProcessTestComponent,
    ApproveTestComponent,
    ReportsComponent,
    LabreportsComponent,
    PaymentOverdueComponent,
    FilterReportsComponent,
    ConsultReportsComponent,
    BlocklabUserComponent,
    FeesetupComponent,
    DocFeeComponent,
    LabFeeComponent,
    ProcessCompletedComponent,
    DocRequestComponent,
    UploadDocComponent,
    UserApprovalComponent,
    FileUploadComponent,
    UserplanEditComponent,
    UserPrintComponent,
    UserPaymentDialougeComponent,
    ViewTestPageComponent,
    UserProcessComponent,
    SearchfilterPipe,
    UserPinModelComponent,
    UserLabTestUpdateComponent,
    LabUserpaymentComponent,
    TestNamePipe,
    LabadminpaymentComponent,
    UnregistrationComponent,
    NotificationsComponent,
    DocprofileComponent,
    UnregisterPatientComponent,
    PatientProfileComponent,
    PatientVaccineComponent,
    PatientAllergyComponent,
    PatientMedicinePrescribeComponent,
    ConsultDocComponent,
    PloginComponent,
    DtrStatusComponent,
    DsigndeleteComponent,
    LsigndeleteComponent,
    LabDocApproveComponent,
    TermsServiceComponent,
    PrivacyPolicyComponent,
    PatientPopupComponent,
    DisclaimerComponent,
    CallcompleteComponent,
    PatientVideoComponent,
    DemovideoComponent,
    DtrdemoComponent,
    UserviewtestComponent
   // PatientLoginComponent,
    // UserApprovalComponent,
    

  
  
  
  ],
  imports: [
    SignaturePadModule,
    NgxPrintModule,
    //NgxSpinnerModule,
    NgxCheckboxModule ,
    NgxPaginationModule,
    MatTabsModule,
    MatCheckboxModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    HttpClientModule,
    MzModalModule,
    MzButtonModule,
    RatingModule,
    NgSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    ScrollingModule,
    Ng2TelInputModule,
    AngularMyDatePickerModule,
    NgMultiSelectDropDownModule,
    // MalihuScrollbarModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: true}),

    BrowserAnimationsModule,
    NgxPayPalModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatTableModule,
    MatSortModule,
    NgxMatIntlTelInputModule,
    // NgxIntlTelInputModule,
    // BsDropdownModule.forRoot()
    // NgxMatSelectSearchModule,
    MatFormFieldModule,
        MatInputModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  
 
    

  

  ],
  entryComponents: [
    // LabSignComponent,
    LabresultUpdateComponent,
    AlertComponent,
    Login2Component,
    Login3Component,
    ReportViewComponent,
    LabviewComponent,
    RegistrationComponent,
    DoctorformComponent ,
    ReportTestComponent,
    LabformComponent,
    MedicaldetailsComponent,
    DoctorvisitsComponent,
    LabvisitsComponent,
    LabviewComponent,
    DoctorratingComponent,
    LabratingComponent,
    HospitalratingComponent,
    ForgetpswComponent,
    DoctorDataComponent,
    PatientsearchComponent,
    PatientmedhistoryComponent,
    LabinformationComponent,
    PrescriptionComponent,
    LabtestresultComponent,
    EditpatientComponent,
    EditdoctorComponent,
    LabeditComponent,
    AddpersonComponent,
    PatientlistComponent,
    HospitalregComponent,
    AdddoctorComponent,
    ChildeditComponent,
    ChilddeleteComponent,
    LogoutComponent,
    PinModelComponent,
    RestrictdocmodelComponent,
    ReportEditComponent,
    ConformModelComponent,
    PerceptionEditComponent,
    ResultLabComponent,
    TestModelComponent,
    PatientlistComponent,
    ViewimageComponent,
    AllocdocComponent,
    LoginstaffComponent,
    ChangepassComponent,
    DocpinComponent,
    PrintComponent,
    EditassignComponent,
    SignaturelabComponent,
    SignatureDoctorComponent,
    ApproveComponent,
    PatientUpdateIdComponent,
    LabLoginComponent,
    LabRegComponent,
    NewLabComponent,
    DeleteUserComponent,
    EditUserComponent,
    SelectDropComponent,
    UserAddComponent,
    // LabSignComponent,
    LabEditUserComponent,
    LabHomeComponent,
    LabProfileComponent,
    LabRolePageComponent,
    ResetPasswordComponent,
    SetPassComponent,
    LabUserOtpComponent,
    UserPrintComponent,
    UserPaymentDialougeComponent,
    
    
    // SelectDropDownModule

    
    ],
// MatDialogRef,
  providers: [AuthGuard,MatDialog,DatePipe,{provide:LocationStrategy,useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
