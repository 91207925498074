import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import{AuthService} from './../../services/auth.service';
import{ ActivatedRoute, Router} from '@angular/router';
declare var swal;
import { switchAll } from 'rxjs/operators';
declare var Razorpay: any; 

@Component({
  selector: 'app-labform',
  templateUrl: './labform.component.html',
  styleUrls: ['./labform.component.css']
})
export class LabformComponent implements OnInit {
  validations_form: FormGroup;
  questlist: any;
  listOfCountries: any;
  currency: any;
  price: number;
  constructor(private fb: FormBuilder,
    private router: Router, 
    // private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute) { 
      this.createForm();
      this.currency='INR';
      this.price=100;

    }

   ngOnInit(){
    this.countries();
    this.questions();
   }

   validation_messages = {
    
    'name': [
      { type: 'required', message: 'name is required.' },
      { type: 'minlength', message: 'name must be at least 1 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'lab_phone': [
      { type: 'required', message: 'phone_no is required.' },],
  
    'password': [
         { type: 'required', message: 'password is required.' }],
    'address': [
          { type: 'required', message: 'address is required.' },
        ],
  
        
        'country': [
          { type: 'required', message: 'country  is required.' }],
  
              'city': [
                { type: 'required', message: 'city  is required.' }],
                'state': [
                   { type: 'required', message: 'state  is required.' }],
                   'question'  : [{ type: 'required', message: 'select security question is required.' }],
                   'answer': [
                     { type: 'required', message: 'answer  is required.' },
                     { type: 'maxlength', message: 'answer cannot be more than 20 digits.' },
                     { type: 'minLength', message: 'answer cannot be less than 4 digits.' },
  
  
                   ],
                 
                  
  };
   createForm() {
    this.validations_form = this.fb.group({
      name: ['',Validators.required],
      lab_phone: ['',Validators.required],
      address: ['',Validators.required],
      city: ['',Validators.required],
      state: ['',Validators.required],
      password: ['',Validators.required],
      fundingSource:[''],
      payer_id: [''],
      country_code:[''],
      charges:[''],
      country:['', Validators.required],
      question:['',Validators.required],
      answer:['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(4),
      ])],


});
  
  }


  questions(){
    this.auth.quest().subscribe(res => {
      if (res.status == "success") {
         this.questlist=res.response 
      }
    },() => {
    });
   }
 
    labregister() {
      this.auth.lab(this.validations_form.value).subscribe(res=>{
        if(res.status=="success"){
            swal("lab saved succesfully");
            // this.router.navigate(['/login3'])
            this.router.navigate(['/new-lab'])
            .then(() => {
              location.reload();
            });
        } else {
          swal("Please enter valid details")
        }
      },(error)=>{
        swal("Server Error")
      })
   }

   razorPayOpen(){
    let options:any = {
        'key': 'rzp_test_pCNbE9R46AoFLA',
        'amount': this.price*100,
        'name': 'One Health',
        'description': 'Charges towards Registration',
        'image': '',
        'currency': this.currency,
        'notes': {},
        'prefill': {
            'name': sessionStorage.getItem('full_name'),
            'email': sessionStorage.getItem('email'),
            'contact': sessionStorage.getItem('phone_no')
        }
    };

   options.handler = ((response) => {
     // this.pay_id = response.razorpay_payment_id;

      this.validations_form.value.email_address='';
      let orderId = response.razorpay_payment_id;
     // let signature = payment_id.razorpay_signature;

      this.validations_form.value.payer_id=orderId;
      this.validations_form.value.country_code='IND';
      this.validations_form.value.purchase_units=this.currency;
      this.validations_form.value.fundingSource="RazorePay";
      this.labregister();

   });
   let rzp1 = new Razorpay(options);
   rzp1.open();
}

   keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
       event.preventDefault();
    }
  }
  countries(){
    this.auth.countryList().subscribe(res => {
      if (res.status == "success") {
         this.listOfCountries=res.response 
         console.log( 333,this.listOfCountries)
      }
    },() => {
    });
   }

}
