
import { Component, OnInit, Renderer } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MzModalService } from 'ngx-materialize';
import { ConformModelComponent } from '../pages/patient/conform-model/conform-model.component';
import { ViewimageComponent } from '../pages/viewimage/viewimage.component';
import { AuthService } from '../services/auth.service';
declare var swal;
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  // constructor() { 
  //   console.log(this.router.getCurrentNavigation().extras.state.example); // should log out 'bar'

  // }




  //

  result: any;
  imagesurl: any[];
  patient_id: any;
  images: string | Blob;
  userData: any;
  phone_no: string;
  // id:any='60ab502edfceee07fc90d995';
  // id:any='60abac8632a1191c9ce87532';
id:any;
  baseurl: string;
  text: string;
  button: string;
  labId: string;
  idddd: string;
  constructor(private router: Router,
    public dialog: MatDialog,
    // private formBuilder: FormBuilder,
    public form_val : FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService,
    private renderer: Renderer) { 
        // console.log(this.router.getCurrentNavigation().extras.state.example); 



       this.id=sessionStorage.getItem("id")

       this.idddd = sessionStorage.getItem("lab_id")

       console.log("", this.idddd)
      //  this.id="612cbf511b459a1adc5ab3f3"
      console.log(101,this.id);
      this.userData = sessionStorage.setItem("userData",JSON.stringify(this.userData));

      console.log(",", this.userData)


      if( sessionStorage.getItem("id")==" " || sessionStorage.getItem("id") == undefined){
        this.router.navigateByUrl('/new-lab');
      }

    }

    ngOnInit() {
      window.scrollTo(0,0);
      this.baseurl=this.auth.baseUrl;

      if(sessionStorage.getItem("doc_id")){
        this.text=document.getElementById('a').innerHTML="Upload Doctor Registration Documents"
        this.getimg1()
        this.button='docdelete'
      }

      if(sessionStorage.getItem("lab_id")){
        this.text=document.getElementById('a').innerHTML="Upload Lab Registration Documents"
        this.button='labdelete'
        this.getimg();
      }
      this.labId = sessionStorage.getItem("lab_id")
      console.log("this.labId",this.labId)

    }

    // new(i){
    //   window.open(this.baseurl+i)
    // }

    new(i){
      if(sessionStorage.getItem("doc_id")){
        // window.open(this.baseurl+i);
        window.open(this.baseurl+'/labdoc/fileinfo/'+i)
      }
      else {
        window.open(this.baseurl+'/labdoc/fileinfo/'+i)
      }
    }

    delImg(image){

      console.log(image);
      
      if(sessionStorage.getItem("doc_id")){
        this.auth.deleteimagesdoc(image,this.id).subscribe(res=>{
          console.log(res);
          
        },res=>{
          this.getimg1()
          swal('File Removed')
        })
      }
  
      else{
        this.auth.deleteimageslab(image,this.id).subscribe(res=>{
          console.log(res);
        },res=>{
          this.getimg()
          swal('File Removed')
        })

      }
    }
  //API To show Uploaded data
    getimg() {
      // location.reload();fileuplodlabgetimg
      
      console.log("",sessionStorage.getItem("lab_id"))

       this.auth.lab_user_gst({lab_id:sessionStorage.getItem("lab_id")}).subscribe(res => {
         console.log(137,sessionStorage.getItem("lab_id"))
         this.result=res['response'][0].docpath
         console.log(this.result);
         if(this.result.length==0){
          console.log('',545,this.a);
          this.a=false
         }
        }, () => {
         swal("Server failed");
   
       });
       
     }

     getimg1() {
      // location.reload();
      
       this.auth.fileuploddocgetimg(this.id).subscribe(res => {
         this.result=res.response[0].docpath
         console.log(this.result);
         if(this.result.length==0){
          console.log('',545,this.a);
          this.a=false
         }
        }, () => {
         swal("Server failed");
   
       });
     }

  // ngOnInit() {
  //   this.getimg(this.id)
  // }
  // getimg(id) {
  //   // location.reload();
  //   console.log('id::',  this.id)
  //    this.auth.getimages(id).subscribe(res => {
  //      this.result=res;
  //      if (this.result.status == "success") {
  //        this.result=this.result.response;
  //        this.imagesurl=[];
  //        for(let obj of this.result[0].imagPath)
  //        {
  //          console.log(sessionStorage.getItem('baseUrl')+obj);
  //          this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
  //        }
  //      }
  //     }, () => {
  //      swal("Server failed");
 
  //    });
  //  }


   selectfile(event){
    if(event.target.files.length>0){
      const file= event.target.files[0];
      this.images = file;
      console.log(this.images);
    }
  }

  logout(){
    this.router.navigateByUrl('/login')
  }


  //  confirmdeleat(id,url): void {
  //   const dialogRef = this.dialog.open(ConformModelComponent, {
  //     width: '80px',
  //     height:'80px'
      
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
   
  //     console.log(result.sts)
  //     if(result.sts=="yes")
  //     {
  //      this.deleteupload(id,url)
  //     }
   
  //   //  this.login();
  //   });
  // }

  
  view(img): void {
    const dialogRef = this.dialog.open(ViewimageComponent, {
      width: '100px',
      data:img

    });
    dialogRef.afterClosed().subscribe(result => {
   
      console.log(result.sts)
     
   
    //  this.login();
    });
  }

  
  a:Boolean;
  onSubmit(){
    const formData = new FormData();
    formData.append('file', this.images);
    formData.append('id', this.id);
    console.log('id:',this.id);

    if(sessionStorage.getItem("doc_id")){
      this.auth.fileuplodoc(this.id,formData).subscribe(res =>{

          console.log(res);
          
          this.images="";
          swal("File Uploaded successfully");
          // this.getimg(this.id);
          this.getimg1()
         
          this.images="";
          this.a=true;
        } ,
        //(err) => console.log(err) 
        ()=>{
          swal("File Uploaded successfully");
          this.a=true;
          this.getimg1()
          
        }
   );

    }

    if(sessionStorage.getItem("lab_id")){
      this.auth.fileuplod(this.id,formData).subscribe(
        (res) =>{
          this.images="";
          swal("File Uploaded Successfully");
          // this.getimg(this.id);
          this.getimg()
          console.log(res);
          
         
          this.images="";
          this.a=true;
        } ,
        // (err) => console.log(err) 
        ()=>{
          this.a=true;
          swal("File Uploaded Successfully");
          this.getimg()
        }
   );

 
    }

      //  location.reload()
       }
      
       onSubmi(){
         this.a
         
         if(sessionStorage.getItem("doc_id")){
          swal('Doctor Registration Completed Successfully');
          setTimeout(() => {
            // localStorage.clear();
            // sessionStorage.clear();
          //  window.location.reload();
           this.router.navigate(['/DoctorDataComponent']);
          
          });
         }


         if(sessionStorage.getItem("lab_id")){
          // swal('Lab Registration Completed Successfully');
          swal('You will receive an email with labid after approval');
          setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
    
           this.router.navigate(['/new-lab']);

           window.location.reload();
          });
         }


       
       }

       cancelUpload(){
        // "_id": "61c56d55aac55b1c206bf456",
        // "subject":"text",
        // "text":"test",
        //  "status":2

        if(sessionStorage.getItem("lab_id")){
          let id=this.id;
          let sub="This Mail is Regarding You Have Not Done With Step 2 Of Our Registration";
          let text="  Hello, Greetings, We Have Sent Your Lab Id Kindly Login With Your Admin Id And Password, Complete The Step 2 In Our Process";
          let status=2;
          this.auth.labRegStep2(id,sub,text,status).subscribe(res=>{
            console.log(res);

            swal
('An email has been forwarded with labID, Please use labid to login and complete the registration by uploading lab registration documents')
            setTimeout(() => {
              this.router.navigate(['/new-lab']);
            },3500 );
          })
        }

        else {
          this.router.navigate(['/login2']);
        }

      }

  //  deleteupload(id,url)
  //  {
  //    this.auth.getimgdeleate({_id:id,imagePath:url}).subscribe(res => {
  //      this.result=res;
  //      if (this.result.status == "success") {
  //        this.result=this.result.response;
  //        this.imagesurl=[];
  //        this.getimg(this.patient_id);
  //      }
  //     }, (err) => {
  //      swal("Server failed");
 
  //    });
  //  }


  //

}
