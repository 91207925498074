import { Injectable } from '@angular/core';
import{AuthService} from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(public auth: AuthService,public router:Router) {
  }
  canActivate(): boolean {
      let value = this.auth.isAuthenticated(); // <= from auth service
      if (!value) {
          this.router.navigateByUrl("/login");
      }
      return value;
  }
}
