import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
declare var swal;

@Component({
  selector: 'app-hospitalrating',
  templateUrl: './hospitalrating.component.html',
  styleUrls: ['./hospitalrating.component.css']
})
export class HospitalratingComponent implements OnInit {
  hospitalratingForm: FormGroup;
  hoslist: any;
  constructor(private fb: FormBuilder,
    private router: Router, 
   
    protected auth: AuthService,
    protected route: ActivatedRoute) {
      this.hospital()
     }
    
  ngOnInit() {
    this.createForm();
  }
  
  createForm() {
    this.hospitalratingForm = this.fb.group({
      hospital_name: ['', Validators.required],
      rating: ['',Validators.required],
      comments: ['',Validators.required]
    
});
  }
  save() {
 
    this.auth.hospitalrating(this.hospitalratingForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){
          this.auth.HospitalratingData.next(res.response);
         this.router.navigateByUrl("/medicaldetails");
          
        }
      }
    })
    swal("hospital rating entered successfully");
  }
  hospital(){

    this.auth.hospitalList().subscribe(res => {
      
      if (res.status == "success") {
     {
         this.hoslist=res.response 
        //  this.list();
     
        
     }}
     
    },
       
 
       () => {
        

     }
     );
   }
}