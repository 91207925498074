import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MzModalService } from 'ngx-materialize';
import { TestModelComponent } from 'src/app/pages/labModel/assignTest/test-model.component';
import { EditassignComponent } from 'src/app/pages/labModel/editassign/editassign.component';
import { LabresultUpdateComponent } from 'src/app/pages/labModel/labresult-update/labresult-update.component';
import { AuthService } from 'src/app/services/auth.service';
import { PrintComponent } from 'src/app/vedio/print/print.component';
declare var swal;

@Component({
  selector: 'app-user-process',
  templateUrl: './user-process.component.html',
  styleUrls: ['./user-process.component.css']
})
export class UserProcessComponent implements OnInit {

  userData: any;
  patient_id: string;
  name: string;
  phone_no: string;
  child_name: any;
  child: any;
  visits: any;
  patData: any;
  labData: string;
  result: any;
  test_res: string;
  searchText = '';
  years: any = [{ year: 2019 }, { year: 2020 }, { year: 2021 }, { year: 2022 }, { year: 2023 }, { year: 2024 }, { year: 2025 }, { year: 2026 }, { year: 2027 }, { year: 2028 }];
  searchOptions = {
    year: "",
    child_name: ""
  };

  chidData: Object;
  lablist: any;
  test_name: any;
  doc_date: any;
  reports: any;
  dateee: any;
  lab_id: any;
  config: any;
  email: string;
  resultres: any;
  response: any;
  private _id: string;
  patient_data: any;
  lab_name: string;
  user_data: string;
  city: string;
  userLabname: any;
  labAddressName: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private datePipe: DatePipe,
  ) {
    this.patient_id = sessionStorage.getItem("patient_id")
    this.phone_no = sessionStorage.getItem("phone_no")
    this.name = sessionStorage.getItem("full_name")
    this.email = sessionStorage.getItem("email");
    this._id = sessionStorage.getItem("_id");

    this.city = sessionStorage.getItem("lab_address");
    console.log("name****===>", this.city);

    this.lab_name = sessionStorage.getItem("lab_name");
    console.log("name****===>", this.lab_name);
    console.log("45454", this._id)
    this.lab_id = sessionStorage.getItem('lab_id');

    console.log("this.lab_id====>", this.lab_id)
    this.visits = "";
    this.dateee = (this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();


    this.user_data = sessionStorage.getItem("userData");
    console.log("this.user_data====>", this.user_data)

    //  if( sessionStorage.getItem("lab_phone")==" " || sessionStorage.getItem("lab_phone") == undefined){
    //   this.router.navigateByUrl('/login');
    // }
  }

  ngOnInit() {

    this.Labaddress();



    this.auth.testProcess({ lab_id: this.lab_id, status: 1, payment_status: 1, _id: this._id }).subscribe(res => {
      this.resultres = res;
      if (this.resultres.status == "success") {

        this.response = this.resultres.response;
        console.log("this.response", this.response)
        this.visits = res.response.reverse();
        this.config = {
          itemsPerPage: 3,
          currentPage: 1,
          totalItems: this.visits.count
        };
        console.log(22, this.visits);
        //console.log(res.json())
        //console.log({res.lab_id})
        // this.Contact_Name=res.response;
        // this.dataSource = new MatTableDataSource<PeriodicElement>(this.Contact_Names);
      }
    })



  }
  Labaddress() {
    this.auth.lab_user_gst({ lab_id: this.lab_id }).subscribe(res => {
      this.resultres = res;

      if (this.resultres.status == "success") {

        this.response = this.resultres.response[0].gst;
        this.userLabname = this.resultres.response[0].name;
        this.labAddressName = this.resultres.response[0].address;
        //  console.log(132,    this.userLabname );

        //  this.Gst= this.response.gst;
        //  console.log("testname===>", this.response.gst)
        //  console.log(this.response);
        //  this.Gst = this.response.gst;
        //  sessionStorage.setItem('gst',this.response);

        //  this.Gst = Number(sessionStorage.getItem("gst"));
        //  console.log("this.gst=====>", this.Gst)
        //  console.log('45454', this.response);
      }
    })
  }

  // a:Boolean=false;
  //   details(e) {

  // console.log("e  ==>  ",e)
  //     this.listbyid(e.test_name,e._id,e.status,e.docter_name,sessionStorage.getItem('name'),e.child_name,e.comment,e.date,e.timeStamp,e.gender,e.age,e.phone_no,e.patient_id,this.city);


  // }
  details(age, phone_no, child_name,
    date, docter_id, docter_name,
    gender, lab_id, lab_name, patient_id,
    patient_name, payment_status, ref_id,
    status, test_fee, timeStamp,
    year,
    p_id, tid, range, test_id, test_name) {


    console.log("e  ==>  ",
      age, phone_no, child_name,
      date, docter_id, docter_name,
      gender, lab_id, lab_name, patient_id,
      patient_name, payment_status,
      ref_id, status, test_fee, timeStamp,
      year, p_id,
      tid,
      range,
      test_id,
      test_name)

    // var testname = e.test_name;
    // var testnames = testname.map(item  => item.name);
    // console.log("testname", testnames);

    //this.listbyid(e.tid,a.phone_no);
    //this.listbyid(e.test_name,e._id,e.status,e.docter_name,sessionStorage.getItem('name'),e.child_name,e.comment,e.date,e.timeStamp,e.gender,e.age,e.phone_no,e.patient_id,this.city);
    this.listbyid(age,
      phone_no, child_name,
      date, docter_id, docter_name,
      gender, lab_id,
      lab_name,
      patient_id,
      patient_name,
      payment_status,
      ref_id, status,
      test_fee,
      timeStamp,
      year, p_id, tid,
      range, test_id, test_name);
    console.log("this.list===>", this.listbyid);
    console.log(168,
      age, phone_no, child_name,
      date, docter_id, docter_name,
      gender, lab_id, lab_name, patient_id,
      patient_name, payment_status, ref_id,
      status, test_fee, timeStamp, year, p_id,
      tid, range, test_id, test_name);


  }


  // listbyid(test_id,list_id,sts,docter_name,lab_name,child_name,comment,doc_date,timeStamp,gender,age,phone_no,patient_id,city)
  listbyid(age,
    phone_no,
    child_name,
    date,
    docter_id,
    docter_name,
    gender,
    lab_id,
    lab_name,
    patient_id,
    patient_name,
    payment_status,
    ref_id,
    status,
    test_fee,
    timeStamp,
    year,
    p_id,
    tid,
    range,
    test_id,
    test_name) {
    // this.labupdateForm .value.docter_id=this.docter_id;
  console.log(232,age,
    phone_no,
    child_name,
    date,
    docter_id,
    docter_name,
    gender,
    lab_id,
    lab_name,
    patient_id,
    patient_name,
    payment_status,
    ref_id,
    status,
    test_fee,
    timeStamp,
    year,
    p_id,
    tid,
    range,
    test_id,
    test_name);
    
    console.log("city==>", this.city);
    console.log("labAddressName===>", this.labAddressName);

    this.auth.testbyid({ test: [{ name: tid, range: range, _id: p_id }] }).subscribe(res => {
      if (res.status == "success") {
        if (res.response != "") {
          this.test_res = res.response;
          console.log("test_id", this.test_res)

          // this.router.navigate(['/LabresultUpdateComponent'],{ queryParams:{test_id:JSON.stringify(this.test_res),_id:list_id,sts:sts,"docter_name":docter_name,"lab_name":lab_name,"child_name":child_name,comment:comment,"doc_date":doc_date,"timeStamp":timeStamp}});
          console.log(185, { test_id: this.test_res, _id: p_id, sts: status, "docter_name": docter_name, "lab_name": this.userLabname, "child_name": child_name, "doc_date": date, "timeStamp": timeStamp, gender: gender, age: age, phone_no: phone_no, "patient_id": patient_id, "lab_city": this.labAddressName })
          this.view({
            test_id: this.test_res,
            _id: p_id, sts: status,
            "docter_name": docter_name,
            "lab_name": this.userLabname,
            "child_name": child_name,
            "doc_date": date,
            "timeStamp": timeStamp,
            gender: gender, age: age,
            phone_no: phone_no, "patient_id": patient_id,
            "lab_city": this.labAddressName
          });
          console.log("lab details=====>", this.view)
        }
      }
    }, () => {
      swal("Server failed, Server details not exits ");
    });
  }
  view(e) {
    const dialogRef = this.dialog.open(LabresultUpdateComponent, {
      width: '250px',
      data: e
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit()
    });

  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  sendtoApprovl(_id) {
    // if( this.test_res){


    this.auth.testUpdate({ lab_id: this.lab_id, status: 2, payment_status: 1, _id: _id, lab_name:this.userLabname,lab_city:this.labAddressName}).subscribe(res => {
      console.log("_id", this._id);
      // this.resultres = res;
      if (this.resultres.status == "success") {
        this.ngOnInit();
        // this.response=this.resultres.response;

        console.log("this.response", this.response)

      }
      //location.reload();
    }, () => {
      //  swal("server failed, server details not exits ");
    })

  }



  // }

  pageChanged(event) {
    this.config.currentPage = event;
  }
}
