
import { Component, OnInit, Input, EventEmitter, Optional, Inject } from '@angular/core';
import { MzBaseModal } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportViewComponent } from 'src/app/pages/report-view/report-view.component';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
import { ApproveComponent } from 'src/app/approve/approve.component';
declare var swal;
@Component({
  selector: 'app-user-print',
  templateUrl: './user-print.component.html',
  styleUrls: ['./user-print.component.css']
})
export class UserPrintComponent extends MzBaseModal implements OnInit {
  popup: any;
  result: any;
  testname: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  child_name: any;
  visits: any;
  parent: any;
  lab_phone: any;
  lab_id: any;
  lab_name: any;
  age: string;
  phone_no: any;
  sex: string;
  lab_city: any;
  test_date: any;
  doc_date: any;
  comment: any;
  docter_name: any;
  patient_name: any;
  sig: any;
  burl: string;
  resultres: any;
  response: any;
  private _id: string;
  patient_data: any;
  patient_id: string;
  email: string;
  name: string;
  dateee: any;
  read: any;
  status: any;
  imagesurl: any;
  id: any;
  sign: any;
  result1: any;
  personData: any;
  //imagePath: string;
  sigNew: any;
  arr: any;
  lab_user: any;
  //imagePath:any;
  names: any;
  imagePath: any;
  lab_user_phone: string;
  token: string;
  user_name: any;
  quesion: string;
  no: string;
  userSig: any;
  ss: any;
  userName: string;
  usersign: any;
  labsign: any;
  sin: string;
  name1: string;
  signature: any;
  userLabname: any;
  labAddressName: any;
  lab_name21: any;

  constructor(public dialogRef: MatDialogRef<UserPrintComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,

    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private datePipe: DatePipe,) {

    super();
    console.log("data got ====>", this.data);
    this.result = data.reports;
    this.lab_name21 = data.lab_name;
    console.log("878this.result==>", this.result);
    this.testname = data.testname;
    console.log("test====/.", this.testname)

    this.patient_name = data.patient_name;
    this._id = data._id;
    console.log("report_id=====>", this._id)
    // this.arr = data.arr;
    // console.log("imagePath====>", this.arr[0]);

    this.age = data.age;
    this.sex = data.gender;
    this.phone_no = data.phone_no;

    console.log("phone.no====.", this.phone_no)
    this.child_name = data.child_name;

    // this.imagePath=data.imagePath;
    // console.log("8989 this._id==>", this.imagePath);



    if (this.result[0]) {

    }
    console.log("Reports ..!", this.result);
    this.doc_date = data.doc_date;
    this.status = data.status;
    console.log("this.status ==>", this.status);

    this.test_date = data.date;

    this.docter_name = data.docter_name;

    this.parent = sessionStorage.getItem("full_name");

    //  this.lab_phone= sessionStorage.getItem("lab_phone");
    //  this.lab_id = sessionStorage.getItem("lab_id");
    this.lab_name = data.lab_name;
    console.log(128,this.lab_name)

    this.sin = data.sig;
    console.log("454654", this.sin)

    this.lab_city = data.lab_city;
    console.log(134, this.lab_city);
    

    this.burl = sessionStorage.getItem('baseUrl')
    console.log(this.burl)

    this.personData = JSON.parse(sessionStorage.getItem("userData"));
    console.log("454545", this.personData);

    this.lab_user_phone = sessionStorage.getItem("lab_user_phone");
    console.log("454545", this.lab_user_phone);

    this.email = sessionStorage.getItem("email");
    console.log("454545", this.email);



    this.token = sessionStorage.getItem("token");
    console.log("4545", this.token);

    this.user_name = sessionStorage.getItem("user_name");
    console.log("4545", this.user_name);

    this.quesion = sessionStorage.getItem("question");
    console.log("4545", this.quesion);

    this.lab_id = sessionStorage.getItem('lab_id');
    console.log("4545454", this.lab_id);
    this.lab_id = sessionStorage.getItem('user_name');
    console.log("4545454", this.lab_id);
    // this._id = sessionStorage.getItem("id");
    console.log("this._id =>", this._id)
    this.visits = "";
    this.dateee = (this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();
    //  this.personData= JSON.parse(sessionStorage.getItem("userData"));
    //  console.log("54545",this.personData);
    //  this.imagePath=sessionStorage.getItem("imagePath");
    //  console.log( "img ==> ",  sessionStorage.getItem("imagePath"))
    //  this.imagePath=this.imagePath;
    //  console.log(this.imagePath[0])

    this.lab_user = JSON.parse(sessionStorage.getItem("userData"));

    let imagePath = sessionStorage.getItem("imageUrl");
    console.log("454545", this.lab_user)

    console.log("78979", this.lab_user);
    this.lab_id = sessionStorage.getItem("lab_id");
    console.log("78979", this.lab_id);

    this.no = sessionStorage.getItem("id");
    console.log("78787", this.no)

    this.user_name = sessionStorage.getItem("user_name");
    console.log("78787", this.user_name)

    this.usersign = imagePath;


    console.log("first imagepath ==> ", this.usersign);

    if (imagePath !== undefined) {
      // this.labsign = this.auth.baseUrl + this.lab_user.imagePath ;
      this.labsign = imagePath;
    } else {
      this.labsign = this.lab_user.imageUrl;
    }

    console.log("labsign==> ", this.labsign); // Print the parameter to the console. 




    console.log("4454545", sessionStorage.getItem('user_sig'))

    // this.sin =this.labsign
  }

  ngOnInit() {
    // this.sigNew=this.imagePath;
    // console.log("89899==>" , this.sigNew);

    // this.sig=(this.auth.baseUrl+this.sig).trim();
    // console.log("89899==>" , this.sig);

    this.sig = (this.auth.baseUrl + this.ss).trim();
    console.log("89899==>", this.sig);

    // this.sig=(this.auth.baseUrl+this.arr[0]).trim();
    // this.sig=(this.auth.baseUrl+this.arr).trim();
    // console.log("89899==>" , this.sig);

    // this.getimg(this.id)


    //id:this.id
    // this.auth.sigimages({_id:this.no}).subscribe(res=>{
    //   let result: any=res;
    //   console.log("454545",result.response[0].imagPath[0]);

    //   this.imagePath = this.auth.baseUrl+result.response[0].imagPath[0]+".jpeg";
    //   console.log(this.imagePath)


    //   console.log("45454", this.imagePath);
    //   if (result.status == "success") {
    //     result= result.response;
    //     this.imagesurl=[];
    //     for(let obj of result[0].imagPath)
    //     {
    //        console.log("788", this.result)
    //       console.log("7878888",sessionStorage.getItem('baseUrl')+obj);
    //       this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
    //        this.imagesurl = [...new Set(this.imagesurl)];
    //       console.log("778787",this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj}))
    //     }
    //   }
    // })

    this.auth.sigimages({ _id: this.no }).subscribe(async (res) => {

      this.result1 = res;

      if (this.result1.status == "success") {
        this.result1 = this.result1.response;
        this.imagesurl = [];
        for (let obj of this.result1[0].imagPath) {
          console.log(sessionStorage.getItem('baseUrl') + obj);
          this.signature = (sessionStorage.getItem('baseUrl') + obj);
          await this.imagesurl.push({ imag: this.auth.baseUrl + obj, id: sessionStorage.getItem('id'), imagPath: obj });
        }
      }
      this.sign = this.result1[0].imagPath[0];

      console.log("9701758164,===>", this.signature)
      this.sign = (this.auth.baseUrl + this.sign).trim();
      console.log("Console Doc Images prescription...!", this.result1[0].imagPath[0]);
    }, () => {
      swal("Server failed");

    });
  }
labAdrress(){
  this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res =>{
    this.resultres = res;

      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response[0].gst;
       this.userLabname = this.resultres.response[0].name;
       this.labAddressName = this.resultres.response[0].address;
      //  console.log(132,    this.userLabname );
       
      //  this.Gst= this.response.gst;
      //  console.log("testname===>", this.response.gst)
      //  console.log(this.response);
      //  this.Gst = this.response.gst;
      //  sessionStorage.setItem('gst',this.response);

      //  this.Gst = Number(sessionStorage.getItem("gst"));
      //  console.log("this.gst=====>", this.Gst)
      //  console.log('45454', this.response);
      }
  })
}
  // 

  // getimg(labid) {
  //   // location.reload();


  // this.auth.sigimages({_id:labid}).subscribe(res => {
  //      console.log('hello');

  //      this.result=res;
  //      if (this.result.status == "success") {
  //        this.result=this.result.response;
  //        this.imagesurl=[];
  //        for(let obj of this.result[0].imagPath)
  //        {
  //          console.log(sessionStorage.getItem('baseUrl')+obj);
  //          this.imagesurl.push({imag:this.auth.baseUrl+obj,id:sessionStorage.getItem('id'),imagPath:obj});
  //        }
  //      }
  //     }, () => {
  //      swal("Server failed");

  //    });
  //  }





  close() {
    this.dialogRef.close();

  }
  approved() {


    if (this.signature == undefined) {
      swal("please upload a signature")
    }
    else {
      // this.sin=this.labsign
      // this.sin = this.imagePath
      this.sin = this.signature;
      this.name1 = this.user_name;

      swal({
        title: "Do you want to approve?",
        dangerMode: true,
        buttons: {
          confirm: "Yes",
          cancel: "No",
        },
        confirmButtonColor: "#DD6B55",

      })
        .then(confirm => {
          if (confirm === true) {
            swal("Approved Successfully");

            // let signature= this.sin;
            // var t1= signature.split("4200/");
            // var sigurl=t1[1]; 

  this.auth.testUpdate({ status: 3, payment_status: 1, _id: this._id, 
    imageUrl: this.signature, user_name: this.user_name, lab_name:this.lab_name21,lab_city:this.labAddressName
   }).subscribe(res => {
    console.log("dfjgh", this._id)
    //imagePath:this.imagePatlab_id:this.lab_id,h
      this.resultres = res;
      if (this.resultres.status == "success") {

      this.response = this.resultres.response;
         this.ngOnInit();
        console.log("this.response", this.response)
        }
       })
    //  setTimeout(() => {
    //    window.location.reload();
    //  }, 3000);

          }

          else {
            swal("Not approved !")
          }

        });



    }
  }
  //    Disapproved(){


  //     swal({
  //       title: "Do You Want Reject?",

  //       dangerMode: true,
  //       buttons: {
  //         cancel: true,
  //         confirm: true,
  //       }
  //       })
  //       .then(confirm => {
  //       if (confirm===true) {
  //         swal("Rejected Successfully");

  //         let dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy','en-IN')).toString();

  //         this.auth.editdoc({_id:this._id,test_date:dateee,status:4}).subscribe(res => {
  //           console.log("4545454", this._id);
  //           if (res.status == "success") {
  //             if (res.response != "") {
  //            this.read=res.response;

  //             }

  //           }
  //          location.reload()

  //         })



  //       }

  //       else{
  //         swal("Not Dissapproved !")
  //       }

  //       });




  // }

  Disapproved(text) {


    let dateee = (this.datePipe.transform(Date.now(), 'd-M-yyyy', 'en-IN')).toString();

    this.auth.editdoc({ _id: this._id, test_date: dateee, status: 4, test_use1: text }).subscribe(res => {
      console.log("4545454", this._id);
      console.log("Declined Text :", text);
      if (res.status == "success") {
        if (res.response != "") {
          this.read = res.response;

        }

      }
      location.reload()

    })


    // swal({
    //   title: "An input!",
    //   text: "Write something interesting:",
    //   content: "input",
    //   element:"input",
    //   showCancelButton: true,
    //   closeOnConfirm: false,
    //   inputPlaceholder: "Write something"
    // }) 
    // function (inputValue) {
    //   if (inputValue === false) return false;
    //   if (inputValue === "") {
    //     swal.showInputError("You need to write something!");
    //     return false
    //   }
    //   swal("Nice!", "You wrote: " + inputValue, "success");
    // });
    // swal({
    //   title: "Do You Want Reject?",
    //   input: 'text',
    //   dangerMode: true,
    //   buttons: {
    //     cancel: true,
    //     confirm: true,
    //   }
    //   })
    //   .then(confirm => {
    //   if (confirm===true) {

    //     swal("Rejected Successfully !")

    //     let dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy','en-IN')).toString();

    //     this.auth.editdoc({_id:this._id,test_date:dateee,status:4}).subscribe(res => {
    //       console.log("4545454", this._id);
    //       if (res.status == "success") {
    //         if (res.response != "") {
    //        this.read=res.response;

    //         }

    //       }
    //      location.reload()

    //     })



    //   }

    //   else{
    //     swal("Not Dissapproved !")
    //   }

    //   });




  }

  // upload(e){
  //   let dialogRef1 = this.dialog.open(ApproveComponent, {
  //     width:'600px',
  //   });
  //   dialogRef1.afterClosed().subscribe(result =>{
  //     if(result.sig!=0){
  //       let sig = result.sig;
  //       this.approvesig(sig,e);
  //     }
  //   });
  // }
  approvesig(sig, e) {
    this.auth.approvesig({ _id: e, approval: 1, imageUrl: sig }).subscribe(res => {
      this.response = res;
      if (this.response) {
        this.names = this.response.response;
        //location.reload();
        console.log(98, this.names)
      } else {
        swal("Server error")
      }
    })

  }
}