import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var swal;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public validations_form: FormGroup;
  relationForm: FormGroup;
  lab_id: string;
  phone_no: any;
  email: any;
  lab_user_phone: any;
  _id: any;
  userData: any;
  activatedRoute: any;
  id: any;

  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute) {
      this.relationForm = this.fb.group({
        lab_id: ['',Validators.required],
        // user_name: ['',Validators.required],
        // lab_user_phone: ['',Validators.required],
        email: ['',Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])],
        // phone_no: ['',Validators.required],
        // password: ['',Validators.required],
        // status: [true ,Validators.required],
        //  role:[[]]
      });
     }
  

  ngOnInit() {
    
  }
  validation_messages = {
    'lab_id': [
      { type: 'required', message: 'phone no is required.' },
    ],
  };
  add(relationForm){
    this.auth.lab_forgotPassword( this.relationForm.value).subscribe(res => {
      if(res.status == "Email Sent To User"){
        sessionStorage.setItem("lab_user_phone",res.data.lab_user_phone)
        sessionStorage.setItem("email",res.data.email)
        sessionStorage.setItem("lab_id",res.data.lab_id)

        swal({
          title: 'Email Sent to User',
          
      })
      .then(() => {
        this.router.navigateByUrl('/lab-user-otp');
      });
      } 
 
        else {
          (res.status === "email is not in database")
        
         swal("email is not in database");
        }
       
  

 
     });
      // this.router.navigateByUrl('/new-lab');
       
      } 
}
