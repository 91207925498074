import { Component, OnInit, Renderer } from '@angular/core';

import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import{ ActivatedRoute, Router} from '@angular/router';
import{AuthService} from './../../services/auth.service';

import { MzModalService } from 'ngx-materialize';
// import { EditpatientComponent } from '../editpatient/editpatient.component';
import { EditdoctorComponent } from '../editdoctor/editdoctor.component';
import { MatDialogModule, MatDialog} from '@angular/material/dialog';
import { PinModelComponent } from '../pin-model/pin-model.component';
import { ChangepassComponent } from 'src/app/changepass/changepass.component';
import { DocpinComponent } from './docpin/docpin.component';
//import { PatientUpdateIdComponent } from 'src/app/patient-update-id/patient-update-id.component';
import { SignatureDoctorComponent } from 'src/app/medicaldetails/signature-doctor/signature-doctor.component';

declare var swal;
@Component({
  selector: 'app-doctor-data',
  templateUrl: './doctor-data.component.html',
  styleUrls: ['./doctor-data.component.css']
})
export class DoctorDataComponent implements OnInit {
  name: any;
  patient_id: any;
  userData:any;
  display: string;
  docter_phone: any;
  phone_no: any;
  modals: any;
  pinno: any;
  resiltpin: any;
  docter_id;
  checkresult: any;
  restrickey: any;
  //pin: string;
  types: any;
  message: string;
  activatedRoute: any;
  activests:any
  onoffline:any={}
  sts: number;
  constructor(private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService)
    { 

      if( sessionStorage.getItem("docter_phone")==" " || sessionStorage.getItem("docter_phone") == undefined){
        this.router.navigateByUrl('/login2');
      }
    console.log( 23,this.userData);
    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    sessionStorage.setItem("zoom_name",this.userData.docter_name);
    this.patient_id= sessionStorage.getItem("patient_id");
    this.docter_phone= sessionStorage.getItem("docter_phone");
     this.types= sessionStorage.getItem("type");
     sessionStorage.setItem('doc_id',this.userData['docter_id'])
   // this.types = '1';
     console.log( 123,this.docter_phone);
     this.userData= this.userData;
     console.log( 123,this.userData.docter_id);

    console.log(this.userData);
    

     
      

 
    this.route.url.subscribe((url)=>{     
      this.activatedRoute = url;
    });
  }
  // ngOnInit() {
  // this.userData=JSON.parse(sessionStorage.getItem("userData"));

  // this.restric(this.patient_id, this.userData.docter_id);

  
    
  // }
  ngOnInit() {
    this.activests=0
    
      this.userData=JSON.parse(sessionStorage.getItem("userData"));
    
    console.log(sessionStorage.getItem('doc_id'));
    
    let a={docter_id:sessionStorage.getItem('doc_id')};
    setTimeout(() => {
      this.auth.doconlinestatus(a).subscribe(res=>{
        console.log(res);
        sessionStorage.setItem("editcharges",res['response'][0].charges)
        sessionStorage.setItem("finalcharges",res['response'][0].finalCharges)
        sessionStorage.setItem("onecharges",res['response'][0].onehealthcharges)
        sessionStorage.setItem("_cid",res['response'][0]._id)
        let b=res['response'][0]
        if(b){
        this.activests=1;
        
        this.onoffline._id=res['response'][0]._id
        
        console.log(this.onoffline);
        
        this.sts=res['response'][0].onlineStatus
        console.log(this.sts);
        }
        
        })
    },2000);
        
      }
  change()
  {
   if((this.phone_no).length>10){
this.message="Patient Id / User Id cannot be more than 10 digits."
   } 
   
       else{
        this.message='';
       }
  }
  
  Search(){
    
    this.auth.patientcheck({phone_no:this.phone_no}).subscribe(res => {
        if (res.response.length > 0 ) {
              if (res.response != "") {
                  this.name=res.response[0];
                  sessionStorage.setItem("full_name",this.name.full_name);
                  sessionStorage.setItem("patient_id",this.name.patient_id);
                  sessionStorage.setItem("age", this.name.age)
                  sessionStorage.setItem("phone_no",String(this.name.phone_no))
                  sessionStorage.setItem("sex",this.name.sex)
    
                  this.pinno=this.name.pin_no;
                  //this.restric(this.name.patient_id,this.userData.docter_id);
              }
          } else {
            swal("Please enter registered phone number")
          }
    }, () => {
         swal("Login failed, Login details not exits ");
     }
     );
   }

   pin()  {
    sessionStorage.setItem("ptypepop","0");
     let dialogRef = this.dialog.open(DocpinComponent, {
        width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      // if(result.pin==this.pinno) { 
      //             this.router.navigateByUrl('/patientsearch');
      //           }

      //           else{
      //           swal("pin not matched");
      //         }
    });
}
 
   openServiceModal(): void {
        const dialogRef = this.dialog.open(EditdoctorComponent, {
          width: '250px',
          position: {
            top: '0px',
            left: '0px'
          },
        });
        dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
       this.getdata();
       });
   }
  //Doctor Update
   getdata() {
        sessionStorage.setItem('token', "res.response.token");
        this.auth.getdoctor({docter_id:this.userData.docter_id}).subscribe(res => {
      
            if (res.status == "success") {
                    this.userData=res.response[0];
                    sessionStorage.setItem("userData",JSON.stringify(this.userData));
                    sessionStorage.setItem("patient_id",this.userData.patient_id);
                   
      
              }
           }, () => {
                this.auth.presentToast("Server failed");
          });
  }

  add(){
     this.router.navigateByUrl('/adddoctor');
   }
  doctor(){
    this.router.navigateByUrl('/doctorlist');
  } 

 // Check Patient Pin No in Search
//  pins() {
//   let dialogRef = this.dialog.open(DocpinComponent, {
//     height: '400px',
//     width: '600px',
//   });
//   dialogRef.afterClosed().subscribe(result => {
//         if(result.pin==this.pinno) { 
//           this.router.navigateByUrl('/patientsearch');
//         }
//         else{
//         swal("pin not matched");
//       }   
//     });
// }


  
  restric(patient_id,docter_id){
   // this.pin='0';
    this.auth.checkrest({patient_id:patient_id,docter_id:docter_id}).subscribe(res => {
      this.checkresult=res;
      if (this.checkresult.status == "success") {
                if (this.checkresult.response != '') {
                      this.restrickey=this.checkresult.response[0];
                    if(this.restrickey.restricted=="1") {
                          swal("you are restricted to see the patient details");
                    } else{
                          //this.pins();
                          sessionStorage.setItem("full_name",this.name.full_name);
                          sessionStorage.setItem("patient_id",this.name.patient_id);
                    }
              } else{
                  //this.pins();
              }
      }
    });
   }
  pins() {
    throw new Error('Method not implemented.');
  }
   
   changepass(): void {
    const dialogRef = this.dialog.open(ChangepassComponent, {
      width: '250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    // this.login();
    });
  }

  logo(){
    // localStorage.setItem('login','');
    localStorage.setItem('login2','');
    location.reload();
    this.auth.logout()
  }

  signature()  {
    let dialogRef = this.dialog.open(SignatureDoctorComponent, {
       width: '600px',
   });
   dialogRef.afterClosed().subscribe(result => {
 
   });
}



online(a){

  if(a==1){
    this.onoffline.onlineStatus=a
    this.auth.doconoffstatus(this.onoffline).subscribe(res=>{
    console.log(res);
    
    })
  swal('Status Changed to Online','','warning')
  console.log('online');
  this.ngOnInit()
  }

  if(a==0){
    this.onoffline.onlineStatus=a
    this.auth.doconoffstatus(this.onoffline).subscribe(res=>{
    console.log(res);
    
    })
  swal('Status Changed to Offline','','warning')
  console.log('offline');
  this.ngOnInit();
  }
  console.log(a);

 this.ngOnInit();

}
}
