import { Component, ElementRef, OnInit, Renderer, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
// import { SwPush, SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TermsServiceComponent } from '../terms-service/terms-service.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';

//import { CountryCode } from 'ngx-mat-intl-tel-input/lib/data/country-code';
declare var swal;
@Component({
  selector: 'app-lab-reg',
  templateUrl: './lab-reg.component.html',
  styleUrls: ['./lab-reg.component.css']
})
export class LabRegComponent implements OnInit {
  //PlanList : any = ['1 - 3 : 1000 Rs', '4 - 10 : 2000 Rs', '11 - 25 : 3000 Rs'];
  websiteList: any = ['1 - 3 : 1000 Rs', '4 - 10 : 2000 Rs', '11 - 25 : 3000 Rs']
  validations_form: FormGroup;
  questlist: any;
  listOfCountries: any;
  currency: any;
  price: number;
  lab_id: any;
  result: any;
  images: string | Blob;
  id: any;
  country: any = [];
  imagesurl: any[];
  userData: any;
  //phone_no: number;
  patient_id: any;
  imagePath : any;
  countryCode : any;
  myDropDown: string;
 plan:any;
 userName = '';


  //lab_id: any;
  @ViewChild('selectList', { static: false }) selectList: ElementRef;
  filteredArr: any;
  //lab_id: any;
  data: any;
  lab_phone: string;
  phone_no: any;
  response: any;
  test_lab: string;
  resLabid: any;
  constructor(private fb: FormBuilder,

    public http:HttpClient,//public swupdate:SwUpdate,public swpush:SwPush,

    public dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute) {
    this.createForm();
    // this.country = [{"id":"1","data-country":"India","desp":"+91 IN"},
    // {"id":"2","data-country":"India","desp":"+91 IN"}]
    
    this.country = [
      {
        "code": "+7 840",
        "name": "Abkhazia"
      },
      {
        "code": "+93",
        "name": "Afghanistan"
      },
      {
        "code": "+355",
        "name": "Albania"
      },
      {
        "code": "+213",
        "name": "Algeria"
      },
      {
        "code": "+1 684",
        "name": "American Samoa"
      },
      {
        "code": "+376",
        "name": "Andorra"
      },
      {
        "code": "+244",
        "name": "Angola"
      },
      {
        "code": "+1 264",
        "name": "Anguilla"
      },
      {
        "code": "+1 268",
        "name": "Antigua and Barbuda"
      },
      {
        "code": "+54",
        "name": "Argentina"
      },
      {
        "code": "+374",
        "name": "Armenia"
      },
      {
        "code": "+297",
        "name": "Aruba"
      },
      {
        "code": "+247",
        "name": "Ascension"
      },
      {
        "code": "+61",
        "name": "Australia"
      },
      {
        "code": "+672",
        "name": "Australian External Territories"
      },
      {
        "code": "+43",
        "name": "Austria"
      },
      {
        "code": "+994",
        "name": "Azerbaijan"
      },
      {
        "code": "+1 242",
        "name": "Bahamas"
      },
      {
        "code": "+973",
        "name": "Bahrain"
      },
      {
        "code": "+880",
        "name": "Bangladesh"
      },
      {
        "code": "+1 246",
        "name": "Barbados"
      },
      {
        "code": "+1 268",
        "name": "Barbuda"
      },
      {
        "code": "+375",
        "name": "Belarus"
      },
      {
        "code": "+32",
        "name": "Belgium"
      },
      {
        "code": "+501",
        "name": "Belize"
      },
      {
        "code": "+229",
        "name": "Benin"
      },
      {
        "code": "+1 441",
        "name": "Bermuda"
      },
      {
        "code": "+975",
        "name": "Bhutan"
      },
      {
        "code": "+591",
        "name": "Bolivia"
      },
      {
        "code": "+387",
        "name": "Bosnia and Herzegovina"
      },
      {
        "code": "+267",
        "name": "Botswana"
      },
      {
        "code": "+55",
        "name": "Brazil"
      },
      {
        "code": "+246",
        "name": "British Indian Ocean Territory"
      },
      {
        "code": "+1 284",
        "name": "British Virgin Islands"
      },
      {
        "code": "+673",
        "name": "Brunei"
      },
      {
        "code": "+359",
        "name": "Bulgaria"
      },
      {
        "code": "+226",
        "name": "Burkina Faso"
      },
      {
        "code": "+257",
        "name": "Burundi"
      },
      {
        "code": "+855",
        "name": "Cambodia"
      },
      {
        "code": "+237",
        "name": "Cameroon"
      },
      {
        "code": "+1",
        "name": "Canada"
      },
      {
        "code": "+238",
        "name": "Cape Verde"
      },
      {
        "code": "+ 345",
        "name": "Cayman Islands"
      },
      {
        "code": "+236",
        "name": "Central African Republic"
      },
      {
        "code": "+235",
        "name": "Chad"
      },
      {
        "code": "+56",
        "name": "Chile"
      },
      {
        "code": "+86",
        "name": "China"
      },
      {
        "code": "+61",
        "name": "Christmas Island"
      },
      {
        "code": "+61",
        "name": "Cocos-Keeling Islands"
      },
      {
        "code": "+57",
        "name": "Colombia"
      },
      {
        "code": "+269",
        "name": "Comoros"
      },
      {
        "code": "+242",
        "name": "Congo"
      },
      {
        "code": "+243",
        "name": "Congo, Dem. Rep. of (Zaire)"
      },
      {
        "code": "+682",
        "name": "Cook Islands"
      },
      {
        "code": "+506",
        "name": "Costa Rica"
      },
      {
        "code": "+385",
        "name": "Croatia"
      },
      {
        "code": "+53",
        "name": "Cuba"
      },
      {
        "code": "+599",
        "name": "Curacao"
      },
      {
        "code": "+537",
        "name": "Cyprus"
      },
      {
        "code": "+420",
        "name": "Czech Republic"
      },
      {
        "code": "+45",
        "name": "Denmark"
      },
      {
        "code": "+246",
        "name": "Diego Garcia"
      },
      {
        "code": "+253",
        "name": "Djibouti"
      },
      {
        "code": "+1 767",
        "name": "Dominica"
      },
      {
        "code": "+1 809",
        "name": "Dominican Republic"
      },
      {
        "code": "+670",
        "name": "East Timor"
      },
      {
        "code": "+56",
        "name": "Easter Island"
      },
      {
        "code": "+593",
        "name": "Ecuador"
      },
      {
        "code": "+20",
        "name": "Egypt"
      },
      {
        "code": "+503",
        "name": "El Salvador"
      },
      {
        "code": "+240",
        "name": "Equatorial Guinea"
      },
      {
        "code": "+291",
        "name": "Eritrea"
      },
      {
        "code": "+372",
        "name": "Estonia"
      },
      {
        "code": "+251",
        "name": "Ethiopia"
      },
      {
        "code": "+500",
        "name": "Falkland Islands"
      },
      {
        "code": "+298",
        "name": "Faroe Islands"
      },
      {
        "code": "+679",
        "name": "Fiji"
      },
      {
        "code": "+358",
        "name": "Finland"
      },
      {
        "code": "+33",
        "name": "France"
      },
      {
        "code": "+596",
        "name": "French Antilles"
      },
      {
        "code": "+594",
        "name": "French Guiana"
      },
      {
        "code": "+689",
        "name": "French Polynesia"
      },
      {
        "code": "+241",
        "name": "Gabon"
      },
      {
        "code": "+220",
        "name": "Gambia"
      },
      {
        "code": "+995",
        "name": "Georgia"
      },
      {
        "code": "+49",
        "name": "Germany"
      },
      {
        "code": "+233",
        "name": "Ghana"
      },
      {
        "code": "+350",
        "name": "Gibraltar"
      },
      {
        "code": "+30",
        "name": "Greece"
      },
      {
        "code": "+299",
        "name": "Greenland"
      },
      {
        "code": "+1 473",
        "name": "Grenada"
      },
      {
        "code": "+590",
        "name": "Guadeloupe"
      },
      {
        "code": "+1 671",
        "name": "Guam"
      },
      {
        "code": "+502",
        "name": "Guatemala"
      },
      {
        "code": "+224",
        "name": "Guinea"
      },
      {
        "code": "+245",
        "name": "Guinea-Bissau"
      },
      {
        "code": "+595",
        "name": "Guyana"
      },
      {
        "code": "+509",
        "name": "Haiti"
      },
      {
        "code": "+504",
        "name": "Honduras"
      },
      {
        "code": "+852",
        "name": "Hong Kong SAR China"
      },
      {
        "code": "+36",
        "name": "Hungary"
      },
      {
        "code": "+354",
        "name": "Iceland"
      },
      {
        "code": "+91",
        "name": "India"
      },
      {
        "code": "+62",
        "name": "Indonesia"
      },
      {
        "code": "+98",
        "name": "Iran"
      },
      {
        "code": "+964",
        "name": "Iraq"
      },
      {
        "code": "+353",
        "name": "Ireland"
      },
      {
        "code": "+972",
        "name": "Israel"
      },
      {
        "code": "+39",
        "name": "Italy"
      },
      {
        "code": "+225",
        "name": "Ivory Coast"
      },
      {
        "code": "+1 876",
        "name": "Jamaica"
      },
      {
        "code": "+81",
        "name": "Japan"
      },
      {
        "code": "+962",
        "name": "Jordan"
      },
      {
        "code": "+7 7",
        "name": "Kazakhstan"
      },
      {
        "code": "+254",
        "name": "Kenya"
      },
      {
        "code": "+686",
        "name": "Kiribati"
      },
      {
        "code": "+965",
        "name": "Kuwait"
      },
      {
        "code": "+996",
        "name": "Kyrgyzstan"
      },
      {
        "code": "+856",
        "name": "Laos"
      },
      {
        "code": "+371",
        "name": "Latvia"
      },
      {
        "code": "+961",
        "name": "Lebanon"
      },
      {
        "code": "+266",
        "name": "Lesotho"
      },
      {
        "code": "+231",
        "name": "Liberia"
      },
      {
        "code": "+218",
        "name": "Libya"
      },
      {
        "code": "+423",
        "name": "Liechtenstein"
      },
      {
        "code": "+370",
        "name": "Lithuania"
      },
      {
        "code": "+352",
        "name": "Luxembourg"
      },
      {
        "code": "+853",
        "name": "Macau SAR China"
      },
      {
        "code": "+389",
        "name": "Macedonia"
      },
      {
        "code": "+261",
        "name": "Madagascar"
      },
      {
        "code": "+265",
        "name": "Malawi"
      },
      {
        "code": "+60",
        "name": "Malaysia"
      },
      {
        "code": "+960",
        "name": "Maldives"
      },
      {
        "code": "+223",
        "name": "Mali"
      },
      {
        "code": "+356",
        "name": "Malta"
      },
      {
        "code": "+692",
        "name": "Marshall Islands"
      },
      {
        "code": "+596",
        "name": "Martinique"
      },
      {
        "code": "+222",
        "name": "Mauritania"
      },
      {
        "code": "+230",
        "name": "Mauritius"
      },
      {
        "code": "+262",
        "name": "Mayotte"
      },
      {
        "code": "+52",
        "name": "Mexico"
      },
      {
        "code": "+691",
        "name": "Micronesia"
      },
      {
        "code": "+1 808",
        "name": "Midway Island"
      },
      {
        "code": "+373",
        "name": "Moldova"
      },
      {
        "code": "+377",
        "name": "Monaco"
      },
      {
        "code": "+976",
        "name": "Mongolia"
      },
      {
        "code": "+382",
        "name": "Montenegro"
      },
      {
        "code": "+1664",
        "name": "Montserrat"
      },
      {
        "code": "+212",
        "name": "Morocco"
      },
      {
        "code": "+95",
        "name": "Myanmar"
      },
      {
        "code": "+264",
        "name": "Namibia"
      },
      {
        "code": "+674",
        "name": "Nauru"
      },
      {
        "code": "+977",
        "name": "Nepal"
      },
      {
        "code": "+31",
        "name": "Netherlands"
      },
      {
        "code": "+599",
        "name": "Netherlands Antilles"
      },
      {
        "code": "+1 869",
        "name": "Nevis"
      },
      {
        "code": "+687",
        "name": "New Caledonia"
      },
      {
        "code": "+64",
        "name": "New Zealand"
      },
      {
        "code": "+505",
        "name": "Nicaragua"
      },
      {
        "code": "+227",
        "name": "Niger"
      },
      {
        "code": "+234",
        "name": "Nigeria"
      },
      {
        "code": "+683",
        "name": "Niue"
      },
      {
        "code": "+672",
        "name": "Norfolk Island"
      },
      {
        "code": "+850",
        "name": "North Korea"
      },
      {
        "code": "+1 670",
        "name": "Northern Mariana Islands"
      },
      {
        "code": "+47",
        "name": "Norway"
      },
      {
        "code": "+968",
        "name": "Oman"
      },
      {
        "code": "+92",
        "name": "Pakistan"
      },
      {
        "code": "+680",
        "name": "Palau"
      },
      {
        "code": "+970",
        "name": "Palestinian Territory"
      },
      {
        "code": "+507",
        "name": "Panama"
      },
      {
        "code": "+675",
        "name": "Papua New Guinea"
      },
      {
        "code": "+595",
        "name": "Paraguay"
      },
      {
        "code": "+51",
        "name": "Peru"
      },
      {
        "code": "+63",
        "name": "Philippines"
      },
      {
        "code": "+48",
        "name": "Poland"
      },
      {
        "code": "+351",
        "name": "Portugal"
      },
      {
        "code": "+1 787",
        "name": "Puerto Rico"
      },
      {
        "code": "+974",
        "name": "Qatar"
      },
      {
        "code": "+262",
        "name": "Reunion"
      },
      {
        "code": "+40",
        "name": "Romania"
      },
      {
        "code": "+7",
        "name": "Russia"
      },
      {
        "code": "+250",
        "name": "Rwanda"
      },
      {
        "code": "+685",
        "name": "Samoa"
      },
      {
        "code": "+378",
        "name": "San Marino"
      },
      {
        "code": "+966",
        "name": "Saudi Arabia"
      },
      {
        "code": "+221",
        "name": "Senegal"
      },
      {
        "code": "+381",
        "name": "Serbia"
      },
      {
        "code": "+248",
        "name": "Seychelles"
      },
      {
        "code": "+232",
        "name": "Sierra Leone"
      },
      {
        "code": "+65",
        "name": "Singapore"
      },
      {
        "code": "+421",
        "name": "Slovakia"
      },
      {
        "code": "+386",
        "name": "Slovenia"
      },
      {
        "code": "+677",
        "name": "Solomon Islands"
      },
      {
        "code": "+27",
        "name": "South Africa"
      },
      {
        "code": "+500",
        "name": "South Georgia and the South Sandwich Islands"
      },
      {
        "code": "+82",
        "name": "South Korea"
      },
      {
        "code": "+34",
        "name": "Spain"
      },
      {
        "code": "+94",
        "name": "Sri Lanka"
      },
      {
        "code": "+249",
        "name": "Sudan"
      },
      {
        "code": "+597",
        "name": "Suriname"
      },
      {
        "code": "+268",
        "name": "Swaziland"
      },
      {
        "code": "+46",
        "name": "Sweden"
      },
      {
        "code": "+41",
        "name": "Switzerland"
      },
      {
        "code": "+963",
        "name": "Syria"
      },
      {
        "code": "+886",
        "name": "Taiwan"
      },
      {
        "code": "+992",
        "name": "Tajikistan"
      },
      {
        "code": "+255",
        "name": "Tanzania"
      },
      {
        "code": "+66",
        "name": "Thailand"
      },
      {
        "code": "+670",
        "name": "Timor Leste"
      },
      {
        "code": "+228",
        "name": "Togo"
      },
      {
        "code": "+690",
        "name": "Tokelau"
      },
      {
        "code": "+676",
        "name": "Tonga"
      },
      {
        "code": "+1 868",
        "name": "Trinidad and Tobago"
      },
      {
        "code": "+216",
        "name": "Tunisia"
      },
      {
        "code": "+90",
        "name": "Turkey"
      },
      {
        "code": "+993",
        "name": "Turkmenistan"
      },
      {
        "code": "+1 649",
        "name": "Turks and Caicos Islands"
      },
      {
        "code": "+688",
        "name": "Tuvalu"
      },
      {
        "code": "+1 340",
        "name": "U.S. Virgin Islands"
      },
      {
        "code": "+256",
        "name": "Uganda"
      },
      {
        "code": "+380",
        "name": "Ukraine"
      },
      {
        "code": "+971",
        "name": "United Arab Emirates"
      },
      {
        "code": "+44",
        "name": "United Kingdom"
      },
      {
        "code": "+1",
        "name": "United States"
      },
      {
        "code": "+598",
        "name": "Uruguay"
      },
      {
        "code": "+998",
        "name": "Uzbekistan"
      },
      {
        "code": "+678",
        "name": "Vanuatu"
      },
      {
        "code": "+58",
        "name": "Venezuela"
      },
      {
        "code": "+84",
        "name": "Vietnam"
      },
      {
        "code": "+1 808",
        "name": "Wake Island"
      },
      {
        "code": "+681",
        "name": "Wallis and Futuna"
      },
      {
        "code": "+967",
        "name": "Yemen"
      },
      {
        "code": "+260",
        "name": "Zambia"
      },
      {
        "code": "+255",
        "name": "Zanzibar"
      },
      {
        "code": "+263",
        "name": "Zimbabwe"
      }
    ]
    
  }


  
  
  a={
    country:"",
    labname:"",
    contact:"",
    email:"",
    city:"",
    address:"",
    state:"",
    phone_no:"",
   
   }; 


   push:object={

   }
   
   selectImage(){}
   onSubmit(){}
   myClick(){}
   
   
   
     keyup(a:any){
   console.log(this.a);
   this.auth.plan2(this.a);
   
     }

  ngOnInit() {
   
    this.lab_id='';
    this.countries();
    this.questions();

   console.log( this.plan = this.auth.c);

   swal("You Have Selected - ",this.plan);

   console.log(this.plan);


   console.log(this.auth.b);

   this.a=this.auth.b
 
}

  createForm() {
    this.validations_form = this.fb.group({
      //lab_id: ['', Validators.required],
      name:['' ,Validators.required],
      //lab_name:['' ,Validators.required],
      //name:['', Validators.required],
      contact_name:['',Validators.required],
      lab_phone: ['', Validators.required],
      plan : ['',Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      fundingSource: [''],
      payer_id: [''],
      country_code: [''],
      phone_no: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(5)])],
      charges: [''],
      gst: 0,
      payment_status : false,
      select_plan : ['',Validators.required],
      check:['', Validators.required],
      country: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(4),
      ])],
      status:0

    });
  }
  
  openDialog() {
    const dialogRef = this.dialog.open(TermsServiceComponent, {
      panelClass: 'my-custom-dialog-class'
    });
    
  }

  validation_messages = {
   
    'docter_name': [
      { type: 'required', message: 'name is required.' }      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'languages': [
      { type: 'required', message: 'Languages is required.' }      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'age': [
      { type: 'required', message: 'age is required.' },],

    'password': [
       { type: 'required', message: 'password is required.' }],
    'date_of_birth': [
          { type: 'required', message: 'date_of_birth is required.' },
        ],
        'email': [
          { type: 'required', message: 'email id is required.' },
          { type: 'pattern', message: 'please enter valid email' }],

           'speciality': [
            { type: 'required', message: 'speciality is required.' }
           ],
           'phone_no': [
            // { type: 'required', message: 'phone number is required.' },
            { type: 'maxlength', message: 'phone number cannot be more than 10 digits.' },
                        { type: 'minlength', message: 'phone number cannot be less than 5 digits.' },
           ],
           
    'clinic_address': [
              { type: 'required', message: 'address  is required.' }],
              'city': [
                { type: 'required', message: 'city  is required.' }],
                'state': [
                   { type: 'required', message: 'state  is required.' }],

                   'country': [
                    { type: 'required', message: 'country  is required.' }],

                      'hospital': [
                    { type: 'required', message: 'hospital  is required.' }],
                    'sex': [
                      { type: 'required', message: 'gender  is required.' }],
                      'question'  : [{ type: 'required', message: 'select security question is required.' }],
                      // 'zoom_id'  : [{ type: 'required', message: 'zoom id is required.' }],

                      
                      'answer': [
                        { type: 'required', message: 'answer  is required.' },
                        { type: 'maxlength', message: 'answer cannot be more than 20 digits.' },
                        { type: 'minlength', message: 'answer cannot be less than 4 digits.' },
    
    
                      ],
                  
  };
  privacyPolicy(){
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, {
      panelClass: 'my-custom-dialog-class'
    });
  }
  createlabForm() {
    this.validations_form = this.fb.group({
      // patient_id: ['', Validators.required],
      name:['', Validators.required],
      //lab_name:['' ,Validators.required],
      //lab_id: ['', Validators.required],
      contact_name:['',Validators.required],
      full_name: ['', Validators.required],
      plan : [this.plan,Validators.required],
      date_of_birth: [''],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      address: [''],
      city: ['',],
      state: [''],
      sex: ['', Validators.required],
      age: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(3),
        Validators.minLength(1),
      ])],
      phone_no: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(5)])],
      password: ['', Validators.required],
      question: [''],
      answer: [''],
      gst: 0,
      payment_status : 0,
      country: ['', Validators.required],

      check:['', Validators.required],
      pin_no: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(4),
        Validators.minLength(4),
      ])],
      status:0,
      insurance: [false]
    });
  }


  questions() {
    this.auth.quest().subscribe(res => {
      if (res.status == "success") {
        this.questlist = res.response
      }
    }, () => {
    });
  }












  testid:any

readonly vapid='BJmJEEosahpnHmStQEZFsciTSVsU6Afgh5ujhp5KfmaODAlG83vnWdTBdpDkKdDaVbXIOhX5hSeDpg4OixYyRaM'
// public notif='http://localhost:2998/sub';

  labregister() {


    console.log(1246, this.validations_form.value.plan)

    if(this.validations_form.value.plan==''){
      swal("Please Select Your Plan")
    }
    else if(this.validations_form.value.name=="" || this.validations_form.value.name== null){
      swal("Please Enter Lab name")
    }
    else if(this.validations_form.value.contact_name=="" || this.validations_form.value.contact_name== null){
      swal("Please Enter Contact name")
    }
    else if(this.validations_form.value.email=="" || this.validations_form.value.email== null){
      swal("Please Enter Email")
    }
    else if(this.validations_form.value.phone_no==""|| this.validations_form.value.phone_no==null){
      swal("Please Enter Phone number")
    }
    else if(this.validations_form.value.city==""|| this.validations_form.value.city==null){
      swal("Please Enter City")
    }
    else if(this.validations_form.value.lab_phone==""|| this.validations_form.value.lab_phone==null){
      swal("Please Enter Admin Id")
    }
    else if(this.validations_form.value.address==""|| this.validations_form.value.address==null){
      swal("Please Enter Address")
    }
    else if(this.validations_form.value.state==""|| this.validations_form.value.state==null){
      swal("Please Enter state")
    }
      else if(this.validations_form.value.country=="" ||this.validations_form.value.country== null) {
        swal("Please Select Country")
      }
      else if(this.validations_form.value.question=="" ||this.validations_form.value.question== null) {
        swal("Please Select Security Question")
      }
      else if(this.validations_form.value.password=="" ||this.validations_form.value.password== null)
    
      swal("Please enter admin password")
      else{
      this.auth.lab(this.validations_form.value).subscribe(res => {
     
        if (res.status == "success") {
          this.response = res.response
          console.log(1287,this.response)
          this.resLabid = this.response.lab_id

          console.log("name-==>", this.resLabid)
          sessionStorage.setItem("lab_id",this.response.lab_id)
          this.test_lab=sessionStorage.getItem('lab_id')
console.log(1288, this.test_lab)

          //sessionStorage.setItem("email",res.email)
          // swal("Registration Completed Successfully !!");
          // this.router.navigate(['/login3'])
  
         
          // this.router.navigate(['/new-lab'])
          // .then(() => {
          //   // location.reload();
          //   this.ngOnInit
          // });
          sessionStorage.setItem('id',res.response['_id']);
          this.testid=sessionStorage.getItem('id')
          console.log(sessionStorage.getItem('id'));
          console.log(this.testid,'ck');
         
         
         //sessionStorage.setItem("email",res.email)
        //swal("Your Details are Registered With Us! Kindly Upload Documents To Complete the Process");
        //setTimeout(() => {
          this.router.navigate(['/file-upload'], { state: { example: 'response' } });

          //this.router.navigate(['/file-upload'])
        //},3500 );
         // this.router.navigate(['/login3'])
        //  this.router.navigate(['/file-upload'])
        //  .then(() => {
        //     location.reload();
        //  });
  
          // let email={email:this.validations_form.value.email}
          // console.log(email);
  
  
  
  
  
          // var status={
          //   state:1,
          //   labid:res._id,
          //   email:this.validations_form.value.email
          // };
          
          // // location.reload()
          // this.http.post('http://localhost:2998/notif',status).subscribe(res=>{
          //   console.log(res);
            
          // })
  
          var status={
            state:1,
            labid:this.validations_form.value.lab_phone,
            email:this.validations_form.value.email
          };
          
          // location.reload()
          this.http.post(this.auth.baseUrl+'/notify/notif',status).subscribe(res=>{
          console.log(res);
            
          })
  
  
  
  
  
  
  
  
          //this.auth.notif(1);
  
  
  
  
       //var sub:PushSubscription;
  // if (this.swpush.isEnabled) {
  //   this.swpush.notificationClicks.subscribe(
  //       event => {
  //           window.focus();
  //           window.open(event.notification.data.url, '_self');
  //       },
  //       error => {
  //           // handle error
  //       }
  //   );
  // }
  
  
  // if(this.swupdate.isEnabled){
  //   this.swpush.requestSubscription({
  //     serverPublicKey:this.vapid
  //   }).then(sub =>{
      
      
  //     console.log(JSON.stringify(sub))
  //     this.http.post(this.notif,sub).pipe().subscribe() 
    
  //   }).catch(err=>console.log(err) )
  // }
  
  
        } 
        
        else {
          swal("Please enter valid details")
        }
  
  
  
      }, (error) => {
        swal("Server Error")
      })
    }
   
// let email={email:this.validations_form.value.email}
// console.log(email);

//     this.http.post('http://localhost:2998/notif',email).subscribe(res=>{
//   console.log(res);
  
// })



///terms nd conditions

  }















  countries() {
    this.auth.countryList().subscribe(res => {
      if (res.status == "success") {
        this.listOfCountries = res.response
        console.log(333, this.listOfCountries)
      }
    }, () => {
    });
  }


   
  logout(){
    this.router.navigateByUrl('/login');
  }



   country_change(e){
     this.countryCode = e.target.value;
     console.log(1008, e.target.value);
}

filterItem(event) {
  if (!event) {
    this.country = this.country;
  } // when nothing has typed*/   
  if (typeof event === 'string') {
    console.log(event);
    let country=this.country;
    this.filteredArr = country.filter(a => a.toLowerCase()
      .startsWith(event.toLowerCase()));
      this.country =  this.country.filter(function(country) {
        return country.code == +91;
      });
  }
  console.log(this.country.length);
  this.selectList.nativeElement.size = this.country.length + 1;
}


onChangeofOptions(newGov) {
  console.log(newGov);
}
openDisclaimar(){
  const dialogRef = this.dialog.open(DisclaimerComponent, {
    panelClass: 'my-custom-dialog-class'
  });
}
// changePlan(e){
//   console.log(98989,e.target.value);
// }





// changeWebsite(e) {
//   console.log(e.target.value);
// }











// counter:any
//  counter=0
// app(){
// //     let sub:PushSubscription;

// //   //let sub:{"endpoint":"https://sg2p.notify.windows.com/w/?token=BQYAAAABTC3zERJjVACaxfVw3Cs9b0FfQy9JDdsHDBhkrA3ZWdVLChWNLGUWb3kSPH%2f2NJ%2bRdL2%2f533AQhW4E5vbbHESdMryYslsZhYFvFIA2mkgdZhR9wjijFp2t7uHhb36pTzmzGOXWPyr7Td5onBCzBNUcgcNlVi70lKNP1JDPlHFJvTHJO1%2b8vYXyV%2byHn5N4WKsDgZtGWTtsTtdE7M3UcvrnOzQ4C4kX5n7kfYVaAe8t75ax6GQt1dDt7wago0qia%2b%2bYf5a4RrGgIQDoMaShYOxWts3lV%2fb86Kunfvm70c8MquJgUdk1MvXvHxXUkMAsDn6657GIZ6W%2brqbZqzTO3v6","expirationTime":null,"keys":{"p256dh":"BFY6V_QTdmvCMwTQgy0ARyozz4Nr-WYQhtG4rMLP7a_xws6njx8jy4D2-38t7jY2e4j7b52klEwUT1gixV_-bcE","auth":"sX27AJLreaEKHAz817PQ1A"}}
// //   if (this.swpush.isEnabled) {
// //     this.swpush.notificationClicks.subscribe(
// //         event => {
// //             window.focus();
// //             window.open(event.notification.data.url, '_self');
// //         },
// //         error => {
// //             // handle error
// //         }
// //     );
// //   }
  
  
// //   if(this.swupdate.isEnabled){
// //     this.swpush.requestSubscription({
// //       serverPublicKey:this.vapid
// //     }).then(sub =>{
 
// //       console.log(JSON.stringify(sub))
// //       this.http.post(this.notif,sub).pipe().subscribe() 
    
// //     }).catch(err=>console.log(err) )
// //   }


// var status={
//   state:1,
//   labid:this.validations_form.value.lab_phone,
//   email:this.validations_form.value.email
// };

// // location.reload()
// this.http.post('http://localhost:2998/notif',status).subscribe(res=>{
//   console.log(res);
  
// })



// // var status={
// //   state:1
// // };

// // // location.reload()
// // this.http.post('http://localhost:2998/notif',status).subscribe(res=>{
// //   console.log(res);
  
// // })


// this.counter=this.counter+1
// console.log(this.counter);

// // this.auth.notif(this.counter);
// // this.ngOnInit


// // this.auth.notif(1);
// // this.ngOnInit




//   }







  }
  


// swal({
//   title: "Success",
//   text: "One health has received your registration request.Approval request is processed within 24 hours.",
//   timer: 4000,
//   showConfirmButton: false,
// });
// }




