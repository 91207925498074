import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators,FormControl } from '@angular/forms';
import{ ActivatedRoute, Router} from '@angular/router';
import { AuthService } from '../services/auth.service';
//import{AuthService} from './../../services/auth.service';
declare var swal: any;

@Component({
  selector: 'app-unregistration',
  templateUrl: './unregistration.component.html',
  styleUrls: ['./unregistration.component.css']
})
export class UnregistrationComponent implements OnInit {

  validations_form: FormGroup;
  patientData:any;
  patient:any
  docter_name: any;
  age;
  showAge;
  questlist: any;
  listOfCountries: any;
  email: string;
  constructor(private fb: FormBuilder,
            protected auth: AuthService,
            protected route: ActivatedRoute,
            private router: Router, 
    ) {
      
      this.questions();
      this.countries();
    }
      
  ngOnInit() {
    if(sessionStorage.getItem("ptype")=='3'){
        this.createlabForm();
    }
else{

     this.createForm();
    }
  }
  
  createForm() {
    this.validations_form = this.fb.group({
     // patient_id: ['', Validators.required],
     full_name: ['',Validators.required],
    date_of_birth: ['',Validators.required],
    email: ['',Validators.compose([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ])],
    address: ['',Validators.required],
    city: ['',Validators.required],
    state:['', Validators.required],
    sex:['',Validators.required],
    age:[''],
    phone_no:['',Validators.required],
    password:['',Validators.required],
    question:['', Validators.required],
    answer:['', Validators.compose([
      Validators.required,
      Validators.maxLength(20),
      Validators.minLength(4),
    ])],


    country:['',Validators.required],
    pin_no:['', Validators.compose([
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ])],
    insurance: [false]
    });
  }


  createlabForm() {
    this.validations_form = this.fb.group({
     // patient_id: ['', Validators.required],
     full_name: ['',Validators.required],
    date_of_birth: ['',Validators.required],
    email: ['',Validators.compose([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
    ])],
    address: ['',Validators.required],
    city: ['',Validators.required],
    state:['',Validators.required],
    sex:['',Validators.required],
    age:[''],
  phone_no:['', Validators.compose([
    Validators.required,
    Validators.maxLength(10),
    Validators.minLength(5),
  ])],
    password:['',Validators.required],
    question:['',Validators.required],
    answer:['',Validators.required],


    country:['',Validators.required],
    pin_no:['', Validators.compose([
      Validators.required,
      Validators.maxLength(4),
      Validators.minLength(4),
    ])],
    insurance: [false]
    });
  }


  validation_messages = {
    'insurance': [
      // { type: 'required', message: 'upc is required.' },
      { type: 'minlength', message: 'insurence must be at least 1 characters long.' },
      { type: 'maxlength', message: 'insurence cannot be more than 20 characters long.' },
     // { type: 'validUsername', message: 'Your insurence has already been taken.' }
    ],
    'full_name': [
      { type: 'required', message: 'Name is required.' },
      { type: 'minlength', message: 'Name must be at least 1 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    
      'sex': [
        { type: 'required', message: 'Gender is required.' },],

        'email': [
          { type: 'required', message: 'Email id is required.' },
          { type: 'pattern', message: 'Please enter valid email' }],

    'date_of_birth': [
          { type: 'required', message: 'Date Of Birth is required.' },
        ],
  
    'address': [
              { type: 'required', message: 'Address  is required.' }],

              'city': [
                { type: 'required', message: 'City  is required.' }],

                'state': [
                   { type: 'required', message: 'State  is required.' }],

                   'country': [
                    { type: 'required', message: 'Country  is required.' }],

                   'phone_no': [
                    { type: 'required', message: 'Phone No  is required.' },
                    { type: 'maxlength', message: 'Phone No cannot be more than 10 digits.' },
                    { type: 'minLength', message: 'Phone No cannot be less than 5 digits.' },
                  
                  
                  
                  ],

                    'password': [
                      { type: 'required', message: 'Password  is required.' }],
                      'pin_no': [
                        { type: 'required', message: 'Pin_no  is required.' },
                        { type: 'maxlength', message: 'pin_no cannot be more than 4 digits.' },
                        { type: 'minLength', message: 'pin_no cannot be less than 4 digits.' },
    
    
                      ],

                      'question'  : [{ type: 'required', message: 'Select Security Question Is Required.' }],
                      'answer': [
                        { type: 'required', message: 'answer  is required.' },
                        { type: 'maxlength', message: 'answer cannot be more than 20 digits.' },
                        { type: 'minLength', message: 'answer cannot be less than 4 digits.' },
    
    
                      ],
                      'age': [
                        { type: 'required', message: 'age  is required.' },
                        { type: 'maxlength', message: 'age cannot be more than 1 digits.' },
                        { type: 'minLength', message: 'age cannot be less than 3 digits.' },
    
    
                      ],
                  
  };

  regester() {

    if(this.age){
      const convertAge = new Date(this.age);
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365);


      console.log("55555", this.showAge)


    }
  
    console.log("age====>", this.validations_form.value.age)
  this.validations_form.value.age= this.showAge;

  console.log("agedefine======>", this.validations_form.value.age)
  this.auth.newpatient(this.validations_form.value).subscribe(res=>{
    sessionStorage.setItem("email", this.email);
    if(res.status=="success"){
      
        swal("Patient saved successfully")
        this.auth.NewpatientData.next(res.response);
      if(sessionStorage.getItem("ptype")=='3'){
       
        sessionStorage.setItem("ptypepop",'3');
       console.log(8888, sessionStorage.setItem("ptypepop",'3'))
        // this.router.navigateByUrl('/Labinformation');
        this.router.navigateByUrl('/lab-home');
       

      }
      
      else{
        this.router.navigateByUrl('/login');

      }
     
    }
    else{
        
    }
  }, (error)=>{
    console.log(343,error);
    swal("user already register");
  })

}
 
questions(){
  this.auth.quest().subscribe(res => {
    if (res.status == "success") {
       this.questlist=res.response 
    }
  },() => {
  });
 }


countries(){
  this.auth.countryList().subscribe(res => {
    if (res.status == "success") {
       this.listOfCountries=res.response 
       console.log( 333,this.listOfCountries)
    }
  },() => {
  });
 }
 myClick(){

 }
 logout(){
   
 }
}

