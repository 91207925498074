import { Component, OnInit, Renderer, Input, EventEmitter, Inject } from '@angular/core';
import { MzBaseModal, MzModalService } from 'ngx-materialize';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { title } from 'process';
import { Router, ActivatedRoute } from '@angular/router';
declare var swal;
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-editpatient',
  templateUrl: './editpatient.component.html',
  styleUrls: ['./editpatient.component.css']
})
export class EditpatientComponent extends MzBaseModal  implements OnInit{
  
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  userData: any;
  full_name: string;
  patient_id: string;
  validations_form:FormGroup;
  modalCtrl: any;
  sex:any;
  pin_no:any;
  listOfCountries: any;
  questlist: any;

  showAge1: number;
  age;
  showAge;
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<EditpatientComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,) {
    super();
    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    this.patient_id=sessionStorage.getItem("patient_id");
    this.sex = this.userData.sex;
    this.pin_no = this.userData.pin_no;
    console.log("22",this.userData)
    this.countries();
    this.questions();
   }

  ngOnInit()  {
    this.validations_form  =  this.formBuilder.group({
     
    full_name: ['',Validators.required],
    date_of_birth: ['',Validators.required],
    email: ['',Validators.compose([
      Validators.required,
      Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),

    ])],
    address: ['',Validators.required],
    city: ['',Validators.required],
    state:['',Validators.required],
    sex:['',Validators.required],
    // age: ['',Validators.required],
    age:sessionStorage.setItem("age", this.showAge),
    phone_no:[false,Validators.required],
    insurance: ['false',Validators.required],
    country:['',Validators.required],
    question:['',Validators.required],
    answer:['',Validators.required],

    pin_no:['', Validators.compose([
      Validators.required,
      Validators.maxLength(4),
    ])], 
    _id:['']
  
  }); 
  this.validations_form.patchValue(this.userData); 
  }

  validation_messages = {
    'insurance': [
      // { type: 'required', message: 'upc is required.' },
      { type: 'minlength', message: 'insurence must be at least 1 characters long.' },
      { type: 'maxlength', message: 'insurence cannot be more than 20 characters long.' },
     // { type: 'validUsername', message: 'Your insurence has already been taken.' }
    ],
    'full_name': [
      { type: 'required', message: 'name is required.' },
      { type: 'minlength', message: 'name must be at least 1 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'age': [
      { type: 'required', message: 'age is required.' },],
      'sex': [
        { type: 'required', message: 'sex is required.' },],

        'email': [
          { type: 'required', message: 'Email id is required.' },
          { type: 'pattern', message: 'please enter valid email' }],
    'date_of_birth': [
          { type: 'required', message: 'date_of_birth is required.' },
        ],
  
    'address': [
              { type: 'required', message: 'address  is required.' }],
              'city': [
                { type: 'required', message: 'city  is required.' }],
                'state': [
                   { type: 'required', message: 'state  is required.' }],
                   'country': [
                    { type: 'required', message: 'country  is required.' }],
                    'question': [
                      { type: 'required', message: 'question  is required.' }],
                      'answer': [
                        { type: 'required', message: 'answer  is required.' }],
                   'pin_no': [
                    { type: 'required', message: 'pin_no  is required.' },
                    { type: 'maxlength', message: 'pin_no cannot be more than 4 digits.' },
                    { type: 'minLength', message: 'pin_no cannot be less than 4 digits.' },


                  ],
                   'phone_no': [
                    { type: 'required', message: 'phone_no  is required.' }],
                  
  };
 
    save(){


      let dateOfBirth = this.validations_form.value.date_of_birth;

    console.log("", dateOfBirth)

    var arr1 = dateOfBirth.split('-');
    console.log("", arr1);
    var arr2 = arr1[1].split(',');
    console.log("", arr2);
    console.log('date: ', arr1[0]);

    if (this.validations_form.value.date_of_birth) {
      var convertAge = new Date(this.validations_form.value.date_of_birth);
      console.log(convertAge)
      var timeDiff = Math.abs(Date.now() - convertAge.getTime());
      console.log(1000, timeDiff)
      this.showAge = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
      console.log("55555", this.showAge)
      this.validations_form.value.age = this.showAge;
    }

    if (arr1[0] == new Date().getFullYear()) {
      this.showAge1 = (new Date().getMonth() + 1 - arr2[0]) / 10
      console.log("", this.showAge1)
      console.log(2000, this.validations_form.value.age = this.showAge1);
    }
    console.log('month: ', arr2[0]);
    console.log('year: ', arr2[1]);




      console.log(123,this.validations_form.value);
      if(this.validations_form.value.full_name != '' && this.validations_form.value.sex != '' && this.validations_form.value.age != '' && this.validations_form.value.email != '' && this.validations_form.value.address != '' &&  this.validations_form.value.city != '' && this.validations_form.value.pin_no != '' && this.validations_form.value.state != '') {
        this.auth.editpatient(this.validations_form.value).subscribe(res => {
          if (res.status == 'success') {
            sessionStorage.setItem("age", this.showAge);
            sessionStorage.setItem("userData",res.response);
            this.dialogRef.close();
                  
            swal("The profle has been edited successfully");
              console.log(39,res);
              this.dialogRef.close();
            
          }
        }, console.log);
      } else {
        swal ('Edit not success please fill required fields');
      }
     
     }

    // closed() {
    //   close(): void {
    //     this.dialogRef.close();
    //  }
    questions(){
      this.auth.quest().subscribe(res => {
        if (res.status == "success") {
           this.questlist=res.response 
        }
      },() => {
      });
     }
    
    close(): void {
          this.dialogRef.close();
       }

       countries(){
        this.auth.countryList().subscribe(res => {
          if (res.status == "success") {
             this.listOfCountries=res.response 
             console.log( 333,this.listOfCountries)
          }
        },() => {
        });
       }
    
  
   // listen for close event after opening the modal

    }



   