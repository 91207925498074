import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import{ ActivatedRoute, Router} from '@angular/router';
import{AuthService} from './../../services/auth.service';
import { MatDialog } from '@angular/material';
import { AlertComponent } from '../patient/alert/alert.component';
declare var swal;

@Component({
  selector: 'app-loginstaff',
  templateUrl: './loginstaff.component.html',
  styleUrls: ['./loginstaff.component.css']
})
export class LoginstaffComponent implements OnInit {
  loginForm: FormGroup;
  type:any;
  userData: any;
  password:any;
 
public validations_patient: FormGroup;
  public validations_docter: FormGroup;
  public validations_lab: FormGroup;

  selectitem: any;
  menu: any;
  M: any;
  
  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
        public dialog: MatDialog,

   
    ) {
     
      this.selectitem=4;
  
      this.validations_patient  =  this.formBuilder.group({
        admin_phone: ['', Validators.required],
        password: ['', Validators.required,],
        // phone_no: ['', Validators.required],
       
    });
   
     }

forgot(e)
{
  this.router.navigateByUrl('/forgetpsw?id='+e);

  
}

  ngOnInit() {
  }

  login(ex,formData) {
   
    sessionStorage.setItem('token', "res.response.token");
   // location.reload();
    // this.auth.presentLoading();
    this.auth.login(formData.value).subscribe(res => {
    
      if (res.status == "success") {
        this.userData=res.response;

        
          sessionStorage.setItem("userData",JSON.stringify(this.userData));
            sessionStorage.setItem("patient_id",this.userData.patient_id);
            sessionStorage.setItem("full_name",this.userData.full_name);
            sessionStorage.setItem("phone_no",formData.value.phone_no);
            sessionStorage.setItem("country",this.userData.country);
            sessionStorage.setItem("id",this.userData._id);
            this.router.navigateByUrl('/alloc');
           
          //  this.router.navigateByUrl('/login');
        
       
        
        
       // location.reload();

       // this.route.navigateByUrl('/list');
      } if(res.status=='error')
      {
        swal(res.message);
      }
       }, (err) => {
         console.log(99,err)
  
         if(err.error.message.err=="No account exists")
         {
          swal("No account exists");
        }
     
  
      });
     

    
  }
  
 
  onChange(item){
    console.log(11,item);
    this.selectitem = item.target.value;
  }

  // reg()
  // {
  //   this.router.navigateByUrl('/register');
  
  // }
  need(): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '250px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result.sts=='no')
      {
        this.router.navigateByUrl('/registration');

      }
      console.log('The dialog was closed');
    });
  }

  reg(){

     this.router.navigateByUrl('/registration');


  }
  logo(){
    
  }
}



// (err) => {
//   console.log(343,err);
//   swal( err.error.message.error, " no already exist");

// });

