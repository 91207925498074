import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


declare var swal;

export interface PeriodicElement {
  Name: string;
  address: string;
  // weight: number;
  // symbol: string; 
}

@Component({
  selector: 'app-lab-request',
  templateUrl: './lab-request.component.html',
  styleUrls: ['./lab-request.component.css']
})

export class LabRequestComponent implements OnInit {

  //displayedColumns: string[] = ['lab_id','Contact_Name','Lab_Name'];
  doc:any;
  lab_id: string;
  lab_ids: any;
  city:any
  Contact_Name: string;
  Contact_Names: any;
  _id: any;
  id: any;
  response:any;
  newItem: string;
   ELEMENT_DATA: PeriodicElement[]=[]
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
  validations_form: any;
  email: any;
  b:Object={};
  baseurl:any

  
  constructor(private fb: FormBuilder,private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute) {
        this.email = sessionStorage.getItem("email");
        console.log('000', this.email)
      this.lab_create();






      if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
        this.router.navigateByUrl('/admin-sign');
      }
     }
  
  ngOnInit() {
  }
  app(){
    sessionStorage.setItem('labapprove','2')
  }
  createForm() {

    this.validations_form = this.fb.group({
      lab_id: ['', Validators.required],
      lab_name:['' ,Validators.required],
      name:['', Validators.required],
      contact_name:['',Validators.required],
      lab_phone: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      fundingSource: [''],
      payer_id: [''],
      country_code: [''],
      charges: [''],
      select_plan : ['',Validators.required],
      country: ['', Validators.required],
      question: ['', Validators.required],
      answer: ['', Validators.compose([
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(4),
      ])],


    });
  }
  lab_create(){
    this.auth.lab_find().subscribe(res =>  {
     
     if(res['status'] == "success"){
      this.response=res['response'].reverse();

      this.baseurl=this.auth.baseUrl;
      // let img=res['response'][0].docpath[0].split('/')
      // console.log(img[3]);

      // this.doc=img[3];
      // console.log(this.doc);
      
      //console.log(res.json())
        //console.log({res.lab_id})
      // this.Contact_Name=res.response;
      // this.dataSource = new MatTableDataSource<PeriodicElement>(this.Contact_Names);
     }
    })
  }
  Approve_mail(e,email,sts) {
  
   this.auth.lab_approved({email:email,status:sts}).subscribe(res =>{
     console.log("email", this.email);
     swal("You have Approved the request !");
    // if(res.status == "Email Sent To User"){
      
    // }
   })
  }
  // Decline_mail(e,email,sts) {
  
  //   this.auth.lab_approved({email:email,status:sts}).subscribe(res =>{
  //     console.log("email", this.email);
  //    if(res.status == "Email Sent To User"){

  //    }
  //   })
  //  }
   




   Decline_mail(text) {
   
    this.auth.lab_approved({status:0,text:text,_id:this.b['_id'],email:this.b['email']}).subscribe(res =>{
          console.log("email :", this.b['email']);
   
          console.log("Declined Text :", text);
          
          swal("You have Declined the request !");
          window.location.reload();
        //  if(res.status == "Email Sent To User"){
          
        //  }
        })
       }


   Decline2_mail(_id,email){

    this.b={};
    console.log(this.b);
    
   console.log(_id,email);
     this.b['_id']=_id;
     this.b['email']=email;
    console.log(this.b);
    
   }


   logout(){
  
   // this.auth.logout();
 
   }
   new(i){
    window.open(this.baseurl+'/labdoc/fileinfo/'+i)
  }

  }

