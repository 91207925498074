import { Component, OnInit, Inject, Input, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal } from 'ngx-materialize';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
declare var swal
@Component({
  selector: 'app-user-pin-model',
  templateUrl: './user-pin-model.component.html',
  styleUrls: ['./user-pin-model.component.css']
})
export class UserPinModelComponent extends MzBaseModal  implements OnInit {

  pin:any;
  validations_form: FormGroup;

  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  phone_no: any;
  dob: any;
  dateOfBirth: any;
  age: number;
  DateOfBirth: number;
  Dob: any;
  dtBirth: any;
  age12333: number;
  birthdate: any;
  constructor(

    public dialogRef: MatDialogRef<UserPinModelComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,private fb: FormBuilder,private router: Router,    protected auth: AuthService,
    ) {
    super();
  
    this.validations_form = this.fb.group({
      phone_no:['', Validators.compose([
        Validators.required,
        Validators.maxLength(10),
        Validators.minLength(1),
      ])],
     pin:['', Validators.compose([
       Validators.required,
       Validators.maxLength(4),
       Validators.minLength(4),
     ])],
    
     });
   }
 


   validation_messages = {
    'pin': [
      { type: 'required', message: 'pin_no  is required.' },
      { type: 'maxlength', message: 'pin_no cannot be more than 4 digits.' },
      { type: 'minLength', message: 'pin_no cannot be less than 4 digits.' },
    ]
    ,
    'phone_no': [
      { type: 'required', message: 'Patient Id/ User Id  is required.' },
      { type: 'maxlength', message: 'Patient Id/ User Id cannot be more than 10 digits.' },
      { type: 'minLength', message: 'Patient Id/ User Id cannot be less than 4 digits.' },


    ]
  }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }

  Search(){
    this.auth.pinlab({phone_no:this.phone_no,pin_no:this.pin}).subscribe(res => {
      if (res.response.length > 0) {
        console.log(111,res.response)

        
        this.dob = res.response[0].date_of_birth;
       
console.log("000000000", this.dob)
  
  
  //this.Dob= this.dob.split("-").reverse().join("-");
  
  this.birthdate = this.dob;
  if (this.birthdate) {
  var timeDiff = Math.abs(Date.now() - new Date(this.birthdate).getTime());
  this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

  console.log("1" ,this.age)
    }
          if (res.response != "") {
              let name=res.response[0];
              sessionStorage.setItem("patient_id",name.patient_id);
              sessionStorage.setItem("full_name",name.full_name);
              //sessionStorage.setItem("age", name.age)
              sessionStorage.setItem("age", this.age.toString());
              sessionStorage.setItem("phone_no", name.phone_no)
           
              sessionStorage.setItem("sex", name.sex)
              sessionStorage.setItem("number", name.number)
              // sessionStorage.setItem("patient_id",this.patient_id);
              // sessionStorage.setItem("lab_phone",this.lab_phone);
              this.dialogRef.close();

              this.router.navigate(['/user-lab-test-update']).then(() => {
                //window.location.reload();
              });
                
              
            

              // this.router.navigateByUrl("/Labtestupdate");
          }
      } else {
        console.log("false")
            swal("please enter registered phone number or pin")
      }
    }, () => {
      swal("Login failed, Login detils not exits ");
     }
     );
   }

  //  edit(){
  // }
  // pin()  {
  //      let dialogRef = this.dialog.open(PinModelComponent, {
  //         width: '600px',
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //       this.pin_nos=sessionStorage.getItem("pin_no");
    
  //         if( result.pin == this.pin_nos) {
          
  //               this.router.navigateByUrl('/Labtestupdate');
  //           } else{
  //               swal("Wrong Pin No")
  //           }
  //     });
  // }  
}

