import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var swal
@Component({
  selector: 'app-user-chane-password',
  templateUrl: './user-chane-password.component.html',
  styleUrls: ['./user-chane-password.component.css']
})
export class UserChanePasswordComponent implements OnInit {
  lab_id: string;
  lab_user_phone: string;
  relationForm :FormGroup;
  
  constructor(
    private fb: FormBuilder,
    private router: Router,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
  ) { 
    this.relationForm= this.fb.group({
      lab_user_phone: [this.lab_user_phone ,Validators.required],
      old_password: [''],
      new_password : [''],
     
      //con_password: ['',Validators.required],
    });
  }

  ngOnInit() {
    this.lab_user_phone=sessionStorage.getItem("lab_user_phone");
    
  }
  
  change(relationForm){
    
    if((<HTMLInputElement>document.getElementById("first_input")).value == (<HTMLInputElement>document.getElementById("second_input")).value){
      //they are the same, do stuff for the same
      
      this.relationForm.value.lab_user_phone=this.lab_user_phone;
   
    this.auth.lab_user_forgotpassword(this.relationForm.value).subscribe(res =>{
      if(res.status == "success"){
        swal("Password changed successfully");
        //this.router.navigateByUrl('/new-lab');
      this.router.navigateByUrl('/new-lab');
       
      }
    },err=>{
      swal(" Old password doesn't match")
    })
  }
  else {
    swal("Password doesn't match")
    
  }
      //first is more than second
      
  


    
  }
  
  
  logout= function () {
        this.router.navigateByUrl('/new-lab');
};
}
