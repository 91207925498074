import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';


import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MaterializeAction } from 'angular2-materialize';
import { MzBaseModal } from 'ngx-materialize';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
//import { NgxSpinnerService } from 'ngx-spinner';
declare var swal;
@Component({
  selector: 'app-perception-edit',
  templateUrl: './perception-edit.component.html',
  styleUrls: ['./perception-edit.component.css']
})

  export class PerceptionEditComponent extends MzBaseModal  implements OnInit {
  
    
  userData: any;
  full_name: any;
  patient_id: string;
  testReportUpdate:FormGroup;
  docname: any;
  docter_id: any;
  reason: any;
  ch: any;
  editdc: any;
  datas: any;
  port: any;
  usersData: any;
  child_name:any;
  dropdownSettings:{};
  testNames:any;
  arr:any[];

  response: any;
  @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  names: any;
  selectedItems: any;
  docter_name: any;
  result: any;
  constructor(
   
    public dialogRef: MatDialogRef<PerceptionEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    protected router: Router,private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder,
    public auth:AuthService) {
    super();
  
    //private spinner: NgxSpinnerService,

      this. datas="";
    
       
        this.editdc  = data;
        console.log( 99,this.editdc ); // Print the parameter to the console. 
       /// this. datas=JSON.parse(this.editdc);
        if(this.editdc!=undefined)
        {
          this. datas=this.editdc;
          console.log("hi test_id",this.datas );
         
        }
        
        // Print the parameter to the console. 

    this.testReportUpdate  =  this.formBuilder.group({
    
        blood_pressure: ['', Validators.required],
        comments: ['', Validators.required],
        medicine_prescribed: ['', Validators.required],
        labtest_required: [false, Validators.required],
        child_name: ['',Validators.required],
        patient_id:['', Validators.required],
        docter_id:['', Validators.required],
        docter_name:[this.docter_name],
        reason:['', Validators.required],
        patient_name:[''],

        test_name:[''],
        age:[],
        gender:[],
        phone:[],
        
        _id:['']

     });

     this.dropdownSettings = {
          singleSelection: false,
          idField: '_id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
       };


      this.userData=JSON.parse(sessionStorage.getItem("userData"));
      this.full_name=sessionStorage.getItem("full_name");

      console.log(333,this.full_name)
      this.patient_id=sessionStorage.getItem("patient_id");
      this.docter_name=this.userData.docter_name;
      this.docter_id=this.userData.docter_id;

      this.ch=false;
      this.list();

    }
  

  
  history()
  {
    this.router.navigateByUrl('/patentmedicalhistory');
  }
  
  ngOnInit() {
  
    if(this.datas!="")  {


      this.testReportUpdate.patchValue(this.datas.test_id);
      // this.testReportUpdate.value.test_name=this.datas.test_id.test_name.name;
      this.port=this.datas.test_id.test_name;
      this.child_name=this.datas.test_id.child_name;
      this.selectedItems=this.datas.test_id.test_name
      
    // this.testReportUpdate.patchValue(JSON.parse(this.datas).test_id);

    }
  }
  save(patientupdateForm){
    // this.spinner.show();
    this.testReportUpdate.value.patient_id=this.patient_id;
    this.testReportUpdate.value.docter_id=sessionStorage.getItem("docter_id");
    let age;
    let phone;
    let gender;
    if(this.child_name!="" && this.child_name!=undefined)
    {
        let myItem = this.userData.find(item => item.name === this.child_name);
        gender=myItem.gender;
        phone=sessionStorage.getItem("phone_no");
        age=myItem.age;
    }
    else{
      age=sessionStorage.getItem("age");
      phone=sessionStorage.getItem("phone_no");
      gender=sessionStorage.getItem("sex");
    }
    
    for(let obj of this.selectedItems)
    {
      let myItem =this.testNames.find(s => s.name === obj.name);
      this.arr.push(myItem)
      console.log('arr:', this.arr);
    }
    this.testReportUpdate.value.test_name=this.arr;

    this.testReportUpdate.value.gender=gender;
    this.testReportUpdate.value.age=age;
    this.testReportUpdate.value.phone_no=phone;
    

    if(this.testReportUpdate.value.labtest_required==true && this.testReportUpdate.value.test_name==undefined){
           swal('Please select test name');
     } else if(this.testReportUpdate.value.reason==""){
                swal('Please enter reason for visit');   
     } else if(this.testReportUpdate.value.blood_pressure==""){
                swal('Please enter blood pressure');
     } else {
         this.testReportUpdate.value.child_name=this.child_name;
         this.testReportUpdate.value.patient_name=sessionStorage.getItem("full_name");

         this.auth.editdoc(patientupdateForm.value).subscribe(res => {
          // this.spinner.hide();

            if (res.status == "success") {
              if (res.response != "") {
                  this.router.navigateByUrl("/patientMedicalSearch");
                  this.dialogRef.close();
                  swal("updated successfully");
                 
               
              }
            }
        }, (err) => {
            swal("server failed, server details not exits ");
       });
   }
  }

  //  view(){
  //   this.route.navigateByUrl('/patentmedicalhistory',);
  //  }
   list() {
      console.log("in patch");
      this.auth.findchild({patient_id:this. patient_id}).subscribe(res => {
        this.result=res;
         if (this.result.status == "success") {
            this.usersData=this.result.response;
            console.log(33,this.usersData)
           // sessionStorage.setItem("full_name",this.name);
           // this.router.navigateByUrl("/patientsearch");
            this.search();
         }
       }, () => {
          swal("server failed, server detils not exits ");
       });
     }

     check(e){
       console.log(e.detail);
         this.ch=e.detail.checked;
     }

    //  portChange(event: {
    //   component: IonicSelectableComponent,
    //   value: any
  
      
    // }) {
    //   let va=event.value;
    //   let c=0;
    //   for(let i=0;i<event.value;i++)
    //   {
    //   if(va[i].relation==0 )
    //   {
    //     c++
    //   }
    // }
      //this.comp(event.value.id);
    //   this.patientupdateForm.value.test_name=event.value;

      

    //   console.log('port:', event.value);
    // }
  
    
    testbyid(){
      this.auth.testbyid().subscribe(res => {
        if (res.status == "success") {
          if (res.response != "") {
            this.testNames=res.response;
          }
        }
      },() => {
          swal("Login failed, Login detils not exits ");
       });
     }
   search(){
       this.auth.lab_tests().subscribe(res => {
          if (res.status == "success") {
            if (res.response != "") {
            this.testNames=res.response;
            }
          }
       }, () => {
          swal("Login failed, Login detils not exits ");
      });
     }
    
    onItemSelect(item: any) {
      console.log(item);
    }
    onSelectAll(items: any) {
      console.log(items);
    }

    testNamess(){
      this.auth.totalTestNames({patient_id:this.patient_id}).subscribe(res => {
          this.response = res;
        if(this.response) {
          this.names = this.response.response;
          console.log(98,this.names)
       } else {
        swal("Server error")
       }
      })
  }

}
