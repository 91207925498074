import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MzBaseModal } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
declare var swal;
declare var Razorpay: any; 
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  public payPalConfig ? : IPayPalConfig;


  price: any;

    neddForm: FormGroup;
    lablis: any;
    docter_name: any;
    patient_id: any;
    doctlist: any;
    booksByStoreID:any=[];
   
  showSuccess: boolean;
  id: any;
  email_address: any;
  payer_id: any;
  purchase_units: any;
  value: any;
  country_code: any;
  fundingSource: any;
  patient_name: any;
  doctspec: any;
  docter_id: any;
  pay_id: any;
  currency: string;
  patientname: string;
  consultDtr: any;
  searchText: String;
  dtr_id: any;
  order_id:any;
  textBoxDisabled=true;
  
  videoDisabled=true;
  doc_phn: any;
      constructor(
        private fb: FormBuilder,
        private router: Router, 
       
        protected auth: AuthService,
      
        protected route: ActivatedRoute) {
        
        this.patient_id= sessionStorage.getItem("patient_id");
        this.patientname= sessionStorage.getItem("full_name");


        this.consultDtr = JSON.parse(sessionStorage.getItem("services"))
        console.log(this.consultDtr);
        
        this.docter_name = this.consultDtr.docter_name;
        this.doc_phn=this.consultDtr.phone;
        this.price = this.consultDtr.finalCharges;
        this.dtr_id = this.consultDtr.docter_id

        console.log(1000,  this.dtr_id);
        this.currency='INR'
      this.Assign();
  
        }
  
 

     
  ngOnInit() {
window.scrollTo(0,0)
    this.initConfig();

    this.createForm();

    
  }
  createForm() {
    this.neddForm = this.fb.group({
     
      call_type: ['',Validators.required],
      reason: ['',Validators.required],
      charges:[''],
      patient_id:[''],
      patient_name:[this.patientname],
      alloc_status:[0],
      date:Date.now(),
      fundingSource:[''],
      payer_id: [''],
      country_code:[''],
      docter_name:[''],
      doc_phn:[''],

      order_id:"",
      docter_id:[this.docter_id],
      patient_phone_no:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }

  save() {
   
    console.log(104,this.neddForm.value.patient_phone_no);

    console.log(109,this.neddForm.value.call_type);

    this.neddForm.value.patient_id=this.patient_id;
    this.neddForm.value.docter_name=this.docter_name;
    this.neddForm.value.docter_id = this.dtr_id;
    this.neddForm.value.doc_phn=this.doc_phn;

    console.log(this.neddForm.value.order_id);
    
    this.auth.needadoc(this.neddForm.value).subscribe(res=>{
      if(res.status=="success"){
        if(res.status=='success'){
          console.log(res);
        //  this.router.navigate(['/needbyid'])
        swal("Request Sent successfully");
        //setTimeout(() => {
          localStorage.setItem("payment_sts", "1")
          this.router.navigate(['/needbyid'])
          setTimeout(() => {
            location.reload();
          }, 2000);
        //}, 1000);
  // .then(() => {
  //   window.location.reload();
  //   // swal("Meeting Schedule ! Pls Wait For 10 Mins")
  // });

    

        }
      }
    })
  }

  Assign(){
    
    this.auth.docBytype({type:1}).subscribe(res => {
      
      if (res.status == "success") {
     
         this.doctlist=res.response 
         console.log(33,this.doctlist);
      }
    }, (err) => {

     }
     );
}

  
   
   getprice(e) {
     this.Assign()
    this.booksByStoreID = this.doctlist.filter(
      book => book.docter_name === e.target.value);

console.log(101,this.booksByStoreID)
      let i=this.booksByStoreID[0].charges.split(' ');
      this.price=i[1]
      console.log(99,this.price)
this.docter_id=this.booksByStoreID[0].docter_id;
     // this.neddForm.value.docter_id=this.booksByStoreID[0].docter_id;

      this.neddForm.value.docter_name=this.booksByStoreID[0].docter_name;
      this.neddForm.value.docter_phone=this.booksByStoreID[0].docter_phone;
    }
    validation_messages = {
      'insurance': [
        // { type: 'required', message: 'upc is required.' },
        { type: 'minlength', message: 'insurence must be at least 1 characters long.' },
        { type: 'maxlength', message: 'insurence cannot be more than 20 characters long.' },
       // { type: 'validUsername', message: 'Your insurence has already been taken.' }
      ],
      'full_name': [
        { type: 'required', message: 'Name is required.' },
        { type: 'minlength', message: 'Name must be at least 1 characters long.' },
        //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
      ],
      
        'sex': [
          { type: 'required', message: 'Gender is required.' },],
  
          'email': [
            { type: 'required', message: 'Email id is required.' },
            { type: 'pattern', message: 'Please enter valid email' }],
  
      'date_of_birth': [
            { type: 'required', message: 'Date of Birth is required.' },
          ],
    
      'address': [
                { type: 'required', message: 'Address  is required.' }],
  
                'city': [
                  { type: 'required', message: 'City  is required.' }],
  
                  'state': [
                     { type: 'required', message: 'State  is required.' }],
  
                     'country': [
                      { type: 'required', message: 'Country  is required.' }],
  
                    //  'phone_no': [
                    //   { type: 'required', message: 'Phone no  is required.' }],
                      'patient_phone_no': [
                        { type: 'required', message: 'Phone no is required.' },
                        { type: 'maxlength', message: 'Phone no cannot be more than 10 digits.' },
                        { type: 'minLength', message: 'Phone no cannot be less than 4 digits.' },
                  
                  
                      ],
  
                      'password': [
                        { type: 'required', message: 'Password  is required.' }],
                        'pin_no': [
                          { type: 'required', message: 'pin_no  is required.' },
                          { type: 'maxlength', message: 'pin_no cannot be more than 4 digits.' },
                          { type: 'minLength', message: 'pin_no cannot be less than 4 digits.' },
      
      
                        ],
  
                        'question'  : [{ type: 'required', message: 'select security question is required.' }],
                        'answer': [
                          { type: 'required', message: 'answer  is required.' },
                          { type: 'maxlength', message: 'answer cannot be more than 20 digits.' },
                          { type: 'minLength', message: 'answer cannot be less than 4 digits.' },
      
      
                        ],
                        'age': [
                          { type: 'required', message: 'age  is required.' },
                          { type: 'maxlength', message: 'age cannot be more than 1 digits.' },
                          { type: 'minLength', message: 'age cannot be less than 3 digits.' },
      
      
                        ],
                    
    };


  
    private initConfig(): void {
      this.payPalConfig = {
      currency: 'INR',
      clientId: 'AUGMf1BRyf-Bn5iQCart6y_dNtTY9RXW6u2rS2e2hN7GZZmNxbXcWYjLkMseYYRnGnJkir4Ugq-3iKA6',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'INR',
              value: this.price,
              breakdown: {
                item_total: {
                  currency_code: 'INR',
                  value: this.price
                }
              }
            },
            items: [
               {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'INR',
                  value: this.price,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);


 this.id=details.id;
 this.email_address=details.payer.email_address;
 this.payer_id=details.payer.payer_id;
 this.country_code=details.payer.address.country_code;
 this.purchase_units=details.purchase_units[0].amount.currency_code;
 this.value=details.purchase_units[0].amount.value;


 this.patient_name=sessionStorage.getItem('full_name');

 this.neddForm.value.email_address=this.email_address;
 this.neddForm.value.payer_id=this.payer_id;
 this.neddForm.value.country_code=this.country_code;
 this.neddForm.value.purchase_units=this.purchase_units;
 this.neddForm.value.value=this.value;
 this.neddForm.value.fundingSource=this.fundingSource;


 




 console.log(909,{'email':this.email_address,'payer_id':this.payer_id,'country_code':this.country_code,'value':this.value});


          this.save();
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);

        this.fundingSource=data.fundingSource;


      },
    };
    }


    razorPayOpen(){
      let data={
        'amount': this.price*100,
        'name': 'One Health',
        'description': 'Charges towards consultation',
        'image': 'assets/images/oneLogo copy.jpg',
        'currency': this.currency,
        'notes': {},
        'prefill': {
            'name': sessionStorage.getItem('full_name'),
            'email': sessionStorage.getItem('email'),
            'contact': this.neddForm.value.patient_phone_no
            
        },
        'signature':this.neddForm.value.razorpay_signature,
        'order_id':this.neddForm.value.order_id,
        'payer_id': this.neddForm.value.payer_id
      }


      this.auth.createOrder(data).subscribe(res=>{
        console.log(res);
        options.order_id=res['orderId']
        this.order_id=res
    })

      let options:any = {
        // 'key': 'rzp_test_pCNbE9R46AoFLA',rzp_live_6HFl2nN6F0Wtoa
        'key': 'rzp_live_6HFl2nN6F0Wtoa',
        //'key': 'rzp_live_yR5j1iobfY7fA2', rzp_test_zslkbtdsPPe4Uy, rzp_test_Ot0R6daXxZepqx 
        'amount': this.price*100,
        'name': 'One Health',
        'description': 'Charges towards consultation',
        'image': 'assets/images/oneLogo copy.jpg',
        'currency': this.currency,
        'notes': {},
        'prefill': {
            'name': sessionStorage.getItem('full_name'),
            'email': sessionStorage.getItem('email'),
            'contact': this.neddForm.value.patient_phone_no
            
        },
        // 'order_id':'',
        handler:((response) => {
          this.pay_id = response.razorpay_payment_id;

          this.neddForm.value.email_address='';
         console.log(response,this.id);
         
          this.neddForm.value.payer_id=this.pay_id;
          this.neddForm.value.order_id=options.order_id;
          this.neddForm.value.country_code='IND';
          this.neddForm.value.purchase_units=this.currency;
          this.neddForm.value.fundingSource="RazorePay";
          this.neddForm.value.order_id=response.razorpay_order_id;
          this.neddForm.value.razorpay_signature=response.razorpay_signature;

          let verify={
            "razorpay_order_id":response.razorpay_order_id ,
            "razorpay_payment_id":response.razorpay_payment_id,
            "razorpay_signature":response.razorpay_signature
                }


          this.auth.signatureVerify(verify).subscribe(res=>console.log(res))
          this.save();
       })
    };



      console.log(300,this.neddForm.value.call_type);
      console.log(104,this.neddForm.value.patient_phone_no);
      console.log(339,typeof this.neddForm.value.patient_phone_no);
    let name = this.neddForm.value.patient_phone_no;
   
  
    const myArr = name;

    console.log(344, myArr);
    
console.log( JSON.stringify(myArr).split(","));
var mbno = JSON.stringify(myArr).toString().split(",")
console.log(345, mbno[0].toString().split("").length  );

// if(mbno[0].toString().split("").length > 10){
//   swal("dmjfghb")
// }
//       // if(this.neddForm.value.patient_phone_no>10 ){
      //  swal("Please Enter 10 Digit Mobile Number")
      //  this.save()
      // }
      
      if(this.neddForm.value.call_type==''){
        swal("Please Select Call Type")
      }

      else if (mbno[0].toString().split("").length > 10 ){
        swal("Please Enter 10 Digit Mobile Number")
      }
      
      else{
          console.log(options);
          setTimeout(() => {
           let rzp1 = new Razorpay(options)
           rzp1.open();
          }, 1000);
      }
}

// sendOrder(options){
//   this.auth.createOrder(options).subscribe(res=>{
//     	console.log(res);
//   })
// }


logo(){
  sessionStorage.clear();
  localStorage.clear();
    this.router.navigateByUrl('/login');
}
audio(){
  this.textBoxDisabled = !this.textBoxDisabled;

}
video(){

  this.videoDisabled = !this.videoDisabled;
}




// tab(a){
//   console.log(300, a)
//   if(a){
//     this.a == a
//   }
// }

// manage(txt) {
//   var bt = <HTMLInputElement>document.getElementById('btSubmit');
//   if (txt.value != '') {
//       bt.disabled = false;
//   }
//   else {
//       bt.disabled = true;
//   }
// }
// manage1(txt) {
//   var bt = <HTMLInputElement>document.getElementById('btSubmit');
//   if (txt.value != '') {
//       bt.disabled = false;
//   }
//   else {
//       bt.disabled = true;
//   }
// }
}
