import { HttpClient } from '@angular/common/http';
import { Component, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-log',
  templateUrl: './admin-log.component.html',
  styleUrls: ['./admin-log.component.css']
})
export class AdminLogComponent implements OnInit {

  constructor(private auth:AuthService, private router:Router,public http:HttpClient,public swupdate:SwUpdate,public swpush:SwPush) {

    if( sessionStorage.getItem("full_name")==" " || sessionStorage.getItem("full_name") == undefined){
      this.router.navigateByUrl('/backend');
    }

   }

   
readonly vapid='BJmJEEosahpnHmStQEZFsciTSVsU6Afgh5ujhp5KfmaODAlG83vnWdTBdpDkKdDaVbXIOhX5hSeDpg4OixYyRaM'
//public notif='http://localhost:2998/sub';

sub:any;
counter:any;

nofi:any={
  state:""
}


ngOnInit(){
  if(this.swupdate.isEnabled){
    this.swpush.requestSubscription({
      serverPublicKey:this.vapid
    }).then(sub =>{
      
    }).catch(err=>console.log(err))
  }
}

//    ngOnInit() {

// setInterval((()=>{
// // this.http.post('http://localhost:2998/notifi','helo').subscribe()
// console.log('helo');
// this.http.get('http://localhost:2998/notifi').subscribe(res=>{
//   console.log(res,'he');
// this.nofi.state=res['state']
//   this.counter
// if(this.nofi.state==1){
//   // this.counter=1

// this.counter=(+this.counter)+1;
// // sessionStorage.setItem('count',this.counter)

// let sub:PushSubscription;

// if(this.swupdate.isEnabled){
//   this.swpush.requestSubscription({
//     serverPublicKey:this.vapid
//   }).then(sub =>{
    
    
//     console.log(JSON.stringify(sub))
//     this.http.post(this.notif,sub).pipe().subscribe() 
  
//   }).catch(err=>console.log(err))
// }


// if (this.swpush.isEnabled) {
//   this.swpush.notificationClicks.subscribe(
//       event => {
//           window.focus();
//           window.open(event.notification.data.url, '_self');
//       },
//       error => {
//           // handle error
//       }
//   );
// }




// //sessionStorage.setItem();
// }
  
// })
//  //location.reload()
// }

// ),1000)

//    }





// app(){
// let sub:PushSubscription;
// if (this.swpush.isEnabled) {
//   this.swpush.notificationClicks.subscribe(
//       event => {
//           window.focus();
//           window.open(event.notification.data.url, '_self');
//       },
//       error => {
//           // handle error
//       }
//   );
// }


// if(this.swupdate.isEnabled){
//   this.swpush.requestSubscription({
//     serverPublicKey:this.vapid
//   }).then(sub =>{
    
    
//     console.log(JSON.stringify(sub))
//     this.http.post(this.notif,sub).pipe().subscribe() 
  
//   }).catch(err=>console.log(err) )
// }


// }


logout(){

  localStorage.clear();
  sessionStorage.clear();
  this.router.navigate(['/backend'])

}


  x=setInterval(()=>{
    console.log('hello !');

    this.http.post(this.auth.baseUrl+'/notify/find','').subscribe((res)=>{
    // console.log(res['response']);
    // this.counter=res['response'].length



    this.counter=res['response'].length
    if(this.counter==0){
      this.counter='' ;
    }
    else{
      this.counter=res['response'].length
     // this.b=res['response'].reverse()
      
    }
    })



    ////////////////////////////////////////////////////

    // console.log('helo');



// this.http.get(this.auth.baseUrl+'/notify/notifi').subscribe(res=>{
// //   console.log(res,'he');
// this.nofi.state=res['state']
//   // this.counter
// if(this.nofi.state==1){
//   // this.counter=1

// // this.counter=(+this.counter)+1;
// // sessionStorage.setItem('count',this.counter)

// let sub:PushSubscription;

// if(this.swupdate.isEnabled){
//   this.swpush.requestSubscription({
//     serverPublicKey:this.vapid
//   }).then(sub =>{
    
    
//     console.log(JSON.stringify(sub))
//     this.http.post(this.auth.baseUrl+'notify/sub',sub).pipe().subscribe() 
  
//   }).catch(err=>console.log(err))
// }


// if (this.swpush.isEnabled) {
//   this.swpush.notificationClicks.subscribe(
//       event => {
//           window.focus();
//           window.open(event.notification.data.url, '_self');
//       },
//       error => {
//           // handle error
//       }
//   );
// }

// //sessionStorage.setItem();
// }
  
// })


  }
  
  ,5000);

  ngOnDestroy(){
    clearInterval(this.x)
      }

}
