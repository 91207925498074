import { Component, OnInit , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators,FormGroup} from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { data } from 'jquery';
declare var swal;
@Component({
  selector: 'app-lab-user-otp',
  templateUrl: './lab-user-otp.component.html',
  styleUrls: ['./lab-user-otp.component.css']
})
export class LabUserOtpComponent implements OnInit {
  isDisabled: Boolean;
  relationForm :FormGroup;
  lab_id: string;
  lab_user_phone: string;
  email: string;
  resend:any
  m:number | string;
  s:number | string;
  constructor(
    private fb: FormBuilder,
    private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute
    // protected route: ActivatedRoute
  ) {
    this.relationForm= this.fb.group({
      lab_user_phone: ['' ,Validators.required],
      lab_id: ['',Validators.required],
      email: ['',Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],
      code:['',Validators.required ]
    });
    
   }

  ngOnInit() {
    
    this.isDisabled=true;
    this.email=sessionStorage.getItem("email");
    console.log(99989,this.email);
    this.lab_user_phone=sessionStorage.getItem("lab_user_phone");
    console.log(99989,this.lab_user_phone);
    this.lab_id=sessionStorage.getItem("lab_id");
    console.log(99989,this.lab_id);
    // this.email=sessionStorage.getItem("email")
    // console.log(99989,this.email);
    // this.lab_id=sessionStorage.getItem("lab_id");
    // console.log(99989,this.lab_id);
    //for timer
    let timerOn = true;

let timer=(remaining)=> {
 m = Math.floor(remaining / 60);
  s = remaining % 60;
  
  var m = m < 10 ? '0' + m : m;
  var s = s < 10 ? '0' + s : s;
  document.getElementById('timer').innerHTML = m + ':' + s;
  remaining -= 1;
  
  if(remaining >= 0 && timerOn) {
    setTimeout(function() {
        timer(remaining);
    }, 1000);
    return;
  }

  if(!timerOn) {
    // Do validate stuff here
    return;
  } 
  
  // Do timeout stuff here
  //alert('Timeout for otp');
  swal({
    text: "timeOut",
    
  },
//location.reload()

  )
 
  this.isDisabled=false;

}

timer(120);
  }
  add(relationForm){
    this.relationForm.value.lab_user_phone=this.lab_user_phone;
    console.log(898989, this.relationForm.value.lab_user_phone)
    this.auth.lab_user_validate_code(this.relationForm.value).subscribe(res => {
      if(res.status == "success"){
        swal("Otp verified Sucessfully");
        //this.router.navigateByUrl('/new-lab');
      this.router.navigateByUrl('/set-pass');
       
      }
    })
  }
  resendUserOTP(relationForm) {
   
    this.relationForm.value.email=this.email;
    console.log(898989, this.relationForm.value.email)
    this.relationForm.value.lab_id=this.lab_id;
    console.log(898989, this.relationForm.value.lab_id)
    this.auth.lab_forgotPassword( this.relationForm.value).subscribe(res => {
      if(res.status == "Email Sent To User"){
        //sessionStorage.setItem("email",res.data.email)
        swal({
          title: 'Email Sent to User',
          text: 'Redirecting...',
          icon: 'success',
          timer: 4000,
          buttons: false,
      })
      .then(() => {
        location.reload();
        this.router.navigateByUrl('/lab-user-otp');
      });
      } 
        })
      // this.router.navigateByUrl('/new-lab');
//       setTimeout(()=>{ 
//         document.getElementById(this.resend).removeAttribute('disabled'); // removes the disabled attribute 
//         document.getElementById(this.resend).innerHTML = 'Click'; // changes tne button text 
//         this.isDisbled=false;

//         }, 
// 2000 )
  }
  logout(){
    this.router.navigateByUrl('/new-lab');
  } 

  
}
