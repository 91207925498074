import { Component, OnInit, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MzBaseModal,MzModalService } from 'ngx-materialize';
import { MaterializeAction } from 'angular2-materialize';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
;
declare var swal;
@Component({
  selector: 'app-editdoctor',
  templateUrl: './editdoctor.component.html',
  styleUrls: ['./editdoctor.component.css']
})
export class EditdoctorComponent extends MzBaseModal  implements OnInit{
   @Input() public modalActions: EventEmitter<string | MaterializeAction>;
  userData: any;
  full_name: string;
  patient_id: string;
  password:any;
  validations_form:FormGroup;
  modalCtrl: any;
  listOfCountries: any;
  questlist: any;
  speciality:any;




  constructor(private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    public dialogRef: MatDialogRef<EditdoctorComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected route: ActivatedRoute,) {
   
    super();
    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.full_name=sessionStorage.getItem("full_name");
    this.patient_id=sessionStorage.getItem("patient_id");
    
    console.log("22",this.userData)
    this.countries();
    this.questions();
   }

  ngOnInit()  {
    this.validations_form  =  this.formBuilder.group({
      _id: ['', Validators.required],
      docter_id:['', Validators.required],
      docter_name: ['', Validators.required],
      docter_phone: ['', Validators.required],
      sex: ['', Validators.required],
      speciality: ['', Validators.required],
      // labtest_required:new FormControl(false, [Validators.required]),
      email: ['',Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
      ])],     
      clinic_address:['', Validators.required],
      city:['', Validators.required],
      // zoom_id:['', Validators.required],

      state:['', Validators.required],
      hospital:['', Validators.required],
      country: ['',Validators.required] ,
      question:['',Validators.required],
      answer:['',Validators.required],
  });

  this.validations_form.patchValue(this.userData); 
  this.speciality1()
  }


  validation_messages = {
   
    'docter_name': [
      { type: 'required', message: 'name is required.' },
      { type: 'minlength', message: 'name must be at least 1 characters long.' },
      //{ type: 'pattern', message: 'Your password must contain only numbers and letters.' },
    ],
    'age': [
      { type: 'required', message: 'age is required.' },],
      'zoom_id'  : [{ type: 'required', message: 'zoom id is required.' }],

    // 'phone': [
    //     { type: 'required', message: 'phone is required.' }],
    'date_of_birth': [
          { type: 'required', message: 'date_of_birth is required.' },
        ],
        'email': [
          { type: 'required', message: 'email id is required.' },
          { type: 'pattern', message: 'please enter valid email' }],

           'speciality': [
            { type: 'required', message: 'speciality is required.' }
           ],
           'docter_phone': [
            { type: 'required', message: 'docter_phone is required.' }
           ],
           
    'clinic_address': [
              { type: 'required', message: 'address  is required.' }],
              'city': [
                { type: 'required', message: 'city  is required.' }],
                'state': [
                   { type: 'required', message: 'state  is required.' }],
                      'hospital': [
                    { type: 'required', message: 'hospital  is required.' }],
                    'sex': [
                      { type: 'required', message: 'sex  is required.' }],
                      'country': [
                        { type: 'required', message: 'country  is required.' }],
                        'question': [
                          { type: 'required', message: 'question  is required.' }],
                          'answer': [
                            { type: 'required', message: 'answer  is required.' }],
                  
  };

    save(){
      if(this.validations_form.value.docter_name != '' && this.validations_form.value.speciality != '' &&  this.validations_form.value.email != '' && this.validations_form.value.docter_phone != ''){
        this.auth.doctoredit(this.validations_form.value).subscribe(res => {
          if (res.status == 'success') {
            sessionStorage.setItem("userData",res.response);
            this.dialogRef.close();
             swal("Edited successfully");
           this.auth.doctoreditData.next(res.response);
            console.log(39,res);
           
          }
        }, console.log);
      } else {
         swal("Please Fill the required fields")
      }
     
     }
    

     questions(){
      this.auth.quest().subscribe(res => {
        if (res.status == "success") {
           this.questlist=res.response 
        }
      },() => {
      });
     }


     close(): void {
      this.dialogRef.close();
     }
     countries(){
      this.auth.countryList().subscribe(res => {
        if (res.status == "success") {
           this.listOfCountries=res.response 
           console.log( 333,this.listOfCountries)
        }
      },() => {
      });
     }


     /////////////Changed by chaitanya
  speciality1(){
    this.auth.spetiality().subscribe(res=>{
      console.log(res);
      this.speciality=res.response
      
    })
  }
 }

 