import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MzModalService } from 'ngx-materialize';
import { ChangepassComponent } from 'src/app/changepass/changepass.component';
import { AuthService } from 'src/app/services/auth.service';
import { EditdoctorComponent } from '../pages/editdoctor/editdoctor.component';
//import { DocpinComponent } from '../doctor-data/docpin/docpin.component';
//import { EditdoctorComponent } from '../editdoctor/editdoctor.component';

declare var swal;
@Component({
  selector: 'app-docprofile',
  templateUrl: './docprofile.component.html',
  styleUrls: ['./docprofile.component.css']
})
export class DocprofileComponent implements OnInit {

  // constructor() { }

  // ngOnInit() {
  // }


  name: any;
  patient_id: any;
  userData:any;
  display: string;
  docter_phone: any;
  phone_no: any;
  modals: any;
  pinno: any;
  resiltpin: any;
  docter_id;
  checkresult: any;
  restrickey: any;
  pin: string;
  types: any;
  message: string;
  activatedRoute: any;
  editcharges:any;
fincharges:any
onehecharges:any=sessionStorage.getItem('onecharges')
  constructor(private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService)
    { 
    console.log( 23,this.userData);

    this.editcharges=sessionStorage.getItem('editcharges');

    this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.patient_id= sessionStorage.getItem("patient_id");
    this.docter_phone= sessionStorage.getItem("docter_phone");
     this.types= sessionStorage.getItem("type");
   // this.types = '1';
     console.log( 123,this.docter_phone);
     this.userData= this.userData;
     console.log( 123,this.userData);
 
    this.route.url.subscribe((url)=>{     
      this.activatedRoute = url;
    });
    if( sessionStorage.getItem("docter_phone")==" " || sessionStorage.getItem("docter_phone") == undefined){
      this.router.navigateByUrl('/login2');
    }
  }
  ngOnInit() {
    // this.userData=JSON.parse(sessionStorage.getItem("userData"));
    this.fincharges=(+this.editcharges)+(+sessionStorage.getItem('onecharges'));
  }

//   editCharges(a,b){
// console.log(a,b);

//   }


  editCharges(id,doccharges){
    console.log(id,doccharges);
// console.log((+doccharges)+(+));
let finalCharges=(+doccharges)+(+sessionStorage.getItem('onecharges'));


if(doccharges!==''||null){
 this.auth.editonecharges({_id:sessionStorage.getItem('_cid'),charges:doccharges,onehealthcharges:sessionStorage.getItem('onecharges'),finalCharges:finalCharges}).subscribe(res=>{
   console.log(res);
   swal('Consultation Charges Updated','','success')
   this.ngOnInit()
  // this.router.navigateByUrl('/docprofile')
   })
}

else {
 swal("Enter Charges","Invalid values","warning");
}


  }


  change()
  {
   if((this.phone_no).length>10){
this.message="Patient Id / User Id cannot be more than 10 digits."
   } 
   
       else{
        this.message='';
       }
  }
  
  // Search(){
    
  //   this.auth.patientcheck({phone_no:this.phone_no}).subscribe(res => {
  //       if (res.response.length > 0 ) {
  //             if (res.response != "") {
  //                 this.name=res.response[0];
  //                 sessionStorage.setItem("full_name",this.name.full_name);
  //                 sessionStorage.setItem("patient_id",this.name.patient_id);
  //                 sessionStorage.setItem("age", this.name.age)
  //                 sessionStorage.setItem("phone_no",String(this.name.phone_no))
  //                 sessionStorage.setItem("sex",this.name.sex)
    
  //                 this.pinno=this.name.pin_no;
  //                 this.restric(this.name.patient_id,this.userData.docter_id);
  //             }
  //         } else {
  //           swal("Please enter registered phone number")
  //         }
  //   }, () => {
  //        swal("Login failed, Login details not exits ");
  //    }
  //    );
  //  }
 
   openServiceModal(): void {
        const dialogRef = this.dialog.open(EditdoctorComponent, {
          width: '0px',
          height:'0px',
          
          position: {
            top: '0px',
            left: '0px'
          },
        });
        dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
       this.getdata();
       });
   }




  //Doctor Update
   getdata() {
        sessionStorage.setItem('token', "res.response.token");
        this.auth.getdoctor({docter_id:this.userData.docter_id}).subscribe(res => {
      
            if (res.status == "success") {
                    this.userData=res.response[0];
                    sessionStorage.setItem("userData",JSON.stringify(this.userData));
                    sessionStorage.setItem("patient_id",this.userData.patient_id);
                   
      
              }
           }, () => {
                this.auth.presentToast("Server failed");
          });
  }

  add(){
     this.router.navigateByUrl('/adddoctor');
   }
  doctor(){
    this.router.navigateByUrl('/doctorlist');
  } 

 // Check Patient Pin No in Search
  // pins() {
  //     let dialogRef = this.dialog.open(DocpinComponent, {
  //       height: '400px',
  //       width: '600px',
  //     });
  //     dialogRef.afterClosed().subscribe(result => {
  //           if(result.pin==this.pinno) { 
  //             this.router.navigateByUrl('/patientsearch');
  //           }
  //           else{
  //           swal("pin not matched");
  //         }   
  //       });
  // }
  // 
  // restric(patient_id,docter_id){
  //   this.pin='0';
  //   this.auth.checkrest({patient_id:patient_id,docter_id:docter_id}).subscribe(res => {
  //     this.checkresult=res;
  //     if (this.checkresult.status == "success") {
  //               if (this.checkresult.response != '') {
  //                     this.restrickey=this.checkresult.response[0];
  //                   if(this.restrickey.restricted=="1") {
  //                         swal("you are restricted to see the patient details");
  //                   } else{
  //                         this.pins();
  //                         sessionStorage.setItem("full_name",this.name.full_name);
  //                         sessionStorage.setItem("patient_id",this.name.patient_id);
  //                   }
  //             } else{
  //                 this.pins();
  //             }
  //     }
  //   });
  //  }


   changepass(): void {
    const dialogRef = this.dialog.open(ChangepassComponent, {
      width: '250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    // this.login();
    });

  }


  logo(){
    localStorage.setItem('/login','');
    //localStorage.clear();
    this.auth.logout()
    this.router.navigate(['/login2'])

  }
}
