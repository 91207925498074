import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { MzModalService } from 'ngx-materialize';
import { ChangepassComponent } from '../changepass/changepass.component';
import { SignaturelabComponent } from '../medicaldetails/signaturelab/signaturelab.component';
import { LabeditComponent } from '../pages/labedit/labedit.component';
import { PinModelComponent } from '../pages/pin-model/pin-model.component';
import { AuthService } from '../services/auth.service';
import { UserPinModelComponent } from '../user-pin-model/user-pin-model.component';

@Component({
  selector: 'app-user-billing',
  templateUrl: './user-billing.component.html',
  styleUrls: ['./user-billing.component.css']
})
export class UserBillingComponent implements OnInit {
  userData: any;
  reg: boolean;
  lab_id: any;
  result: any;
  lab_phone: any;
  constructor(
    private router: Router, 
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    private modalService: MzModalService,
  ) { 
    this.userData= JSON.parse(sessionStorage.getItem("userData"));
    console.log("this.userData" ,this.userData)
  this.lab_id=sessionStorage.getItem("lab_id");
  this.lab_phone= sessionStorage.getItem("lab_phone");
  console.log( 123,this.userData);
  if(sessionStorage.getItem("ptypepop")=="3")
  
  {
    this.pin1();
  }
  }

  ngOnInit() {
  }
  openServiceModal(): void {
    const dialogRef = this.dialog.open(LabeditComponent, {
      width: '250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed'+result);
if(result!=1)
{
     this.login();
}
    });
  }

  changepass(): void {
    const dialogRef = this.dialog.open(ChangepassComponent, {
      width: '250px', 
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      alert(result);
     this.login();
    });
  }



  login() {
    sessionStorage.setItem('token', "res.response.token");
   
      this.auth.labEdit({lab_id:this.lab_id}).subscribe(res => {
        this.result=res;
        
        sessionStorage.setItem("userData",JSON.stringify(this.result.response[0]))
            this.userData=this.result.response[0];
            console.log(32323,this.result)

      
     }, () => {
 
    });
  }
 
  pin1() {
      sessionStorage.setItem("ptypepop","0");
       let dialogRef = this.dialog.open(UserPinModelComponent, {
          width: '600px',
      });
      dialogRef.afterClosed().subscribe(result => {
    
      });
  }  
  regi()
  {
    this.reg=true;
  }


  signature()  {
     let dialogRef = this.dialog.open(SignaturelabComponent, {
        width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
  
    });
} 
logout(){
  this.router.navigateByUrl('/new-lab');
}

}
