import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { LabviewComponent } from '../labview/labview.component';
import { MatDialog } from '@angular/material';
import { PrintComponent } from 'src/app/vedio/print/print.component';
declare var swal;
@Component({
  selector: 'app-labvisits',
  templateUrl: './labvisits.component.html',
  styleUrls: ['./labvisits.component.css']
})
export class LabvisitsComponent implements OnInit {
  formatsDateTest: string[] = [
    'MM/dd/yyyy',
    ];
  patient_id: any;
  visits: any;
  userData: any;
  usersData:any;
  child_name: any;
  full_name: any;
  searchOptions = {
    year:"",
    child_name:""
    
   };
   config:any;
  sechild_name:any;
  res: any;
  phone_no: any;
  lablis: any;
  name: any;
  names: any;
  years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  seyear:any;
  doc_date: any;
  reports: any;
  lab_city: any;

  constructor(private router: Router, 
    private formBuilder: FormBuilder,    public dialog: MatDialog,

    protected auth: AuthService,
    protected route: ActivatedRoute) {
      this.usersData=JSON.parse(sessionStorage.getItem("userData"));
      this.full_name=sessionStorage.getItem("full_name");
      this.patient_id= sessionStorage.getItem("patient_id");;
      this.phone_no= sessionStorage.getItem("phone_no");
      // this.patientlist();
      this.seyear="";
      this.sechild_name="";
      this.Search();
      this.list();

     }

  ngOnInit() {

    window.scrollTo(0,0)
this.visits = [];
this.userData=[];
   
    
  }

  logout(){
    this.router.navigateByUrl('/login');
  }



  labData(){
    this.auth.labupdate({patient_id:this.patient_id}).subscribe(res => {  
      if (res.status == "success") {
         this.visits = res.response.reverse();
        console.log(34,res.response)


        this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };

     }
     
    },() => {
      swal("patientlist detils not exits ");
     });
   }

   pageChanged(event){
    this.config.currentPage = event;
  }


   list()
   {
    
      // this.labupdateForm .value.docter_id=this.docter_id;
       this.auth.findchild( {patient_id:this.patient_id}).subscribe(res => {
         
         if (res.status == "success") {
           if (res.response != "") {
          this.userData=res.response;
          
            // sessionStorage.setItem("full_name",this.name);
            // this.router.navigateByUrl("/patientsearch");
           }
        
         }
       },
          
    
        //   () => {
        //  alert("Login failed, Login detils not exits ");
        // }
        );
      }
   lablist(){
      this.auth.lablist().subscribe(res => {
            if (res.status == "success") {
              this.lablis=res.response 
              //  this.list();
          }
      }, () => { 
      }
      );
    }
 
    Search(){
 
      
        this.auth.labhistory({"year":this.seyear,"patient_id":this.patient_id,"child_name":this.sechild_name}).subscribe(res => {
          if (res.status == 'success') {
        
      
            this.visits = res.response.reverse();
            console.log(39,this.res);
            this.config = {
              itemsPerPage: 5,
              currentPage: 1,
              totalItems: this.visits.count
            };
            this.lab_city = res.response[0].lab_city;
          }
      
        //  this.visits = res.response.reverse();
        }, console.log);
       }


       report(tid,docid,doc_date,complete,range,child_name,timeStamp,test_date,docter_name,patient_id,patient_name,gender,age,phone_no,lab_name,lab_city,sig,user_name) {
        this.doc_date=doc_date;
        this.auth.test_report({
          complete:complete,
          test_name:tid,
          patient_id:patient_id,
          docter_id:docid,
          doc_date:this.doc_date,
          range:range?range:"",child_name:child_name?child_name:"",
          timeStamp:timeStamp,
          user_name:user_name,
          city:this.lab_city}).subscribe(res => {
          this.reports=res;
          if (this.reports.status == "success") {
            // city:this.lab_city,
              this.reportview({
                reports:this.reports.response,
                child_name:child_name,
                testname:tid,doc_date:doc_date,
                date:test_date,
                docter_name:docter_name,
                patient_name:patient_name,
                gender:gender,
                age:age,
                phone_no:phone_no,
                lab_name:lab_name, 
                lab_city,
                sig:sig,
                user_name:user_name});
            }
        }, (err) => {
            swal("server failed, server details not exits ");
         });
       }

       reportview(e) {
        const dialogRef = this.dialog.open(PrintComponent, {
          width: '250px',
          data: e
        });
        dialogRef.afterClosed().subscribe(result => {
  let vr=this.visits;
  this.visits=vr;


          console.log('The dialog was closed');
                     });
      
      }
      logo(){
        this.router.navigate(['/login']);
      }

  
}
