import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-dtr-status',
  templateUrl: './dtr-status.component.html',
  styleUrls: ['./dtr-status.component.css']
})
export class DtrStatusComponent implements OnInit {
sts:any;
resultres: any;
response: any;
  constructor(public http:HttpClient,
    private fb: FormBuilder,private router: Router, 
    private formBuilder: FormBuilder,
    protected auth: AuthService,
    protected route: ActivatedRoute,
    public dialog: MatDialog) {


    }
    
  ngOnInit() {
    window.scrollTo(0,0)
    // setInterval(()=>{

      // this.http.post('http://13.233.122.108:4200/doctor_online/findonlineDoctor','').subscribe(res=>{
      //   console.log(res['response']);
      //   this.sts=res['response'];
      // })
    // },3500);
   //
   var speciality =sessionStorage.getItem('speciality');
   this.dtrProfile(speciality);
   console.log(speciality);
   

   window.scrollTo(0,0)
  }
   

  dtrProfile(speciality){
    console.log(speciality);
    
    this.auth.doctorProfile({speciality}).subscribe(res=>{ 
      //this.resultres = res.response;
      this.sts=res['response'];
      if( this.resultres .status == "success"){
       console.log(this.response);
      //  console.log('45454', this.response);
      }
    })
  }


  logo(){
    sessionStorage.clear();
    localStorage.clear();
      this.router.navigateByUrl('/login');
  }

  consultNow(x){
    console.log(1 ,x);
    this.router.navigate(['services'], { state: { x: x } });
    //this.router.navigate(['services'],{queryParams: {"user": JSON.stringify(x)} });
              sessionStorage.setItem("services", JSON.stringify(x));
  }
}
