import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup,Validators, } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { DatePipe } from '@angular/common';
declare var swal;
import { LabresultUpdateComponent }  from '../labModel/labresult-update/labresult-update.component';
import { TestModelComponent } from '../labModel/assignTest/test-model.component';
import { MatDialogModule, MatDialog} from '@angular/material/dialog';
import { ResultLabComponent } from '../result-lab/result-lab.component';
import { PrintComponent } from 'src/app/vedio/print/print.component';
import { EditassignComponent } from '../labModel/editassign/editassign.component';
@Component({
  selector: 'app-labtestresult',
  templateUrl: './labtestresult.component.html',
  styleUrls: ['./labtestresult.component.css']
})
export class LabtestresultComponent implements OnInit {
  labupdateForm:FormGroup;
  userData: any;
  patient_id: string;
  name: string;
  phone_no: string;
  child_name: any;
  child: any;
  visits: any;
  patData: any;
  labData: string;
  result: any;
  test_res: string;
   years:any=[{year:2019},{year:2020},{year:2021},{year:2022},{year:2023},{year:2024},{year:2025},{year:2026},{year:2027},{year:2028}];
  searchOptions = {
    year:"",
    child_name:""
   };
   resultres: any;
   response: any;
  chidData: Object;
  lablist: any;
  test_name: any;
  doc_date: any;
  reports: any;
  dateee: any;
  lab_id: any;
  config:any;
  email: string;
  private _id: string;
  number: string;
  user_name: any;
  city: string;
  lab_name: string;
  name1: string;

  constructor(private router: Router, private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    protected auth: AuthService,
    protected route: ActivatedRoute,) {
       this.patient_id=sessionStorage.getItem("patient_id")
       console.log("email", this.patient_id);
       this.phone_no=sessionStorage.getItem("phone_no")
      this.name=sessionStorage.getItem("full_name")
      this.email=sessionStorage.getItem("email");
      this. lab_id=sessionStorage.getItem('lab_id');

      console.log("this.lab_id===>", this.lab_id)
      this.number=sessionStorage.getItem("number")


      this.city = sessionStorage.getItem("lab_city");
   console.log("name****===>", this.city);

   this.name1 = sessionStorage.getItem("name");
   console.log("name****===>", this.name1);
      this.visits="";
      this.dateee=(this.datePipe.transform(Date.now(), 'd-M-yyyy')).toString();
  
      // if( sessionStorage.getItem("lab_phone")==" " || sessionStorage.getItem("lab_phone") == undefined){
      //   this.router.navigateByUrl('/new-lab');
      // }
     
     }
    
  ngOnInit() {
    this.list();
    
      this.patientData();
      this.childNameDropDwon();
    //  sessionStorage.setItem('num','0');
    //  for(let i=1;i==Number(sessionStorage.getItem('num'));i--)
    //  {
    //   this.patientData();

    //    sessionStorage.setItem('num','1');

    //  }


    this.auth.testUpdate({lab_id:this.lab_id,status:3,payment_status:1,_id:this._id}).subscribe(res =>{
      this.resultres = res;
      if( this.resultres .status == "success"){
        
       this.response=this.resultres.response;
       console.log("this.response" , this.response)
       //console.log(res.json())
         //console.log({res.lab_id})
       // this.Contact_Name=res.response;
       // this.dataSource = new MatTableDataSource<PeriodicElement>(this.Contact_Names);
      }
     })

  }
  assignTest() {
    const dialogRef = this.dialog.open(TestModelComponent, {
      width: '850px',
      data: ''
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.search();
      // this.details(result);
    });
  
  }
  // editassignTest(item) {
  //   const dialogRef = this.dialog.open(EditassignComponent, {
  //     width: '250px',
  //     data: {
  //       test_id: item
  //     }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.search();
  //   });
  
  // }
  

  
  update(labupdateForm){
    this.labupdateForm.value.patient_id=this.patient_id;
    this.labupdateForm.value.phone_no=this.phone_no;
    this.auth.updates(labupdateForm.value).subscribe(res => {
        if (res.status == "success") {
            if (res.response != "") {
              sessionStorage.setItem("patient_id",this.userData[0].patient_id);
              //console.log("95", this.patient_id);
              // console.log("21",this.labupdateForm);
              sessionStorage.setItem("full_name",this.name);
       
            // this.router.navigateByUrl("/patientsearch");
            }
          swal("lab reports entered successfully");
          this.router.navigateByUrl("/Labinformation");
        }
    },  () => {
      swal("Lab failed, Lap detils not exits ");
    });
   
   }
   list() {
       this.auth.findchild( {patient_id:this.patient_id,phone_no:this.phone_no,child_name:this.child_name}).subscribe(res => {
          if (res.status == "success") {
            if (res.response != "") {
                this.userData=res.response;
              }
            }
       });
    }
  //
  patientData(){
    //lab_id remove
      this.auth.patientList({patient_id:this.patient_id,lab_id:this.lab_id}).subscribe(res => {
        console.log("878787", this.patient_id)
        sessionStorage.setItem("email", this.email);
        sessionStorage.setItem("name", this.name1);
          if(res.response!="" ) {
              this.visits = res.response.reverse();
              this.config = {
                itemsPerPage: 5,
                currentPage: 1,
                totalItems: this.visits.count
              };
              this.user_name = res.response[0].user_name;
              console.log("user_name====>" , this.user_name)
          } 
      })
  }



  

  details(e) {
       

          this.listbyid(e.test_name,e._id,e.status,e.docter_name,sessionStorage.getItem('name'),e.child_name,e.comment,e.date,e.timeStamp,e.gender,e.age,e.phone_no,this.city,this.name1);


      }

  
   
    
   childNameDropDwon() {
    this.auth.findchild({patient_id:this.patient_id}).subscribe(res => {
      this.child=res;
      if(res.response) {
          this.child = this.child.response;
      } 
    })
   }
  //  Search() {
  //      this.searchOptions.name;
  //      this.searchOptions.yearData;
  //      this.assignTest();
  //      console.log(323232,this.searchOptions.name,this.searchOptions.yearData)
  //  }
  search(){
   
    this.auth.patient_history({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
      if (res.status == 'success') {
       
        this.visits = (res.response).reverse() ;
     //  this.labhistory();
        console.log(39,this.visits);
        console.log(39,this.visits[0].imageUrl);
        this.config = {
          itemsPerPage: 5,
          currentPage: 1,
          totalItems: this.visits.count
        };
       
      }
      else{
        this.visits=[];
      }
    }, console.log);
   }
   
   labhistory(){
       this.auth.labhistory({year:this.searchOptions.year,patient_id:this.patient_id,child_name:this.searchOptions.child_name}).subscribe(res => {
        if (res.status == 'success') {
          this.lablist = res.response;
         
        }
        this.lablist = res.response;
       }, console.log);
     }

   

     

   listbyid(test_id,list_id,sts,docter_name,lab_name,child_name,comment,doc_date,timeStamp,gender,age,phone_no,city,name1) {
         // this.labupdateForm .value.docter_id=this.docter_id;
          this.auth.testbyid( {test:test_id}).subscribe(res => {
            if (res.status == "success") {
              if (res.response != "") {
             this.test_res=res.response;
             console.log("test_id",this.test_res)
            // this.router.navigate(['/LabresultUpdateComponent'],{ queryParams:{test_id:JSON.stringify(this.test_res),_id:list_id,sts:sts,"docter_name":docter_name,"lab_name":lab_name,"child_name":child_name,comment:comment,"doc_date":doc_date,"timeStamp":timeStamp}});
              this.view({test_id:this.test_res,_id:list_id,sts:sts,"docter_name":docter_name,"lab_name":this.name1,"child_name":child_name,comment:comment,"doc_date":doc_date,"timeStamp":timeStamp,gender:gender,age:age,phone_no:phone_no,"lab_city":this.city});
            }
            }
          }, () => {
               swal("Server failed, Server details not exits ");
           });
          }

          view(e) {
            const dialogRef = this.dialog.open(LabresultUpdateComponent, {
              width: '250px',
              data: e
            });
        
            dialogRef.afterClosed().subscribe(result => {
             this.search();
            });
          
          }

          report(tid,docid,doc_date,complete,range,child_name,timeStamp,test_date,docter_name,patient_name,gender,age,phone_no,lab_name,lab_city,sig,user_name,city,name1) {
              this.doc_date=doc_date;
              this.auth.test_report({complete:complete,test_name:tid,patient_id:this.patient_id,docter_id:docid,doc_date:this.doc_date,range:range?range:"",child_name:child_name?child_name:"",timeStamp:timeStamp,user_name:user_name,city:this.city,name:this.name1}).subscribe(res => {
                this.reports=res;
                if (this.reports.status == "success") {
                    this.reportview({reports:this.reports.response,testname:tid,child_name:child_name,doc_date:doc_date,date:test_date,docter_name:docter_name,patient_name:patient_name,gender:gender,age:age,phone_no:phone_no,lab_name, lab_city:lab_city,sig:sig,user_name:user_name,city:this.city});
                  }
              }, (err) => {
                  swal("server failed, server details not exits ");
               });
             }
      //printComponent
             reportview(e) {
              const dialogRef = this.dialog.open(PrintComponent, {
                height: '800px',
                width: '1000px',
                data: e
              });
              dialogRef.afterClosed().subscribe(result => {
              let vr=this.visits;
              this.visits=vr;


                console.log('The dialog was closed');
                           });
            
            }


            pageChanged(event){
              this.config.currentPage = event;
            }


            logo(){
              localStorage.setItem('login','');
              //location.reload();

            }
            paynow(obj){

              // this.router.navigateByUrl('/user-payment');

              this.router.navigate(['user-payment'], { state: { obj: obj } });
              sessionStorage.setItem("user-payment", JSON.stringify(obj));

              this.auth.lab_user_gst({lab_id:this.lab_id}).subscribe(res=>{ 
                this.resultres = res;
     
                if( this.resultres .status == "success"){
                  
                 this.response=this.resultres.response.gst;
                
                 console.log("testname===>", this.response.gst)
                 console.log(this.response);
                //  console.log('45454', this.response);
                }
              })
            }
           
            logout() {
              // sessionStorage.clear();
              // localStorage.clear();
              this.router.navigateByUrl('/new-lab');
            };
       
     
     
//dropdown



selectePatient(){
  
 this.search();
}

}


